import {
  Box,
  Container,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import Image, { StaticImageData } from 'next/image';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TestimonyComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import IconIgComments from '@/../public/assets/icons/instagram-comment.svg';
import IconIgHeart from '@/../public/assets/icons/instagram-heart.svg';
import IconIgSave from '@/../public/assets/icons/instagram-save.svg';
import IconIgSend from '@/../public/assets/icons/instagram-send.svg';
import ImgIgAccount from '@/../public/assets/img/logo_instagram@2x.png';
import KustomString from '@/lib/kustomcms-sdk/lib/components/KustomString';
import Link from 'next/link';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useLightWidgetData from '@/hooks/useLightWidgetData';
import { useSwipeable } from 'react-swipeable';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

export interface IgPostsProps
  extends KustomPageComponentViewProps<TestimonyComponentData> {
  lightWidgetId: string;
  igUsername: string;
  igAccountLink: string;
  igProfilPic: StaticImageData;
}

const IgPosts: React.FC<IgPostsProps> = (props) => {
  const {
    page,
    component,
    lightWidgetId,
    igUsername = 'Hôtel Les Elmes & Spa',
    igAccountLink = 'https://www.instagram.com/leselmes_hotelspa/',
    igProfilPic = ImgIgAccount,
  } = props;

  const slides = component?.data?.slides;

  const mediaContainerRef = useRef<HTMLDivElement>(null);
  const [hasInteracted, setHasInteracted] = useState(false);

  const bpIndex = useBreakpointIndex();

  const [currentPostIndex, setCurrentPostIndex] = useState(0);

  const { posts: igPosts } = useLightWidgetData(lightWidgetId);

  const posts = useMemo(() => {
    return slides?.map((slide, index) => ({
      id: slide?.id,
      description: slide?.description,
      author: slide?.author,
      date: slide?.publishDate,
      mediaUrl: igPosts?.[index]?.mediaUrl,
      likes: igPosts?.[index]?.likes,
      alt: igPosts?.[index]?.alt,
      isSlider: false,
    }));
  }, [slides, igPosts]);

  const [postWidth, postHeight, postMarginRight] = useBreakpointValue({
    base: [mediaContainerRef.current?.clientWidth || 260, 480, -20],
    md: [389, 480, -20],
    lg: [389, 480, 20],
  }) as [number, number, number];

  const next = useCallback(() => {
    if (currentPostIndex === posts?.length - 1) {
      setCurrentPostIndex(0);
    } else {
      setCurrentPostIndex(currentPostIndex + 1);
    }
  }, [currentPostIndex, posts?.length]);

  const prev = useCallback(() => {
    if (currentPostIndex === 0) {
      setCurrentPostIndex(posts?.length - 1);
    } else {
      setCurrentPostIndex(currentPostIndex - 1);
    }
  }, [currentPostIndex, posts?.length]);

  useEffect(() => {
    if (!hasInteracted) {
      const interval = setInterval(() => {
        next();
      }, 4000);
      return () => clearInterval(interval);
    }
  }, [hasInteracted, next]);

  const postDist = (postWidth || 0) + (postMarginRight || 0);

  const userNext: MouseEventHandler<HTMLDivElement> = (e) => {
    setHasInteracted(true);
    // has clicked right or left to .smartphone-display
    const phoneDisplayX =
      document.querySelector('.smartphone-display')?.getBoundingClientRect()
        .x || 0;

    const hasClickedLeft = e.clientX < phoneDisplayX;
    if (hasClickedLeft) {
      prev();
    } else {
      next();
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <Box
      as="div"
      bgColor="accent.100"
      py={[28, null, null, 60]}
      pb={[20, null, null, 60]}
      onClick={userNext}
      display="flex"
      overflow="hidden"
      px={10}
    >
      <Container
        maxW="container.xl"
        position="relative"
        px={3.5}
        {...handlers}
        display={['flex', null, null, 'unset']}
        alignItems="center"
        justifyContent="center"
        zIndex={2}
      >
        <>
          <Box
            position="absolute"
            top="-20"
            left="0"
            right={0}
            display="flex"
            h="672px"
          >
            {/* SMARTPHONE DISPLAY */}
            <Box
              display={['none', null, null, 'block']}
              className="smartphone-display"
              bgColor="white"
              w="414px"
              px={3.5}
              boxShadow="0px 0px 99px #00000029"
              borderRadius="xl"
              zIndex={1}
            >
              <Link href={igAccountLink} target="_blank">
                <Box h="20" display="flex" alignItems="center">
                  <Image
                    src={igProfilPic}
                    width={42}
                    alt="instagram"
                    loading="lazy"
                  />
                  <Text
                    ml={2}
                    fontFamily="body"
                    color="black"
                    fontSize="14px"
                    fontWeight="bold"
                    as="div"
                  >
                    {igUsername}
                  </Text>
                </Box>
              </Link>
              <Box
                w={postWidth + 'px'}
                h={postHeight + 'px'}
                borderRadius="lg"
                bgColor="gray.100"
              ></Box>
              <Box px={1}>
                <Box
                  pt={5}
                  position="relative"
                  display="flex"
                  justifyContent="space-between"
                >
                  <SimpleGrid columns={3} spacing={4}>
                    <IconIgHeart />
                    <IconIgComments />
                    <IconIgSend />
                  </SimpleGrid>

                  <IconIgSave />
                </Box>
                {/* <Box py={3}>
                  <Text
                    fontFamily="body"
                    color="black"
                    fontSize="13px"
                    fontWeight="bold"
                  >
                    {posts[currentPostIndex]?.likes} likes
                  </Text>
                </Box> */}
              </Box>
            </Box>
            <Box
              position="relative"
              zIndex={2}
              w="100%"
              h={[
                postHeight + 'px',
                null,
                null,
                (postHeight || 0) + 110 + 'px',
              ]}
              mt={20}
            >
              <Box
                top={`calc(-100% * ${currentPostIndex})`}
                h="100%"
                position="relative"
                transition="top 0.4s ease"
              >
                {slides?.map((slide, index) => (
                  <IgPostsSlide
                    key={slide.id + index}
                    page={page}
                    component={component}
                    isActiveSlide={index === currentPostIndex}
                    slideIndex={index}
                    postWidth={postWidth}
                    postHeight={postHeight}
                  />
                ))}
              </Box>
            </Box>
          </Box>
          <Box flexShrink={0} ref={mediaContainerRef} w={postWidth}>
            <Box
              pr={20}
              display="flex"
              flexWrap="nowrap"
              position="relative"
              zIndex={1}
              transition="left 0.4s ease"
              left={-postDist * currentPostIndex + 'px'}
            >
              {posts?.map((post, index) => (
                <Box
                  key={post.id + index}
                  w={postWidth + 'px'}
                  h={postHeight + 'px'}
                  flexShrink={0}
                  position="relative"
                  overflow={'hidden'}
                  mr={[postMarginRight + 'px']}
                  transition="all 0.4s ease"
                  borderRadius="lg"
                  bgColor="accent.100"
                  transform={[
                    `scale(${index === currentPostIndex ? 1.03 : 0.7})`,
                    null,
                    null,
                    'scale(1)',
                  ]}
                >
                  {post.mediaUrl && (
                    <Image
                      alt={post.alt || ''}
                      loading="lazy"
                      src={post.mediaUrl}
                      fill
                      sizes="(max-width: 959px) 80vw, (max-width: 1919px) 30vw, 640px"
                      style={{
                        objectFit: 'cover',
                        opacity:
                          bpIndex < 3
                            ? 0.15
                            : index === currentPostIndex
                            ? 1
                            : 0.15,
                      }}
                    />
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </>
      </Container>
    </Box>
  );
};

interface IgPostsSlideProps
  extends KustomPageComponentViewProps<TestimonyComponentData> {
  postWidth: number;
  postHeight: number;
  isActiveSlide: boolean;
  slideIndex: number;
}

const IgPostsSlide: React.FC<IgPostsSlideProps> = (props) => {
  const { page, component, isActiveSlide, postWidth, postHeight, slideIndex } =
    props;

  const { currentLang } = useContext(LanguageContext);

  const descBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.description`,
  );

  const authorBind = useKustomData<string>(
    page,
    component,
    `data.slides.${slideIndex}.author`,
  );

  const publishDateBind = useKustomData<string>(
    page,
    component,
    `data.slides.${slideIndex}.publishDate`,
  );

  return (
    <Box
      m="auto"
      p={[3, null, null, 0]}
      pl={[3, null, null, 40]}
      h={[postHeight + 'px', null, null, postHeight + 110 + 'px']}
      display="flex"
      alignItems="center"
      opacity={isActiveSlide ? 1 : 0}
      transition="opacity 0.4s ease"
      w={[postWidth, null, null, 'unset']}
    >
      <Box h="20" display={['none', null, null, 'block']}></Box>
      <Box
        h={[null, null, null, postHeight + 'px']}
        display="flex"
        position="relative"
        flexDir="column"
        justifyContent="center"
        maxW={[null, null, null, '50vw']}
        wordBreak="break-word"
      >
        <Box
          h={['auto', null, null, postHeight + 'px']}
          alignItems="center"
          display="flex"
          mb={5}
        >
          <RichText
            color="brand.500"
            fontFamily="jhaSemilight"
            lineHeight={['40px', null, null, null, '65px']}
            fontSize={['40px', null, null, null, '50px']}
            {...descBind}
            as="div"
          />
        </Box>
        <Box
          fontSize={['20px', null, null, '32px']}
          display={['block', null, 'flex']}
          position="relative"
          top={[0, null, 7]}
          fontFamily="jhaSemilight"
        >
          <KustomString
            color="brand.500"
            display="inline-block"
            flexShrink={0}
            {...authorBind}
            as="div"
          />
          <KustomString
            color="brand.500"
            ml={5}
            display="inline-block"
            flexShrink={0}
            {...publishDateBind}
            as="div"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default IgPosts;
