export const getCSSDashedBorder = (color: string, borderWidth = '1px') => ({
  // backgroundImage: `repeating-linear-gradient(0deg, ${color}, ${color} 12px, transparent 12px, transparent 18px, ${color} 18px), repeating-linear-gradient(90deg, ${color}, ${color} 12px, transparent 12px, transparent 18px, ${color} 18px), repeating-linear-gradient(180deg, ${color}, ${color} 12px, transparent 12px, transparent 18px, ${color} 18px), repeating-linear-gradient(270deg, ${color}, ${color} 12px, transparent 12px, transparent 18px, ${color} 18px)`,
  // backgroundSize: `${borderWidth} 100%, 100% ${borderWidth}, ${borderWidth} 100% , 100% ${borderWidth}`,
  // backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
  // backgroundRepeat: 'no-repeat',
  // border: `${borderWidth} solid transparent`,

  _before: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    PointerEvents: 'none',
    backgroundImage: `repeating-linear-gradient(0deg, ${color}, ${color} 12px, transparent 12px, transparent 18px, ${color} 18px), repeating-linear-gradient(90deg, ${color}, ${color} 12px, transparent 12px, transparent 18px, ${color} 18px), repeating-linear-gradient(180deg, ${color}, ${color} 12px, transparent 12px, transparent 18px, ${color} 18px), repeating-linear-gradient(270deg, ${color}, ${color} 12px, transparent 12px, transparent 18px, ${color} 18px)`,
    backgroundSize: `${borderWidth} 100%, 100% ${borderWidth}, ${borderWidth} 100% , 100% ${borderWidth}`,
    backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
    backgroundRepeat: 'no-repeat',
  },
});
