import { Box, BoxProps } from '@chakra-ui/react';
import LanguageContext, { LanguageContextKey } from '../contexts/language';
import { useContext, useState } from 'react';

// import EditRichTextModal from '../admin/components/EditRichTextModal';
import { KustomTranslatedString } from '../types';
import WrapIf from './atomics/WrapIf';
import dynamic from 'next/dynamic';
import getCSSAdminWysiwygItem from '../admin/helpers/getCSSAdminWysiwygItem';
import { useKustomSelector } from '../admin/hooks/useKustomSelector';

const DynamicEditRichTextModal = dynamic(
  () => import('../admin/components/EditRichTextModal'),
);

interface RichTextProps extends Omit<BoxProps, 'children' | 'onChange'> {
  value?: KustomTranslatedString;
  children?: KustomTranslatedString;
  onChange?: (value: KustomTranslatedString) => void;
}

const RichText = (props: RichTextProps) => {
  const { value: _text, children, as, onChange, ...boxStyle } = props;

  const language = useContext(LanguageContext);
  const { user } = useKustomSelector((state) => state.app);

  const hasWysiwygEdit = !!(user && onChange);

  const text = children ||
    _text || {
      fr: '',
      en: '',
      es: '',
    };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const lang = (language.currentLang || 'fr') as LanguageContextKey;

  const _renderString = text[lang] || text.fr;

  // const renderString = as
  //   ? _renderString
  //       .replace(/^<[a-z]+>/g, `<${as}>`)
  //       .replace(/<\/[a-z]+>$/g, `</${as}>`)
  //   : _renderString;

  return (
    <WrapIf
      condition={hasWysiwygEdit}
      wrapper={(children) => (
        <DynamicEditRichTextModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          value={_renderString}
          onChange={(newValue) => {
            onChange?.({
              ...text,
              [lang]: newValue,
            });
          }}
        >
          {children}
        </DynamicEditRichTextModal>
      )}
    >
      <Box
        {...boxStyle}
        {...getCSSAdminWysiwygItem({
          hasWysiwygEdit,
          isWysiwygEditOpen: isModalOpen,
          setIsOpen: setIsModalOpen,
        })}
        as={as}
        dangerouslySetInnerHTML={{ __html: _renderString }}
      />
    </WrapIf>
  );
};

export default RichText;
