import {
  Box,
  Button,
  Container,
  Heading,
  SimpleGrid,
  Text,
  VStack,
  chakra,
  useTheme,
} from '@chakra-ui/react';
import {
  JobOfferComponentData,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  contractTypes,
  workTimes,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext } from 'react';

import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import { Parallax } from 'react-scroll-parallax';
import RecruitmentForm from './RecruitmentForm';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';

import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useKustomData, {
  useKustomTranslation,
} from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import { SvgShape } from '../SvgShape';

const RecruitmentCard: React.FC<
  KustomPageComponentViewProps<JobOfferComponentData>
> = (props) => {
  const { component } = props;
  const theme = useTheme();
  const data = component.data;

  const { currentLang } = useContext(LanguageContext);

  const contract = useKustomTranslation('recruitment_contract')?.value?.[
    currentLang
  ];
  const place = useKustomTranslation('recruitment_place')?.value?.[currentLang];
  const workTimeText = useKustomTranslation('recruitment_workTime')?.value?.[
    currentLang
  ];
  const apply = useKustomTranslation('recruitment_apply')?.value?.[currentLang];

  const { establishment } = useContext(PagesContext);

  const location = establishment?.recruitmentCities?.find(
    (city) => city.id === component.data.locationId,
  );

  const bpIndex = useBreakpointIndex();

  const postulate = () => {
    if (data.buttonActionType === 'EXTERNAL_LINK') {
      window.open(data.buttonExternalLink, '_blank');
    } else if (data.buttonActionType === 'EMAIL') {
      window.location.href = `mailto:${data.buttonEmail}`;
    } else {
      const element = document.getElementById('recruitment-form-title');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const titleBind = useKustomData<KustomTranslatedString>(
    props.page,
    component,
    `data.title`,
  );

  const descBind = useKustomData<KustomTranslatedString>(
    props.page,
    component,
    `data.description`,
  );

  data.buttonLabel = {
    en: 'Apply',
    es: 'Solicitar',
    fr: 'Postuler',
    de: 'Bewerbung',
  };

  const workTime = workTimes.find((wt) => wt.value === data.workTime)?.label?.[
    currentLang
  ];

  return (
    <Box>
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        px={[6, null, 20]}
        pt={[24, null, 24]}
        pb={[6, null, 24]}
        position="relative"
        zIndex={1}
      >
        <Box
          display={['none', null, 'block']}
          position="absolute"
          top={-20}
          right={-100}
        >
          <Parallax speed={-20}>
            <SvgShape
              file="illu-poisson-2"
              width={bpIndex > 5 ? '500px' : '420px'}
              style={{
                transform: 'scale(-1, 1)',
              }}
            />
          </Parallax>
        </Box>
        <RichText
          as="h2"
          position="relative"
          fontFamily="heading"
          fontSize={['38px', null, '76px', null, null, '96px']}
          lineHeight={1}
          color="brand.500"
          mb={[10, null, 20]}
          {...titleBind}
        />
      </Container>
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        px={[0, null, 20]}
        position="relative"
      >
        <Box
          bgColor="white"
          position="relative"
          p={[6, null, 14, null, 20]}
          display="flex"
          shadow="-8px 8px 8px #00000014"
          flexDir={['column', null, 'row']}
        >
          <Box
            flex="3 1 0"
            fontSize={['18px', null, '23px', null, null, '28px']}
          >
            <RichText
              fontFamily="jhaSemilight"
              lineHeight={1.4}
              color="brand.500"
              {...descBind}
            />
          </Box>
          <Box
            flex="1 1 0"
            ml={[0, null, 10]}
            mt={[8, null, 0]}
            alignItems="left"
          >
            <SimpleGrid columns={[2, null, 1]} spacing={4}>
              <Box>
                <Heading
                  fontFamily={['jhaSemiboldItalic', null, 'jhaSemibold']}
                  fontSize={['18px', null, '20px']}
                  as="h3"
                  lineHeight={1}
                >
                  {contract}
                </Heading>
                <Text
                  color={['brand.500', null, 'accent.800']}
                  fontFamily={['jhaSemibold', null, 'jhaSemilight']}
                  fontSize={['16px', null, '24px']}
                >
                  {contractTypes.find(
                    (contractType) => contractType.value === data.contractType,
                  )?.label || '-'}
                </Text>
              </Box>
              <Box>
                <Heading
                  fontFamily="jhaSemibold"
                  fontSize={['18px', null, '20px']}
                  as="h3"
                  lineHeight={1}
                >
                  {workTimeText}
                </Heading>
                <Text
                  color={['brand.500', null, 'accent.800']}
                  fontFamily="jhaSemilight"
                  fontSize={['16px', null, '24px']}
                >
                  {workTime ? <>{workTime}</> : '-'}
                </Text>
              </Box>
              <Box>
                <Heading
                  fontFamily="jhaSemibold"
                  fontSize={['18px', null, '20px']}
                  as="h3"
                  lineHeight={1}
                >
                  {place}
                </Heading>
                <Text
                  color={['brand.500', null, 'accent.800']}
                  fontFamily="jhaSemilight"
                  fontSize={['16px', null, '24px']}
                >
                  {location?.name || '-'}
                </Text>
              </Box>
            </SimpleGrid>
            <Button
              mt={8}
              variant="brand"
              size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
              rightIcon={
                <chakra.span
                  fontSize={['42px', null, '56px']}
                  className="icon-elmes-arrow1-white"
                />
              }
              onClick={postulate}
            >
              {data.buttonLabel ? (
                <TranslatedString color="white">
                  {data.buttonLabel}
                </TranslatedString>
              ) : (
                'Postuler'
              )}
            </Button>
          </Box>
        </Box>
      </Container>
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        px={[6, null, 20]}
        position="relative"
        zIndex={1}
      >
        <Box position="relative">
          <Box display={['block']} position="absolute" left={-350} top={-100}>
            <Parallax speed={-20}>
              <SvgShape
                file="illu-coquillage-4"
                width={bpIndex > 5 ? '400px' : '300px'}
              />
            </Parallax>
          </Box>
          <Heading
            id="recruitment-form-title"
            as="h2"
            position="relative"
            fontFamily="heading"
            fontSize={['38px', null, '76px', null, null, '96px']}
            lineHeight={1}
            color="brand.500"
            my={20}
            mb={[0, null, 20]}
          >
            {apply}
          </Heading>
        </Box>
        <RecruitmentForm mt={8} position="relative" />
      </Container>
    </Box>
  );
};

export default RecruitmentCard;
