const { map, isArray, isObject, isPlainObject } = require('lodash');

async function mapValuesAsync(object: any, asyncFn: any) {
  return Object.fromEntries(
    await Promise.all(
      Object.entries(object).map(async ([key, value]) => [
        key,
        await asyncFn(value, key, object),
      ]),
    ),
  );
}

const mapValuesDeepAsync = async (
  obj: any,
  fn: (value: any, key: string | number) => any,
  key?: string | number,
) => {
  return isArray(obj)
    ? await Promise.all(
        map(
          obj,
          async (innerObj: any, idx: number) =>
            await mapValuesDeepAsync(innerObj, fn, idx),
        ),
      )
    : isPlainObject(obj)
    ? await fn(
        await mapValuesAsync(
          obj,
          async (val: any, key: string) =>
            await mapValuesDeepAsync(val, fn, key),
        ),
        key || '',
      )
    : isObject(obj)
    ? obj
    : fn(obj, key || '');
};

export default mapValuesDeepAsync;
