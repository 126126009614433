import {
  Box,
  BoxProps,
  Fade,
  IconButton,
  Portal,
  Spinner,
  chakra,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

interface PersistantModalProps {
  modalStyle?: BoxProps;
  containerStyle?: BoxProps;
  isOpen: boolean;
  isLoading?: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const PersistantModal: React.FC<PersistantModalProps> = (props) => {
  const {
    modalStyle,
    containerStyle,
    children,
    isOpen: isOpenProps,
    isLoading,
    onClose,
  } = props;

  const [isDisplayed, setIsDisplayed] = useState(isOpenProps);
  const [isOpen, setIsOpen] = useState(isOpenProps);

  useEffect(() => {
    if (isOpen) {
      setIsDisplayed(true);
    } else {
      setTimeout(() => {
        setIsDisplayed(false);
        onClose();
      }, 500);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(isOpenProps);
  }, [isOpenProps]);

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Portal>
      <Box
        position="fixed"
        display={isDisplayed ? 'flex' : 'none'}
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={99999}
      >
        <Fade in={isOpen} unmountOnExit={false}>
          <Box
            className="modal-overlay"
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            display={isDisplayed ? 'flex' : 'none'}
            bgColor="rgba(0,0,0,0.7)"
            justifyContent="center"
            onClick={(e) => {
              if (e.target === e.currentTarget) close();
            }}
            {...containerStyle}
          >
            <Box
              className="modal-container"
              position="relative"
              bgColor="white"
              shadow="base"
              borderRadius="base"
              w="100%"
              mt={[0, null, 20]}
              {...modalStyle}
            >
              <Box
                position="absolute"
                top={1}
                right={1}
                display="flex"
                alignItems="center"
              >
                {isLoading && <Spinner size="sm" color="brand.500" mr={2} />}
                <IconButton
                  aria-label="Close"
                  fontSize={['12px']}
                  p={[5, null, null, 0]}
                  py={[6, null, null, 0]}
                  bgColor="transparent"
                  _hover={{
                    bgColor: 'gray.100',
                  }}
                  icon={
                    <chakra.span
                      className="icon-diadao-cross1"
                      fontSize="14px"
                    />
                  }
                  onClick={close}
                />
              </Box>
              <Box position="relative" w="100%" h="100%" overflowY="auto">
                {children}
              </Box>
            </Box>
          </Box>
        </Fade>
      </Box>
    </Portal>
  );
};

export default PersistantModal;
