import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  chakra,
} from '@chakra-ui/react';
import { Media, MediasUsage, mediasActions } from '../../features/medias/slice';
import { useContext, useMemo, useState } from 'react';

import AdminThemeProvider from '../AdminThemeProvider';
import PagesContext from '../../../contexts/pages';
import WrapIf from '../../../components/atomics/WrapIf';
import moment from '../../helpers/date';
import { toHumanFileSize } from '../../helpers/media';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useKustomSelector } from '../../hooks/useKustomSelector';

interface MediasPropertiesProps {
  medias: Media[];
}

const MediasProperties = (props: MediasPropertiesProps) => {
  const { medias } = props;
  const dispatch = useAppDispatch();

  const [isMediaUsageModalOpen, setIsMediaUsageModalOpen] = useState(false);

  const media = medias[0];

  const { mediasUsage } = useKustomSelector(({ medias }) => medias);

  const usedNTimes = media
    ? Object.values(mediasUsage?.[media.metadata._id] || {}).reduce(
        (acc, cur) => acc + cur,
        0,
      )
    : 0;

  return (
    <Box
      width="100%"
      backgroundColor="white"
      borderRadius="base"
      boxShadow="0px 0px 21px #00000012"
      p={6}
    >
      <Text textStyle="text" fontWeight={700} fontSize="16px" mt={1} mb={5}>
        Propriétés
      </Text>
      <Box>
        {medias.length === 1 ? (
          <>
            {media?.metadata?.key && (
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Text fontSize="14px" color="gray.500">
                  Nom du fichier :
                </Text>
                <Box
                  display="flex"
                  flexGrow={1}
                  ml={3}
                  // maybe fix this hard coded width
                  maxW={60}
                  flex={1}
                  overflow={'hidden'}
                  textAlign="right"
                >
                  <Text
                    as="span"
                    textAlign="right"
                    fontSize="14px"
                    color="gray.500"
                    maxW="100%"
                    ml="auto"
                  >
                    {media.metadata.key}
                  </Text>
                </Box>
              </Box>
            )}
            {media?.metadata?.updatedAt && (
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Text fontSize="14px" color="gray.500">
                  Ajouté le :
                </Text>
                <Text fontSize="14px" color="gray.500">
                  {moment(+media?.metadata.updatedAt).format(
                    'D MMMM YYYY Ã  H:mm',
                  )}
                </Text>
              </Box>
            )}
            {media?.metadata?.width && (
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Text fontSize="14px" color="gray.500">
                  Dimensions :
                </Text>
                <Text fontSize="14px" color="gray.500">
                  {media?.metadata.width} x {media?.metadata.height}
                </Text>
              </Box>
            )}
            {media?.metadata?.size && (
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Text fontSize="14px" color="gray.500">
                  Poids :
                </Text>
                <Text fontSize="14px" color="gray.500">
                  {toHumanFileSize(+media?.metadata.size)}
                </Text>
              </Box>
            )}
            {media?.metadata?.duration && (
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Text fontSize="14px" color="gray.500">
                  Durée :
                </Text>
                <Text fontSize="14px" color="gray.500">
                  {media?.metadata.duration}
                </Text>
              </Box>
            )}
            {typeof media?.metadata?.autoplay === 'boolean' && (
              <Box display="flex" justifyContent="space-between" mb={1}>
                <Text fontSize="14px" color="gray.500">
                  Lecture automatique :
                </Text>
                <Switch
                  isChecked={media?.metadata.autoplay}
                  ml={2}
                  id="visibility"
                  onChange={(e) => {
                    dispatch(
                      mediasActions.updateMetadata({
                        id: media?.metadata._id,
                        metadata: {
                          autoplay: e.target.checked + '',
                        },
                      }),
                    );
                  }}
                />
              </Box>
            )}
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Text fontSize="14px" color="gray.500">
                Utilisé sur le site :
              </Text>
              <WrapIf
                condition={usedNTimes > 0}
                wrapper={(children) => (
                  <Button
                    variant="link"
                    color="brand.500"
                    onClick={() => {
                      setIsMediaUsageModalOpen(true);
                    }}
                  >
                    {children}
                  </Button>
                )}
              >
                <Text fontSize="14px">{usedNTimes} fois</Text>
              </WrapIf>
            </Box>
          </>
        ) : (
          <Text fontSize="14px" color="gray.500">
            {medias.length} items sélectionnés
          </Text>
        )}
      </Box>
      {mediasUsage && media && (
        <MediaUsageModal
          isOpen={isMediaUsageModalOpen}
          onClose={() => setIsMediaUsageModalOpen(false)}
          mediaUsage={mediasUsage[media.metadata._id]}
        />
      )}
    </Box>
  );
};

export default MediasProperties;

interface MediaUsageModalProps {
  isOpen: boolean;
  onClose: () => void;
  mediaUsage?: MediasUsage['key'];
}

const MediaUsageModal: React.FC<MediaUsageModalProps> = (props) => {
  const { isOpen, onClose, mediaUsage } = props;

  const pagesCtx = useContext(PagesContext);

  const pages = pagesCtx.pages;

  const kustomUrl = pagesCtx.kustomClient?.APP_URL;

  const total = Object.values(mediaUsage || {}).reduce(
    (acc, cur) => acc + cur,
    0,
  );

  const clientUrl = useMemo(() => {
    if (typeof window !== 'undefined') {
      return window.location.hostname;
    }
    return '';
  }, [typeof window]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent pt={5} pb={3}>
        <AdminThemeProvider>
          <ModalHeader>
            <Heading fontSize="28px" textStyle="heading">
              Média Utilisé sur le site
            </Heading>
          </ModalHeader>
          <ModalCloseButton top={9} />
          <ModalBody>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th
                      pl={2}
                      textTransform="capitalize"
                      color="brand.850"
                      fontFamily="Nunito"
                      letterSpacing="normal"
                    >
                      Total ({total})
                    </Th>
                    <Th></Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {mediaUsage &&
                    Object.entries(mediaUsage).map(([pageId, count], index) => {
                      const page = pages.find((p) => p._id === pageId);

                      if (!page) {
                        return null;
                      }

                      const isLastLine =
                        index === Object.values(mediaUsage).length - 1;

                      return (
                        <Tr key={pageId}>
                          <Td
                            fontSize="14px"
                            color="gray.500"
                            pl={2}
                            borderBottom={isLastLine ? 'none' : undefined}
                          >
                            {page.title.fr} ({count})
                          </Td>
                          <Td borderBottom={isLastLine ? 'none' : undefined}>
                            <Button
                              variant="link"
                              color="brand.500"
                              textTransform={'uppercase'}
                              isDisabled={!clientUrl}
                              onClick={() =>
                                window.open(
                                  (clientUrl || '') + '/' + page.prettyUrl?.fr,
                                )
                              }
                            >
                              Voir la page en front
                            </Button>
                          </Td>
                          <Td borderBottom={isLastLine ? 'none' : undefined}>
                            <chakra.a
                              href={kustomUrl + `/sitemanagement/${pageId}`}
                              target="_blank"
                            >
                              <Button
                                variant="link"
                                textTransform={'uppercase'}
                                color="brand.500"
                                mx={2}
                              >
                                Voir la page en back
                              </Button>
                            </chakra.a>
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter justifyContent="flex-start">
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </AdminThemeProvider>
      </ModalContent>
    </Modal>
  );
};
