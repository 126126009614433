import { KustomSettings, KustomTranslatedString } from '../types';
import { createContext, useCallback, useMemo, useState } from 'react';

import { KustomClient } from '..';
import runToast from '../admin/helpers/runToast';
import { useToast } from '@chakra-ui/react';

type TranslationsKeyMapped = { [key: string]: KustomTranslatedString };

interface SettingsContextType {
  settings?: KustomSettings;
  translations?: TranslationsKeyMapped;
  updateTranslation: (key: string, newValue: KustomTranslatedString) => void;
}

const SettingsContext = createContext<SettingsContextType>({
  settings: undefined,
  translations: undefined,
  updateTranslation: () => {},
});

interface SettingsContextProviderProps {
  children: React.ReactNode;
  settings?: KustomSettings;
  kustomClient?: KustomClient;
}

export const SettingsContextProvider = (
  props: SettingsContextProviderProps,
) => {
  const { children, settings: settingsProp, kustomClient } = props;

  const toast = useToast();
  const [settings, setSettings] = useState(settingsProp);

  const translations = useMemo(
    () =>
      settings?.translations?.translations.reduce((acc, tr) => {
        acc[tr.key] = tr;
        return acc;
      }, {} as TranslationsKeyMapped),
    [settings?.translations?.translations],
  );

  const updateTranslation = useCallback(
    async (key: string, newValue: KustomTranslatedString) => {
      const newTranslations = settings?.translations?.translations.map((tr) =>
        tr.key === key ? { key, ...newValue } : tr,
      );

      try {
        const newSettings = await kustomClient?.app
          .service('establishments-settings')
          .patch(settings?._id, {
            translations: {
              translations: newTranslations,
            },
          });

        setSettings(newSettings);

        runToast({
          toast,
          title: 'Composant mis à jour avec succès',
        });
      } catch (e: any) {
        console.error(e);
        runToast({
          toast,
          title: 'Erreur lors de la mise à jour du composant',
          content: e?.message,
          type: 'error',
        });
      }
    },
    [],
  );

  return (
    <SettingsContext.Provider
      value={{ settings, translations, updateTranslation }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
