import React, { useContext, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import { KustomPage } from '@/lib/kustomcms-sdk/lib/types';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';

interface DiadaoHscProps {}

const DiadaoHsc: React.FC<DiadaoHscProps> = () => {
  const { rooms } = useContext(PagesContext);

  const bpIndex = useBreakpointIndex();
  const isMobile = bpIndex < 3;

  const roomsId = rooms.reduce(
    (rooms: { id: string; name: string }[], page: KustomPage) => {
      if (page.model !== 'room') return rooms;
      const bookingId: string | null =
        page.components.find((component) => component.type === 'roomBooking')
          ?.data.systemData.roomCode || null;

      // TEST MEWS PROV
      // const bookingId: string | null =
      //   page.components.find((component) => component.type === 'roomBooking')
      //     ?.data.systemData.tcode || null;

      if (bookingId) rooms.push({ id: bookingId, name: page.title.fr });
      return rooms;
    },
    [],
  );

  useEffect(() => {
    const body = document.querySelector('body');
    if (!body) return;
    if (bpIndex < 3) {
      body.classList.add('device-responsive');
      body.classList.add('device-mobile');
      body.classList.remove('device-desktop');
    } else {
      body.classList.remove('device-responsive');
      body.classList.remove('device-mobile');
      body.classList.add('device-desktop');
    }
  }, [bpIndex < 3]);

  return (
    <>
      {/* ROOMS */}

      <Box
        id="diadao-hsw-qs-roomsticky"
        position="fixed"
        zIndex={1001}
        top={0}
      ></Box>

      {/* ROOM */}

      <Box
        position="fixed"
        id="diadao-hsc-wrapper"
        zIndex={-1}
        top={'100%'}
        opacity={0}
        pointerEvents={'none'}
      >
        {/* Booking */}

        {roomsId.map(({ id, name }, index) => (
          // <Box
          //   key={'booking-container' + id}
          //   position="fixed"
          //   zIndex={1000}
          //   left={(index + 1) * 100 + 'px'}
          //   top={index * 100 + 'px'}
          //   opacity={'0.5'}
          // >
          // {id}
          <Box
            key={'booking' + id}
            id={`diadao-hsw-qs-roomstickydetail-${id}`}
            data-ratesid={id}
            className={`diadao-hsw-qs diadao-hsw-qs-roomstickydetail`}
            data-test={name}
            sx={{
              '--hsw-mobile-display': isMobile ? 'block' : 'none',
              '--hsw-desktop-display': isMobile ? 'none' : 'block',
            }}
            // border="5px solid red"
          ></Box>
          // </Box>
        ))}

        {/* Best price */}

        {roomsId.map(({ id, name }, index) => (
          <Box
            key={'bestPrice-container' + id}
            className="diadao-room-item"
            data-ratesid={id}
            // border="3px solid orange"
            // opacity={'0.5'}
            // position="fixed"
            // zIndex={1001}
            // top={index * 40 + 'px'}
            // right={'50px'}
          >
            <Box
              // TEST best price
              key={'bestPrice' + id}
              data-room={id}
              className="diadao-hsw-qs diadao-hsw-qs-bestprice"
              id={`diadao-hsw-qs-bestprice-${id}`}
              data-test={name}
              // border="3px solid orange"
            ></Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default DiadaoHsc;
