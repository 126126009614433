import { Box, Flex, VStack, chakra } from '@chakra-ui/react';
import {
  JobOfferComponentData,
  KustomPage,
  KustomPageComponent,
  RecruitmentCity,
  contractTypes,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext } from 'react';

import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import Link from 'next/link';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';

interface RecruitmentListItemsProps {
  recruitmentCities?: RecruitmentCity[];
  jobOffers: KustomPage[];
}

const RecruitmentListItems: React.FC<RecruitmentListItemsProps> = (props) => {
  const { recruitmentCities, jobOffers } = props;

  const { currentLang } = useContext(LanguageContext);

  return (
    <VStack my={10}>
      {jobOffers.map((jobOffer, index) => {
        const jobOfferCmp = jobOffer.components.find(
          (component) => component.type === 'jobOffer',
        );

        const {
          title,
          contractType: contractTypeValue,
          locationId,
        } = jobOfferCmp?.data as KustomPageComponent<JobOfferComponentData>['data'];

        const location = recruitmentCities?.find(
          (city) => city.id === locationId,
        );

        const contractType = contractTypes?.find(
          (type) => type.value === contractTypeValue,
        );

        return (
          <Link
            href={
              jobOffer.prettyUrl[currentLang || 'fr'] || jobOffer.prettyUrl.fr
            }
            key={jobOffer._id}
            style={{
              width: '100%',
            }}
          >
            <Box
              key={jobOffer._id}
              bgColor={index % 2 ? 'transparent' : 'white'}
              px={6}
              py={6}
              w="100%"
              position="relative"
            >
              <TranslatedString as="h3" color="brand.500">
                {title}
              </TranslatedString>
              <Flex mt={1} alignItems="center">
                <chakra.span
                  className="icon-diadao-calendar4"
                  color="accent.500"
                  fontSize="14px"
                  mr={1.5}
                ></chakra.span>
                <chakra.span
                  color="brand.500"
                  fontFamily="body"
                  fontSize="12px"
                  mr={3}
                >
                  {contractType?.label}
                </chakra.span>
                <chakra.span
                  className="icon-diadao-pin2"
                  color="accent.500"
                  fontSize="14px"
                  mr={1.5}
                ></chakra.span>
                <chakra.span
                  color="brand.500"
                  fontFamily="body"
                  fontSize="12px"
                >
                  {location?.name}
                </chakra.span>
              </Flex>
              <chakra.span
                color="brand.500"
                className="icon-diadao-arrow-right"
                position="absolute"
                right={3}
                top={'50%'}
                transform={'translateY(-50%)'}
              />
            </Box>
          </Link>
        );
      })}
    </VStack>
  );
};

export default RecruitmentListItems;
