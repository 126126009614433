import {
  ActionButtonComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Container, SimpleGrid, chakra } from '@chakra-ui/react';

import ActionButton from '@/lib/kustomcms-sdk/lib/components/ActionButton';
import { Parallax } from 'react-scroll-parallax';
import React from 'react';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';

import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { SvgShape } from '../SvgShape';

interface SeminariesClientsSectionProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {}

const SeminariesClientsSection: React.FC<SeminariesClientsSectionProps> = (
  props,
) => {
  const { component } = props;

  const {
    data: { slides, atomicComponents },
  } = component;

  const buttons = atomicComponents.filter(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  ) as ActionButtonComponent[];

  const titleBind = useKustomData<KustomTranslatedString>(
    props.page,
    component,
    `data.textTitle`,
  );

  return (
    <Box overflowX="clip">
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        position="relative"
        px={[6, null, 20]}
        pt={[20, null, 32]}
        pb={[10, null, 20]}
      >
        <TranslatedString
          fontFamily="heading"
          lineHeight={1}
          mb={[10, null, null, 20]}
          fontSize={['48px', null, '96px']}
          color="brand.500"
          {...titleBind}
          as="h2"
        />
        <Box
          p={[6, null, 16]}
          zIndex={1}
          bgColor="white"
          boxShadow="-8px 8px 8px #00000014"
          position="relative"
        >
          <SimpleGrid
            columns={[2, null, null, 4]}
            spacing={[10, null, null, 20]}
          >
            {slides.map((slide, index) => (
              <SeminariesClientsSectionItem
                key={slide.id}
                page={props.page}
                component={component}
                slideIndex={index}
              />
            ))}
          </SimpleGrid>
        </Box>
        {!!buttons.length && (
          <Box mt={10} position="relative">
            {buttons.map((button, index) => (
              <SeminariesClientsSectionButton
                key={button.id}
                page={props.page}
                component={component}
                buttonIndex={index}
              />
            ))}
          </Box>
        )}
        <Box
          display={['block']}
          position="absolute"
          right={[-40, null, 10]}
          bottom={0}
          width={[150, null, null, 300]}
        >
          <Parallax speed={-20}>
            <SvgShape file="illu-poisson-1" width={'100%'} />
          </Parallax>
        </Box>
      </Container>
    </Box>
  );
};

interface SeminariesClientsSectionItemProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  slideIndex: number;
}

const SeminariesClientsSectionItem: React.FC<
  SeminariesClientsSectionItemProps
> = (props) => {
  const { component, slideIndex } = props;

  const mediaBind = useKustomData<KustomResponsiveMedia>(
    props.page,
    component,
    `data.slides.${slideIndex}.medias`,
  );

  return (
    <Box position="relative" h="50px">
      <ResponsiveMedias
        currentDevice="desktop"
        width={undefined}
        height={undefined}
        fill
        sizes={chakraResponsiveToImageSizes(['50vw', null, null, '25vw'])}
        style={{
          opacity: 0.5,
          pointerEvents: 'none',
          background: 'transparent',
          objectFit: 'contain',
          filter: 'grayscale(100%)',
          '-webkit-filter': 'grayscale(100%)',
        }}
        {...mediaBind}
        containerProps={{
          boxSizing: 'border-box',
        }}
      />
    </Box>
  );
};

interface SeminariesClientsSectionButtonProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  buttonIndex: number;
}

const SeminariesClientsSectionButton: React.FC<
  SeminariesClientsSectionButtonProps
> = (props) => {
  const { component, buttonIndex } = props;

  const buttonBind = useKustomData<ActionButtonComponent>(
    props.page,
    component,
    `data.atomicComponents.${buttonIndex}`,
  );

  return (
    <Box mt={[4, null, 6, null, null, 8]}>
      <ActionButton
        rightIcon={
          <chakra.span
            fontSize={['42px', null, '56px']}
            className="icon-elmes-arrow1-white"
          />
        }
        {...buttonBind}
        variant="brand"
        size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
      />
    </Box>
  );
};

export default SeminariesClientsSection;
