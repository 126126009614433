import React, { useCallback, useState } from 'react';

export interface useInputBind {
  value: string;
  onChange: (e: React.SyntheticEvent) => void;
  onFocus: () => void;
  onBlur: () => void;
  placeholder?: string;
  error?: boolean | string;
}

type useInputReturn = [
  string,
  useInputBind,
  boolean,
  (s: string) => void,
  (b: boolean) => void,
];

const useInput: (
  defaultValue: string,
  placeholder?: string,
) => useInputReturn = (defaultValue, placeholder = '') => {
  const [hasError, setHasError] = useState<boolean | string>(false);
  const [value, setValue] = useState(defaultValue);
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = useCallback(() => setIsFocused(true), []);
  const onBlur = useCallback(() => setIsFocused(false), []);

  const onChange = useCallback((event: React.SyntheticEvent) => {
    setHasError(false);
    setValue((<HTMLInputElement>event.currentTarget).value);
  }, []);

  return [
    value,
    {
      value,
      onChange,
      onFocus,
      onBlur,
      ...(placeholder && { placeholder }),
      error: hasError || undefined,
    },
    isFocused,
    setValue,
    setHasError,
  ];
};

export default useInput;
