import { Box, Container, useBreakpointValue } from '@chakra-ui/react';
import {
  CarouselTextImageComponentData,
  KustomPage,
  KustomPageComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediasComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import useKustomData, {
  useKustomDataReturn,
} from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

import AnimatePresence from '../atomics/AnimatePresence';
import { Parallax } from 'react-scroll-parallax';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import Slideshow from '../atomics/Slideshow';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import { SvgShape } from '../SvgShape';

const Section3 = (
  props: KustomPageComponentViewProps<CarouselTextImageComponentData>,
) => {
  const { component, page } = props;

  const currentDevice = useResponsiveMediasDevice();

  const sketchWidth = useBreakpointValue({
    base: 200,
    lg: 360,
    '2xl': 450,
  });

  const medias1 = usePageComponent<MediasComponentData>(
    page,
    null,
    '16881304006695783',
  );

  const medias1Bind = useKustomData<KustomResponsiveMedia[]>(
    page,
    medias1,
    'data.medias',
  );

  const slidesMediasBind = [medias1Bind];

  return (
    <Box
      bgColor="accent.100"
      py={[10, null, null, 20]}
      pb={[20, null, null, 36]}
      overflowX="clip"
    >
      <Container
        maxW={['container.xl', null, null, null, null, '1780px']}
        position="relative"
        px={[6, null, 20]}
      >
        {component.data.slides.map((slide, index) => (
          <Section3Slide
            page={page}
            key={index}
            component={component}
            slideIndex={index}
            mediasBind={slidesMediasBind[index]}
            sketchWidth={sketchWidth}
          />
        ))}
      </Container>
    </Box>
  );
};

interface Section3SlideProps {
  page: KustomPage;
  component: KustomPageComponent<CarouselTextImageComponentData>;
  slideIndex: number;
  mediasBind?: useKustomDataReturn<KustomResponsiveMedia[]>;
  sketchWidth?: number;
}

const Section3Slide = (props: Section3SlideProps) => {
  const { page, component, slideIndex, mediasBind, sketchWidth } = props;

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.title`,
  );

  const descriptionBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.description`,
  );

  return (
    <Box
      mt={[5, null, null, 20]}
      display="flex"
      w="100%"
      flexDir={['column-reverse', null, null, 'row']}
      flexBasis={['100%', null, null, '663px', null, '829px']}
      position="relative"
      minH={['180px', null, null, '600px']}
      justifyContent="center"
    >
      {mediasBind?.value && (
        <Slideshow
          usingMediaViewer
          {...mediasBind}
          hideArrows
          sizes={chakraResponsiveToImageSizes([
            null,
            null,
            null,
            null,
            null,
            '1780px',
          ])}
          hideCounter
          height={['192px', null, '300px', '565px', null, '707px']}
          containerStyle={{
            width: ['100%', null, null, '663px', null, '829px'],
            borderRadius: 'base',
            mt: [8, null, null, 0],
          }}
        />
      )}
      <Box
        position={['relative', null, null]}
        mt={[null, null, 4, '36']}
        display="flex"
        flexDir="column"
        alignItems={[null, null, slideIndex % 2 ? 'center' : 'end']}
        ml={[1, null, null, 12]}
        flex={1}
        flexShrink={1}
        minW={0}
        maxW={[null, null, slideIndex % 2 ? '800px' : null]}
      >
        <AnimatePresence x={slideIndex % 2 ? -60 : 60} opacity>
          <TranslatedString
            {...titleBind}
            fontFamily="heading"
            fontSize={['38px', null, '96px', null, null, '120px']}
            mr={[null, null, null, '50px']}
            ml={[null, null, null, slideIndex % 2 ? -60 : null]}
            minW={[null, null, null, '900px']}
            mb={4}
            lineHeight="1.2"
            as="h2"
          />
        </AnimatePresence>
        <AnimatePresence x={60} opacity>
          <RichText
            maxW={['100%', null, '950px']}
            color="brand.500"
            {...descriptionBind}
            fontFamily="jhaSemilight"
            fontSize={['18px', null, '29px', null, null, '36px']}
            mb={4}
            as="p"
          />
        </AnimatePresence>
      </Box>

      {!(slideIndex % 2) && (
        <Box position="absolute" ml={10} bottom={-500} right={[0, null, 10]}>
          <Parallax speed={-20}>
            <SvgShape file="illu-poisson-1" width={`${sketchWidth}px`} />
          </Parallax>
        </Box>
      )}
    </Box>
  );
};

export default Section3;
