import { Box, SimpleGrid, chakra } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

interface ItemsGridProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  disableHeading?: boolean;
}

const ItemsGrid: React.FC<ItemsGridProps> = (props) => {
  const { component, disableHeading } = props;

  const titleBind = useKustomData<KustomTranslatedString>(
    props.page,
    component,
    `data.textTitle`,
  );

  return (
    <Box>
      <Box
        p={[6, null, 12]}
        pb={[6, null, 12]}
        zIndex={1}
        bgColor="white"
        boxShadow="-8px 8px 8px #00000014"
        position="relative"
      >
        {!disableHeading && (
          <TranslatedString
            as="h3"
            mb={[6, null, 10]}
            fontFamily="jhaSemiboldItalic"
            color="brand.500"
            fontSize={['24px', null, '33px', null, null, '42px']}
            {...titleBind}
          />
        )}
        <SimpleGrid
          columns={[1, null, 2, 3]}
          spacingX={10}
          spacingY={[3, null, 3]}
        >
          {component.data.slides.map(({ id }, index) => (
            <ItemsGridItem
              key={id}
              page={props.page}
              component={component}
              slideIndex={index}
              as={!!disableHeading ? 'h3' : 'h4'}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

interface ItemsGridItemProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  slideIndex: number;
  as?: 'h3' | 'h4';
}

const ItemsGridItem: React.FC<ItemsGridItemProps> = (props) => {
  const { page, component, slideIndex, as = 'span' } = props;

  const icon = component.data.slides[slideIndex]?.title;

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.subtitle`,
  );

  return (
    <Box display="flex" alignItems="center">
      <chakra.span
        fontSize="42px"
        className={icon?.fr || icon?.en || icon?.es}
        mr={4}
      />
      <RichText
        color="brand.500"
        fontSize={['14px', null, null, '16px', null, '20px']}
        fontFamily="jhaSemiboldItalic"
        {...textBind}
        as={as}
      />
    </Box>
  );
};

export default ItemsGrid;
