import {
  ActionButtonComponent,
  CarouselComponentSlide,
  KustomPage,
  KustomPageComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import {
  Box,
  BoxProps,
  Container,
  chakra,
  useBreakpointValue,
  useTheme,
} from '@chakra-ui/react';

import ActionButton from '@/lib/kustomcms-sdk/lib/components/ActionButton';
import { Parallax } from 'react-scroll-parallax';
import React, { useContext } from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import Slideshow from './atomics/Slideshow';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import checkTranslatedStringValues from '@/lib/kustomcms-sdk/lib/helpers/checkTranslatedStringValues';
import kustomDataMediasToSlidesTransformOptions from '@/lib/kustomcms-sdk/lib/helpers/kustomDataMediasToSlidesTransformOptions';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

type ExtraElement = React.ComponentType<{
  page: KustomPage;
  component: KustomPageComponent<TextCarouselComponentData>;
}>;

export interface TextMediasSectionProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  CentralExtraElement?: ExtraElement;
  TopHeadExtraElement?: ExtraElement;
  HeadExtraElement?: ExtraElement;
  ParallaxExtraElement?: ExtraElement;
  containerProps?: BoxProps;

  // deprecated
  ParallaxElement?: any;
  parallaxElementSpeed?: number;
  parallaxElementContainerStyle?: BoxProps;
}

const TextMediasSection: React.FC<TextMediasSectionProps> = (props) => {
  const {
    component,
    page,
    ParallaxElement,
    parallaxElementSpeed = -20,
    parallaxElementContainerStyle,
    containerProps,
    CentralExtraElement,
    ParallaxExtraElement,
    TopHeadExtraElement,
    HeadExtraElement,
  } = props;

  const { currentLang } = useContext(LanguageContext);

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textTitle`,
  );

  const subtitleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textSubtitle`,
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const hasText =
    [titleBind.value, subtitleBind.value, textBind.value].filter(
      (t) => t?.fr || t?.en || t?.es,
    ).length > 0;

  const medias = component.data.slides
    ?.map((slide) => slide.medias)
    .filter((m) => !!m);

  const mediasBind = useKustomData<
    CarouselComponentSlide[],
    KustomResponsiveMedia[]
  >(page, component, `data.slides`, kustomDataMediasToSlidesTransformOptions);

  const buttonsIndex = component.data.atomicComponents
    .map((cmp, index) =>
      cmp.type === 'ACTION_BUTTON' ? { index, id: cmp.id } : undefined,
    )
    .filter((i) => i !== undefined) as { id: string; index: number }[];

  const sketchWidth = useBreakpointValue({
    base: 250,
    md: 320,
    xl: 400,
    '2xl': 460,
  });
  const theme = useTheme();

  return (
    <Box
      py={[10, null, null, 14]}
      overflowX="clip"
      position="relative"
      {...containerProps}
    >
      {ParallaxExtraElement && (
        <ParallaxExtraElement page={page} component={component} />
      )}
      {hasText && (
        <Container
          maxW={['container.lg', null, null, null, null, '1362px']}
          position="relative"
          px={[6, null, 20]}
          py={[10, null, 20]}
          // zIndex={2}
        >
          {ParallaxElement && (
            <Box
              display={['block']}
              position="absolute"
              right={[-40, null, 10]}
              {...parallaxElementContainerStyle}
            >
              <Parallax speed={parallaxElementSpeed}>
                <ParallaxElement
                  width={sketchWidth}
                  fill={theme.colors.accent[500]}
                />
              </Parallax>
            </Box>
          )}
          <Box position="relative">
            {TopHeadExtraElement && (
              <TopHeadExtraElement page={page} component={component} />
            )}
            {titleBind.value?.[currentLang] && (
              <RichText
                fontFamily="heading"
                lineHeight={1}
                mb={4}
                fontSize={['48px', null, '96px']}
                color="brand.500"
                as="h2"
                {...titleBind}
              />
            )}
            {subtitleBind.value?.[currentLang] && (
              <RichText
                fontFamily="jhaSemiboldItalic"
                color="brand.500"
                fontSize={['20px', null, '28px']}
                {...subtitleBind}
                as="h3"
              />
            )}
            {HeadExtraElement && (
              <HeadExtraElement page={page} component={component} />
            )}
            {textBind.value?.[currentLang] &&
              textBind.value?.[currentLang] !== '\n' && (
                <RichText
                  mt={[12, null, 20]}
                  fontSize={['20px', null, '28px']}
                  fontFamily="jhaSemilight"
                  color="brand.500"
                  mr={[0, null, 60]}
                  {...textBind}
                  as="div"
                />
              )}
            {!!buttonsIndex.length && (
              <Box mt={10} position="relative">
                {buttonsIndex.map(({ index: buttonIndex, id }, index) => (
                  <TextMediasSectionButton
                    component={component}
                    page={page}
                    key={id}
                    buttonIndex={buttonIndex}
                  />
                ))}
              </Box>
            )}
            {CentralExtraElement && (
              <Box mt={16}>
                <CentralExtraElement page={page} component={component} />
              </Box>
            )}
          </Box>
        </Container>
      )}
      {mediasBind.value?.length ? (
        <Container
          maxW={['container.xl', null, null, null, null, '1780px']}
          px={[6, null, 20]}
          pb={[6, null, 20]}
        >
          <Box
            w="100%"
            h={['420px', null, '819px']}
            position="relative"
            zIndex={1}
          >
            <Slideshow
              usingMediaViewer
              {...mediasBind}
              hideArrows
              hideCounter
              sizes={chakraResponsiveToImageSizes([
                null,
                null,
                null,
                null,
                null,
                '1780px',
              ])}
              height={['420px', null, '819px']}
              containerStyle={{
                w: '100%',
                borderRadius: 'base',
              }}
            />
          </Box>
        </Container>
      ) : null}
    </Box>
  );
};

interface TextMediasSectionButtonProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  buttonIndex: number;
}

const TextMediasSectionButton: React.FC<TextMediasSectionButtonProps> = (
  props,
) => {
  const { component, page, buttonIndex } = props;

  const buttonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${buttonIndex}`,
  );

  return (
    <Box mt={[4, null, 6, null, null, 8]}>
      <ActionButton
        rightIcon={
          <chakra.span
            fontSize={['42px', null, '56px']}
            className="icon-elmes-arrow1-white"
          />
        }
        {...buttonBind}
        variant="brand"
        size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
      />
    </Box>
  );
};

export default TextMediasSection;
