import {
  ActionButtonComponent,
  CarouselComponentSlide,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediasComponentData,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Container, useBreakpointValue } from '@chakra-ui/react';

import ActionButton from '@/lib/kustomcms-sdk/lib/components/ActionButton';
import AnimatePresence from '../atomics/AnimatePresence';
import { Parallax } from 'react-scroll-parallax';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';

import Slideshow from '../atomics/Slideshow';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useKustomDataMediasToSlidesTransformOptions from '@/lib/kustomcms-sdk/lib/helpers/kustomDataMediasToSlidesTransformOptions';
import { useMemo } from 'react';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import { SvgShape } from '../SvgShape';

const Section1 = (
  props: KustomPageComponentViewProps<TextCarouselComponentData>,
) => {
  const { component, page } = props;

  const medias1Size = useBreakpointValue({
    base: [
      {
        width: 154,
        height: 200,
      },
      {
        width: 154,
        height: 200,
      },
    ],
    md: [
      {
        width: 230,
        height: 300,
      },
      {
        width: 230,
        height: 300,
      },
    ],
    lg: [
      {
        height: '600px',
        width: '624px',
      },
      {
        height: '580px',
        width: '396px',
      },
    ],
    '2xl': [
      {
        height: '832px',
        width: '976px',
      },
      {
        height: '873px',
        width: '620px',
      },
    ],
  });

  const medias2Size = useBreakpointValue({
    base: [
      {
        width: 154,
        height: 200,
      },
      {
        width: 154,
        height: 200,
      },
    ],
    md: [
      {
        width: 230,
        height: 300,
      },
      {
        width: 230,
        height: 300,
      },
    ],
    lg: [
      {
        height: '506px',
        width: '716px',
      },
      {
        height: '871px',
        width: '716px',
      },
    ],
    '2xl': [
      {
        height: '633px',
        width: '896px',
      },
      {
        height: '1089px',
        width: '896px',
      },
    ],
  });

  const galleryCmp1 = usePageComponent<MediasComponentData>(
    page,
    null,
    '16881297979184422',
  );
  const component2 = usePageComponent<TextCarouselComponentData>(
    page,
    null,
    '16881300315284190',
  );
  const galleryCmp2 = usePageComponent<MediasComponentData>(
    page,
    null,
    '16881300733583978',
  );

  const slidesMediasBind = useKustomData<
    CarouselComponentSlide[],
    KustomResponsiveMedia[]
  >(page, component, `data.slides`, {
    ...useKustomDataMediasToSlidesTransformOptions,
  });

  const slidesMediasBind2 = useKustomData<
    CarouselComponentSlide[],
    KustomResponsiveMedia[]
  >(page, component2, `data.slides`, {
    ...useKustomDataMediasToSlidesTransformOptions,
  });

  const title1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textTitle`,
  );
  const desc1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );
  const atomActionButton1Id = component.data.atomicComponents?.findIndex(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  );
  const atomActionButton1Bind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${atomActionButton1Id}`,
  );
  const title2Bind = useKustomData<KustomTranslatedString>(
    page,
    component2,
    `data.textTitle`,
  );
  const desc2Bind = useKustomData<KustomTranslatedString>(
    page,
    component2,
    `data.text`,
  );
  const atomActionButton2Id = component2?.data.atomicComponents?.findIndex(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  );
  const atomActionButton2Bind = useKustomData<ActionButtonComponent>(
    page,
    component2,
    `data.atomicComponents.${atomActionButton2Id}`,
  );

  const galleryMediasBind = useKustomData<KustomResponsiveMedia[]>(
    page,
    galleryCmp1,
    `data.medias`,
  );
  const galleryMediasBind2 = useKustomData<KustomResponsiveMedia[]>(
    page,
    galleryCmp2,
    `data.medias`,
  );

  const slides = useMemo(
    () => [
      {
        id: component.id,
        titleBind: title1Bind,
        descriptionBind: desc1Bind,
        mediasBind: slidesMediasBind,
        medias2Bind: galleryMediasBind,
        actionButtonBind: atomActionButton1Bind,
      },
      {
        id: component2?.id,
        titleBind: title2Bind,
        descriptionBind: desc2Bind,
        mediasBind: slidesMediasBind2,
        medias2Bind: galleryMediasBind2,
        actionButtonBind: atomActionButton2Bind,
      },
    ],
    [
      component,
      component2,
      galleryMediasBind,
      galleryMediasBind2,
      slidesMediasBind,
      slidesMediasBind2,
      title1Bind,
      title2Bind,
      desc1Bind,
      desc2Bind,
      atomActionButton1Bind,
      atomActionButton2Bind,
    ],
  );

  return (
    <Box bgColor="accent.100" py={[8, null, 16, 24, null, 40]} overflow="clip">
      <Container
        maxW={['container.xl', null, null, null, null, '1780px']}
        px={[6, null, 20]}
      >
        {slides?.map((slide, index) => {
          return (
            <Box
              key={slide.id}
              display="flex"
              position="relative"
              flexDir={['column-reverse', null, null, 'row-reverse']}
            >
              <Box
                display="flex"
                // flex={1}
                // flexShrink={1}
                flexDir="column"
                position={['relative', null, null]}
                // maxW={['100%', null, '900px']}

                my={[6, null, 8, 16]}
                minW={0}
              >
                <Box display="flex">
                  <Box
                    position="relative"
                    zIndex={1}
                    flexShrink={1}
                    minW={20}
                    my={[8, null, null, 20, null, '150px']}
                    mr={8}
                    ml={[
                      null,
                      null,
                      null,
                      index % 2 ? '140px' : null,
                      null,
                      index % 2 ? '250px' : null,
                    ]}
                  >
                    <AnimatePresence x={-60} opacity>
                      <TranslatedString
                        fontSize={['38px', null, '78px', '96px', null, '120px']}
                        fontFamily="heading"
                        lineHeight={1}
                        {...slide.titleBind}
                        minW={[null, null, null, '900px']}
                        isHeading={true}
                        as="h2"
                      />
                      <RichText
                        color="brand.500"
                        fontFamily="jhaSemilight"
                        maxW={['100%', null, '560px']}
                        fontSize={['18px', null, '29px', null, null, '36px']}
                        {...slide.descriptionBind}
                        mt={[0, null, 6]}
                        mb={5}
                        as="p"
                      />
                      {slide.actionButtonBind && (
                        <ActionButton
                          textAlign="left"
                          fontSize={['18px', null, '29px', null, null, '36px']}
                          fontFamily="jha"
                          variant="link"
                          my={6}
                          {...slide.actionButtonBind}
                        />
                      )}
                    </AnimatePresence>
                  </Box>
                  <Box
                    mb={[14, null, 20]}
                    display={['none', null, null, 'flex']}
                    flexDir={['row', null, null, 'column']}
                    flexGrow={0}
                    flexShrink={0}
                    flexBasis={medias1Size?.[index]?.width}
                  >
                    <AnimatePresence y={60} opacity amount={0.1}>
                      <Box
                        position="relative"
                        style={{
                          ...medias1Size?.[index],
                        }}
                      >
                        {slide.mediasBind.value && (
                          <Slideshow
                            usingMediaViewer
                            value={slide.mediasBind.value}
                            onChange={slide.mediasBind.onChange}
                            hideArrows
                            hideCounter
                            sizes={chakraResponsiveToImageSizes([
                              medias1Size?.[index]?.width || null,
                            ])}
                            height={medias1Size?.[index]?.height}
                            containerStyle={{
                              borderRadius: 'base',
                            }}
                          />
                        )}
                      </Box>
                    </AnimatePresence>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box
                    display={['flex', null, null, 'none']}
                    flexDir={['row', null, null, 'column']}
                    mr={3}
                    flexGrow={0}
                    flexShrink={0}
                    flexBasis={medias1Size?.[index]?.width}
                  >
                    <AnimatePresence y={60} opacity amount={0.1}>
                      <Box
                        position="relative"
                        style={{
                          ...medias1Size?.[index],
                        }}
                      >
                        {slide.mediasBind.value && (
                          <Slideshow
                            usingMediaViewer
                            value={slide.mediasBind.value}
                            onChange={slide.mediasBind.onChange}
                            hideArrows
                            hideCounter
                            sizes={chakraResponsiveToImageSizes([
                              medias1Size?.[index]?.width || null,
                            ])}
                            height={medias1Size?.[index]?.height}
                            containerStyle={{
                              borderRadius: 'base',
                            }}
                          />
                        )}
                      </Box>
                    </AnimatePresence>
                  </Box>
                  {slide.medias2Bind.value && (
                    <Box
                      style={{ ...medias2Size?.[index] }}
                      mt={0}
                      ml={index % 2 ? 0 : [0, null, null, 40]}
                      position="relative"
                    >
                      <Slideshow
                        usingMediaViewer
                        value={slide.medias2Bind.value}
                        onChange={slide.medias2Bind.onChange}
                        hideArrows
                        hideCounter
                        sizes={chakraResponsiveToImageSizes([
                          medias2Size?.[index]?.width || null,
                        ])}
                        height={medias2Size?.[index]?.height}
                        containerStyle={{
                          borderRadius: 'base',
                        }}
                      />
                      {/* Parallax sketch  */}
                      <Box
                        display={['none', null, null, 'block']}
                        position="absolute"
                        top={index % 2 ? '500' : '100'}
                        left={index % 2 ? undefined : '-150'}
                        right={index % 2 ? '-400' : undefined}
                        zIndex={2}
                      >
                        <Parallax speed={10}>
                          {index % 2 ? (
                            <SvgShape file="illu-mer-1" width="500px" />
                          ) : (
                            <SvgShape file="illu-mer-2" width="400px" />
                          )}
                        </Parallax>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Container>
    </Box>
  );
};

export default Section1;
