import { Establishment } from '../../types';

export function getUserCurrentEstablishment(
  user: any = {},
): Establishment | undefined {
  if (!user._id) {
    return undefined;
  }

  const index = user.currentEstablishmentIndex;
  return user.establishments[index];
}
