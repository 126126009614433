import * as Yup from 'yup';

import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  chakra,
  useToast,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';

import Link from 'next/link';
import React, { useContext } from 'react';
import Toast from '../atomics/Toast';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

interface RGPDFormProps extends BoxProps {}

const RGPDForm: React.FC<RGPDFormProps> = (props) => {
  const { ...boxProps } = props;

  const { currentLang } = useContext(LanguageContext);

  const name = useKustomTranslation('form_name')?.value?.[currentLang];
  const rightsTxt = useKustomTranslation('form_rights')?.value?.[currentLang];
  const firstname =
    useKustomTranslation('form_firstname')?.value?.[currentLang];
  const email = useKustomTranslation('form_email')?.value?.[currentLang];
  const address = useKustomTranslation('form_address')?.value?.[currentLang];
  // const phone = useKustomTranslation('form_phone')?.value?.[currentLang];
  const message = useKustomTranslation('form_message')?.value?.[currentLang];
  const accept = useKustomTranslation('form_accept')?.value?.[currentLang];
  const cgu = useKustomTranslation('form_cgu')?.value?.[currentLang];
  const and = useKustomTranslation('form_and')?.value?.[currentLang];
  const policy = useKustomTranslation('form_policy')?.value?.[currentLang];
  const button = useKustomTranslation('form_button')?.value?.[currentLang];
  const errorGen = useKustomTranslation('form_error')?.value?.[currentLang];
  const toastA = useKustomTranslation('form_toastA')?.value?.[currentLang];
  const toastB = useKustomTranslation('form_toastB')?.value?.[currentLang];
  const errorEmail =
    useKustomTranslation('form_errorEmail')?.value?.[currentLang];
  const errorCgu = useKustomTranslation('form_errorCgu')?.value?.[currentLang];

  const toasting = useToast();

  const toast = (title: string, content: string, isError?: boolean) => {
    toasting({
      position: 'bottom-right',
      duration: 3000,
      render: (props) => (
        <Toast
          {...props}
          error={isError}
          duration={3000}
          title={title}
          content={content}
        />
      ),
    });
  };

  const sendEmail = (values: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await fetch('/api/send-rgpd-email', {
          method: 'POST',
          body: values,
        });

        const json = await res.json();

        if (json.error) {
          toast(errorGen || 'error', json.error, true);
          reject();
          return;
        }

        toast(toastA || 'error', toastB || '');
        resolve(json);
      } catch (error: any) {
        toast(errorGen || 'error', error.message, true);
        reject();
      }
    });
  };

  const selectOptions = [
    {
      value: 'acceder',
      text: {
        fr: "Droit d'accéder aux données à caractère personnel dont l'hôtel dispose peut-être à votre sujet",
        en: 'Right to access personal data that the hotel may have about you',
        es: 'Derecho de acceso a los datos personales que el hotel pueda tener sobre usted',
        de: 'Recht auf Zugang zu den personenbezogenen Daten, die das Hotel möglicherweise über Sie hat',
      },
    },
    {
      value: 'rectifier',
      text: {
        fr: 'Droits de rectifier, de compléter ou de mettre à jour vos données à caractère personnel',
        en: 'Rights to rectify, complete or update your personal data',
        es: 'Derecho a rectificar, completar o actualizar sus datos personales',
        de: 'Rechte zur Berichtigung, Ergänzung oder Aktualisierung Ihrer personenbezogenen Daten',
      },
    },
    {
      value: 'effacement',
      text: {
        fr: "Droit à l'effacement (« droit à l’oubli ») de vos données à caractère personnel",
        en: 'Right to erasure ("right to be forgotten") of your personal data',
        es: 'Recht auf Löschung („Recht auf Vergessenwerden“) Ihrer personenbezogenen Daten',
      },
    },
    {
      value: 'limitation',
      text: {
        fr: 'Droit à la limitation du traitement de vos données à caractère personnel',
        en: 'Right to limit the processing of your personal data',
        es: 'Derecho a limitar el tratamiento de sus datos personales',
        de: 'Recht auf Einschränkung der Verarbeitung Ihrer personenbezogenen Daten',
      },
    },
    {
      value: 'portabilite',
      text: {
        fr: 'Droit à la portabilité de vos données à caractère personnel',
        en: 'Right to data portability',
        es: 'Derecho a la portabilidad de sus datos',
        de: 'Recht auf Übertragbarkeit Ihrer personenbezogenen Daten',
      },
    },
    {
      value: 'objection',
      text: {
        fr: "Droits d'objection, d'opposition et de retrait de traitement de vos données à caractère personnel",
        en: 'Rights of objection, opposition and withdrawal of processing of your personal data',
        es: 'Derecho de oposición y retirada de tratamiento de sus datos personales',
        de: 'Rechte auf Widerspruch, Widerspruch und Widerruf der Verarbeitung Ihrer personenbezogenen Daten',
      },
    },
  ];

  const validationSchema = Yup.object({
    lastname: Yup.string().required(),
    firstname: Yup.string().required(),
    email: Yup.string().email(errorEmail).required(),
    // phone: Yup.string().required(),
    address: Yup.string().required(),
    rights: Yup.string().required(),
    message: Yup.string().required(),
    cgu: Yup.boolean().oneOf([true], errorCgu).required(),
  });

  return (
    <Container
      maxW={['container.lg', null, null, null, null, '1362px']}
      position="relative"
      px={[6, null, 20]}
      pt={[20, null, 32]}
      pb={[20, null, 32]}
    >
      <Box mt={14} {...boxProps}>
        <Formik
          initialValues={{
            firstname: '',
            lastname: '',
            email: '',
            // phone: '',
            address: '',
            rights: '',
            message: '',
            cgu: false,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const data = new FormData();
            data.append('lastname', values.lastname);
            data.append('firstname', values.firstname);
            data.append('email', values.email);
            // data.append('phone', values.phone);
            data.append('address', values.address);
            data.append('rights', values.rights);
            data.append('message', values.message);

            sendEmail(data).finally(() => {
              actions.setSubmitting(false);
              actions.resetForm();
            });
          }}
        >
          {(props) => (
            <Form>
              <Box display="flex" mb={4} flexDir={['column', null, 'row']}>
                <Field name="lastname">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      flex={1}
                      variant="floating"
                      mr={[0, null, 6]}
                      mb={[4, null, 0]}
                      isInvalid={
                        !!(form.errors.lastname && form.touched.lastname)
                      }
                    >
                      <Input w="100%" {...field} size="lg" placeholder=" " />
                      <FormLabel>{name}</FormLabel>
                    </FormControl>
                  )}
                </Field>
                <Field name="firstname">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      flex={1}
                      variant="floating"
                      isInvalid={
                        !!(form.errors.firstname && form.touched.firstname)
                      }
                    >
                      <Input w="100%" {...field} size="lg" placeholder=" " />
                      <FormLabel>{firstname}</FormLabel>
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Box display="flex" mb={4} flexDir={['column', null, 'row']}>
                <Field name="email">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      variant="floating"
                      flex={1}
                      mr={[0, null, 6]}
                      mb={[4, null, 0]}
                      isInvalid={!!(form.errors.email && form.touched.email)}
                    >
                      <Input w="100%" {...field} size="lg" placeholder=" " />
                      <FormLabel>{email}</FormLabel>
                    </FormControl>
                  )}
                </Field>
                {/* <Field name="phone">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      variant="floating"
                      flex={1}
                      isInvalid={!!(form.errors.phone && form.touched.phone)}
                    >
                      <Input w="100%" {...field} size="lg" placeholder=" " />
                      <FormLabel>Téléphone</FormLabel>
                    </FormControl>
                  )}
                </Field> */}
                <Field name="address">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      variant="floating"
                      flex={1}
                      isInvalid={
                        !!(form.errors.address && form.touched.address)
                      }
                    >
                      <Input w="100%" {...field} size="lg" placeholder=" " />
                      <FormLabel>{address}</FormLabel>
                    </FormControl>
                  )}
                </Field>
              </Box>
              <Field name="rights">
                {({
                  field,
                  form: { setFieldValue, errors, touched },
                }: FieldProps) => (
                  <FormControl isInvalid={!!(errors.rights && touched.rights)}>
                    <Select
                      mb={4}
                      placeholder={rightsTxt}
                      border="none !important"
                      onChange={(e) => {
                        setFieldValue(field.name, e.target.value);
                      }}
                    >
                      {selectOptions.map((option) => (
                        <option
                          key={option.value}
                          value={option.text[currentLang]}
                        >
                          {option.text[currentLang]}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Field name="message">
                {({ field, form }: FieldProps) => (
                  <FormControl
                    // display={['none', null, 'block']}
                    variant="floating"
                    isInvalid={!!(form.errors.message && form.touched.message)}
                  >
                    <Textarea
                      className="ignoreSmoothScroll"
                      minH="250px"
                      borderWidth="2px !important"
                      w="100%"
                      bgColor="white"
                      fontFamily="jhaSemilight"
                      border="none"
                      borderRadius="2px"
                      color="brand.500"
                      pt={7}
                      px={6}
                      {...field}
                      size="lg"
                      placeholder=" "
                      rows={5}
                      overflow="auto"
                    />
                    <FormLabel>{message}</FormLabel>
                  </FormControl>
                )}
              </Field>
              <Box
                display="flex"
                mt={5}
                justifyContent="space-between"
                flexDir={['column', null, 'row']}
                alignItems={['left', null, 'center']}
              >
                <Field name="cgu">
                  {({ field, form }: FieldProps) => (
                    <Checkbox
                      alignItems="top"
                      colorScheme="brand"
                      fontFamily="jhaSemilight"
                      color="brand.500"
                      {...field}
                      isChecked={form.values.cgu}
                      isInvalid={!!(form.errors.cgu && form.touched.cgu)}
                    >
                      <chakra.span fontSize={'18px'}>
                        {accept}{' '}
                        <Link style={{ textDecoration: 'underline' }} href="#">
                          {cgu}
                        </Link>{' '}
                        {and}{' '}
                        <Link style={{ textDecoration: 'underline' }} href="#">
                          {policy}
                        </Link>
                        .
                      </chakra.span>
                    </Checkbox>
                  )}
                </Field>
                <Button
                  mr={['auto', null, 0]}
                  mt={[4, null, 0]}
                  isLoading={props.isSubmitting}
                  rightIcon={
                    <chakra.span
                      fontSize={['42px', null, '56px']}
                      position="relative"
                      left="-1"
                      className="icon-elmes-arrow1-white"
                    />
                  }
                  type="submit"
                  variant="brand"
                  size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
                >
                  {button}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
};

export default RGPDForm;
