import { ChakraStyledOptions } from '@chakra-ui/react';
import Image from 'next/image';

type SvgShapeProps = {
  file: string;
  width?: string;
  style?: ChakraStyledOptions;
};

export function SvgShape({ file, width, style }: Readonly<SvgShapeProps>) {
  return (
    <Image
      src={`/assets/svg/${file}.svg`}
      width={1}
      height={1}
      alt=""
      style={{
        display: 'block',
        width: width || '100%',
        objectFit: 'contain',
        ...style,
      }}
    />
  );
}
