import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import { createRouterMiddleware, routerReducer } from 'connected-next-router';

import { KustomClient } from '..';
import appReducer from './features/app/slice';
import feathers from '@feathersjs/feathers';
import mediasReducer from './features/medias/slice';
import uploadReducer from './features/upload/slice';

const routerMiddleware = createRouterMiddleware();

export function makeStore(kustomClient?: KustomClient) {
  return configureStore({
    reducer: {
      app: appReducer,
      upload: uploadReducer,
      medias: mediasReducer,
      router: routerReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            feathersApp: kustomClient?.app,
            config: {
              API_URL: kustomClient?.API_URL,
            },
          },
        },
      }).concat(routerMiddleware),
  });
}

const store = makeStore();

export type StoreState = ReturnType<typeof store.getState>;

export type AppThunkAPI = {
  dispatch: typeof store.dispatch;
  state: StoreState;
  extra: {
    feathersApp?: feathers.Application<any>;
    config: {
      API_URL?: string;
    };
  };
};

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  StoreState,
  AppThunkAPI,
  Action<string>
>;
