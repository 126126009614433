import { Box, Container } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import ExpandableText from '../atomics/ExpandableText';
import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import WrapIf from '../atomics/WrapIf';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

const RoomsDescription: React.FC<
  KustomPageComponentViewProps<TextComponentData>
> = (props) => {
  const { page, component } = props;

  // const title = component.data.text;
  const descriptionId = component.data.atomicComponents.findIndex(
    (cmp) => cmp.type === 'TEXT',
  );

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const descriptionBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${descriptionId}.text`,
  );

  const bpIndex = useBreakpointIndex();

  return (
    <Box bgColor="accent.100" overflowX="clip">
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        px={[6, null, 20]}
        pt={[10, null, 20, null, null, 28]}
        position="relative"
        zIndex={1}
      >
        <RichText
          as="h2"
          fontFamily="heading"
          fontSize={['38px', null, '76px', null, null, '96px']}
          lineHeight={1}
          color="brand.500"
          mb={[10, null, 14, null, null, 20]}
          {...titleBind}
        />
        <WrapIf
          condition={bpIndex < 2}
          wrapper={(children) => (
            <ExpandableText noOfLines={8}>{children}</ExpandableText>
          )}
        >
          <RichText
            fontFamily="jhaSemilight"
            fontSize={['18px', null, '22px', null, null, '28px']}
            lineHeight={1.5}
            color="brand.500"
            mr={[0, null, 40]}
            {...descriptionBind}
            as="p"
          />
        </WrapIf>
      </Container>
    </Box>
  );
};

export default RoomsDescription;
