import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TableComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useEffect, useRef, useState } from 'react';

import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

interface TableSectionProps
  extends KustomPageComponentViewProps<TableComponentData> {
  containerProps?: ContainerProps;
}

const $cellStyle = {
  py: [9, null, null, 6],
} as BoxProps;

const TableSection: React.FC<TableSectionProps> = (props) => {
  const { page, component, containerProps } = props;

  const [isOverflowed, setIsOverflowed] = useState(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!tableContainerRef.current) return;

    const checkSize = () => {
      if (!tableContainerRef.current) return;

      const hasHorizontalScrollbar =
        tableContainerRef.current.scrollWidth >
        tableContainerRef.current.clientWidth;

      setIsOverflowed(hasHorizontalScrollbar);
    };

    checkSize();
    window.addEventListener('resize', checkSize);

    return () => window.removeEventListener('resize', checkSize);
  }, [tableContainerRef.current]);

  return (
    <Box pb={[10, null, 20]} pt={[20, null, 32]}>
      <Container
        maxW={['container.lg', null, null, null, null, '1080px']}
        position="relative"
        pl={[6, null, 20]}
        pr={isOverflowed ? 0 : [6, null, 20]}
        className={isOverflowed ? 'ignoreSmoothScroll' : ''}
        {...containerProps}
      >
        {isOverflowed && (
          <Box
            position="absolute"
            pointerEvents={'none'}
            zIndex={2}
            width={'65px'}
            height="100%"
            top={0}
            bottom={0}
            right={0}
            background="transparent linear-gradient(90deg, #FFFFFF00 0%, #DDB07947 100%) 0% 0% no-repeat padding-box"
          ></Box>
        )}
        <TableContainer
          ref={tableContainerRef}
          position="relative"
          overflowX={isOverflowed ? 'scroll' : 'auto'}
        >
          <Table fontSize="28px">
            <Thead>
              <Tr>
                {Array(component.data.nColumns)
                  .fill(0)
                  .map((_, i) => (
                    <TableSectionCell
                      key={i}
                      page={page}
                      component={component}
                      cellX={i}
                      cellY={0}
                      as={Th}
                      cellProps={{
                        textTransform: 'capitalize',
                        fontSize: '28px',
                      }}
                      innerAs="h3"
                    />
                  ))}
              </Tr>
            </Thead>
            <Tbody>
              {Array(component.data.nRow - 1)
                .fill(0)
                .map((_, i) => (
                  <Tr
                    key={i}
                    {...(!(i % 2) && {
                      bgColor: 'accent.200',
                    })}
                  >
                    {Array(component.data.nColumns)
                      .fill(0)
                      .map((_, j) => (
                        <TableSectionCell
                          key={j}
                          {...props}
                          cellX={j}
                          cellY={i + 1}
                          innerAs={j === 0 ? 'h4' : 'span'}
                        />
                      ))}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

interface TableSectionCellProps
  extends KustomPageComponentViewProps<TableComponentData> {
  cellX: number;
  cellY: number;
  as?: React.ElementType;
  cellProps?: BoxProps;
  innerAs?: 'h3' | 'h4' | 'span';
}

const TableSectionCell: React.FC<TableSectionCellProps> = (props) => {
  const {
    component,
    page,
    cellX,
    cellY,
    as,
    cellProps,
    innerAs = 'span',
  } = props;

  const cellBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.data.${cellY}.${cellX}`,
  );

  return (
    <Box
      as={as || Td}
      {...$cellStyle}
      {...cellProps}
      textAlign={cellX !== 0 ? 'center' : undefined}
    >
      <TranslatedString
        fontFamily={cellX === 0 || cellY === 0 ? 'jhaSemibold' : 'jhaSemilight'}
        {...cellBind}
        as={innerAs}
      />
    </Box>
  );
};

export default TableSection;
