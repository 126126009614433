import {
  ActionButtonComponent,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Container, chakra } from '@chakra-ui/react';

import ActionButton from '@/lib/kustomcms-sdk/lib/components/ActionButton';
import ItemsGrid from './ItemsGrid';
import React from 'react';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

interface ItemsGridSectionProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  isHeadingInside?: boolean;
}

const ItemsGridSection: React.FC<ItemsGridSectionProps> = (props) => {
  const { component, isHeadingInside } = props;

  const title = component.data.textTitle;

  const buttons = component.data.atomicComponents.filter(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  ) as ActionButtonComponent[];

  const titleBind = useKustomData<KustomTranslatedString>(
    props.page,
    component,
    `data.textTitle`,
  );

  return (
    <Box>
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        position="relative"
        overflowX="clip"
        px={[6, null, 20]}
        pt={[20, null, 32]}
        pb={[10, null, 20]}
      >
        <TranslatedString
          fontFamily="heading"
          lineHeight={1}
          mb={[10, null, null, 20]}
          fontSize={['48px', null, '96px']}
          color="brand.500"
          {...titleBind}
          as="h2"
        />
        <ItemsGrid
          index={0}
          component={component}
          page={props.page}
          disableHeading={!isHeadingInside}
        />
        {!!buttons.length && (
          <Box mt={10} position="relative">
            {buttons.map((button, index) => (
              <ItemsGridSectionButton
                key={button.id}
                page={props.page}
                component={component}
                buttonIndex={index}
              />
            ))}
          </Box>
        )}
      </Container>
    </Box>
  );
};

interface ItemsGridSectionButtonProps
  extends KustomPageComponentViewProps<TextCarouselComponentData> {
  buttonIndex: number;
}

const ItemsGridSectionButton: React.FC<ItemsGridSectionButtonProps> = (
  props,
) => {
  const { component, buttonIndex } = props;

  const buttonBind = useKustomData<ActionButtonComponent>(
    props.page,
    component,
    `data.atomicComponents.${buttonIndex}`,
  );

  return (
    <Box mt={[4, null, 6, null, null, 8]}>
      <ActionButton
        variant="brand"
        size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
        rightIcon={
          <chakra.span
            fontSize={['42px', null, '56px']}
            className="icon-elmes-arrow1-white"
          />
        }
        {...buttonBind}
      />
    </Box>
  );
};

export default ItemsGridSection;
