import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';

import AdminThemeProvider from '../components/AdminThemeProvider';
import Toast from '../components/atomics/Toast';
import { mediasActions } from '../features/medias/slice';
import { useAppDispatch } from './useAppDispatch';
import { useKustomSelector } from './useKustomSelector';

const useMediaRemove = () => {
  const [wantRemoveMedias, setWantRemoveMedias] = useState(0);
  const { selectedMedias, mediasUsage } = useKustomSelector(
    (state) => state.medias,
  );
  const dispatch = useAppDispatch();
  const toast = useToast();

  const removeSelectedMedias = async () => {
    const promises = selectedMedias.map((media) =>
      dispatch(mediasActions.removeMedia({ file: media })).unwrap(),
    );
    setWantRemoveMedias(2);

    await Promise.all(promises).then((data) => {
      setWantRemoveMedias(0);
      const count = data.filter((d) => !!d.removedSize).length;
      toast({
        position: 'bottom-right',
        duration: 3000,
        render: (props) => (
          <Toast
            {...props}
            duration={3000}
            title={`MÉDIA${count > 1 ? 'S' : ''} SUPPRIMÉ${
              count > 1 ? 'S' : ''
            }`}
            content={`${count} média${count > 1 ? 's' : ''} supprimé${
              count > 1 ? 's' : ''
            } de la galerie`}
          />
        ),
      });
    });
  };

  const areMediasUsed =
    !!mediasUsage &&
    selectedMedias.some((media) => !!mediasUsage[media.metadata._id]);

  const plural = selectedMedias.length > 1 ? 's' : '';

  return {
    removeSelectedMedias,
    wantRemoveMedias: () => setWantRemoveMedias(1),
    modalRemoveMedia: (
      <Modal
        size="3xl"
        isOpen={!!wantRemoveMedias}
        onClose={() => setWantRemoveMedias(0)}
      >
        <ModalOverlay />
        <ModalContent>
          <AdminThemeProvider>
            <ModalCloseButton />
            <ModalBody mr={7} my={4}>
              <Heading
                mt={2}
                mb={4}
                fontSize="30px"
                as="h4"
                textStyle="heading"
              >
                Attention
              </Heading>
              <Text mb={3} fontSize="18px" textStyle="text">
                Êtes-vous sur de vouloir supprimer {selectedMedias.length} média
                {plural} de la galerie ?
              </Text>
              {areMediasUsed && (
                <Box bgColor="gray.100" p={4} textAlign="center" mt={4}>
                  <Text color="gray.500">
                    Média{plural} utilisé sur le site
                  </Text>
                </Box>
              )}
              <Box mt={4}>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  px={10}
                  size="xl"
                  onClick={() => setWantRemoveMedias(0)}
                >
                  ANNULER
                </Button>
                <Button
                  px={10}
                  ml={2}
                  size="xl"
                  colorScheme="brand"
                  onClick={() => removeSelectedMedias()}
                  isLoading={wantRemoveMedias === 2}
                >
                  SUPPRIMER
                </Button>
              </Box>
            </ModalBody>
          </AdminThemeProvider>
        </ModalContent>
      </Modal>
    ),
  };
};

export default useMediaRemove;
