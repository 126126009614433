import {
  ActionButtonComponent,
  AtomicComponent,
  CustomComponentData,
  KustomPageComponentViewProps,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, BoxProps, Container, chakra } from '@chakra-ui/react';

import ActionButton from '@/lib/kustomcms-sdk/lib/components/ActionButton';
import React from 'react';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

interface CustomSectionProps
  extends KustomPageComponentViewProps<CustomComponentData> {
  alignItems?: string;
  containerStyling?: BoxProps;
}

const CustomSection: React.FC<CustomSectionProps> = (props) => {
  const { component, alignItems = 'center', containerStyling } = props;
  return (
    <Box {...containerStyling}>
      <Container
        maxW="container.lg"
        px={[6, null, 20]}
        display="flex"
        flexDir="column"
        alignItems={alignItems}
      >
        {component?.data?.atomicComponents.map((c, index) => (
          <CustomSectionItem
            key={index}
            componentIndex={index}
            page={props.page}
            component={component}
          />
        ))}
      </Container>
    </Box>
  );
};

interface CustomSectionItemProps
  extends KustomPageComponentViewProps<CustomComponentData> {
  componentIndex: number;
}

const CustomSectionItem: React.FC<CustomSectionItemProps> = (props) => {
  const { componentIndex, component, page } = props;

  const cmpBind = useKustomData<AtomicComponent>(
    page,
    component,
    `data.atomicComponents.${componentIndex}`,
  );

  return (
    <Box>
      {
        // TODO: Add more component types
        {
          TEXT: null,
          MEDIA: null,
          ACTION_BUTTON: (
            <ActionButton
              variant="brand"
              size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
              mt={[4, null, 6, null, null, 8]}
              rightIcon={
                <chakra.span
                  fontSize={['42px', null, '56px']}
                  className="icon-elmes-arrow1-white"
                />
              }
              value={cmpBind.value as ActionButtonComponent}
              onChange={cmpBind.onChange}
            />
          ),
          ACCORDION: null,
          CODE: null,
          '': null,
        }[cmpBind.value?.type || '']
      }
    </Box>
  );
};

export default CustomSection;
