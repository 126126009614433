import { Box, BoxProps, useMediaQuery } from '@chakra-ui/react';
import {
  KustomResponsiveMedia,
  KustomResponsiveMediaKey,
} from '../../../types';

import { Media } from '../../features/medias/slice';
import MediaViewer from './MediaViewer';
import MediasPicker from './MediasPicker';
import PickMediasButton from './PickMediasButton';
import React from 'react';
import { ReactSortable } from 'react-sortablejs';
import SingleResponsiveMediaPicker from './SingleResponsiveMediaPicker';
import { uniqID } from '../../helpers/random';
import { useState } from 'react';

interface MediaEditComponentProps {
  media?: KustomResponsiveMedia;
  onChange: (medias: KustomResponsiveMedia) => void;
  setIsModalOpen?: (isOpen: boolean) => void;
}

const MediaEditComponent = (props: MediaEditComponentProps) => {
  const { media, onChange, setIsModalOpen } = props;

  const [isMediaPickerOpen, _setIsMediaPickerOpen] = useState<
    string | undefined
  >(undefined);
  const setIsMediaPickerOpen = (key: string | undefined) => {
    setIsModalOpen?.(!!key);
    _setIsMediaPickerOpen(key);
  };

  const [isMediaViewerOpen, _setIsMediaViewerOpen] = useState<
    Media | undefined
  >(undefined);
  const setIsMediaViewerOpen = (media: Media | undefined) => {
    setIsModalOpen?.(!!media);
    _setIsMediaViewerOpen(media);
  };

  return (
    <Box>
      <SingleResponsiveMediaPicker
        mb={5}
        media={media}
        onMediaViewerOpen={(media: Media) => {
          setIsMediaViewerOpen(media);
        }}
        onRemoveMedia={(responsiveKey: KustomResponsiveMediaKey) => {
          onChange({
            id: uniqID(),
            [responsiveKey]: undefined,
          });
        }}
        onOpenMediaPicker={(key) => setIsMediaPickerOpen(key)}
      />
      {/* Pick one  */}
      <MediasPicker
        singleMedia
        isOpen={!!isMediaPickerOpen}
        onClose={() => setIsMediaPickerOpen(undefined)}
        onPickMedias={(newMedia) => {
          onChange({
            id: uniqID(),
            default: newMedia[0],
            mobile: undefined,
            tablet: undefined,
          });
          setIsMediaPickerOpen(undefined);
        }}
      />
      <MediaViewer
        media={isMediaViewerOpen}
        onClose={() => setIsMediaViewerOpen(undefined)}
      />
    </Box>
  );
};

export default MediaEditComponent;
