import * as Yup from 'yup';

import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  Select,
  Textarea,
  chakra,
  useToast,
} from '@chakra-ui/react';
import Toast from '../atomics/Toast';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import useNavigationKustomHref from '@/lib/kustomcms-sdk/lib/hooks/useNavigationKustomHref';

interface RestaurantFormProps extends BoxProps {
  onClose: () => void;
}

const datePlaceholder = '__/__/____';

const hoursAM = [
  { text: '12:00' },
  { text: '12:30' },
  { text: '13:00' },
  { text: '13:30' },
];

const hoursPM = [
  { text: '19:00' },
  { text: '19:30' },
  { text: '20:00' },
  { text: '20:30' },
  { text: '21:00' },
];

export const RestaurantForm: React.FC<RestaurantFormProps> = ({ onClose }) => {
  const { currentLang } = useContext(LanguageContext);

  const titleTable =
    useKustomTranslation('resa_restaurant_table_title')?.value?.[currentLang] ||
    '';
  const coverNumber = useKustomTranslation('resa_restaurant_cover_number')
    ?.value?.[currentLang];
  const date = useKustomTranslation('resa_date')?.value?.[currentLang];
  const hourly = useKustomTranslation('resa_restaurant_hourly')?.value?.[
    currentLang
  ];

  const lunch = useKustomTranslation('resa_restaurant_lunch')?.value?.[
    currentLang
  ];
  const dinner = useKustomTranslation('resa_restaurant_dinner')?.value?.[
    currentLang
  ];

  const contactInformation = useKustomTranslation(
    'resa_restaurant_contact_information',
  )?.value?.[currentLang];
  const name = useKustomTranslation('resa_name_firstname')?.value?.[
    currentLang
  ];

  const email = useKustomTranslation('form_email')?.value?.[currentLang];
  const errorEmail =
    useKustomTranslation('form_errorEmail')?.value?.[currentLang];
  const phone = useKustomTranslation('form_phone')?.value?.[currentLang];

  const comment = useKustomTranslation('resa_restaurant_comment')?.value?.[
    currentLang
  ];

  const accept = useKustomTranslation('form_accept')?.value?.[currentLang];
  const cgu = useKustomTranslation('form_cgu')?.value?.[currentLang];
  const and = useKustomTranslation('form_and')?.value?.[currentLang];
  const policy = useKustomTranslation('form_policy')?.value?.[currentLang];
  const errorCgu = useKustomTranslation('form_errorCgu')?.value?.[currentLang];

  const send = useKustomTranslation('resa_send')?.value?.[currentLang];
  const errorGen = useKustomTranslation('form_error')?.value?.[currentLang];
  const toastA = useKustomTranslation('form_toastA')?.value?.[currentLang];
  const toastB = useKustomTranslation('form_toastB')?.value?.[currentLang];

  const toasting = useToast();

  const toast = (title: string, content: string, isError?: boolean) => {
    toasting({
      position: 'bottom-right',
      duration: 3000,
      render: (props) => (
        <Toast
          {...props}
          error={isError}
          duration={3000}
          title={title}
          content={content}
        />
      ),
    });
  };

  const sendEmail = (values: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await fetch('/api/send-resa-restaurant', {
          method: 'POST',
          body: values,
        });
        const json = await res.json();
        if (json.error) {
          toast(errorGen || 'error', json.error, true);
          reject();
          return;
        }
        toast(toastA || 'error', toastB || '');
        resolve(json);
      } catch (error: any) {
        toast(errorGen || 'error', error.message, true);
        reject();
      }
    });
  };

  const validationSchema = Yup.object({
    number: Yup.number().min(1),
    date: Yup.date().required(),
    hours: Yup.string().required(),
    name: Yup.string().required(),
    email: Yup.string().email(errorEmail).required(),
    phone: Yup.string().required(),
    message: Yup.string().required(),
    cgu: Yup.boolean().oneOf([true], errorCgu).required(),
  });

  const [isIOS, setIsIOS] = useState(false);
  useEffect(() => {
    setIsIOS(/iPad|iPhone|iPod/.test(navigator.userAgent));
    // alert('isIOSDevice: ' + /iPad|iPhone|iPod/.test(navigator.userAgent));
  }, []);

  const linkCgu = useNavigationKustomHref('{g53y76}');
  const linkPolicy = useNavigationKustomHref('{dkj4o8}');

  return (
    <Formik
      initialValues={{
        number: 0,
        date: '',
        hours: '',
        name: '',
        email: '',
        phone: '',
        message: '',
        cgu: false,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        const data = new FormData();
        data.append('number', `${values.number}`);
        data.append('date', values.date);
        data.append('hours', values.hours);
        data.append('name', values.name);
        data.append('email', values.email);
        data.append('phone', values.phone);
        data.append('message', values.message);
        sendEmail(data).finally(() => {
          actions.setSubmitting(false);
          actions.resetForm();
          onClose();
        });
      }}
    >
      {(props) => (
        <Form>
          <Box
            padding="6"
            minH={'100%'}
            bgColor="accent.100"
            display={'flex'}
            flexDirection={'column'}
            gap="1rem"
          >
            <Heading
              as="h2"
              fontSize={['24px']}
              fontFamily="samsungSharpSans"
              fontWeight="bold"
              mt="2"
              mb="2"
            >
              {titleTable}
            </Heading>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              w="100%"
              mb="2"
            >
              <Box
                fontSize={'1.25rem'}
                fontFamily="jhaSemiboldItalic"
                color="brand.500"
              >
                {coverNumber}
              </Box>
              <Box>
                <Field name="number">
                  {({
                    field,
                    form: { setFieldValue, setTouched, touched },
                  }: FieldProps) => (
                    <FormControl
                      variant="floating"
                      flex={1}
                      isInvalid={
                        !!((!field.value || field.value == 0) && touched.number)
                      }
                    >
                      <NumberInput
                        defaultValue={0}
                        min={0}
                        display={'flex'}
                        w="11.5rem"
                        gap="0.5rem"
                        onChange={(value) => {
                          setFieldValue(field.name, value);
                        }}
                        onClick={() => {
                          setTouched({ ...touched, number: true });
                        }}
                      >
                        <NumberDecrementStepper
                          bg="#FFFFFF"
                          _active={{ opacity: '0.5' }}
                          borderRadius="100em !important"
                          w="2.75rem"
                          h="2.75rem"
                          lineHeight="1.75rem"
                          color="brand.500"
                          fontSize="1.375rem"
                          onClick={() => {
                            setTouched({ ...touched, number: true });
                          }}
                        >
                          -
                        </NumberDecrementStepper>
                        <NumberInputField
                          w="5rem"
                          border="none !important"
                          bg="#FFFFFF"
                          borderRadius="100em !important"
                          textAlign="center"
                          padding="0 !important"
                          h="2.75rem"
                        />
                        <NumberIncrementStepper
                          bg="#FFFFFF"
                          _active={{ opacity: '0.5' }}
                          borderRadius="100em !important"
                          w="2.75rem"
                          h="2.75rem"
                          lineHeight="1.75rem"
                          color="brand.500"
                          fontSize="1.375rem"
                          onClick={() => {
                            setTouched({ ...touched, number: true });
                          }}
                        >
                          +
                        </NumberIncrementStepper>
                      </NumberInput>
                    </FormControl>
                  )}
                </Field>
              </Box>
            </Box>

            <Field name="date">
              {({ field, form }: FieldProps) => (
                <FormControl
                  variant="floating"
                  flex={1}
                  isInvalid={!!(form.errors.date && form.touched.date)}
                >
                  <Input
                    w="100%"
                    {...field}
                    type="date"
                    size="lg"
                    pr="3"
                    minHeight="3.6rem"
                    width={'100% !important'}
                    sx={{
                      '::-webkit-calendar-picker-indicator': {
                        opacity: '.75',
                      },
                      // '::-webkit-appearance': 'none',
                      appearance: 'none',
                      '::before': {
                        content: `"${datePlaceholder}"`,
                        display: isIOS ? 'block' : 'none',
                      },
                    }}
                    onFocus={(e) => {
                      setIsIOS(false);
                    }}
                  />
                  <FormLabel>{date}</FormLabel>
                </FormControl>
              )}
            </Field>

            <Field name="hours">
              {({
                field,
                form: { setFieldValue, setTouched, touched },
              }: FieldProps) => (
                <FormControl
                  isInvalid={
                    !!((!field.value || field.value === '') && touched.hours)
                  }
                >
                  <Select
                    mb={4}
                    placeholder={hourly}
                    border="none !important"
                    onChange={(e) => {
                      setFieldValue(field.name, e.target.value);
                    }}
                    onClick={() => {
                      setTouched({ ...touched, hours: true });
                    }}
                    color={
                      field.value === ''
                        ? 'brand.500 !important'
                        : 'gray.500 !important'
                    }
                    fontSize={
                      field.value === ''
                        ? '1.25rem !important'
                        : '1rem !important'
                    }
                  >
                    <optgroup label={lunch}>
                      {hoursAM.map((hour) => (
                        <option key={hour.text} value={hour.text}>
                          {hour.text}
                        </option>
                      ))}
                    </optgroup>
                    <optgroup label={dinner}>
                      {hoursPM.map((hour) => (
                        <option key={hour.text} value={hour.text}>
                          {hour.text}
                        </option>
                      ))}
                    </optgroup>
                  </Select>
                </FormControl>
              )}
            </Field>

            <Heading
              as="h2"
              fontSize={['24px']}
              fontFamily="samsungSharpSans"
              fontWeight="bold"
              mt="4"
              mb="2"
            >
              {contactInformation}
            </Heading>

            <Field name="name">
              {({ field, form }: FieldProps) => (
                <FormControl
                  flex={1}
                  variant="floating"
                  isInvalid={!!(form.errors.name && form.touched.name)}
                >
                  <Input w="100%" {...field} size="lg" placeholder=" " />
                  <FormLabel>{name}</FormLabel>
                </FormControl>
              )}
            </Field>

            <Field name="email">
              {({ field, form }: FieldProps) => (
                <FormControl
                  variant="floating"
                  flex={1}
                  isInvalid={!!(form.errors.email && form.touched.email)}
                >
                  <Input w="100%" {...field} size="lg" placeholder=" " />
                  <FormLabel>{email}</FormLabel>
                </FormControl>
              )}
            </Field>

            <Field name="phone">
              {({ field, form }: FieldProps) => (
                <FormControl
                  variant="floating"
                  flex={1}
                  isInvalid={!!(form.errors.phone && form.touched.phone)}
                >
                  <Input w="100%" {...field} size="lg" placeholder=" " />
                  <FormLabel>{phone}</FormLabel>
                </FormControl>
              )}
            </Field>

            <Field name="message">
              {({ field, form }: FieldProps) => (
                <FormControl
                  variant="floating"
                  isInvalid={!!(form.errors.message && form.touched.message)}
                >
                  <Textarea
                    className="ignoreSmoothScroll"
                    minH="250px"
                    borderWidth="2px !important"
                    w="100%"
                    bgColor="white"
                    fontFamily="jhaSemilight"
                    border="none"
                    borderRadius="2px"
                    color="brand.500"
                    pt={7}
                    px={6}
                    {...field}
                    size="lg"
                    placeholder=" "
                    rows={5}
                    overflow="auto"
                  />
                  <FormLabel>{comment}</FormLabel>
                </FormControl>
              )}
            </Field>

            <Box
              display="flex"
              mt={5}
              justifyContent="space-between"
              flexDir={['column', null, 'row']}
              alignItems={['left', null, 'center']}
            >
              <Field name="cgu">
                {({ field, form }: FieldProps) => (
                  <Checkbox
                    alignItems="top"
                    colorScheme="brand"
                    fontFamily="jhaSemilight"
                    color="brand.500"
                    {...field}
                    isChecked={form.values.cgu}
                    isInvalid={!!(form.errors.cgu && form.touched.cgu)}
                  >
                    <chakra.span fontSize={'18px'}>
                      {accept}{' '}
                      <Link
                        style={{ textDecoration: 'underline' }}
                        href={linkCgu.href}
                        target={linkCgu.target}
                        onClick={onClose}
                      >
                        {cgu}
                      </Link>{' '}
                      {and}{' '}
                      <Link
                        style={{ textDecoration: 'underline' }}
                        href={linkPolicy.href}
                        target={linkPolicy.target}
                        onClick={onClose}
                      >
                        {policy}
                      </Link>
                      .
                    </chakra.span>
                  </Checkbox>
                )}
              </Field>
            </Box>

            <Button
              mr={['auto', null, 0]}
              mt={[4, null, 0]}
              isLoading={props.isSubmitting}
              rightIcon={
                <chakra.span
                  fontSize={['42px', null, '56px']}
                  position="relative"
                  left="-1"
                  className="icon-elmes-arrow1-white"
                />
              }
              type="submit"
              variant="brand"
              size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
            >
              {send}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
