'use client';

import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useTheme,
} from '@chakra-ui/react';
import {
  CodeComponent,
  KustomMedia,
  KustomPageComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediasComponentData,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import MediasViewerContext from '@/contexts/MediasViewerContext';
import ParallaxElement from '../ParallaxElement';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';

import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useLazyAutoplay from '@/lib/kustomcms-sdk/lib/hooks/useLazyAutoplay';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import { SvgShape } from '../SvgShape';

const MasonryGrid = dynamic(() => import('./MasonryGrid').then((mod) => mod), {
  loading: () => <Box />,
  ssr: false,
});

const nColumns = Array(2).fill(0);

interface GallerySectionProps
  extends KustomPageComponentViewProps<TextComponentData> {}

const GallerySection: React.FC<GallerySectionProps> = (props) => {
  const { page } = props;

  const theme = useTheme();

  const [currentGalleryIndex, setCurrentGalleryIndex] = useState(0);
  const [externalVideoViewer, setExternalVideoViewer] = useState('');

  const { labelsCmp, extraMedias, galleryCmps } = useMemo(() => {
    const labelsCmp = page.components
      .map((cmp) => (cmp.type === 'text' ? cmp : null))
      .filter(
        (cmp) => cmp !== null,
      ) as KustomPageComponent<TextComponentData>[];

    const extraMedias = page.components
      .map((cmp) =>
        cmp.type === 'text'
          ? (
              (cmp.data as TextComponentData).atomicComponents.filter(
                (acmp) => acmp.type === 'CODE',
              ) as CodeComponent[]
            ).map((acmp) => acmp.code)
          : null,
      )
      .filter((cmp) => !!cmp) as string[][];

    const galleryCmps = page.components.filter(
      (cmp) => cmp.type === 'medias',
    ) as KustomPageComponent<MediasComponentData>[];

    return {
      labelsCmp,
      extraMedias,
      galleryCmps,
    };
  }, [page]);

  const goToAnchor = (index: number) => {
    const anchor = document.getElementById('anchor-gallery-' + index);

    if (anchor) {
      // anchor.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'start',
      // });
      window.scrollTo({
        behavior: 'smooth',
        top:
          anchor.getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          110,
      });
    }
  };

  const lazyAutoplayBind = useLazyAutoplay();

  return (
    <Box
      display="flex"
      flexDir={['column', null, null, null, 'row']}
      w="100%"
      minH="40"
      py={[8, null, null, 16, null, 20]}
    >
      <Box flex="1" pl={[0, null, null, null, 10]} zIndex={1}>
        <Box
          position="sticky"
          top={164}
          py={8}
          display="flex"
          flexDir={['column', null, 'row', null, 'column']}
          justifyContent={'center'}
        >
          {labelsCmp.map((labelCmp, index) => (
            <GallerySectionTitle
              key={labelCmp.id}
              page={page}
              component={labelCmp}
              onClick={() => goToAnchor(index)}
              isCurrentGallery={index === currentGalleryIndex}
            />
          ))}
        </Box>
      </Box>

      <Box
        flex="4"
        pr={[4, null, null, 16]}
        pl={[4, null, null, 16, 0]}
        position="relative"
      >
        <ParallaxElement
          Element={() => <SvgShape file="illu-algue-1" />}
          top={300}
          right={0}
          width="200px"
        />
        <ParallaxElement
          Element={() => <SvgShape file="illu-crabe" />}
          fill={theme.colors.accent[500]}
          top={'50%'}
          left={[-20, null, null, null, '-300']}
          width={'300px'}
        />
        <ParallaxElement
          Element={() => <SvgShape file="illu-coquillage-1" />}
          bottom={[100, null, null, null, 0]}
          right={100}
          width={['180px', null, null, null, '250px']}
        />
        <MasonryGrid
          columnsClassName="masonryGrid-col"
          itemsClassName="masonryGrid-item"
        >
          {nColumns.map((_, index) => (
            <Box
              key={index}
              className="masonryGrid-col"
              mr={
                index !== nColumns.length - 1 ? [0, 4, null, null, null, 10] : 0
              }
              display={['block', ['none', null, null, 'block']][index]}
              w={['100%', null, null, '50%']}
              // flexGrow="1"
            />
          ))}
          {galleryCmps.map((cmp, galleryIndex) => {
            const medias = cmp.data.medias;

            const _extraMedias = extraMedias[galleryIndex];

            return (
              <>
                {_extraMedias?.map((mediaUrl, index) => (
                  <GalleryItem
                    key={mediaUrl}
                    mediaUrl={mediaUrl}
                    galleryMedias={medias}
                    galleryIndex={galleryIndex}
                    index={index}
                    setCurrentGalleryIndex={setCurrentGalleryIndex}
                    setExternalVideoViewer={setExternalVideoViewer}
                  />
                ))}
                {medias.map((media, index) => (
                  <GalleryItem
                    key={media.id}
                    media={media}
                    galleryMedias={medias}
                    galleryIndex={galleryIndex}
                    index={index + (_extraMedias?.length || 0)}
                    mediasIndex={index}
                    setCurrentGalleryIndex={setCurrentGalleryIndex}
                  />
                ))}
              </>
            );
          })}
        </MasonryGrid>
        <Modal
          isOpen={!!externalVideoViewer}
          onClose={() => setExternalVideoViewer('')}
          size="full"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton
              zIndex={3}
              bgColor="white"
              color="brand.500"
              size="lg"
              right={1.5}
            />
            <ModalBody p={0} position="relative">
              <video
                {...lazyAutoplayBind}
                loop
                controls
                style={{
                  height: '100%',
                  objectFit: 'cover',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <source src={externalVideoViewer} type="video/mp4" />
              </video>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

interface GalleryItemProps {
  media?: KustomResponsiveMedia;
  mediaUrl?: string;
  galleryMedias: KustomResponsiveMedia[];
  galleryIndex: number;
  mediasIndex?: number;
  index: number;
  setCurrentGalleryIndex: (index: number) => void;
  setExternalVideoViewer?: (url: string) => void;
}

const GalleryItem: React.FC<GalleryItemProps> = (props) => {
  const {
    media,
    mediaUrl,
    galleryMedias,
    setCurrentGalleryIndex,
    setExternalVideoViewer,
    galleryIndex,
    mediasIndex,
    index,
  } = props;

  const device = useResponsiveMediasDevice();
  const mediasViewer = useContext(MediasViewerContext);

  const currentMedia = (media?.[device as keyof KustomResponsiveMedia] ||
    media?.default) as KustomMedia | undefined;

  const { ref, inView } = useInView({});

  useEffect(() => {
    if (inView) {
      setCurrentGalleryIndex(galleryIndex);
    }
  }, [inView]);

  const ratio = useMemo(() => {
    const w = +(currentMedia?.metadata.width || 0);
    const h = +(currentMedia?.metadata.height || 0);

    return w > h ? '16 / 9' : w < h ? '9 / 16' : '1 / 1';
  }, []);

  const lazyAutoplayBind = useLazyAutoplay();

  return (
    <Box
      ref={ref}
      id={index === 0 ? 'anchor-gallery-' + galleryIndex : undefined}
      className={'masonryGrid-item masonryGrid-item' + (index + 1)}
      data-item-height-value={currentMedia?.metadata.height || '200'}
      position="relative"
      borderRadius="base"
      overflow="clip"
      mb={[4, null, null, 6, null, 10]}
      onClick={
        media
          ? () => {
              mediasViewer.setMedias(galleryMedias);
              mediasViewer.setIndex(mediasIndex || 0);
            }
          : () => setExternalVideoViewer?.(mediaUrl || '')
      }
    >
      <Box
        cursor="pointer"
        transition="transform 0.3s"
        role="group"
        _hover={{
          transform: 'scale(1.2)',
        }}
        width="100%"
        sx={{
          aspectRatio: ratio,
        }}
        overflow="clip"
      >
        {currentMedia ? (
          <ResponsiveMedias
            value={media!}
            currentDevice={device}
            fill
            sizes={chakraResponsiveToImageSizes(['100vw', null, null, '50vw'])}
            width={undefined}
            height={undefined}
          />
        ) : (
          <video
            {...lazyAutoplayBind}
            loop
            muted
            playsInline
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          >
            <source src={mediaUrl} type="video/mp4" />
          </video>
        )}
      </Box>
    </Box>
  );
};

interface GallerySectionTitleProps
  extends KustomPageComponentViewProps<TextComponentData> {
  isCurrentGallery: boolean;
  onClick: () => void;
}

const GallerySectionTitle: React.FC<GallerySectionTitleProps> = (props) => {
  const { page, component, onClick, isCurrentGallery } = props;

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    'data.text',
  );

  return (
    <Box onClick={onClick} cursor="pointer" role="group" mr={[0, null, 6]}>
      <RichText
        fontFamily="jhaSemibold"
        color="brand.500"
        textAlign={['center', null, 'unset']}
        fontSize={['20px', null, null, '22px', null, '28px']}
        transition="color 0.2s"
        {...(isCurrentGallery && {
          color: 'brand.100',
        })}
        _groupHover={{
          color: 'brand.100',
        }}
        {...titleBind}
        as="h3"
      />
    </Box>
  );
};

export default GallerySection;
