import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  JobOfferComponentData,
  KustomPage,
  KustomPageComponent,
  RecruitmentCity,
  contractTypes,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext } from 'react';

import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import moment from 'moment';
import { useRouter } from 'next/router';

interface RecruitmentListTableProps {
  recruitmentCities?: RecruitmentCity[];
  jobOffers: KustomPage[];
}

const titles = {
  fr: ['Offres', 'Contrat', 'Lieu de travail', 'Publié le'],
  en: ['Offers', 'Contract', 'Workplace', 'Published on'],
  es: ['Ofertas', 'Contrato', 'Lugar de trabajo', 'Publicado el'],
  de: ['Angebote', 'Vertrag', 'Arbeitsplatz', 'Veröffentlicht am'],
};

const RecruitmentListTable: React.FC<RecruitmentListTableProps> = (props) => {
  const { recruitmentCities, jobOffers } = props;
  const router = useRouter();

  const { currentLang } = useContext(LanguageContext);

  return (
    <TableContainer>
      <Table variant="simple" mt={14}>
        <Thead>
          <Tr h="20">
            {titles[currentLang].map((title, index) => (
              <Th
                key={index}
                color="brand.500"
                fontFamily="body"
                textTransform="none"
                fontSize="14px"
              >
                {title}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {jobOffers.map((jobOffer, index) => {
            const jobOfferCmp = jobOffer.components.find(
              (component) => component.type === 'jobOffer',
            );

            const {
              title,
              contractType: contractTypeValue,
              locationId,
            } = jobOfferCmp?.data as KustomPageComponent<JobOfferComponentData>['data'];

            const location = recruitmentCities?.find(
              (city) => city.id === locationId,
            );

            const contractType = contractTypes?.find(
              (type) => type.value === contractTypeValue,
            );

            const createdAt = new Date(jobOffer.createdAt || new Date());

            return (
              <Tr
                key={jobOffer._id}
                w="100%"
                h={20}
                bgColor={index % 2 ? 'transparent' : 'white'}
                borderRadius="lg"
                color="brand.500"
                cursor="pointer"
                onClick={() => {
                  router.push(
                    jobOffer.prettyUrl[currentLang || 'fr'] ||
                      jobOffer.prettyUrl.fr,
                  );
                }}
              >
                <Td>
                  <TranslatedString color="brand.500">{title}</TranslatedString>
                </Td>
                <Td>{contractType?.label}</Td>
                <Td>{location?.name}</Td>
                <Td>{moment(createdAt).format('DD/MM/YYYY')}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default RecruitmentListTable;
