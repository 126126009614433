import { KUSTOM_URL, ESTABLISHMENT_ID, KUSTOM_SERVER_URL } from '@/config';
import getKustomClient from '@/lib/kustomcms-sdk/lib';

const getKustomClientInstance = () =>
  getKustomClient({
    establishmentId: ESTABLISHMENT_ID,
    kustomAppUrl: KUSTOM_URL,
    kustomApiUrl: KUSTOM_SERVER_URL,
  });

export default getKustomClientInstance;
