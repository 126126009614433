'use client';

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import { Media, mediasActions } from '../../features/medias/slice';
import { useLayoutEffect, useRef } from 'react';

import AdminThemeProvider from '../AdminThemeProvider';
import { ClientComponent } from '../../../HOC/ClientComponent';
import MediaSEO from './MediaSEO';
import MediasFinder from './MediasFinder';
import MediasProperties from './MediasProperties';
import MediasPublicFinder from './MediasPublicFinder';
import MediasTags from './MediasTags';
import StorageLimit from './StorageLimit';
import UploadCanvas from './UploadCanvas';
import { shallowEqual } from 'react-redux';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useKustomSelector } from '../../hooks/useKustomSelector';
import useMediaRemove from '../../hooks/useMediaRemove';
import useUpload from '../../hooks/useUpload';

interface MediasPickerProps {
  isOpen: boolean;
  onClose: () => void;
  onPickMedias: (m: Media[]) => void;
  singleMedia?: boolean;
}

const MediasPicker = (props: MediasPickerProps) => {
  const { isOpen, onClose, onPickMedias, singleMedia } = props;

  const dispatch = useAppDispatch();
  const { medias, upload } = useKustomSelector(
    (state) => ({ medias: state.medias, upload: state.upload }),
    shallowEqual,
  );

  const openUploadRef = useRef<() => void>(null);

  useLayoutEffect(() => {
    if (isOpen) {
      dispatch(mediasActions.selectMedias([]));
    }
  }, [isOpen, dispatch]);

  const { handleUpload, handleReupload } = useUpload();
  const { wantRemoveMedias, modalRemoveMedia } = useMediaRemove();

  const scrollerRef = useRef<HTMLDivElement>(null);

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        // ref={scrollerRef}
        className="ignoreSmoothScroll"
        maxW="90%"
        height={'90%'}
        overflow="scroll !important"
        bgColor="gray.50"
      >
        <AdminThemeProvider>
          <ModalCloseButton size="lg" />
          <ModalBody mx={7} mr={14} my={4}>
            <Box display="flex">
              <Box
                display="flex"
                flexDir="column"
                flex={3}
                mr={6}
                alignSelf="flex-start"
              >
                <UploadCanvas
                  openUploadRef={openUploadRef}
                  mb={4}
                  onFileUpload={handleUpload}
                />
                <MediasPublicFinder />
                <MediasFinder
                  scrollableContainerRef={scrollerRef}
                  medias={medias.establishmentMedias}
                  selectedMedias={medias.selectedMedias}
                  pendingUploads={upload.pendingUploads}
                  failedUploads={upload.failedUploads}
                  isLoading={medias.getEstablishmentMedias.pending}
                  onFailedUploadClick={handleReupload}
                  onRemoveSelectedMedias={wantRemoveMedias}
                  onMediaPick={
                    singleMedia ? (media) => onPickMedias([media]) : undefined
                  }
                />
              </Box>
              <Box
                display="flex"
                flex={1}
                flexGrow={1}
                alignSelf="flex-start"
                flexDir="column"
              >
                {!singleMedia && (
                  <Button
                    colorScheme="brand"
                    py={6}
                    mb={4}
                    disabled={!medias.selectedMedias.length}
                    onClick={() => {
                      onPickMedias(medias.selectedMedias);
                    }}
                  >
                    VALIDER
                  </Button>
                )}
                <StorageLimit />
                <MediasProperties medias={medias.selectedMedias} />
                {!!medias.selectedMedias.length && <MediasTags />}
                {medias.selectedMedias.length === 1 && (
                  <MediaSEO media={medias.selectedMedias[0]!} />
                )}
              </Box>
            </Box>
            {modalRemoveMedia}
          </ModalBody>
        </AdminThemeProvider>
      </ModalContent>
    </Modal>
  );
};

export default ClientComponent(MediasPicker);
