import { ActionButtonComponent, KustomTranslatedString } from '../../types';
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useContext, useEffect, useMemo, useState } from 'react';
import useInput, { useInputBind } from '../hooks/useInput';

import AdminThemeProvider from './AdminThemeProvider';
import Input from './atomics/Input';
import LanguageContext from '../../contexts/language';
import { uniqID } from '../helpers/random';

const buttonTarget = [
  { value: '_blank', label: '_blank (Nouvel onglet)' },
  { value: '_self', label: '_self (Défaut)' },
  {
    value: '_parent',
    label: '_parent (Contexte navigation parent, sinon _self)',
  },
  { value: '_top', label: '_top (Contexte navigation supérieur, sinon _self)' },
];

const AddButtonAction = (props: {
  isOpen: boolean;
  button: ActionButtonComponent;
  onChange: (button: ActionButtonComponent) => void;
  onClose: () => void;
}) => {
  const { isOpen, button, onChange, onClose } = props;

  const [action, setAction] = useState('');
  const [actionString, setActionString] = useState('');
  const [target, setTarget] = useState('');

  const [buttonLabel, bindButtonLabel, _, setButtonLabel] =
    useInput('En savoir plus');

  const [_1, linkToBEBind, __1, setLinkToBE] = useInput(
    action === 'LINK_TO_BE' ? actionString || '' : '',
    'Placeholder',
  );
  const [_2, emailBind, __2, setEmail] = useInput(
    action === 'EMAIL' ? actionString || '' : '',
    'email@email.com',
  );
  const [_3, internalLinkBind, __3, setInternalLink] = useInput(
    action === 'LINK_INTERNAL' ? actionString || '' : '',
    "Page d'accueil",
  );
  const [_4, externalLinkBind, __4, setExternalLink] = useInput(
    action === 'LINK_EXTERNAL' ? actionString || '' : '',
    'https://www.diadao.fr',
  );

  const language = useContext(LanguageContext);
  const currentLanguage = language.currentLang;

  useEffect(() => {
    if (isOpen) {
      setButtonLabel(button?.label[currentLanguage] || 'En savoir plus');
      setAction(button?.action || '');
      setActionString(button?.actionString || '');
      setTarget(button?.target || '');

      if (button?.action === 'LINK_TO_BE') {
        setLinkToBE(button?.actionString || '');
      } else if (button?.action === 'EMAIL') {
        setEmail(button?.actionString || '');
      } else if (button?.action === 'LINK_INTERNAL') {
        setInternalLink(button?.actionString || '');
      } else if (button?.action === 'LINK_EXTERNAL') {
        setExternalLink(button?.actionString || '');
      }
    }
  }, [
    currentLanguage,
    button,
    isOpen,
    setButtonLabel,
    setLinkToBE,
    setEmail,
    setInternalLink,
    setExternalLink,
  ]);

  const buttonActions: {
    label: string;
    value: string;
    inputBind: useInputBind;
  }[] = useMemo(
    () => [
      {
        label: 'Lien vers le BE',
        value: 'LINK_TO_BE',
        inputBind: linkToBEBind,
      },
      {
        label: 'Email',
        value: 'EMAIL',
        inputBind: emailBind,
      },
      {
        label: 'Lien interne au site',
        value: 'LINK_INTERNAL',
        inputBind: internalLinkBind,
      },
      {
        label: 'Lien externe au site',
        value: 'LINK_EXTERNAL',
        inputBind: externalLinkBind,
      },
    ],
    [emailBind, externalLinkBind, internalLinkBind, linkToBEBind],
  );

  const validate = () => {
    onChange({
      id: button?.id || uniqID(),
      type: 'ACTION_BUTTON',
      label: { ...button?.label, [currentLanguage]: buttonLabel },
      action,
      actionString,
      target,
    });
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent pt={8} pb={6} px={16}>
          <AdminThemeProvider>
            <ModalCloseButton />
            <ModalBody display="flex" flexDir="column" px={4} m={8}>
              <Heading textStyle="heading" mb={10} fontSize="32px">
                Ajouter un bouton
              </Heading>
              <Input
                {...bindButtonLabel}
                label="Texte sur le bouton"
                isFullWidth
                maxLength={150}
                mb={2}
              />
              <RadioGroup
                w="100%"
                mt={4}
                position="relative"
                onChange={(value) => {
                  const action = buttonActions.find((a) => a.value === value);
                  setAction(value);
                  setActionString(action?.inputBind.value || '');
                }}
                value={action}
              >
                <Stack spacing={6}>
                  {buttonActions.map(({ label, value, inputBind }) => (
                    <Box
                      display="flex"
                      key={value}
                      justifyContent="space-between"
                    >
                      <Radio value={value} display="flex" flex="1" mr={5}>
                        <Text fontSize="14px">{label}</Text>
                      </Radio>
                      <Box flex="2">
                        <Input
                          mt={-1}
                          height={'56px'}
                          fontSize={'16px'}
                          {...inputBind}
                          onChange={(e) => {
                            if (action === value) {
                              setActionString(e.target.value);
                            }
                            inputBind.onChange(e);
                          }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </RadioGroup>
              <Select
                mt="10"
                bgColor="gray.50"
                border="none"
                size="lg"
                fontSize="16px"
                placeholder="Cible du bouton"
                onChange={(e) => setTarget(e.target.value)}
                value={target}
              >
                {buttonTarget.map(({ label, value }) => (
                  <option key={value} value={target}>
                    {label}
                  </option>
                ))}
              </Select>
              <Box display="flex" mt={4}>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  px={16}
                  mr={3}
                  size="xl"
                  onClick={onClose}
                >
                  ANNULER
                </Button>
                <Button
                  px={16}
                  onClick={validate}
                  colorScheme="brand"
                  size="xl"
                >
                  VALIDER
                </Button>
              </Box>
            </ModalBody>
          </AdminThemeProvider>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddButtonAction;
