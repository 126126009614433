import { Box, Fade, Text, useTheme } from '@chakra-ui/react';
import {
  KustomPageComponent,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  RoomBookingComponentData,
  RoomSpecsComponentData,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import useKustomData, {
  useKustomDataReturn,
  useKustomTranslation,
} from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

import KustomString from '@/lib/kustomcms-sdk/lib/components/KustomString';
import { Parallax } from 'react-scroll-parallax';
import React from 'react';
import RoomBooking from './RoomBooking';
import RoomDescription from './RoomDescription';

import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import { SvgShape } from '../SvgShape';

const RoomContent: React.FC<
  KustomPageComponentViewProps<RoomSpecsComponentData>
> = (props) => {
  const { component, page } = props;

  const roomDescription = usePageComponent<TextComponentData>(page, 'text');
  const roomBooking = usePageComponent<RoomBookingComponentData>(
    page,
    'roomBooking',
  );

  const bpIndex = useBreakpointIndex();
  const theme = useTheme();

  const titleBind = useKustomData(page, component, 'data.title');

  const peoplesLabelBind = useKustomTranslation('room_label_peoples');

  const peoplesNumberBind = useKustomData<string>(
    page,
    component,
    `data.peoplesNumber`,
  );

  const areaBind = useKustomData<string>(page, component, `data.area`);
  const bedBind = useKustomData<string | KustomTranslatedString>(
    page,
    component,
    `data.bed`,
  );
  const bedBindString = (
    typeof bedBind.value === 'string' ? bedBind : null
  ) as useKustomDataReturn<string> | null;
  const bedBindObject = (
    typeof bedBind.value === 'object' ? bedBind : null
  ) as useKustomDataReturn<KustomTranslatedString> | null;

  return (
    <Box order={2} mb={['24', null, null, 0]}>
      <Fade in>
        <Box
          display="flex"
          gap={'1rem'}
          pl={[0, null, 0]}
          pr={[6, null, 8, null, null, 10]}
          pt={[8, null, 10, null, null, 20]}
          pb={[4, null, 8, null, 20, 28]}
          flexDir={['column', null, null, null, 'row', null]}
        >
          <Box flex={2} zIndex={1}>
            <TranslatedString
              as="h1"
              fontFamily="jha"
              lineHeight={1}
              fontSize={['24px', null, '57px', null, null, '72px']}
              {...titleBind}
            />
            <Text
              mt={[0.5, null, 4]}
              fontFamily="jhaSemiboldItalic"
              fontSize={['14px', null, '19px', null, null, '24px']}
              display="flex"
              whiteSpace="nowrap"
              as="h2"
            >
              <KustomString as="span" {...peoplesNumberBind} />
              &nbsp;
              <TranslatedString as="span" {...peoplesLabelBind} />
              &nbsp;/&nbsp;
              <KustomString as="span" {...areaBind} />
              &nbsp;/&nbsp;
              {bedBindObject && (
                <TranslatedString as="span" {...bedBindObject} />
              )}
              {bedBindString && <KustomString as="span" {...bedBindString} />}
            </Text>
            {roomDescription && (
              <RoomDescription component={roomDescription} page={page} />
            )}
          </Box>
          <Box flex={1} position="relative">
            <Box
              display={['none', null, null, null, 'block']}
              position="absolute"
              left={bpIndex > 5 ? '-200px' : '-100px'}
              top={100}
            >
              <Parallax speed={-20}>
                <SvgShape
                  file="illu-poisson-2"
                  width={bpIndex > 5 ? '400px' : '200px'}
                />
              </Parallax>
            </Box>
            {roomBooking && <RoomBooking component={roomBooking} page={page} />}
          </Box>
        </Box>
      </Fade>
    </Box>
  );
};

export default RoomContent;
