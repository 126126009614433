import { Box, Container, useBreakpointValue } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextImageComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import AnimatePresence from '../atomics/AnimatePresence';
import { Parallax } from 'react-scroll-parallax';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import { SvgShape } from '../SvgShape';

const Section2 = (
  props: KustomPageComponentViewProps<TextImageComponentData>,
) => {
  const { component, page } = props;

  const media = component.data.media;

  const descriptionId = component.data.atomicComponents.findIndex(
    (cmp) => cmp.type === 'TEXT',
  );

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    'data.text',
  );

  const descBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${descriptionId}.text`,
  );

  const currentDevice = useResponsiveMediasDevice();

  const parallaxSketchWidth = useBreakpointValue({
    base: 160,
    lg: 340,
  });

  return (
    <Box
      bgColor="accent.100"
      display="flex"
      flexDir={['column-reverse', null, 'column']}
      position="relative"
    >
      <AnimatePresence y={60} opacity amount={0.1}>
        <Box
          width="100%"
          height={['547', null, '1659px', null, null, '2074']}
          position="relative"
          overflow="hidden"
        >
          {media && (
            <ResponsiveMedias
              value={media}
              currentDevice={currentDevice}
              style={{
                objectFit: 'cover',
              }}
              fill
              width={undefined}
              height={undefined}
            />
          )}
        </Box>
      </AnimatePresence>
      <Box
        width="100%"
        position={[null, null, 'absolute']}
        mt={[null, null, 400]}
      >
        <Container maxW="container.lg" px={[6, null, 20]}>
          <AnimatePresence x={-60} opacity>
            <Box pl={[null, null, null, 150]} mb={8}>
              <RichText
                fontFamily="heading"
                maxW={['80%', null, 'unset']}
                {...titleBind}
                lineHeight={1}
                mb={[6, null, 10, null, null, 16]}
                fontSize={['38px', null, '96px', null, null, '120px']}
                color={['brand.500', null, 'white']}
                as="h2"
              />
              {descBind.value && (
                <RichText
                  fontSize={['18px', null, '29px', null, null, '36px']}
                  fontFamily="jhaSemilight"
                  color={['brand.500', null, 'white']}
                  {...descBind}
                  as="p"
                />
              )}
            </Box>
          </AnimatePresence>
        </Container>
      </Box>
      <Box
        position="absolute"
        ml={10}
        bottom={[50, null, 400]}
        left={[0, null, null, 300]}
      >
        <Parallax speed={20}>
          <SvgShape
            file="illu-crabe-white"
            width={`${parallaxSketchWidth}px`}
          />
        </Parallax>
      </Box>
    </Box>
  );
};

export default Section2;
