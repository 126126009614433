import {
  AtomicComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediaComponent,
  TextComponent,
  TextImageComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Container, useTheme } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import { Parallax } from 'react-scroll-parallax';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';

import Slideshow from '../atomics/Slideshow';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import { uniqID } from '@/lib/kustomcms-sdk/lib/admin/helpers/random';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { SvgShape } from '../SvgShape';

const RoomsBreakfast: React.FC<
  KustomPageComponentViewProps<TextImageComponentData>
> = (props) => {
  const { page, component } = props;
  const theme = useTheme();

  const mediaBind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.media`,
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const [text2Id, text3Id] = component.data.atomicComponents
    .map((atomicComponent, index) =>
      atomicComponent.type === 'TEXT' ? index : null,
    )
    .filter((index) => index !== null);

  const text2Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${text2Id}.text`,
  );

  const text3Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${text3Id}.text`,
  );

  const _medias = component.data.atomicComponents.filter(
    (atomicComponent) => atomicComponent.type === 'MEDIA',
  ) as MediaComponent[];

  const bpIndex = useBreakpointIndex();
  const medias = [mediaBind.value, ..._medias.map((m) => m.media)].filter(
    (m) => !!m,
  ) as KustomResponsiveMedia[];

  const atomsBind = useKustomData<AtomicComponent[]>(
    page,
    component,
    `data.atomicComponents`,
  );

  const onMediasChange = useMemo(
    () =>
      atomsBind.onChange
        ? (_medias: KustomResponsiveMedia[]) => {
            const [firstMedia, ...medias] = _medias;

            if (firstMedia) {
              mediaBind.onChange?.(firstMedia);
            }
            const atomsNoMedias = atomsBind.value?.filter(
              (cmp) => cmp.type !== 'MEDIA',
            );

            const newAtoms = medias.map((media) => {
              const mediaAtomCmp = (atomsBind.value?.find(
                (cmp) =>
                  cmp.type === 'MEDIA' &&
                  (cmp as MediaComponent).media.id === media.id,
              ) || {
                id: uniqID(),
                type: 'MEDIA',
                media,
              }) as MediaComponent;

              mediaAtomCmp.media = media;

              return mediaAtomCmp;
            });

            atomsBind.onChange?.([...newAtoms, ...(atomsNoMedias || [])]);
          }
        : undefined,
    [atomsBind.onChange, atomsBind.value],
  );

  return (
    <>
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        position="relative"
        overflowX="clip"
        px={[6, null, 20]}
        pt={[20, null, 32]}
        pb={[10, null, 20]}
      >
        <Box
          display={['block']}
          position="absolute"
          right={[-40, null, 10]}
          zIndex={-1}
        >
          <Parallax speed={-20}>
            <SvgShape
              file="illu-coquillage-1"
              style={{
                transform: 'rotate(-90deg)',
              }}
              width={bpIndex > 5 ? '400px' : '320px'}
            />
          </Parallax>
        </Box>
        <Box position="relative">
          <RichText
            fontFamily="heading"
            fontSize={['48px', null, '96px']}
            color="brand.500"
            {...textBind}
            as="h2"
          />

          <RichText
            fontFamily="jhaSemiboldItalic"
            color="brand.500"
            fontSize={['20px', null, '28px']}
            {...text2Bind}
            as="h3"
          />

          <RichText
            mt={[12, null, 20]}
            fontSize={['20px', null, '28px']}
            fontFamily="jhaSemilight"
            color="brand.500"
            mr={[0, null, 60]}
            {...text3Bind}
          />
        </Box>
      </Container>
      {medias && (
        <Container
          maxW={['container.xl', null, null, null, null, '1780px']}
          px={[6, null, 20]}
          pb={[6, null, 20]}
        >
          <Box w="100%" h={['420px', null, '819px']} position="relative">
            <Slideshow
              usingMediaViewer
              value={medias}
              onChange={onMediasChange}
              hideArrows
              hideCounter
              sizes={chakraResponsiveToImageSizes([
                null,
                null,
                null,
                null,
                null,
                '1780px',
              ])}
              height={['420px', null, '819px']}
              containerStyle={{
                w: '100%',
                // flexBasis: ['100%', null, '663px', null, null, '829px'],
                borderRadius: 'base',
              }}
            />
          </Box>
        </Container>
      )}
    </>
  );
};

export default RoomsBreakfast;
