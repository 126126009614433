import { ChakraTheme, extendTheme } from '@chakra-ui/react';

import { cssVar } from '@chakra-ui/theme-tools';

const inputXLSize = {
  fontSize: 'lg',
  px: 4,
  h: '58px',
  borderRadius: 'md',
};

const $bg = cssVar('tooltip-bg');
const $arrowBg = cssVar('popper-arrow-bg');

const defaultTheme = {
  fonts: {
    heading: 'Montserrat, sans-serif',
    body: 'Nunito, sans-serif',
  },
  colors: {
    backgroundSecondary: '#F6F7FB',
    navBarDividerColor: '#28394f',
    brand: {
      10: 'rgba(30, 136, 229, 0.1)',
      20: 'rgba(30, 136, 229, 0.2)',
      50: '#ebeffb',
      500: '#1E88E5',
      600: '#1B73C1',
      700: '#0158C2',
      800: '#0b2b4e',
      850: '#0A1E4A',
      900: '#081B34',
    },
    gray: {
      50: '#F6F7FB',
      100: '#eff0f1',
      200: '#E9E9E9',
      300: '#CBD5E0',
      400: '#B3BAD1',
      500: '#60636D',
      600: '#4A5568',
      700: '#23344a',
      800: '#0B283A',
      900: '#171923',
      1000: '#101010',
    },
    green: {
      400: '#61c081',
      500: '#3cb164',
    },
    red: {
      400: '#e84747',
      500: '#e00d0d',
    },
    orange: {
      500: '#CC8100',
    },
    cyan: {
      500: '#00B7CC',
    },
  },
  sizes: {
    inputHeight: 58,
  },
  textStyles: {
    heading: { letterSpacing: '-1px', color: '#0A1E4A' },
    brand: { color: '#0A1E4A' },
    text: { color: 'gray.900', fontSize: 14, fontFamily: 'body' },
    textSecondary: { color: '#60636D', fontSize: 14 },
  },
  components: {
    Alert: {
      baseStyle: {
        container: {
          // admin UI bar
          my: -20,
        },
      },
    },
    Input: {
      sizes: {
        xl: {
          field: inputXLSize,
          addon: inputXLSize,
        },
      },
    },
    MenuButton: {
      sizes: {
        xl: {
          h: '56px',
          fontSize: 14,
          letterSpacing: '1.26px',
          fontWeight: '700',
        },
      },
    },
    Button: {
      sizes: {
        xl: {
          h: '56px',
          fontSize: 14,
          letterSpacing: '1.26px',
          fontWeight: '700',
        },
        md: {
          fontSize: 14,
        },
      },
      variants: {
        brandIcon: {
          backgroundColor: 'brand.800',
        },
        darkIcon: {
          backgroundColor: 'gray.1000',
        },
      },
    },
    Tooltip: {
      baseStyle: {
        [$bg.variable]: 'colors.white',
        [$arrowBg.variable]: [$bg.reference],
        color: 'gray.700',
        fontSize: '12',
        padding: 2,
        boxShadow: '0px 0px 6px #00000029',
      },
    },
  },
};

const theme = extendTheme(defaultTheme);

export type CustomTheme = ChakraTheme;

export default theme;
