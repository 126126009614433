import { Box, BoxProps, Heading } from '@chakra-ui/react';

import IconCamera from '@/lib/kustomcms-sdk/lib/icons/icon-camera.svg';
import { getCSSDashedBorder } from '../../helpers/getCSSDashedBorder';
import useTheme from '../../hooks/useTheme';

const PickMediasButton = (props: BoxProps) => {
  const theme = useTheme();

  const mediaBoxProps: BoxProps = {
    display: 'flex',
    m: 1,
    // flex: '1 0 auto',
    // alignItems: 'stretch',
    justifyContent: 'center',
    position: 'relative',
    borderWidth: 1,
    borderColor: 'brand',
    borderRadius: 'base',
    _before: {
      content: '""',
      display: 'block',
      paddingTop: '100%',
    },
  };

  return (
    <Box
      {...mediaBoxProps}
      className="ignore-elements"
      alignItems="center"
      borderWidth={0}
      bgColor="gray.50"
      // backgroundImage="repeating-linear-gradient(0deg, #B3BAD1, #B3BAD1 12px, transparent 12px, transparent 18px, #B3BAD1 18px), repeating-linear-gradient(90deg, #B3BAD1, #B3BAD1 12px, transparent 12px, transparent 18px, #B3BAD1 18px), repeating-linear-gradient(180deg, #B3BAD1, #B3BAD1 12px, transparent 12px, transparent 18px, #B3BAD1 18px), repeating-linear-gradient(270deg, #B3BAD1, #B3BAD1 12px, transparent 12px, transparent 18px, #B3BAD1 18px)"
      // backgroundSize="1px 100%, 100% 1px, 1px 100% , 100% 1px"
      // backgroundPosition="0 0, 0 0, 100% 0, 0 100%"
      // @ts-ignore
      {...getCSSDashedBorder('#B3BAD1')}
      backgroundRepeat="no-repeat"
      cursor="pointer"
      role="group"
      _hover={{
        // @ts-ignore
        ...getCSSDashedBorder(theme.colors.brand['500']),
      }}
      {...props}
    >
      <Box
        display="flex"
        flexDir="column"
        alignItems="center"
        mx={4}
        textAlign="center"
      >
        <Box
          display="none"
          _groupHover={{
            display: 'block',
          }}
        >
          <IconCamera
            // @ts-ignore
            fill={theme.colors.brand[500]}
            width="50px"
          />
        </Box>
        <Box
          _groupHover={{
            display: 'none',
          }}
        >
          <IconCamera
            // @ts-ignore
            fill={theme.colors.gray[500]}
            width="50px"
          />
        </Box>
        <Heading textStyle="heading" fontSize="18px" mt={2}>
          Ajouter une photo ou vidéo
        </Heading>
      </Box>
    </Box>
  );
};

export default PickMediasButton;
