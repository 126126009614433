import { FileWithId, uploadActions } from '../features/upload/slice';

import Toast from '../components/atomics/Toast';
import error from 'next/error';
import { title } from 'process';
import { toHumanFileSize } from '../helpers/media';
import { useAppDispatch } from './useAppDispatch';
import { useCallback } from 'react';
import { useToast } from '@chakra-ui/react';

const MAX_FILE_SIZE = 524288000;

const useUpload = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();

  const handleUpload = (files: File[], isReupload?: Number) => {
    const promises: Promise<any>[] = [];

    files.forEach((file: File) => {
      promises.push(
        dispatch(
          uploadActions.uploadFile({
            file,
            isReupload,
          }),
        ).unwrap(),
      );
    });

    Promise.all(promises)
      .then((datas) => {
        const success = datas.filter((data) => data.success).length;

        if (success) {
          toast({
            position: 'bottom-right',
            duration: 3000,
            render: (props: any) => (
              <Toast
                {...props}
                duration={3000}
                title="TRANSFERT EFFECTUÉ"
                content={`${success} média(s) ajoutée(s) à la galerie`}
              />
            ),
          });
        }
      })
      .catch((err) => {
        toast({
          position: 'bottom-right',
          duration: null,
          render: (props) => (
            <Toast
              error
              {...props}
              title="TRANSFERT ÉCHOUÉ"
              content={`Veuillez recommencer, ou essayer avec un autre média : ${err} ${
                err === 'INVALID_FILE_SIZE'
                  ? `(max ${toHumanFileSize(MAX_FILE_SIZE)})`
                  : ''
              }`}
            />
          ),
        });
      });
  };

  const handleReupload = useCallback((file: FileWithId) => {
    handleUpload([file.file], file.id);
  }, []);

  return { handleReupload, handleUpload };
};

export default useUpload;
