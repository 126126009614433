import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const downloadFile = (url: string, filename: string = 'file') => {
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    });
};

export const downloadFiles = (
  files: { url: string; filename: string }[],
  onUpdate?: (percent: number) => void,
) => {
  const zip = new JSZip();

  files.forEach((file) => {
    zip.file(
      file.filename,
      fetch(file.url).then((res) => {
        return res.blob();
      }),
    );
  });

  zip
    .generateAsync({ type: 'blob' }, (metadata) => {
      onUpdate?.(metadata.percent);
    })
    .then((content) => {
      saveAs(content, 'files.zip');
    });
};
