import { BoxProps, background } from '@chakra-ui/react';
import adminTheme from '../theme';
import { getCSSDashedBorder } from './getCSSDashedBorder';

type SetStateFunc = (f: (b: boolean) => boolean) => void;

const color = adminTheme.colors.brand['500'];
// const colorAlpha = adminTheme.colors.brand['20'];
const borderWidth = '3px';

const $containerWysiwygActiveStyle = {
  backgroundImage: 'none',
  cursor: 'pointer',
  _after: {
    content: '"Modifier"',
    lineHeight: '1',
    fontWeight: 'bold',
    color: 'white',
    bgColor: color,
    position: 'absolute',
    bottom: 1,
    borderRadius: 'base',
    fontFamily: 'Nunito, sans-serif',
    right: 1,
    fontSize: 'xs',
    py: 1.5,
    px: 2,
  },
  _before: {
    backgroundImage: `repeating-linear-gradient(0deg, ${color}, ${color}), repeating-linear-gradient(90deg, ${color}, ${color}), repeating-linear-gradient(180deg, ${color}, ${color}), repeating-linear-gradient(270deg, ${color}, ${color})`,
  },
};

const $containerWysiwygActiveStyleOpen = {
  _before: {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    PointerEvents: 'none',
    backgroundImage: `repeating-linear-gradient(0deg, ${color}, ${color}), repeating-linear-gradient(90deg, ${color}, ${color}), repeating-linear-gradient(180deg, ${color}, ${color}), repeating-linear-gradient(270deg, ${color}, ${color})`,
    backgroundSize: `${borderWidth} 100%, 100% ${borderWidth}, ${borderWidth} 100% , 100% ${borderWidth}`,
    backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
    backgroundRepeat: 'no-repeat',
  },
  // backgroundColor: `${colorAlpha}`,
};

const getCSSAdminWysiwygItem = ({
  hasWysiwygEdit,
  isWysiwygEditOpen,
  setIsOpen,
  useNativeBorder,
}: {
  hasWysiwygEdit: boolean;
  isWysiwygEditOpen: boolean;
  setIsOpen: SetStateFunc;
  useNativeBorder?: boolean;
}): BoxProps => ({
  ...(hasWysiwygEdit && {
    borderRadius: 'base',
    position: 'relative',
    boxSizing: 'content-box',
    role: 'group',
    ...(useNativeBorder
      ? {
          border: '3px dashed',
          borderColor: adminTheme.colors.brand['500'],
        }
      : getCSSDashedBorder(adminTheme.colors.brand['500'], '3px')),
    onClick: (e) => {
      e.stopPropagation();
      setIsOpen((isOp) => !isOp);
    },
  }),
  _hover: hasWysiwygEdit ? $containerWysiwygActiveStyle : undefined,
  ...(hasWysiwygEdit && isWysiwygEditOpen
    ? $containerWysiwygActiveStyleOpen
    : undefined),
});

export default getCSSAdminWysiwygItem;
