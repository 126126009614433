/* eslint-disable @next/next/no-img-element */

import {
  Box,
  Button,
  ButtonGroup,
  Center,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import Cropper from 'cropperjs';
import IconFlipX from '@/lib/kustomcms-sdk/lib/icons/icon-photo-flip-horizontal.svg';
import IconFlipY from '@/lib/kustomcms-sdk/lib/icons/icon-photo-flip-vertical.svg';
import IconRotateLeft from '@/lib/kustomcms-sdk/lib/icons/icon-photo-rotate-left.svg';
import IconRotateRight from '@/lib/kustomcms-sdk/lib/icons/icon-photo-rotate-right.svg';
import IconWarning from '@/lib/kustomcms-sdk/lib/icons/icon-warning.svg';
import { KustomMedia } from '../../../types';
import Toast from '../atomics/Toast';
import { getMediaThumbnailUrl } from '../../helpers/media';
import { mediasActions } from '../../features/medias/slice';
import { uploadActions } from '../../features/upload/slice';
import { useAppDispatch } from '../../hooks/useAppDispatch';

interface ImageEditorProps {
  image?: KustomMedia;
  isOpen: boolean;
  onClose: () => void;
}

const CANVAS_WIDTH = 900;
const CANVAS_HEIGHT = 720;

const formats = [
  {
    w: '95px',
    h: '56px',
    label: 'Paysage',
    aspectRatio: 16 / 9,
  },
  {
    w: '44px',
    h: '75px',
    label: 'Portrait',
    aspectRatio: 9 / 16,
  },
  { w: '75px', h: '75px', label: 'Carré', aspectRatio: 1 },
];

const ImageEditor = (props: ImageEditorProps) => {
  const { image, isOpen, onClose } = props;
  const [aspectRatio, _setAspectRatio] = useState<number | undefined>();
  const cropperRef = useRef<Cropper | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [isModified, setIsModified] = useState(false);
  const dispatch = useAppDispatch();
  const [isUploadNewFramingLoading, setIsUploadNewFramingLoading] =
    useState(false);
  const toast = useToast();
  const [framingToDelete, setFramingToDelete] = useState<KustomMedia>();
  const [lastFlipX, setLastFlipX] = useState(1);
  const [lastFlipY, setLastFlipY] = useState(1);

  const imageThumbnailUrl = image && getMediaThumbnailUrl(image);

  const setAspectRatio = (ratio: number) => {
    setIsModified(true);
    cropperRef.current?.crop();
    cropperRef.current?.setAspectRatio(ratio);
    _setAspectRatio(ratio);
  };

  const [currentZoom, setCurrentZoom] = useState(1);
  const [MPToSmall, setMPToSmall] = useState(false);
  const [imageOpacity, setImageOpacity] = useState(0);

  const analyzeMP = useCallback(
    (cropW: number, cropH: number) => {
      const w = cropW / currentZoom;
      const h = cropH / currentZoom;

      const imgOriginalWidth = +(image?.metadata.width || 0);
      const { width, ...all } = cropperRef.current?.getCanvasData() || {
        width: imgOriginalWidth,
      };

      const ratio = imgOriginalWidth / width;
      const mp = (w * ratio * h * ratio) / 1000000;

      setMPToSmall(mp < 2);
    },
    [currentZoom, image?.metadata.width],
  );

  useEffect(() => {
    if (isOpen && image) {
      setImageOpacity(0);
      setCurrentZoom(1);

      analyzeMP(+(image?.metadata.width || 0), +(image?.metadata.height || 0));
    }
  }, [analyzeMP, image, isOpen]);

  const imageRefHandler = useCallback(
    (node: any) => {
      _setAspectRatio(undefined);
      setIsModified(false);
      if (!node) return;
      node.src = imageThumbnailUrl;
      imageRef.current = node;
      cropperRef.current?.destroy();
      const cropper = new Cropper(node, {
        dragMode: 'move',
        autoCropArea: 1,
        background: false,
        minCropBoxWidth: 100,
        minCropBoxHeight: 100,
        zoomOnWheel: false,
        zoomOnTouch: false,
        ready: () => {
          cropper.clear();
          const imageData = cropper.getImageData();
          const zoom = imageData.width / imageData.naturalWidth;
          setCurrentZoom(zoom);
          setImageOpacity(1);
        },
      });
      cropperRef.current = cropper;
    },
    [imageThumbnailUrl],
  );

  useEffect(() => {
    if (!imageRef.current) return;

    const cropEvent = () => {
      const { width, height } = cropperRef.current?.getCropBoxData() || {
        width: 0,
        height: 0,
      };
      analyzeMP(width, height);
    };

    const zoomEvent = () => {
      setTimeout(() => {
        const imageData = cropperRef.current?.getImageData() || {
          width: 0,
          naturalWidth: 0,
        };
        const zoom = imageData.width / imageData.naturalWidth;
        setCurrentZoom(zoom);
      }, 0);
    };

    imageRef.current.addEventListener('crop', cropEvent);
    imageRef.current.addEventListener('cropmove', cropEvent);
    imageRef.current.addEventListener('zoom', zoomEvent);

    cropEvent();

    return () => {
      imageRef.current?.removeEventListener('crop', cropEvent);
      imageRef.current?.removeEventListener('cropmove', cropEvent);
      imageRef.current?.removeEventListener('zoom', zoomEvent);
    };
  }, [analyzeMP]);

  const rotateImageRight = () => {
    setIsModified(true);
    cropperRef.current?.rotate(90);
  };

  const rotateImageLeft = () => {
    setIsModified(true);
    cropperRef.current?.rotate(-90);
  };

  const zoomIn = () => {
    setIsModified(true);
    cropperRef.current?.zoom(1.01);
  };

  const zoomOut = () => {
    setIsModified(true);
    cropperRef.current?.zoom(-1.01);
  };

  const flipImageX = () => {
    setIsModified(true);
    cropperRef.current?.scaleX(-lastFlipX);
    setLastFlipX(-lastFlipX);
  };

  const flipImageY = () => {
    setIsModified(true);
    cropperRef.current?.scaleY(-lastFlipY);
    setLastFlipY(-lastFlipY);
  };

  const handleFramingRemove = () => {
    if (!framingToDelete) {
      return;
    }

    dispatch(
      mediasActions.removeMedia({
        file: framingToDelete,
        onlyFraming: true,
      }),
    );

    setFramingToDelete(undefined);
  };

  const uploadNewFraming = () => {
    if (!isModified || !image) {
      return;
    }

    const { x, y, width, height } = cropperRef.current?.getData() || {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    };

    const { naturalWidth, naturalHeight } =
      cropperRef.current?.getCanvasData() || {
        naturalWidth: +(image.metadata.width || 0),
        naturalHeight: +(image.metadata.height || 0),
      };

    const cx = x < 0 ? 0 : x > naturalWidth ? naturalWidth : x;
    const cy = y < 0 ? 0 : y > naturalHeight ? naturalHeight : y;
    const cw = width < 0 ? 0 : width > naturalWidth ? naturalWidth : width;
    const ch = height < 0 ? 0 : height > naturalHeight ? naturalHeight : height;

    const ratio = +(image.metadata.width || 0) / naturalWidth;

    const newFilename = buildNewFramingFilename(
      getOriginalFilename(image.filename),
      formats.find((f) => f.aspectRatio === aspectRatio)?.label!,
    );

    setIsUploadNewFramingLoading(true);
    dispatch(
      uploadActions.cropAndUploadImage({
        filename: image.filename,
        crop: {
          x: Math.round(cx * ratio),
          y: Math.round(cy * ratio),
          width: Math.round(cw * ratio),
          height: Math.round(ch * ratio),
        },
        croppedFilename: newFilename,
        isFramingOf: image.filename,
        mutableMetadata: {
          keys: image.metadata.keys,
          titles: image.metadata.titles,
          alts: image.metadata.alts,
          tags: image.metadata.tags,
          autoplay: image.metadata.autoplay,
        },
      }),
    )
      .unwrap()
      .then(() => {
        setIsUploadNewFramingLoading(false);
        toast({
          position: 'bottom-right',
          duration: 3000,
          render: (props) => (
            <Toast
              {...props}
              duration={3000}
              title="RECADRAGE EFFECTUÉ"
              content="1 photo recadrée"
            />
          ),
        });
        onClose();
      })
      .catch((err) => {
        setIsUploadNewFramingLoading(false);
        console.error(err);
        toast({
          position: 'bottom-right',
          duration: null,
          render: (props) => (
            <Toast
              {...props}
              error
              title="RECADRAGE ECHOUÉ"
              content={`Veuillez recommencer ou réessayer plus tard`}
            />
          ),
        });
      });
  };

  return (
    <>
      <Modal closeOnOverlayClick={false} onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent maxW={'1630px'} pt={3}>
          <ModalCloseButton />
          <ModalBody pb={6}>
            {/* LEFT SIDE  */}
            <Box display="flex">
              <Box
                id="image-editor"
                width={CANVAS_WIDTH}
                height={CANVAS_HEIGHT}
                display="flex"
                alignItems="center"
                justifyContent="center"
                backgroundColor="black"
                flexShrink={0}
                position="relative"
              >
                {MPToSmall && (
                  <Box
                    position="absolute"
                    top="0"
                    px={7}
                    py={4}
                    zIndex={999999}
                    display="flex"
                    alignItems={'center'}
                    bgColor="rgba(0,0,0,0.5)"
                  >
                    <IconWarning width={64} height={32} />
                    <Text color="white" fontSize={'16px'} ml={6}>
                      <b>RÉSOLUTION INSUFFISANTE — </b>
                      Le cadrage actuel ne dispose pas d&apos;une résolution
                      suffisante afin d&apos;assurer un affichage qualitatif.
                      Nous vous recommandons d&apos;agrandir la zone affichée ou
                      de changer de photos.
                    </Text>
                  </Box>
                )}
                <img
                  ref={imageRefHandler}
                  loading="lazy"
                  style={{
                    opacity: imageOpacity,
                    display: 'block',
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                  alt="edit image"
                  id="image"
                  src={imageThumbnailUrl}
                />
                <Box position="absolute" bottom={6}>
                  <ButtonGroup size="sm" isAttached>
                    <IconButton
                      aria-label="Rotate image left"
                      variant="darkIcon"
                      icon={<IconRotateLeft width={20} fill="white" />}
                      onClick={rotateImageLeft}
                    />
                    <IconButton
                      aria-label="Rotate image right"
                      variant="darkIcon"
                      icon={<IconRotateRight width={20} fill="white" />}
                      onClick={rotateImageRight}
                    />
                    <IconButton
                      aria-label="Flip image X"
                      variant="darkIcon"
                      icon={<IconFlipX width={20} fill="white" />}
                      onClick={flipImageX}
                    />
                    <IconButton
                      aria-label="Flip image Y"
                      variant="darkIcon"
                      icon={<IconFlipY width={20} fill="white" />}
                      onClick={flipImageY}
                    />
                  </ButtonGroup>
                </Box>
              </Box>
              {/* RIGHT SIDE  */}
              <Box
                mt={5}
                px={16}
                position="relative"
                pb={'140px'}
                display="flex"
                flexDir="column"
                justifyContent="center"
              >
                <Text my={8} fontSize="20px" color="gray.500">
                  Selon les différentes résolutions et supports, votre photo
                  aura un cadrage différent. Par défaut le cadrage se fera au{' '}
                  <b>centre de la photo</b>, mais vous pouvez l&apos;ajuster
                  manuellement.
                </Text>
                <Box display="flex" justifyContent="space-between">
                  {formats.map((data, index) => (
                    <Box
                      key={data.label}
                      borderRadius="base"
                      backgroundColor="gray.50"
                      display="flex"
                      flexDir={'column'}
                      flex="1"
                      flexShrink={0}
                      justifyContent="center"
                      alignItems="center"
                      py={10}
                      px={3}
                      ml={index !== 0 ? 5 : 0}
                      cursor="pointer"
                      borderColor={
                        aspectRatio === data.aspectRatio ? 'brand.500' : 'white'
                      }
                      borderWidth={2}
                      onClick={() => setAspectRatio(data.aspectRatio)}
                    >
                      <Box display="flex" alignItems={'center'} h={'75px'}>
                        <Box
                          w={data.w}
                          h={data.h}
                          borderRadius="base"
                          backgroundColor="brand.850"
                          opacity={0.1}
                        ></Box>
                      </Box>
                      <Heading textStyle="heading" fontSize="20px" mt={6}>
                        {data.label}
                      </Heading>
                    </Box>
                  ))}
                </Box>
                <Box w="100%" position="absolute" bottom={0} left={0} px={16}>
                  <Center>
                    <Button
                      isLoading={isUploadNewFramingLoading}
                      onClick={uploadNewFraming}
                      px={20}
                      isDisabled={!aspectRatio}
                    >
                      VALIDER
                    </Button>
                  </Center>
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        size="3xl"
        isOpen={!!framingToDelete}
        onClose={() => setFramingToDelete(undefined)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mr={7} my={4}>
            <Heading mt={2} mb={4} fontSize="30px" as="h4" textStyle="heading">
              Attention
            </Heading>
            <Text mb={3} fontSize="18px" textStyle="brand">
              Êtes-vous sur de vouloir supprimer ce cadrage ?
            </Text>
            <Box mt={4}>
              <Button
                variant="outline"
                colorScheme="gray"
                px={10}
                onClick={() => setFramingToDelete(undefined)}
              >
                ANNULER
              </Button>
              <Button px={10} ml={2} onClick={handleFramingRemove}>
                SUPPRIMER
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

function buildNewFramingFilename(originalFilename: string, format: string) {
  const pointIndex = originalFilename.lastIndexOf('.');
  const name =
    pointIndex > 0
      ? originalFilename.substring(0, pointIndex)
      : originalFilename;
  const extension =
    pointIndex > 0
      ? originalFilename.substring(pointIndex, originalFilename.length)
      : '';
  const timestamp = new Date().getTime();
  return `${name}-{{${format}-${timestamp}}}${extension}`;
}

function getOriginalFilename(filename: string) {
  const patternStart = filename.indexOf('-{{');
  const patternEnd = filename.lastIndexOf('}}') + 1;

  if (patternStart > 0 && patternEnd > 0 && patternStart < patternEnd) {
    return (
      filename.substring(0, patternStart) +
      filename.substring(patternEnd + 1, filename.length)
    );
  }
  return filename;
}

export default ImageEditor;
