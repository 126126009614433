import { createContext } from 'react';

import { useRouter } from 'next/router';

export type LanguageContextKey = 'fr' | 'en' | 'es' | 'de';
// const langs = ['fr', 'en', 'es'] as LanguageContextKey[];
const defaultLang = 'fr';

interface LanguageContextType {
  currentLang: LanguageContextKey;
}

const LanguageContext = createContext<LanguageContextType>({
  currentLang: defaultLang,
});

interface LanguageContextProviderProps {
  children: React.ReactNode;
}

export const LanguageContextProvider = (
  props: LanguageContextProviderProps,
) => {
  const { children } = props;
  const { locale } = useRouter();

  const currentLang = (locale || defaultLang) as LanguageContextKey;

  return (
    <LanguageContext.Provider value={{ currentLang }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
