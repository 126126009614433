import {
  ActionButtonComponent,
  AtomicComponent,
  CustomComponentData,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediaComponent,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Container, chakra, useBreakpointValue } from '@chakra-ui/react';

import ActionButton from '@/lib/kustomcms-sdk/lib/components/ActionButton';
import AnimatePresence from '../atomics/AnimatePresence';
import { Parallax } from 'react-scroll-parallax';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';

import Slideshow from '../atomics/Slideshow';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import { uniqID } from '@/lib/kustomcms-sdk/lib/admin/helpers/random';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { useMemo } from 'react';
import { SvgShape } from '../SvgShape';

const Restaurants = (
  props: KustomPageComponentViewProps<CustomComponentData>,
) => {
  const { page, component } = props;

  const mediasCmp = component.data.atomicComponents.filter(
    (cmp) => cmp.type === 'MEDIA',
  ) as MediaComponent[];
  const [textIndex1, textIndex2] = component.data.atomicComponents
    .map((cmp, index) => (cmp.type === 'TEXT' ? index : undefined))
    .filter((i) => i !== undefined) as number[];
  const buttonsIndex = component.data.atomicComponents
    .map((cmp, index) =>
      cmp.type === 'ACTION_BUTTON' ? { index, id: cmp.id } : undefined,
    )
    .filter((i) => i !== undefined) as { id: string; index: number }[];

  const sketchSize = useBreakpointValue({
    base: 200,
    lg: 500,
  });

  const medias = mediasCmp.map((cmp) => cmp.media);

  const atomsBind = useKustomData<AtomicComponent[]>(
    page,
    component,
    `data.atomicComponents`,
  );

  const text1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${textIndex1}.text`,
  );

  const text2Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${textIndex2}.text`,
  );

  const onMediasChange = useMemo(
    () =>
      atomsBind.onChange
        ? (medias: KustomResponsiveMedia[]) => {
            const atomsNoMedias = atomsBind.value?.filter(
              (cmp) => cmp.type !== 'MEDIA',
            );

            const newAtoms = medias.map((media) => {
              const mediaAtomCmp = (atomsBind.value?.find(
                (cmp) =>
                  cmp.type === 'MEDIA' &&
                  (cmp as MediaComponent).media.id === media.id,
              ) || {
                id: uniqID(),
                type: 'MEDIA',
                media,
              }) as MediaComponent;

              mediaAtomCmp.media = media;

              return mediaAtomCmp;
            });

            atomsBind.onChange?.([...newAtoms, ...(atomsNoMedias || [])]);
          }
        : undefined,
    [atomsBind.onChange, atomsBind.value],
  );

  const mediaHeight = [190, null, 300, 664, null, 830];

  return (
    <Box bgColor="accent.100" py={20} overflowX="clip">
      <Container
        maxW={['container.xl', null, null, null, null, '1780px']}
        display="flex"
        flexDir={['column', null, null, 'row']}
        px={[6, null, 20]}
      >
        <Box
          height={mediaHeight}
          display="flex"
          flex={[null, null, 3]}
          minW={0}
          position="relative"
        >
          {medias && (
            <Slideshow
              usingMediaViewer
              value={medias}
              onChange={onMediasChange}
              hideArrows
              hideCounter
              sizes={chakraResponsiveToImageSizes([
                null,
                null,
                null,
                null,
                null,
                '1780px',
              ])}
              height={mediaHeight}
              containerStyle={{
                flexBasis: ['100%', null, '663px', null, null, '829px'],
                borderRadius: 'base',
                overflow: 'clip',
              }}
            />
          )}
          <Box
            position="absolute"
            ml={10}
            top={[100, null, -200]}
            right={[0, null, 200]}
          >
            <Parallax speed={20}>
              <SvgShape
                file="illu-poisson-5"
                width={`${sketchSize}px`}
                style={{
                  transform: 'scale(-1, 1)',
                }}
              />
            </Parallax>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDir="column"
          flex={[null, null, 2]}
          minW={0}
          ml={[0, null, null, 20]}
          mt={[0, null, null, 36]}
        >
          <AnimatePresence x={60} opacity>
            <RichText
              fontFamily="heading"
              fontSize={['38px', null, '96px', null, null, '120px']}
              color="brand.500"
              lineHeight={0.8}
              mb={[4, null, 10]}
              mt={[8, null, 10]}
              {...text1Bind}
              position="relative"
              minWidth={[0, null, 800]}
              left={[0, null, null, -300]}
              as="h2"
            />
            <RichText
              fontFamily="jhaSemilight"
              fontSize={['18px', null, '28px', null, null, '36px']}
              color="brand.500"
              {...text2Bind}
            />
            <Box display="flex" flexDir="column">
              {buttonsIndex.map(({ index: buttonIndex, id }, index) => (
                <RestaurantsButton
                  component={component}
                  page={page}
                  key={id}
                  buttonIndex={buttonIndex}
                />
              ))}
            </Box>
          </AnimatePresence>
        </Box>
      </Container>
    </Box>
  );
};

interface RestaurantsButtonProps
  extends KustomPageComponentViewProps<CustomComponentData> {
  buttonIndex: number;
}

const RestaurantsButton: React.FC<RestaurantsButtonProps> = (props) => {
  const { page, component, buttonIndex } = props;

  const buttonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${buttonIndex}`,
  );

  return (
    <Box mt={[4, null, 6, null, null, 8]}>
      <ActionButton
        {...buttonBind}
        rightIcon={
          <chakra.span
            fontSize={['42px', null, '56px']}
            className="icon-elmes-arrow1-white"
          />
        }
        variant="brand"
        size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
      />
    </Box>
  );
};

export default Restaurants;
