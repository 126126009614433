import { Box, Container } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponent,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

interface FAQTitleSectionProps
  extends KustomPageComponentViewProps<TextComponent> {}

const FAQTitleSection: React.FC<FAQTitleSectionProps> = (props) => {
  const { component } = props;

  const textBind = useKustomData<KustomTranslatedString>(
    props.page,
    component,
    `data.text`,
  );

  return (
    <Container
      maxW={['container.lg', null, null, null, null, '1362px']}
      position="relative"
      overflowX="clip"
      px={[6, null, 20]}
      pt={[20, null, null, null, null, 32]}
    >
      <RichText
        position="relative"
        zIndex={1}
        as="h2"
        fontSize={['48px', null, null, '76px', null, '96px']}
        fontFamily="heading"
        color="brand.500"
        lineHeight={1}
        {...textBind}
      />
    </Container>
  );
};

export default FAQTitleSection;
