import { AppThunkAPI, StoreState } from '../../store';
import {
  Establishment,
  KustomMedia,
  KustomPage,
  KustomTranslatedString,
} from '../../../types';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { WritableDraft } from 'immer/dist/types/types-external';
import { getOriginalFilename } from '../../helpers/media';
import { getUserCurrentEstablishment } from '../../helpers/establishments';
import mapValuesDeepAsync from '../../helpers/mapValuesDeepAsync';
import { uploadThunks } from '../upload/slice';

export type Media = KustomMedia;

export interface MediaMutableMetadata {
  _id: string;
  bucketName: string;
  keys?: KustomTranslatedString;
  titles?: KustomTranslatedString;
  alts?: KustomTranslatedString;
  key: string;
  originalKey: string;
  tags: string[];
  autoplay?: string;
}

export type MediasUsage = {
  [mediaMetadataId: string]: {
    [pageId: string]: number;
  };
};

export interface MediasState {
  establishmentMedias: KustomMedia[];
  publicMedias: KustomMedia[];
  medias: KustomMedia[];
  getEstablishmentMedias: {
    pending: boolean;
    error?: string;
    done: boolean;
  };
  mediaFramings: KustomMedia[];
  selectedMedias: KustomMedia[];
  selectedPublicMedias: KustomMedia[];
  mediasUsage?: MediasUsage;
}

const initialState: MediasState = {
  establishmentMedias: [],
  publicMedias: [],
  medias: [],
  getEstablishmentMedias: {
    pending: false,
    done: false,
  },
  mediaFramings: [],
  selectedMedias: [],
  selectedPublicMedias: [],
};

export const mediasThunks = {
  getEstablishmentMedias: createAsyncThunk<
    KustomMedia[],
    { disableLoading?: boolean; resetSelection?: boolean } | undefined,
    AppThunkAPI
  >('medias/getEstablishmentMedias', async (_, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.feathersApp!.service('medias').find();
      return res;
    } catch (err: any) {
      console.log('err', err);
      throw thunkAPI.rejectWithValue(err.message);
    }
  }),
  getPublicMedias: createAsyncThunk<KustomMedia[], undefined, AppThunkAPI>(
    'medias/getPublicMedias',
    async (_, thunkAPI) => {
      try {
        const res = await thunkAPI.extra.feathersApp!.service('medias').find({
          query: {
            public: true,
          },
        });
        return res;
      } catch (err: any) {
        throw thunkAPI.rejectWithValue(err.message);
      }
    },
  ),
  getMediaFramings: createAsyncThunk<
    KustomMedia[],
    { filename: string },
    AppThunkAPI
  >('medias/getMediaFramings', async ({ filename }, thunkAPI) => {
    try {
      const res = await thunkAPI.extra.feathersApp!.service('medias').find({
        query: {
          framings: filename,
        },
      });
      return res;
    } catch (err: any) {
      throw thunkAPI.rejectWithValue(err.message);
    }
  }),
  removeMedia: createAsyncThunk<
    KustomMedia & { removedSize?: number },
    { file: KustomMedia; onlyFraming?: boolean },
    AppThunkAPI
  >('medias/removeMedia', async ({ file, onlyFraming }, thunkAPI) => {
    const filename = file.filename;
    try {
      return await thunkAPI.extra
        .feathersApp!.service('medias')
        .remove(filename, {
          query: {
            onlyFraming,
          },
        });
    } catch (err: any) {
      throw thunkAPI.rejectWithValue(err.message);
    }
  }),
  createTag: createAsyncThunk<
    { newEstablishment: any },
    { tag: string },
    AppThunkAPI
  >('medias/createTag', async ({ tag }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const user = state.app.user;
      const establishment = getUserCurrentEstablishment(user);
      const tags = [...(establishment?.mediaTags || [])];

      tags.push(tag);
      const newEstablishment = await thunkAPI.extra
        .feathersApp!.service('establishments')
        .patch(establishment?._id, {
          mediaTags: [...Array.from(new Set(tags))],
        });

      thunkAPI.dispatch(mediasActions.addTagToSelected({ tag }));

      return {
        newEstablishment,
      };
    } catch (err: any) {
      console.error(err);
      throw thunkAPI.rejectWithValue(err.message);
    }
  }),
  updateTags: createAsyncThunk<
    { newEstablishment: any },
    { tags: string[] },
    AppThunkAPI
  >('medias/updateTags', async ({ tags }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const user = state.app.user;
      const establishment = getUserCurrentEstablishment(user);

      const newEstablishment = (await thunkAPI.extra
        .feathersApp!.service('establishments')
        .patch(establishment?._id, {
          mediaTags: [...Array.from(new Set(tags))],
        })) as any;

      return {
        newEstablishment,
      };
    } catch (err: any) {
      console.error(err);
      throw thunkAPI.rejectWithValue(err.message);
    }
  }),
  addTagToSelected: createAsyncThunk<
    { newMediasMetadatas: any[] },
    { tag: string },
    AppThunkAPI
  >('medias/addTagToSelected', async ({ tag }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const medias = state.medias.selectedMedias;

      const promises = medias.map(async (media) => {
        if (media.metadata?.tags.includes(tag)) {
          return media;
        }
        const mediaTags = [...(media.metadata?.tags || [])];
        mediaTags.push(tag);

        const mutableMetadata = await thunkAPI.extra
          .feathersApp!.service('media-metadata')
          .patch(media.metadata?._id, {
            tags: [...Array.from(new Set(mediaTags))],
          });

        return { filename: media.filename, mutableMetadata };
      });

      const newMediasMetadatas = await Promise.all(promises);

      return {
        newMediasMetadatas,
      };
    } catch (err: any) {
      console.error(err);
      throw thunkAPI.rejectWithValue(err.message);
    }
  }),
  updateTagInMediasMetadata: createAsyncThunk<
    void,
    { tag: string; nextTag: string },
    AppThunkAPI
  >('medias/updateTagInMediasMetadata', async ({ tag, nextTag }, thunkAPI) => {
    const state = thunkAPI.getState();

    state.medias.establishmentMedias.forEach((media) => {
      const index = media.metadata.tags.indexOf(tag);
      if (index > -1) {
        const newTags = [...media.metadata.tags];
        newTags[index] = nextTag;

        const action = mediasActions.updateMetadata({
          id: media.metadata._id,
          metadata: {
            tags: newTags,
          },
        });

        thunkAPI.dispatch(action);
      }
    });
  }),
  removeTagFromSelected: createAsyncThunk<
    { newMediasMetadatas: any[] },
    { tag: string },
    AppThunkAPI
  >('medias/removeTagFromSelected', async ({ tag }, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const medias = state.medias.selectedMedias;

      const promises = medias.map(async (media) => {
        const mediaTags = [...(media.metadata?.tags || [])];

        const index = mediaTags.indexOf(tag);
        if (index < 0) {
          return media;
        }

        mediaTags.splice(index, 1);

        const mutableMetadata = await thunkAPI.extra
          .feathersApp!.service('media-metadata')
          .patch(media.metadata?._id, {
            tags: mediaTags,
          });

        return { filename: media.filename, mutableMetadata };
      });

      const newMediasMetadatas = await Promise.all(promises);

      return {
        newMediasMetadatas,
      };
    } catch (err: any) {
      console.error(err);
      throw thunkAPI.rejectWithValue(err.message);
    }
  }),
  focusFraming: createAsyncThunk<void, { framing: KustomMedia }, AppThunkAPI>(
    'medias/focusFraming',
    async ({ framing }, thunkAPI) => {
      try {
        await thunkAPI.extra
          .feathersApp!.service('media-metadata')
          .patch(framing.metadata?._id, {
            key: framing.filename,
          });
      } catch (err: any) {
        console.error(err);
        throw thunkAPI.rejectWithValue(err.message);
      }
    },
  ),
  updateMetadata: createAsyncThunk<
    MediaMutableMetadata,
    { id: string; metadata: Partial<MediaMutableMetadata> },
    AppThunkAPI
  >('medias/updateMetadata', async ({ id, metadata }, thunkAPI) => {
    try {
      return await thunkAPI.extra
        .feathersApp!.service('media-metadata')
        .patch(id, metadata);
    } catch (err: any) {
      throw thunkAPI.rejectWithValue(err.message);
    }
  }),
  getMedias: createAsyncThunk<KustomMedia[], { ids: string[] }, AppThunkAPI>(
    'medias/getMedias',
    async ({ ids }, thunkAPI) => {
      const establishmentMedias =
        thunkAPI.getState().medias.establishmentMedias;
      const medias = thunkAPI.getState().medias.medias;
      try {
        return (
          await Promise.all(
            ids.map(async (id) => {
              let media =
                establishmentMedias.find(
                  (media) => media.metadata._id === id,
                ) || medias.find((media) => media.metadata._id === id);

              if (!media) {
                media = await thunkAPI.extra
                  .feathersApp!.service('media-metadata')
                  .find({
                    query: {
                      id,
                    },
                  });
              }

              return media;
            }),
          )
        ).filter((media) => !!media) as KustomMedia[];
      } catch (err: any) {
        throw thunkAPI.rejectWithValue(err.message);
      }
    },
  ),
  computeMediasUsage: createAsyncThunk<
    MediasUsage,
    { pages: KustomPage[] },
    {
      state: StoreState;
    }
  >('medias/computeMediasUsage', async ({ pages }, thunkAPI) => {
    const mediasUsage = {} as MediasUsage;

    for (const _page of pages) {
      const page = { ..._page };

      // delete page.versions;

      await mapValuesDeepAsync(page, async (value: any, key) => {
        if (key === 'mutableMetadata') {
          mediasUsage[value._id] = mediasUsage[value._id] || {};

          mediasUsage[value._id]![page._id] =
            mediasUsage[value._id]![page._id] || 0;

          mediasUsage[value._id]![page._id] += 1;
        }

        return value;
      });
    }

    return mediasUsage;
  }),
};

export const mediasSlice = createSlice({
  name: 'medias',
  initialState,
  reducers: {
    selectMedias: (state, action: PayloadAction<KustomMedia[]>) => {
      state.selectedMedias = [...action.payload];
    },
    clearError: (state) => {
      state.getEstablishmentMedias.error = undefined;
    },
    toggleSelectMedia: (
      state,
      action: PayloadAction<{ media: KustomMedia; isPublic?: boolean }>,
    ) => {
      const { media, isPublic } = action.payload;
      const selectedMedias = isPublic
        ? state.selectedPublicMedias
        : state.selectedMedias;
      const index = selectedMedias.findIndex(
        (m) => m.filename === media.filename,
      );
      const newSelectedMedias = [...selectedMedias];

      if (index > -1) {
        newSelectedMedias.splice(index, 1);
      } else {
        newSelectedMedias.push(media);
      }

      if (isPublic) {
        state.selectedPublicMedias = newSelectedMedias;
      } else {
        state.selectedMedias = newSelectedMedias;
      }
    },
  },
  extraReducers: (builder) => {
    const updateLocalMediasMetadata = (
      state: WritableDraft<MediasState>,
      action: PayloadAction<
        {
          newMediasMetadatas: any[];
        },
        string,
        any,
        never
      >,
    ) => {
      const medias = [...state.establishmentMedias];
      const selectedMedias = [...state.selectedMedias];

      action.payload.newMediasMetadatas.forEach((newMedia) => {
        const media = medias.find((m) => m.filename === newMedia.filename);
        const selectedMedia = selectedMedias.find(
          (m) => m.filename === newMedia.filename,
        );
        if (media) {
          media.metadata = newMedia.metadata;
        }
        if (selectedMedia) {
          selectedMedia.metadata = newMedia.metadata;
        }
      });

      state.establishmentMedias = medias;
      state.selectedMedias = selectedMedias;
    };

    builder
      .addCase(mediasThunks.getEstablishmentMedias.pending, (state, action) => {
        state.getEstablishmentMedias.done = true;
        if (!action.meta?.arg?.disableLoading) {
          state.getEstablishmentMedias.pending = true;
        }
      })
      .addCase(
        mediasThunks.getEstablishmentMedias.rejected,
        (state, action) => {
          state.getEstablishmentMedias.error = action.payload as string;
          state.establishmentMedias = [];
          state.getEstablishmentMedias.pending = false;
        },
      )
      .addCase(
        mediasThunks.getEstablishmentMedias.fulfilled,
        (state, action) => {
          if (action.meta.arg?.resetSelection) {
            state.selectedMedias = [];
          }

          // hack because there are duplicated .Key medias
          // TODO: fix duplicated keys
          const mediasMap = new Map<string, KustomMedia>();
          action.payload.forEach((media: KustomMedia) => {
            mediasMap.set(media.metadata.key, media);
          });

          state.establishmentMedias = Array.from(mediasMap.values());
          state.getEstablishmentMedias.pending = false;
        },
      )
      .addCase(mediasThunks.getPublicMedias.fulfilled, (state, action) => {
        state.publicMedias = action.payload;
      })
      .addCase(mediasThunks.getMediaFramings.pending, (state) => {
        state.mediaFramings = [];
      })
      .addCase(mediasThunks.getMediaFramings.fulfilled, (state, action) => {
        state.mediaFramings = action.payload;
      })
      .addCase(mediasThunks.removeMedia.fulfilled, (state, action) => {
        const filename = action.meta.arg.file.filename;
        const newFile = action.payload;

        if (action.meta.arg.onlyFraming) {
          state.mediaFramings = state.mediaFramings.filter(
            (m) => m.filename !== filename && m.filename !== newFile.filename,
          );

          state.establishmentMedias = state.establishmentMedias.map((m) =>
            m.filename === filename ? action.payload : m,
          );
          state.selectedMedias = state.selectedMedias.map((m) =>
            m.filename === filename ? action.payload : m,
          );
        } else {
          state.establishmentMedias = state.establishmentMedias.filter(
            (m) => m.filename !== filename,
          );
          state.selectedMedias = state.selectedMedias.filter(
            (m) => m.filename !== filename,
          );
        }
      })
      .addCase(mediasThunks.focusFraming.fulfilled, (state, action) => {
        const newFraming = action.meta.arg.framing;

        const replaceFraming = (m: KustomMedia) => {
          if (
            getOriginalFilename(m.filename) ===
            getOriginalFilename(newFraming.filename)
          ) {
            return newFraming;
          }
          return m;
        };

        state.establishmentMedias =
          state.establishmentMedias.map(replaceFraming);
        state.selectedMedias = state.selectedMedias.map(replaceFraming);
      })
      .addCase(mediasThunks.updateMetadata.fulfilled, (state, action) => {
        const newMutableMetadata = action.payload;
        const replaceMetadata = (m: KustomMedia) => {
          if (m.metadata._id === newMutableMetadata._id) {
            return {
              ...m,
              mutableMetadata: newMutableMetadata,
            };
          }
          return m;
        };
        state.establishmentMedias =
          state.establishmentMedias.map(replaceMetadata);
        state.selectedMedias = state.selectedMedias.map(replaceMetadata);
      })
      .addCase(uploadThunks.uploadFile.fulfilled, (state, action) => {
        if (action.meta.arg.isFramingOf) {
          state.selectedMedias = state.selectedMedias.filter(
            (m) => m.filename !== action.meta.arg.isFramingOf,
          );
        }
      })
      .addCase(
        mediasThunks.addTagToSelected.fulfilled,
        updateLocalMediasMetadata,
      )
      .addCase(
        mediasThunks.removeTagFromSelected.fulfilled,
        updateLocalMediasMetadata,
      )
      .addCase(mediasThunks.getMedias.fulfilled, (state, action) => {
        state.medias = action.payload;
      })
      .addCase(mediasThunks.computeMediasUsage.fulfilled, (state, action) => {
        state.mediasUsage = action.payload;
      });
  },
});

export const mediasActions = { ...mediasSlice.actions, ...mediasThunks };

export default mediasSlice.reducer;
