import { Media } from '../features/medias/slice';
import mime from 'mime-types';
import { unzip } from 'unzipit';

export enum MediaType {
  IMAGE = 'image',
  PDF = 'pdf',
  VIDEO = 'video',
  APP = 'application',
  UNKNOWN = 'unknown',
}

export const getMediaType = (filename: string): MediaType => {
  const mimeType = mime.lookup(filename);

  if (!mimeType) {
    return MediaType.UNKNOWN;
  }

  if (mimeType === 'application/pdf') {
    return MediaType.PDF;
  }

  const parts = mimeType.split('/');

  return parts[0] as MediaType;
};

export function getMediaThumbnailUrl(media: Media) {
  const index = media.url.lastIndexOf('.');
  const start = media.url.slice(0, index);
  return start + '.thumbnail.jpg';
}

export function getMediaThumbnailUrlFromUrl(url?: string) {
  if (!url) {
    return '';
  }
  const index = url?.lastIndexOf('.');
  const start = url?.slice(0, index);
  return start + '.thumbnail.jpg';
}

export const getFileImageDimensions = (
  image: File,
): Promise<[number, number]> => {
  const fr = new FileReader();

  return new Promise((resolve, reject) => {
    fr.onload = function () {
      const img = new Image();

      img.onload = function () {
        resolve([img.width, img.height]);
      };

      img.onerror = function () {
        resolve([-1, -1]);
      };

      img.src = fr.result as string;
    };

    fr.readAsDataURL(image);
  });
};

export const toHumanFileSize = (size: number) => {
  const isNeg = !!(size < 0);
  size = Math.abs(size);

  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    (isNeg ? '-' : '') +
    ((size / Math.pow(1024, i)) * 1).toFixed(1) +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
};

export function getOriginalFilename(filename: string) {
  const matchs = /(.+)-{{(.+)-(.+)}}(\.[a-z]+){0,1}/gm.exec(filename);

  if (!matchs) {
    return filename;
  }

  return (matchs[1] || '') + (matchs[4] || '');
}

export async function unzipFiles(file: File): Promise<File[]> {
  const parts = file.name.split('.');
  const ext = parts[parts.length - 1] || '';

  if (!['zip', 'rar'].includes(ext)) {
    return [file];
  }

  const { entries } = await unzip(file);

  const promises = Object.entries(entries).map(async ([name, entry]) =>
    name.startsWith('__MACOSX') || !mime.lookup(name)
      ? undefined
      : new File([await entry.blob(mime.lookup(name) as string)], name, {
          type: mime.lookup(name) as string,
        }),
  );

  const files = (await Promise.all(promises)).filter((f) => f) as File[];

  return files;
}

export function getVideoDuration(file: File): Promise<number> {
  const video = document.createElement('video');
  video.preload = 'metadata';

  return new Promise((resolve, reject) => {
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration);
    };

    video.onerror = reject;

    video.src = URL.createObjectURL(file);
  });
}
