import en from '../../../i18n/en.json';
import es from '../../../i18n/es.json';
import fr from '../../../i18n/fr.json';
import { useKustomSelector } from './useKustomSelector';

const langs: { [key: string]: { [key: string]: string } } = {
  fr,
  en: {
    ...fr,
    ...en,
  },
  es: {
    ...fr,
    ...es,
  },
};

const useTranslation = () => {
  const user = useKustomSelector((state) => state.app.user);
  const id = user?.language || 'fr';
  const lang = langs[id] || langs.fr;

  return (key: string) => lang?.[key] || key;
};

export default useTranslation;
