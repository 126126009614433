import { Box, BoxProps, ButtonGroup, IconButton } from '@chakra-ui/react';
import { FunctionComponent, SVGAttributes, Suspense, useState } from 'react';
import {
  KustomMedia,
  KustomResponsiveMedia,
  KustomResponsiveMediaKey,
} from '../../../types';
import {
  MediaType,
  getMediaThumbnailUrl,
  getMediaThumbnailUrlFromUrl,
  getMediaType,
} from '../../helpers/media';

import IconCross from '@/lib/kustomcms-sdk/lib/icons/icon-actions-cross.svg';
import IconDesktop from '@/lib/kustomcms-sdk/lib/icons/icon-device-desktop.svg';
import IconEye from '@/lib/kustomcms-sdk/lib/icons/icon-eye.svg';
import IconMobile from '@/lib/kustomcms-sdk/lib/icons/icon-device-smartphone.svg';
import IconTablet from '@/lib/kustomcms-sdk/lib/icons/icon-device-tablet.svg';
import IconVideo from '@/lib/kustomcms-sdk/lib/icons/icon-video.svg';
import PickMediasButton from './PickMediasButton';
import useLazyAutoplay from '../../../hooks/useLazyAutoplay';
import useTheme from '../../hooks/useTheme';

interface SingleResponsiveMediaPickerProps extends BoxProps {
  media?: KustomResponsiveMedia;
  onMediaViewerOpen: (media: KustomMedia) => void;
  onRemoveMedia: (responsiveKey: KustomResponsiveMediaKey) => void;
  onOpenMediaPicker: (responsiveKey: KustomResponsiveMediaKey) => void;
}

const SingleResponsiveMediaPicker = (
  props: SingleResponsiveMediaPickerProps,
) => {
  const {
    media,
    onMediaViewerOpen,
    onRemoveMedia,
    onOpenMediaPicker,
    ...boxProps
  } = props;
  const theme = useTheme();

  const [currentMediaKey, setCurrentMediaKey] =
    useState<KustomResponsiveMediaKey>('default');

  const lazyAutoplayBind = useLazyAutoplay();

  const [isResponsiveMode, setIsResponsiveMode] = useState(
    !!(media?.mobile || media?.tablet),
  );

  const mediaType =
    media?.[currentMediaKey] && getMediaType(media[currentMediaKey]?.url!);

  return (
    <Box w="200px" borderRadius="base" {...boxProps}>
      {isResponsiveMode && (
        <Box bgColor="white" display="flex" borderRadius="base" mb={2}>
          {(
            [
              ['default', IconDesktop, 12],
              ['tablet', IconTablet, 10],
              ['mobile', IconMobile, 8],
            ] as [
              KustomResponsiveMediaKey,
              FunctionComponent<SVGAttributes<SVGElement>>,
              number,
            ][]
          ).map(([key, Icon, size]) => (
            <Box
              key={key as string}
              flex={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              borderBottom="3px solid"
              borderColor="transparent"
              onClick={() => setCurrentMediaKey(key)}
              py={2}
              {...(currentMediaKey === key && {
                borderColor: 'brand.500',
              })}
            >
              <Icon
                fill={
                  currentMediaKey === key
                    ? // @ts-ignore
                      theme.colors.brand[850]
                    : // @ts-ignore
                      theme.colors.gray[400]
                }
                width={size}
              />
            </Box>
          ))}
        </Box>
      )}
      <Box w="200px" h="200px">
        {media?.[currentMediaKey] ? (
          <Box
            w="100%"
            h="100%"
            borderRadius="base"
            backgroundImage={
              'url("' +
              getMediaThumbnailUrlFromUrl(media[currentMediaKey]?.url) +
              '")'
            }
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            position="relative"
          >
            {mediaType === MediaType.VIDEO && (
              <Box
                overflow="hidden"
                w="100%"
                h="100%"
                cursor="pointer"
                borderRadius="base"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <video
                  {...lazyAutoplayBind}
                  style={{
                    zIndex: 0,
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    objectFit: 'cover',
                    borderRadius: theme.radii.base as string,
                  }}
                >
                  <source src={media[currentMediaKey]?.url} />
                </video>
                <IconVideo
                  style={{
                    position: 'relative',
                    zIndex: 0,
                    margin: theme.space[2] + 'px',
                    boxShadow: '0px 0px 9px #00000066',
                    backgroundColor: 'rgba(0,0,0,0.25)',
                  }}
                  width={32}
                  fill="white"
                />
              </Box>
            )}
            <ButtonGroup
              size="sm"
              isAttached
              position="absolute"
              bottom={4}
              left="50%"
              zIndex={2}
              transform="translate(-50%, 0)"
            >
              <IconButton
                aria-label="see image"
                variant="brandIcon"
                icon={<IconEye fill="white" width={15} />}
                p={3}
                py={5}
                onClick={() => onMediaViewerOpen?.(media[currentMediaKey]!)}
                borderRightWidth={'1px'}
                borderRightColor="#2b3b50"
              />
              <IconButton
                aria-label="Delete image"
                variant="brandIcon"
                icon={<IconCross fill="white" width={15} />}
                p={3}
                py={5}
                onClick={() => onRemoveMedia(currentMediaKey)}
              />
              <IconButton
                aria-label="download image"
                variant="brandIcon"
                bgColor={isResponsiveMode ? 'brand.500' : undefined}
                icon={<IconDesktop fill="white" width={15} />}
                p={3}
                py={5}
                onClick={() => setIsResponsiveMode((irm) => !irm)}
              />
            </ButtonGroup>
          </Box>
        ) : (
          <PickMediasButton
            onClick={() => onOpenMediaPicker(currentMediaKey)}
          />
        )}
      </Box>
    </Box>
  );
};

export default SingleResponsiveMediaPicker;
