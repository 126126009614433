interface WrapIfProps {
  condition: boolean;
  children: React.ReactNode;
  wrapper: (children: React.ReactNode) => React.ReactNode;
}

const WrapIf = (props: WrapIfProps) => {
  const { condition, children, wrapper } = props;

  if (condition) {
    return <>{wrapper(children)}</>;
  }

  return <>{children}</>;
};

export default WrapIf;
