import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family:'SamsungOne';
        font-style:normal;
        font-weight:400;
        font-display: swap;
        src: url(./assets/fonts/SamsungOne/SamsungOne-400.woff) format('woff')};
        
      @font-face{
        font-family:'SamsungOne';
        font-style:normal;
        font-weight:700;
        font-display: swap;
        src: url(./assets/fonts/SamsungOne/SamsungOne-700.woff) format('woff')};
        
      @font-face{
        font-family:'samsung sharp sans';
        font-style:normal;
        font-weight:700;
        font-display: swap;
        src: url(./assets/fonts/SamsungOne/SamsungSharpSans-Bold.woff) format('woff')};
      `}
  />
);

export default Fonts;
