import { Heading, Text, TextProps } from '@chakra-ui/react';
import React, { useState } from 'react';

// import EditTextModal from '../admin/components/EditTextModal';
import WrapIf from './atomics/WrapIf';
import dynamic from 'next/dynamic';
import getCSSAdminWysiwygItem from '../admin/helpers/getCSSAdminWysiwygItem';
import { useKustomSelector } from '../admin/hooks/useKustomSelector';

const DynamicEditTextModal = dynamic(
  () => import('../admin/components/EditTextModal'),
);

interface KustomStringProps extends Omit<TextProps, 'children' | 'onChange'> {
  value?: string;
  isHeading?: boolean;
  children?: string;
  onChange?: (value: string) => void;
}

const KustomString: React.FC<KustomStringProps> = (props) => {
  const { value: text, isHeading, children, onChange, ...textProps } = props;

  const TextComponent = isHeading ? Heading : Text;

  const { user } = useKustomSelector((state) => state.app);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasWysiwygEdit = !!(user && onChange);

  if (!text && !children) return null;

  const renderText = (text || children) as string;

  return (
    <WrapIf
      condition={hasWysiwygEdit}
      wrapper={(children) => (
        <DynamicEditTextModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          value={renderText}
          onChange={(newValue) => {
            onChange?.(newValue);
          }}
        >
          {children}
        </DynamicEditTextModal>
      )}
    >
      <TextComponent
        display="inline"
        {...textProps}
        {...getCSSAdminWysiwygItem({
          hasWysiwygEdit,
          isWysiwygEditOpen: isModalOpen,
          setIsOpen: setIsModalOpen,
        })}
      >
        {renderText}
      </TextComponent>
    </WrapIf>
  );
};

export default KustomString;
