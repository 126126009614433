import 'moment/locale/fr';

import moment from 'moment';

moment.locale('fr', {
  /**/
});

export function getRelativeFromNow(date: Date) {
  return moment(date).fromNow();
}

export default moment;
