import { Box, BoxProps } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextImageComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import Skeleton from 'react-loading-skeleton';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import { useContext, useState } from 'react';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

interface PageHeaderProps
  extends KustomPageComponentViewProps<TextImageComponentData> {
  style?: BoxProps;
}

const PageHeader = (props: PageHeaderProps) => {
  const { page, component, style } = props;

  const { currentLang } = useContext(LanguageContext);

  const [isLoaded, setIsLoaded] = useState(false);

  const medias = component.data.media;
  // const title = component.data.text;
  const subtitleIndex = component.data.atomicComponents.findIndex(
    (cmp) => cmp.type === 'TEXT',
  );

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const subtitleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${subtitleIndex}.text`,
  );

  const currentDevice = useResponsiveMediasDevice();

  return (
    <Box
      w="100%"
      textAlign="center"
      position="relative"
      overflowX="clip"
      {...style}
    >
      <Box position="absolute" top={0} left={0} right={0} bottom={0}>
        {medias && (
          <ResponsiveMedias
            value={medias}
            currentDevice={currentDevice}
            fill
            width={undefined}
            height={undefined}
            onLoaded={() => {
              setIsLoaded(true);
            }}
            style={{
              bgColor: 'transparent',
              objectFit: 'cover',
            }}
          />
        )}
        {!isLoaded && (
          <Skeleton
            width="100%"
            height="100%"
            style={{
              zIndex: 0,
              position: 'absolute',
              right: 0,
              left: 0,
              top: 0,
            }}
            borderRadius={0}
            baseColor="#EFE9DE" // color of the black layer + light background
          />
        )}
      </Box>
      <Box
        position="relative"
        bgColor={isLoaded ? 'rgba(0,0,0, 0.4)' : undefined}
        h="100%"
        pt={[28, null, 60]}
        pb={[8, null, 20]}
        px={8}
        display="flex"
        flexDirection="column"
      >
        {subtitleBind.value?.[currentLang] && (
          <RichText
            as="h1"
            fontFamily="heading"
            fontSize={['29px', null, '38px']}
            color="white"
            {...subtitleBind}
          />
        )}
        <RichText
          as="h2"
          fontFamily="jha"
          fontSize={['36px', null, '72px']}
          lineHeight={1}
          color="white"
          {...titleBind}
          style={{
            order: '-1',
          }}
        />
      </Box>
    </Box>
  );
};

export default PageHeader;
