import React, { useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';

// import useBreakpointIndex from '@/hooks/useBreakpointIndex';

const RoomCardBooking = ({ id }: { id: string }) => {
  // const bpIndex = useBreakpointIndex();
  // const isMobile = bpIndex < 4;

  const bookingContainer = useRef<HTMLDivElement>(null);
  const containerOrigin = useRef<ParentNode | null>(null);
  const hscContent = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!id) return;

    const hscContentDiv = document.getElementById(
      `diadao-hsw-qs-bestprice-${id}`,
    );
    if (hscContentDiv) {
      hscContent.current = hscContentDiv;
    } else return;

    if (hscContent.current?.parentNode) {
      containerOrigin.current = hscContent.current.parentNode;
      bookingContainer.current?.appendChild(hscContent.current);
    }

    return () => {
      if (hscContent.current)
        containerOrigin.current?.appendChild(hscContent.current);
    };
  });

  return <Box ref={bookingContainer} pointerEvents={'none'}></Box>;
};

export default RoomCardBooking;
