import {
  AccordionComponentData,
  KustomPageComponentViewProps,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Container } from '@chakra-ui/react';

import AccordionComponent from '../AccordionComponent';
import ParallaxElement from '../ParallaxElement';
import React from 'react';

import theme from '@/theme';
import { SvgShape } from '../SvgShape';

interface FAQAccordionSectionProps
  extends KustomPageComponentViewProps<AccordionComponentData> {}

const sketches = [
  null,
  null,
  <ParallaxElement
    key={'parallax-element-1'}
    Element={() => <SvgShape file="illu-coquillage-3" />}
    fill={theme.colors.accent[500]}
    right={0}
    top={200}
  />,
  null,
  null,
  null,
  <ParallaxElement
    key={'parallax-element-2'}
    Element={() => <SvgShape file="illu-poisson-2" />}
    left={-110}
    width={[250]}
  />,
  null,
  null,
  null,
  <ParallaxElement
    key={'parallax-element-3'}
    Element={() => <SvgShape file="illu-algue-2" />}
    width={[150, null, null, 200, null, 250]}
    right={[-20, null, null, 0]}
    top={-200}
  />,
];

const FAQAccordionSection: React.FC<FAQAccordionSectionProps> = (props) => {
  const { component, index } = props;

  return (
    <Box overflowX="clip">
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        position="relative"
        px={[6, null, 20]}
        pb={[10, null, 16]}
      >
        {index && sketches[index]}
        <AccordionComponent
          component={component}
          page={props.page}
          index={index}
          containerProps={{
            position: 'relative',
            mt: [6, null, null, 10, null, 14],
            pr: [null, null, null, 200, null, 300],
          }}
        />
      </Container>
    </Box>
  );
};

export default FAQAccordionSection;
