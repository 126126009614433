import { Box, Fade, SlideFade, chakra } from '@chakra-ui/react';
import {
  KustomResponsiveMedia,
  KustomTranslatedString,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useState } from 'react';

import Link from 'next/link';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';

import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import chakraResponsiveToImageSizes from '@/helpers/chakraResponsiveToImageSizes';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useNavigationKustomHref from '@/lib/kustomcms-sdk/lib/hooks/useNavigationKustomHref';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import { SvgShape } from './SvgShape';

interface SubRoute {
  label: KustomTranslatedString;
  href?: string;
  isExternalLink?: boolean;
}

interface Route {
  label?: KustomTranslatedString;
  href: KustomTranslatedString;
  media?: KustomResponsiveMedia;
  isMediaVideo?: boolean;
  isExternalLink?: boolean;
  routes?: SubRoute[];
}

interface MenuModalProps {
  label?: KustomTranslatedString;
  index: number;
  routes: Route[];
  isOpen: boolean;
  onClose?: () => void;
}

const MenuModal: React.FC<MenuModalProps> = (props) => {
  const { label, index, routes, isOpen, onClose } = props;

  const [currentMenu, setCurrentMenu] = useState<Route | null>(
    routes[0] || null,
  );

  const bpIndex = useBreakpointIndex();

  const device = useResponsiveMediasDevice();

  // setCurrentMenuMedia(
  //   menu.find((item) => item.label === itemLabel)?.routes[0].media || null,
  // );

  const [isMenuSubItemOpen, setIsMenuSubItemOpen] = useState(false);

  return (
    <Fade in={isOpen}>
      <Box
        minH={[null, null, '560px']}
        overflow="hidden"
        bgColor="white"
        borderRadius="lg"
        px={[2, null, 10]}
        py={[4, null, 10]}
        display={['block', null, isOpen ? 'flex' : 'none']}
        justifyContent="space-between"
      >
        <Box w={['100%', null, null, '560px']}>
          <TranslatedString
            fontFamily="body"
            fontSize="20px"
            color="brand.100"
            display={['block', null, 'none']}
            textTransform="capitalize"
            fontWeight="bold"
            mb={4}
            mt={4}
            as="div"
          >
            {label}
          </TranslatedString>
          {routes.map((route, index) => (
            <MenuItem
              key={index}
              route={route}
              index={index}
              isOpen={isOpen}
              onClose={onClose}
              setCurrentMenu={setCurrentMenu}
              isMenuSubItemOpen={isMenuSubItemOpen}
              setIsMenuSubItemOpen={setIsMenuSubItemOpen}
            />
          ))}
        </Box>
        <Box
          flex="1 1 0"
          position="relative"
          display={['none', null, null, 'block']}
        >
          {/* Sketch */}
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(25%, -40%) rotate(-5deg)"
            w={[null, null, '409px', null, null, '512px']}
            h={[null, null, '358px', null, null, '448px']}
            overflowX="clip"
            bgColor="#F9F5EC"
            boxShadow="-8px 8px 8px #00000014;"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {index === 0 ? (
              <SvgShape
                file="illu-homard-blue"
                width={bpIndex > 5 ? '360px' : '288px'}
                style={{
                  transform: 'rotate(90deg)',
                }}
              />
            ) : (
              <SvgShape
                file="illu-coquillage-2-blue"
                width={bpIndex > 5 ? '400px' : '320px'}
                style={
                  {
                    // transform: 'translate(20px, 90px)',
                  }
                }
              />
            )}
          </Box>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%) rotate(2deg)"
            h={[null, null, '348px', null, null, '435px']}
            w={[null, null, '600px', null, null, '750px']}
            borderRadius="lg"
            overflow="hidden"
            background="linear-gradient(121deg, #F2EDE4 25%, #FEFEFE 52%, #F2EDE4 78%)"
          >
            <Box position="relative" w="100%" h="100%">
              {currentMenu?.media && (
                <ResponsiveMedias
                  value={currentMenu.media}
                  width={undefined}
                  height={undefined}
                  fill
                  sizes={chakraResponsiveToImageSizes([
                    null,
                    null,
                    '600px',
                    null,
                    null,
                    '750px',
                  ])}
                  currentDevice={device}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

interface MenuItemProps {
  route: Route;
  index: number;
  isOpen: boolean;
  onClose?: () => void;
  setCurrentMenu: (route: Route) => void;
  isMenuSubItemOpen: boolean;
  setIsMenuSubItemOpen: (value: boolean) => void;
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  const {
    route,
    index,
    isOpen,
    onClose,
    setCurrentMenu,
    isMenuSubItemOpen,
    setIsMenuSubItemOpen,
  } = props;

  const linkProps = useNavigationKustomHref(route.href);

  const bpIndex = useBreakpointIndex();

  return (
    <SlideFade key={index} offsetY="80px" in={isOpen} delay={(index + 1) / 20}>
      <Box
        my={[4, null, 6]}
        onMouseEnter={() => {
          if (index === 0) setIsMenuSubItemOpen(true);
        }}
        onMouseLeave={() => {
          if (index === 0) setIsMenuSubItemOpen(false);
        }}
        // border={isMenuSubItemOpen ? '1px solid red' : '1px solid blue'}
        className={`menu-item-${index}`}
        transform={
          isMenuSubItemOpen && index > 0 && bpIndex > 2
            ? `translateY(40px)`
            : `none`
        }
        transition={`transform 0.5s ease ${index * 0.05}s`}
      >
        <Link
          href={linkProps.href}
          onClick={onClose}
          target={route.isExternalLink ? '_blank' : linkProps.target}
        >
          <TranslatedString
            fontSize={['32px', null, '48px', null, null, '60px']}
            fontFamily="'jha_myhappy70ssemilight'"
            lineHeight={1}
            transition="color 0.2s"
            _groupHover={{
              color: 'brand.100',
            }}
            onMouseEnter={() => {
              setCurrentMenu(route);
            }}
            as="div"
          >
            {route.label}
          </TranslatedString>
        </Link>
        {route.routes && (
          <Box
            display="flex"
            gap={[6, null, null, 10]}
            maxHeight={
              isMenuSubItemOpen && bpIndex > 2
                ? '50px'
                : bpIndex > 2
                ? '0'
                : 'none'
            }
            mt={0}
            opacity={isMenuSubItemOpen && bpIndex > 2 ? 1 : bpIndex > 2 ? 0 : 1}
            overflow="hidden"
            transition="all 0.5s ease, opacity 0.4s ease 0.1s"
            position={[null, null, null, 'absolute']}
          >
            {route.routes.map((subRoute, index) => (
              <MenuSubItem key={index} subRoute={subRoute} onClose={onClose} />
            ))}
          </Box>
        )}
      </Box>
    </SlideFade>
  );
};

interface MenuSubItemProps {
  subRoute: SubRoute;
  onClose?: () => void;
}

const MenuSubItem: React.FC<MenuSubItemProps> = (props) => {
  const { subRoute, onClose } = props;

  const linkProps = useNavigationKustomHref(subRoute.href || '#');

  return (
    <Link {...linkProps} onClick={onClose}>
      <Box display="flex" alignItems="center" data-component-name="subroute">
        <chakra.span
          position="relative"
          fontSize={['20px', '20px', '26px', null, null, '32px']}
          top={['+5px', null, '+3px']}
          // mr="1"
          className="icon-elmes-arrow1-white"
          _before={{
            color: 'brand.500',
          }}
          sx={{
            "[data-component-name='subroute']:hover &": {
              _before: {
                color: 'brand.100',
              },
            },
          }}
        />
        <TranslatedString
          fontSize={['20px', '20px', '25px', null, null, '32px']}
          fontFamily="'jha_myhappy70ssemilight'"
          lineHeight={1}
          mt={2}
          // mr={[4, 8, 10]}
          transition="color 0.2s"
          sx={{
            "[data-component-name='subroute']:hover &": {
              color: 'brand.100',
            },
          }}
          as="div"
        >
          {subRoute.label}
        </TranslatedString>
      </Box>
    </Link>
  );
};

export default React.memo(MenuModal);
