import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  chakra,
  useTheme,
} from '@chakra-ui/react';
import {
  CodeComponent,
  CustomComponentData,
  KustomPage,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponent,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useMemo } from 'react';

import { Parallax } from 'react-scroll-parallax';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';

import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import { SvgShape } from '../SvgShape';

const RoomsService: React.FC<
  KustomPageComponentViewProps<CustomComponentData>
> = (props) => {
  const bpIndex = useBreakpointIndex();
  const theme = useTheme();

  const { component, page } = props;

  const textCmp = usePageComponent<TextComponentData>(
    page,
    null,
    '16899421193335740',
  );

  const extraTextBind = useKustomData<KustomTranslatedString>(
    page,
    textCmp,
    `data.text`,
  );

  const titleCmp = usePageComponent<TextComponentData>(
    page,
    null,
    '17137923665017478',
  );

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    titleCmp,
    `data.text`,
  );

  const items = useMemo(() => {
    const n =
      component.data.atomicComponents.length % 2
        ? component.data.atomicComponents.length - 1
        : component.data.atomicComponents.length;

    const items = [];

    for (let i = 0; i < n; i += 2) {
      const icon = component.data.atomicComponents[i] as CodeComponent;
      const label = i + 1;

      items.push({
        id: component.data.atomicComponents[i]?.id,
        icon: icon.code,
        labelIndex: label,
      });
    }

    return items;
  }, [component.data.atomicComponents]);

  return (
    <Box position="relative" mt={[12, null, 14, null, 16]} overflowX="clip">
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        px={[6, null, 10]}
        position="relative"
      >
        <Box
          display={['block']}
          position="absolute"
          right={bpIndex > 5 ? '-200px' : '-100px'}
        >
          <Parallax speed={-20}>
            <SvgShape
              file="illu-coquillage-2"
              width={bpIndex > 5 ? '400px' : '200px'}
            />
          </Parallax>
        </Box>
        <Box
          p={[6, null, 12]}
          pb={[5, null, 8]}
          bgColor="white"
          boxShadow="-8px 8px 8px #00000014"
          position="relative"
          zIndex={1}
        >
          <Heading
            as="h3"
            mb={[6, null, 10]}
            fontFamily="jhaSemiboldItalic"
            color="brand.500"
            fontSize={['24px', null, '33px', null, null, '42px']}
          >
            <RichText {...titleBind} />
          </Heading>
          <SimpleGrid
            columns={[1, null, 2, 3]}
            spacingX={10}
            spacingY={[3, null, 3]}
          >
            {items.map(({ id, icon, labelIndex }, index) => (
              <RoomsServiceItem
                page={page}
                component={component}
                key={id}
                icon={icon}
                labelIndex={labelIndex}
              />
            ))}
          </SimpleGrid>
          <RichText
            mt={[6, null, 8, null, 10]}
            fontFamily="jha"
            fontSize={['14px', null, '16px']}
            color="brand.500"
            {...extraTextBind}
            as="p"
          />
        </Box>
      </Container>
    </Box>
  );
};

interface RoomsServiceItemProps
  extends KustomPageComponentViewProps<CustomComponentData> {
  icon: string;
  labelIndex: number;
}

const RoomsServiceItem: React.FC<RoomsServiceItemProps> = (props) => {
  const { icon, labelIndex, page, component } = props;

  const labelBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${labelIndex}.text`,
  );

  return (
    <Box display="flex" alignItems="center">
      <chakra.span
        fontSize="42px"
        className={icon}
        mr={4}
        color={'accent.500'}
        _before={{ color: 'accent.500' }}
      />
      <RichText
        color="brand.500"
        fontSize={['14px', null, null, '16px', null, '20px']}
        fontFamily="jhaSemiboldItalic"
        {...labelBind}
        as="h4"
      />
    </Box>
  );
};

export default RoomsService;
