import { Box, Container } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

const RecruitmentsDescription: React.FC<
  KustomPageComponentViewProps<TextComponentData>
> = (props) => {
  const { page, component } = props;

  const descriptionIndex = component.data.atomicComponents.findIndex(
    (cmp) => cmp.type === 'TEXT',
  );

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const descBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${descriptionIndex}.text`,
  );

  return (
    <Box bgColor="accent.100" overflowX="clip">
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        px={[6, null, 20]}
        pt={[10, null, 20, null, null, 28]}
        position="relative"
        zIndex={1}
      >
        <RichText
          as="h2"
          fontFamily="heading"
          fontSize={['38px', null, '76px', null, null, '96px']}
          lineHeight={1}
          color="brand.500"
          mb={[10, null, 14, null, null, 20]}
          {...titleBind}
        />
        <RichText
          fontFamily="jhaSemilight"
          fontSize={['18px', null, '22px', null, null, '28px']}
          lineHeight={1.5}
          color="brand.500"
          mr={[0, null, 40]}
          {...descBind}
        />
      </Container>
    </Box>
  );
};

export default RecruitmentsDescription;
