import {
  Establishment,
  KustomPage,
  KustomSettings,
  News,
  Offer,
} from './types';

import feathers from '@feathersjs/feathers';
import getFeathersClientApp from './feathersClient';
import { paramsForServer } from 'feathers-hooks-common';

interface KustomClientConfig {
  establishmentId: string;
  kustomApiUrl?: string;
  kustomAppUrl?: string;
}

const DEFAULT_KUSTOM_API_URL = 'http://185.145.250.140:3030';
const DEFAULT_KUSTOM_APP_URL = 'http://kustomcms.fr';

export interface KustomClient {
  app: feathers.Application<{}>;
  API_URL: string;
  APP_URL: string;
  fetchEstablishment: () => Promise<Establishment | null>;
  fetchPages: () => Promise<KustomPage[]>;
  fetchPage: (id: string) => Promise<KustomPage | undefined>;
  fetchOffers: () => Promise<Offer[]>;
  fetchNews: () => Promise<News[]>;
  getSettings: (query?: any) => Promise<KustomSettings>;
}

let client: KustomClient | null = null;

const getKustomClient = (config: KustomClientConfig): KustomClient => {
  const {
    establishmentId,
    kustomAppUrl = DEFAULT_KUSTOM_APP_URL,
    kustomApiUrl = DEFAULT_KUSTOM_API_URL,
  } = config;

  if (client) {
    return client;
  }

  const app = getFeathersClientApp(kustomApiUrl);

  client = {
    app: app,
    API_URL: kustomApiUrl,
    APP_URL: kustomAppUrl,
    fetchEstablishment: async (): Promise<Establishment | null> => {
      try {
        const data = await app.service('establishments').get(establishmentId);
        return data;
      } catch (error) {
        return null;
      }
    },
    fetchPage: async (id: string): Promise<KustomPage | undefined> => {
      try {
        const data: KustomPage | undefined = await app.service('pages').get(
          id,
          paramsForServer({
            query: { establishmentId },
            stage: 'production',
          }),
        );

        return data;
      } catch (error) {
        console.error(error);
        return undefined;
      }
    },
    fetchPages: async (): Promise<KustomPage[]> => {
      try {
        const data: KustomPage[] = await app.service('pages').find(
          paramsForServer({
            query: { establishmentId },
            stage: 'production',
          }),
        );

        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    fetchOffers: async (): Promise<Offer[]> => {
      try {
        const data = await app.service('offers').find(
          paramsForServer({
            query: { establishmentsIds: establishmentId },
            stage: 'production',
          }),
        );
        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    fetchNews: async (): Promise<Offer[]> => {
      try {
        const data = await app.service('news').find(
          paramsForServer({
            query: { establishmentsIds: establishmentId },
            stage: 'production',
          }),
        );
        return data;
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    getSettings: async (query?: any): Promise<KustomSettings> => {
      try {
        const data = await app.service('establishments-settings').find({
          query: {
            establishmentId: establishmentId,
          },
          ...query,
        });

        return data.data?.[0];
      } catch (error) {
        console.error(error);
        return {};
      }
    },
  };

  return client;
};

export default getKustomClient;
