import { Box, Container } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import SeminariesForm from '../contact/ContactForm';

interface SeminariesQuotationSectionProps
  extends KustomPageComponentViewProps<TextComponentData> {}

const SeminariesQuotationSection: React.FC<SeminariesQuotationSectionProps> = (
  props,
) => {
  const { component } = props;

  const title = component.data.text;

  return (
    <Box overflowX="clip">
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        px={[6, null, 20]}
        pt={[10, null, 20, null, null, 28]}
        position="relative"
        zIndex={1}
      >
        <RichText
          as="h2"
          fontFamily="heading"
          fontSize={['38px', null, '76px', null, null, '96px']}
          lineHeight={1}
          color="brand.500"
          mb={[10, null, 14, null, null, 20]}
        >
          {title}
        </RichText>
        {/* <SeminariesForm py={6} /> */}
      </Container>
    </Box>
  );
};

export default SeminariesQuotationSection;
