import { Box, Button, Text } from '@chakra-ui/react';
import { useMemo, useState } from 'react';

import EditMediaTags from './EditMediaTags';
import Input from '../atomics/Input';
import { getUserCurrentEstablishment } from '../../helpers/establishments';
import { mediasActions } from '../../features/medias/slice';
import { shallowEqual } from 'react-redux';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import useInput from '../../hooks/useInput';
import { useKustomSelector } from '../../hooks/useKustomSelector';

const MediasTags = () => {
  const [newTag, bindNewTag, _, setNewTag] = useInput('');
  const dispatch = useAppDispatch();
  const [isEditTagsOpen, setIsEditTagsOpen] = useState(false);

  const { establishmentTags, selectedMedias } = useKustomSelector(
    (state) => ({
      establishmentTags:
        getUserCurrentEstablishment(state.app.user)?.mediaTags || [],
      selectedMedias: state.medias.selectedMedias,
    }),
    shallowEqual,
  );

  const addTagToMedias = (tag: string) => {
    dispatch(mediasActions.addTagToSelected({ tag }));
  };
  const removeTagFromMedias = (tag: string) => {
    dispatch(mediasActions.removeTagFromSelected({ tag }));
  };

  const tags = useMemo(() => {
    return establishmentTags?.map((tag: string) => {
      const occur = selectedMedias.filter((media) =>
        media.metadata?.tags?.includes(tag),
      ).length;

      return {
        tag,
        occur: occur === selectedMedias.length ? 2 : occur === 0 ? 0 : 1,
      };
    });
  }, [selectedMedias, establishmentTags]);

  const selectedN = tags.filter(
    ({ occur }: { occur: number }) => occur > 1,
  ).length;

  const handleCreateNewTag = () => {
    if (!newTag) return;
    dispatch(mediasActions.createTag({ tag: newTag }));
    setNewTag('');
  };

  return (
    <>
      <Box
        mt={6}
        width="100%"
        backgroundColor="white"
        borderRadius="base"
        boxShadow="0px 0px 21px #00000012"
        p={6}
        // position="sticky"
        // top="0"
      >
        <Box display="flex" justifyContent="space-between" mb="4">
          <Text
            textStyle="brand"
            fontWeight={700}
            fontSize="16px"
            mt={1}
            mb={3}
          >
            Thématiques
          </Text>
          <Button
            variant="outline"
            colorScheme="gray"
            onClick={() => setIsEditTagsOpen(true)}
            size="xs"
          >
            Editer les thématiques
          </Button>
        </Box>
        <Text fontSize="14px" color="gray.500" mb={2}>
          {selectedN === 0 ? 'Aucune' : selectedN} thématiques
          {selectedN > 1 ? 's' : ''} sélectionnée{selectedN > 1 ? 's' : ''}
        </Text>
        <Box mb={8}>
          {tags.map(({ tag, occur }: { tag: string; occur: number }) => (
            <Box
              backgroundColor="gray.50"
              key={tag}
              display="inline-block"
              cursor="pointer"
              px={3.5}
              py={'px'}
              mr={1.5}
              mb={1.5}
              borderColor="gray.500"
              borderWidth={occur > 1 ? 1 : 0}
              borderRadius="2xl"
              borderStyle={['', '', 'solid'][occur]}
              onClick={
                occur === 2
                  ? () => removeTagFromMedias(tag)
                  : () => addTagToMedias(tag)
              }
            >
              <Text
                fontSize="11px"
                color="gray.500"
                as="span"
                textTransform="uppercase"
                position="relative"
                top="-1px"
              >
                {tag}
              </Text>
            </Box>
          ))}
        </Box>
        <Box>
          <Text fontSize="14px" color="gray.500">
            Rajouter une thématiques
          </Text>
          <Box mt={1} display="flex">
            <Input
              placeholder="Nom de la thématiques"
              isFullWidth
              size="xs"
              mr={1}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  handleCreateNewTag();
                }
              }}
              {...bindNewTag}
            />
            <Button
              isDisabled={newTag === ''}
              onClick={handleCreateNewTag}
              size="xs"
            >
              OK
            </Button>
          </Box>
        </Box>
      </Box>
      <EditMediaTags
        tags={establishmentTags}
        isOpen={isEditTagsOpen}
        onClose={() => setIsEditTagsOpen(false)}
      />
    </>
  );
};

export default MediasTags;
