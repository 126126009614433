import '@fontsource/nunito/200.css';
import '@fontsource/nunito/300.css';
import '@fontsource/nunito/400.css';
import '@fontsource/nunito/500.css';
import '@fontsource/nunito/600.css';
import '@fontsource/nunito/700.css';
import '@fontsource/nunito/800.css';
import '@fontsource/nunito/900.css';
import '@fontsource/montserrat/200.css';
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';

import {
  Establishment,
  KustomPage,
  KustomSettings,
  News,
  Offer,
} from '../types';

import { AdminMediasModalsContextProvider } from '../admin/contexts/AdminMediasModalsContext';
import AdminThemeProvider from '../admin/components/AdminThemeProvider';
import { KustomAuthModal } from '../admin/components/KustomAuthModal';
import { KustomClient } from '..';
import { LanguageContextProvider } from '../contexts/language';
import { PagesContextProvider } from '../contexts/pages';
import { Provider } from 'react-redux';
import { SettingsContextProvider } from '../contexts/settings';
import { makeStore } from '../admin/store';
import { useMemo } from 'react';

interface KustomProviderProps {
  kustomClient: KustomClient;
  clientUrl: string;
  settings?: KustomSettings;
  pages: KustomPage[];
  offers?: Offer[];
  news?: News[];
  establishment: Establishment;
  children: React.ReactNode;
  actionButtonPatterns?: {
    [key: string]: (href: string) => void;
  };
}

const KustomProvider = (props: KustomProviderProps) => {
  const {
    kustomClient,
    clientUrl,
    settings,
    children,
    pages,
    offers = [],
    news = [],
    establishment,
    actionButtonPatterns,
  } = props;

  const store = useMemo(() => makeStore(kustomClient), []);

  return (
    // admin store provider
    <Provider store={store}>
      <LanguageContextProvider>
        <PagesContextProvider
          pages={pages}
          offers={offers}
          news={news}
          establishment={establishment}
          actionButtonPatterns={actionButtonPatterns}
          kustomClient={kustomClient}
        >
          <AdminMediasModalsContextProvider>
            <SettingsContextProvider
              settings={settings}
              kustomClient={kustomClient}
            >
              {children}
            </SettingsContextProvider>
            <AdminThemeProvider>
              <KustomAuthModal
                establishmentId={establishment?._id}
                clientUrl={clientUrl}
                pages={pages}
              />
            </AdminThemeProvider>
          </AdminMediasModalsContextProvider>
        </PagesContextProvider>
      </LanguageContextProvider>
    </Provider>
  );
};

export default KustomProvider;
