import { AppThunkAPI, StoreState } from '../../store';
import { Media, mediasThunks } from '../medias/slice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AuthenticationResult } from '@feathersjs/authentication';
import { getUserCurrentEstablishment } from '../../helpers/establishments';
import { push } from 'connected-next-router';
import { uploadThunks } from '../upload/slice';

export type AuthError =
  | 'Failed to fetch'
  | 'MISSING_TOKEN'
  | 'EXPIRED_TOKEN'
  | 'WRONG_TOKEN'
  | 'WRONG_PASSWORD'
  | 'INVALID_LOGIN'
  | 'ASK_2FA';

export type Language = 'fr' | 'en' | 'es' | 'de';

export const languages: { label: string; value: Language }[] = [
  { label: 'Français', value: 'fr' },
  { label: 'English', value: 'en' },
  { label: 'Español', value: 'es' },
];

export interface RecruitmentCity {
  id: string;
  name: string;
}

export interface Establishment {
  _id: string;
  name: string;
  mediaTags?: string[];
  storage: number;
  storageLimit: number;
  recruitmentCities?: RecruitmentCity[];
}

export interface User {
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  lastLoggedIn: Date;
  establishments: Establishment[];
  establishmentsIds: string[];
  currentEstablishmentIndex: number;
  profilePictureId?: string;
  profilePicture?: Media;
  language?: string;
  defaultPage?: string;
  lastVisitedPage?: string;
  organizationId?: string;
  organizationRole?: string;
}

export interface AppState {
  user?: User;
  status?: AuthError;
  error?: AuthError;
  credentialsCache?: {
    email: string;
    password: string;
  };
  haveTriedRelogin: boolean;
}

const initialState: AppState = {
  user: undefined,
  error: undefined,
  status: undefined,
  credentialsCache: undefined,
  haveTriedRelogin: false,
};

export const thunks = {
  //
  // AUTH
  //
  relogin: createAsyncThunk<
    AuthenticationResult | undefined,
    void,
    AppThunkAPI
  >('app/relogin', async (_, thunkAPI) => {
    try {
      return await thunkAPI.extra.feathersApp!.reAuthenticate();
    } catch {
      return null;
    }
  }),
  login: createAsyncThunk<
    AuthenticationResult | undefined,
    {
      email?: string;
      password?: string;
      token?: string;
      useCache?: boolean;
      twoFactorEnabled?: boolean;
    },
    AppThunkAPI
  >(
    'app/login',
    async (
      { email, password, token, twoFactorEnabled, useCache = false },
      thunkAPI,
    ) => {
      thunkAPI.dispatch(appActions.clearError());

      if (useCache) {
        const state = thunkAPI.getState();
        email = state.app.credentialsCache?.email;
        password = state.app.credentialsCache?.password;
      }

      if (!email && !password) {
        thunkAPI.dispatch(appActions.setError({ message: 'INVALID_LOGIN' }));
        return;
      }

      try {
        const response: AuthenticationResult =
          await thunkAPI.extra.feathersApp!.authenticate({
            strategy: 'local',
            email,
            password,
            token,
            twoFactorEnabled,
          });

        // thunkAPI.dispatch(
        //   push(
        //     response.user.defaultPage
        //       ? response.user.defaultPage === 'LAST'
        //         ? response.user.lastVisitedPage || '/'
        //         : response.user.defaultPage
        //       : '/',
        //   ),
        // );
        return response;
      } catch (err: any) {
        const errMessage: AuthError = err.message;

        if (errMessage === 'ASK_2FA') {
          thunkAPI.dispatch(
            appActions.setIntermediateStep({
              status: errMessage,
              email,
              password,
            }),
          );
          return;
        }

        if (errMessage === 'MISSING_TOKEN') {
          thunkAPI.dispatch(
            appActions.setIntermediateStep({
              status: errMessage,
              email,
              password,
            }),
          );
          return;
        }

        thunkAPI.dispatch(appActions.setError({ message: errMessage }));
      }
    },
  ),
  logout: createAsyncThunk<AuthenticationResult | undefined, void, AppThunkAPI>(
    'app/logout',
    async (_, thunkAPI) => {
      await thunkAPI.extra.feathersApp!.logout();
    },
  ),
  askReset: createAsyncThunk<
    AuthenticationResult | undefined,
    {
      email?: string;
    },
    AppThunkAPI
  >('app/askReset', async ({ email }, thunkAPI) => {
    try {
      return await thunkAPI.extra
        .feathersApp!.service('authManagement')
        .create({
          action: 'sendResetPwd',
          value: {
            email,
          },
        });
    } catch (err: any) {
      return {
        error: true,
        message: err.message,
      };
    }
  }),
  updateCurrentEstablishment: createAsyncThunk<
    User,
    {
      index?: number;
      establishmentId?: string;
    },
    AppThunkAPI
  >(
    'app/updateCurrentEstablishment',
    async ({ index, establishmentId }, thunkAPI) => {
      const user = thunkAPI.getState().app.user!;

      if (establishmentId) {
        index = user.establishmentsIds.indexOf(establishmentId);
      }

      if (!index) {
        console.error(
          'KustomError: No index or invalid establishmentId provided',
          index,
          establishmentId,
        );
        return user;
      }

      return await thunkAPI.extra
        .feathersApp!.service('users')
        .patch(user._id, {
          currentEstablishmentIndex: index,
        });
    },
  ),
  resetPassword: createAsyncThunk<
    AuthenticationResult | undefined,
    {
      token: string;
      password: string;
    },
    AppThunkAPI
  >('app/resetPassword', async ({ token, password }, thunkAPI) => {
    try {
      return await thunkAPI.extra
        .feathersApp!.service('authManagement')
        .create({
          action: 'resetPwdLong',
          value: {
            token,
            password,
          },
        });
    } catch (err: any) {
      return {
        error: true,
        message: err.message,
      };
    }
  }),
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearError: (state) => {
      state.error = undefined;
    },
    setError: (state, action) => {
      state.error = action.payload.message;
    },
    setIntermediateStep: (state, action) => {
      state.status = action.payload.status;
      state.credentialsCache = {
        email: action.payload.email,
        password: action.payload.password,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.login.pending, (state) => {
        // state.status = 'loading';
      })
      //
      .addCase(thunks.relogin.fulfilled, (state, action) => {
        state.haveTriedRelogin = true;
        if (action?.payload?.user) {
          state.user = action.payload.user;
          state.credentialsCache = undefined;
          state.status = undefined;
        }
      })
      .addCase(thunks.login.fulfilled, (state, action) => {
        if (action?.payload?.user) {
          state.user = action.payload.user;
          state.credentialsCache = undefined;
          state.status = undefined;
        }
      })
      .addCase(thunks.logout.fulfilled, (state) => {
        state.user = undefined;
      })

      .addCase(mediasThunks.createTag.fulfilled, (state, action) => {
        if (!state.user) return;

        state.user.establishments[state.user.currentEstablishmentIndex] =
          action.payload.newEstablishment;
      })
      .addCase(mediasThunks.updateTags.fulfilled, (state, action) => {
        if (!state.user) return;

        state.user.establishments[state.user.currentEstablishmentIndex] =
          action.payload.newEstablishment;
      })
      .addCase(thunks.updateCurrentEstablishment.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(uploadThunks.uploadFile.fulfilled, (state, action) => {
        const user = state.user;

        if (!user) return;

        const est = getUserCurrentEstablishment(user);
        if (est) {
          est.storage += action.meta.arg.file.size;
        }
      })
      .addCase(mediasThunks.removeMedia.fulfilled, (state, action) => {
        const user = state.user;

        if (!user) return;

        const est = getUserCurrentEstablishment(user);
        if (est) {
          if (action.meta.arg.onlyFraming) {
            est.storage -= +(action.meta.arg.file.metadata.size || 0);
          } else {
            est.storage -= action.payload.removedSize || 0;
          }
        }
      });
  },
});

export const appActions = { ...appSlice.actions, ...thunks };

export default appSlice.reducer;
