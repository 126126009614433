import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
} from '@chakra-ui/react';
import { Media, mediasActions } from '../../features/medias/slice';
import { useEffect, useMemo } from 'react';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import useInput from '../../hooks/useInput';

interface MediaSEOProps {
  media: Media;
}

const MediaSEO = (props: MediaSEOProps) => {
  const { media } = props;

  const dispatch = useAppDispatch();
  const [filenameFR, bindFilenameFR] = useInput(media.metadata?.keys?.fr || '');
  const [filenameEN, bindfilenameEN] = useInput(media.metadata?.keys?.en || '');
  const [titleFR, bindTitleFR] = useInput(media.metadata?.titles?.fr || '');
  const [titleEN, bindTitleEN] = useInput(media.metadata?.titles?.en || '');
  const [altFR, bindAltFR] = useInput(media.metadata?.alts?.fr || '');
  const [altEN, bindAltEN] = useInput(media.metadata?.alts?.en || '');

  const isModified = useMemo(() => {
    return (
      filenameFR !== (media.metadata?.keys?.fr || '') ||
      filenameEN !== (media.metadata?.keys?.en || '') ||
      titleFR !== (media.metadata?.titles?.fr || '') ||
      titleEN !== (media.metadata?.titles?.en || '') ||
      altFR !== (media.metadata?.alts?.fr || '') ||
      altEN !== (media.metadata?.alts?.en || '')
    );
  }, [media.metadata, filenameFR, filenameEN, titleFR, titleEN, altFR, altEN]);

  const handleSaveSEO = () => {
    dispatch(
      mediasActions.updateMetadata({
        id: media.metadata?._id,
        metadata: {
          keys: {
            fr: filenameFR,
            en: filenameEN,
          },
          titles: {
            fr: titleFR,
            en: titleEN,
          },
          alts: {
            fr: altFR,
            en: altEN,
          },
        },
      }),
    );
  };

  return (
    <Box
      mt={6}
      width="100%"
      backgroundColor="white"
      borderRadius="base"
      boxShadow="0px 0px 21px #00000012"
      p={6}
    >
      <Text textStyle="brand" fontWeight={700} fontSize="16px" mt={2} mb={5}>
        SEO
      </Text>
      <Text color="gray.500" fontWeight={700} fontSize="14px">
        Nom du fichier
      </Text>
      {SEOInput('FR', bindFilenameFR)}
      {SEOInput('EN', bindfilenameEN)}
      <Text color="gray.500" fontWeight={700} fontSize="14px" mt="6">
        Titres
      </Text>
      {SEOInput('FR', bindTitleFR)}
      {SEOInput('EN', bindTitleEN)}
      <Text color="gray.500" fontWeight={700} fontSize="14px" mt="6">
        Alt
      </Text>
      {SEOInput('FR', bindAltFR)}
      {SEOInput('EN', bindAltEN)}
      <Box>
        <Button
          isDisabled={!isModified}
          size="md"
          mt="5"
          onClick={handleSaveSEO}
        >
          Enregistrer
        </Button>
      </Box>
    </Box>
  );
};

const SEOInput = (label: string, bindInput?: any) => (
  <InputGroup
    size="sm"
    mt="2"
    backgroundColor="gray.50"
    borderColor={'transparent'}
    borderRadius="base"
    overflow="hidden"
  >
    <InputLeftAddon
      backgroundColor="rgba(95, 95, 97, 0.05)"
      color="gray.500"
      fontSize="10px"
      fontWeight="700"
    >
      {label}
    </InputLeftAddon>
    <Input type="text" placeholder="Description" {...bindInput} />
  </InputGroup>
);

export default MediaSEO;
