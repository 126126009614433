import { Box, Button, Text } from '@chakra-ui/react';

import { getUserCurrentEstablishment } from '../../helpers/establishments';
import { toHumanFileSize } from '../../helpers/media';
import { useKustomSelector } from '../../hooks/useKustomSelector';

const StorageLimit = () => {
  const user = useKustomSelector((state) => state.app.user);

  const establishment = getUserCurrentEstablishment(user) || {
    storage: 0,
    storageLimit: 0,
  };
  const used = Math.ceil(
    (establishment.storage * 100) / establishment.storageLimit,
  );

  return (
    <Box
      width="100%"
      backgroundColor="white"
      borderRadius="base"
      boxShadow="0px 0px 21px #00000012"
      p={6}
      mb={4}
    >
      <Text textStyle="brand" fontWeight={700} fontSize="16px" mb={5}>
        Capacité de stockage
      </Text>
      <Text color="gray.500" fontSize="14px" mt={2} mb={2}>
        {toHumanFileSize(establishment.storage)} sur{' '}
        {toHumanFileSize(establishment.storageLimit)} utilisé
      </Text>
      <Box w="100%" h="8px" backgroundColor="#EBEFFB" borderRadius="base">
        <Box
          w={used + '%'}
          h="8px"
          borderRadius="base"
          backgroundColor={used >= 80 ? 'red.500' : 'brand.500'}
        ></Box>
      </Box>
      {used >= 80 && (
        <a target="_blank" href="https://diadao.fr/" rel="noreferrer">
          <Button px={6} mt={5} colorScheme="gray" variant="outline">
            AJOUTER DU STOCKAGE
          </Button>
        </a>
      )}
    </Box>
  );
};

export default StorageLimit;
