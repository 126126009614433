import '@/styles/globals.css';
import '@/styles/planity.scss';
import '../wdyr';
import '@/../public/assets/fonts/Inter-VariableFont/stylesheet.css';
import '@/../public/assets/fonts/JHA/stylesheet.css';
import '@/../public/assets/fonts/MyHappy/stylesheet.css';
import '../components/atomics/SideSelect.css';
import 'react-slideshow-image/dist/styles.css';
import '../components/atomics/Slideshow.css';
import '../components/gallery/GallerySection.css';
import 'react-loading-skeleton/dist/skeleton.css';
import '../kustomPagesConfig';
import '@/lib/kustomcms-sdk/lib/admin/style.scss';

import { useContext, useEffect, useMemo, useRef } from 'react';

import App, { AppContext, AppInitialProps, AppLayoutProps } from 'next/app';
import { Box, ChakraBaseProvider, Fade } from '@chakra-ui/react';
import {
  Establishment,
  KustomPage,
  KustomSettings,
  News,
  Offer,
} from '@/lib/kustomcms-sdk/lib/types';
import type { NextComponentType, NextLayoutComponentType } from 'next';

import ReservationRouteContext, {
  ReservationRouteContextProvider,
} from '@/contexts/ReservationRoute';

import CookiesConsentContext, {
  CookiesConsentContextProvider,
} from '@/contexts/CookiesConsentContext';

import Footer from '@/components/Footer';
import Head from 'next/head';
import KustomProvider from '@/lib/kustomcms-sdk/lib/components/KustomProvider';
import MediasViewer from '@/components/MediasViewer';
import { MediasViewerContextProvider } from '@/contexts/MediasViewerContext';
import Menu from '@/components/Menu';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import { ParallaxProvider } from 'react-scroll-parallax';
// import SmoothScroll from '@/lib/SmoothScroll';
import getKustomClientInstance from '@/kustomClient';
import loaderAnimationData from '../lotties/loader.json';
import theme from '@/theme';
import { useRouter } from 'next/router';
import useScrollRestoration from '@/hooks/useScrollRestoration';
import { ScriptsInjector } from '@/components/ScriptsInjector';
import Fonts from '@/components/Fonts';
import { LanguageContextKey } from '@/lib/kustomcms-sdk/lib/contexts/language';
import DiadaoHsc from '@/components/DiadaoHsc';

import { KUSTOM_CLIENT_URL } from '@/config';

const kustomClient = getKustomClientInstance();

export const loaderLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderAnimationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

type AppProps = AppLayoutProps & {
  pages: KustomPage[];
  offers: Offer[];
  news: News[];
  establishment: Establishment;
  settings?: KustomSettings;
};

const _App: NextComponentType<AppContext, AppInitialProps, AppProps> = (
  props,
) => {
  const { Component, pageProps, pages, offers, news, establishment, settings } =
    props;

  const router = useRouter();

  // useEffect(() => {
  //   const killSmoothScroll = SmoothScroll(document as any, 120, 12);
  //   return killSmoothScroll;
  // }, [router.pathname]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const windowType = typeof window;

  const restoreScrollPages = useMemo(
    () =>
      [
        pages.find((page) =>
          ['joboffers', 'rooms'].includes(page?.model || ''),
        ),
      ].filter((p) => !!p) as KustomPage[],
    [pages],
  );

  /*
  useScrollRestoration(
    router,
    restoreScrollPages,
    ({ url, removeScrollPosition }) => {
      const page = url
        ? pages.find((p) => Object.values(p.prettyUrl).includes(url))
        : undefined;
      if (!['rooms', 'room'].includes(page?.model || '')) {
        removeScrollPosition();
      }
    },
  );
  */

  useScrollRestoration(
    router,
    restoreScrollPages,
    ({ url, removeScrollPosition }) => {
      const page = url
        ? pages.find((p) => Object.values(p.prettyUrl).includes(url))
        : undefined;

      if (
        !['rooms', 'room', 'joboffer', 'joboffers'].includes(page?.model || '')
      ) {
        removeScrollPosition();
      }
    },
  );

  useEffect(() => {
    if (windowType === 'undefined') return;

    // fixed vh on mobile
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [windowType]);

  return (
    <>
      <Head>
        <title>Les Elmes Hotel</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.png" />

        {/* GTM  */}
        {settings?.googleServices?.googleTagManagerHeaderTag && (
          <script
            async
            id="gtm-head"
            dangerouslySetInnerHTML={{
              __html: settings?.googleServices?.googleTagManagerHeaderTag || '',
            }}
          />
        )}

        <link rel="icon" href="/favicon.png2" />
      </Head>
      <div ref={wrapperRef}>
        <ChakraBaseProvider theme={theme} cssVarsRoot="body">
          <Fonts />
          <ParallaxProvider>
            <CookiesConsentContextProvider>
              <ReservationRouteContextProvider>
                <AppSubLevel
                  pages={pages}
                  offers={offers}
                  news={news}
                  establishment={establishment}
                  settings={settings}
                  Component={Component}
                  pageProps={pageProps}
                />
              </ReservationRouteContextProvider>
            </CookiesConsentContextProvider>
          </ParallaxProvider>
        </ChakraBaseProvider>
      </div>
      <Box
        className="landscape-warning"
        position="absolute"
        zIndex={99999999999}
        bgColor="rgba(0, 0, 0, 0.8)"
        top={0}
        left={0}
        right={0}
        bottom={0}
      />
      {/* GTM  */}
      {settings?.googleServices?.googleTagManagerBodyTag && (
        <div
          id="gtm-body"
          dangerouslySetInnerHTML={{
            __html: settings?.googleServices?.googleTagManagerBodyTag || '',
          }}
        />
      )}
    </>
  );
};

interface AppSubLevelProps {
  pages: KustomPage[];
  offers: Offer[];
  news: News[];
  establishment: Establishment;
  settings?: KustomSettings;
  Component: NextLayoutComponentType;
  pageProps: any;
}

const AppSubLevel: React.FC<AppSubLevelProps> = (props) => {
  const { pages, offers, news, establishment, settings, Component, pageProps } =
    props;

  const reservationCtx = useContext(ReservationRouteContext);

  const buttonActionPatterns = useMemo(
    () => ({
      '@reservationHref': (href: string) => {
        reservationCtx.setRoute(href);
      },
      '@reservationModalHref': (href: string) => {
        reservationCtx.setModalRoute(href);
      },
    }),
    [reservationCtx],
  );

  const { getWinConsents, setConsents } = useContext(CookiesConsentContext);
  useEffect(() => {
    getWinConsents();
    window.addEventListener('kustom:axeptioUpdate', ((
      e: CustomEvent<{ choices: any }>,
    ) => {
      setConsents(e.detail.choices);
    }) as EventListener);

    return () => {
      window.removeEventListener('kustom:axeptioUpdate', ((
        e: CustomEvent<{ choices: any }>,
      ) => {
        setConsents(e.detail.choices);
      }) as EventListener);
    };
  }, []);

  return (
    <KustomProvider
      kustomClient={kustomClient}
      clientUrl={KUSTOM_CLIENT_URL}
      settings={settings}
      pages={pages}
      offers={offers}
      news={news}
      establishment={establishment}
      actionButtonPatterns={buttonActionPatterns}
    >
      {Component.displayName === 'ExternalPage' ? (
        <AppSubLevel2 Component={Component} pageProps={pageProps} />
      ) : (
        <MediasViewerContextProvider>
          <AppSubLevel2 Component={Component} pageProps={pageProps} />
          <MediasViewer />
          <ScriptsInjector />
        </MediasViewerContextProvider>
      )}
    </KustomProvider>
  );
};

interface AppSubLevel2Props {
  Component: NextLayoutComponentType;
  pageProps: any;
}

const AppSubLevel2: React.FC<AppSubLevel2Props> = (props) => {
  const { Component, pageProps } = props;

  const router = useRouter();

  const { locale } = useRouter();
  const language = locale ? (locale as LanguageContextKey) : 'fr';
  const slug = ((router.query.slug || []) as string[])?.join('/');
  const route = `${language}/${slug}`;

  const { routes, pages, previsualizedPage } = useContext(PagesContext);
  const pageData = routes[route] || routes[slug];

  useEffect(() => {
    document.body.classList.remove('kustom-page-rooms', 'kustom-page-room');
    if (pageData?.model)
      document.body.classList.add(`kustom-page-${pageData?.model}`);
  }, [pageData]);

  const layout = useMemo(() => {
    const getLayout =
      Component.getLayout?.(route, previsualizedPage || pageData, pages) ||
      (({ page }) => (
        <>
          <div className="page-content">{page}</div>
          <Footer />
        </>
      ));
    return getLayout({
      page: <Component {...pageProps} />,
    });
  }, [Component, pageData, pageProps, route, language]);

  return (
    <>
      <Fade in>{layout}</Fade>
      {Component.displayName !== 'ExternalPage' ? (
        <>
          <DiadaoHsc />
          <Menu
            isVisible={
              pageData?.model === 'room' ||
              pageData?.model === 'joboffer' ||
              pageData?.model === 'offer'
                ? false
                : true
            }
          />
        </>
      ) : null}
    </>
  );
};

_App.getInitialProps = async (context: AppContext) => {
  const pageProps = await App.getInitialProps(context);

  const establishment = await kustomClient.fetchEstablishment();
  const pages = await kustomClient.fetchPages();
  const settings = await kustomClient.getSettings();
  const offers = await kustomClient.fetchOffers();
  const news = await kustomClient.fetchNews();

  return {
    ...pageProps,
    establishment,
    pages,
    settings,
    offers,
    news,
  };
};

export default _App;
