import { Box, Container, Select } from '@chakra-ui/react';
import {
  JobOfferComponentData,
  JobOfferListComponentData,
  KustomPage,
  KustomPageComponentViewProps,
  contractTypes,
  jobOfferCategories,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useMemo } from 'react';

import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import RecruitmentListItems from './RecruitmentListItems';
import RecruitmentListTable from './RecruitmentListTable';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useInput from '@/hooks/useInput';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

const RecruitmentsList: React.FC<
  KustomPageComponentViewProps<JobOfferListComponentData>
> = (props) => {
  const { component } = props;

  const { currentLang } = useContext(LanguageContext);

  const { establishment, routes } = useContext(PagesContext);

  const bpIndex = useBreakpointIndex();

  const recruitmentCities = establishment?.recruitmentCities;

  // const jobOffersId = component.data.items;
  const jobOffersId = component.data.activeItemsId;

  const citiesText =
    useKustomTranslation('recruitment_cities')?.value?.[currentLang];
  const categoryText = useKustomTranslation('recruitment_category')?.value?.[
    currentLang
  ];
  const contractTypeText = useKustomTranslation('recruitment_contract_type')
    ?.value?.[currentLang];

  const [localisation, bindLocalisation] = useInput('', citiesText);
  const [category, bindCategory] = useInput('', categoryText);
  const [contractType, bindContractType] = useInput('', contractTypeText);

  const jobOffers = useMemo(() => {
    const offers = Object.values(routes).filter(
      (page) => page.model === 'joboffer',
    );

    return jobOffersId
      .map((offerId) => offers.find((offer) => offer._id === offerId))
      .filter((offer) => !!offer)
      .filter((offer) => {
        const specsData = offer?.components.find(
          (component) => component.type === 'jobOffer',
        )?.data as JobOfferComponentData;

        if (localisation && specsData.locationId !== localisation) {
          return false;
        }
        if (category && specsData.category !== category) {
          return false;
        }
        if (contractType && specsData.contractType !== contractType) {
          return false;
        }

        return true;
      });
  }, [
    jobOffersId,
    routes,
    localisation,
    category,
    contractType,
  ]) as KustomPage[];

  return (
    <Container
      maxW={['container.lg', null, null, null, null, '1362px']}
      px={[6, null, 20]}
      pt={[10, null, 20, null, null, 28]}
      position="relative"
      zIndex={1}
    >
      <Box display="flex" flexDir={['column', null, 'row']}>
        <Select
          maxW={[null, null, 350]}
          mr={5}
          mb={3}
          h={14}
          fontWeight="bold"
          size="lg"
          color="brand.500"
          bgColor="white"
          {...bindLocalisation}
        >
          {recruitmentCities?.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Select>
        <Select
          maxW={[null, null, null, 400]}
          mr={5}
          mb={3}
          h={14}
          fontWeight="bold"
          size="lg"
          color="brand.500"
          bgColor="white"
          {...bindCategory}
        >
          {jobOfferCategories?.map(({ label, value }) => (
            <option key={value} value={value}>
              {label[currentLang]}
            </option>
          ))}
        </Select>
        <Select
          maxW={[null, null, 350]}
          mr={5}
          mb={3}
          h={14}
          fontWeight="bold"
          size="lg"
          color="brand.500"
          bgColor="white"
          {...bindContractType}
        >
          {contractTypes?.map(({ label, value }, index) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Select>
      </Box>

      {bpIndex < 2 ? (
        <RecruitmentListItems
          jobOffers={jobOffers}
          recruitmentCities={recruitmentCities}
        />
      ) : (
        <RecruitmentListTable
          jobOffers={jobOffers}
          recruitmentCities={recruitmentCities}
        />
      )}
    </Container>
  );
};

export default RecruitmentsList;
