import { Box, Container } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';

export interface TextProps
  extends KustomPageComponentViewProps<TextComponentData> {}

const Text: React.FC<TextProps> = (props) => {
  const { component } = props;

  const text = component.data.text;

  return (
    <>
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        position="relative"
        overflowX="clip"
        px={[6, null, 20]}
        pt={[20, null, 32]}
      >
        <Box position="relative">
          <RichText
            fontFamily="jhaSemilight"
            fontSize={['20px', null, '28px']}
            color="brand.500"
            as="p"
          >
            {text}
          </RichText>
        </Box>
      </Container>
    </>
  );
};

export default Text;
