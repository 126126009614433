import { useContext, useMemo } from 'react';

import { KustomTranslatedString } from '../types';
import LanguageContext from '../contexts/language';
import PagesContext from '../contexts/pages';

const pageCodeIdRegex = /\{([A-Za-z0-9]+)\}/;
const blankHrefRegex = /^@blank=/;

const useNavigationKustomHref = (_href?: KustomTranslatedString | string) => {
  const pagesCtx = useContext(PagesContext);
  const langCtx = useContext(LanguageContext);
  const language = useContext(LanguageContext);
  const { actionButtonPatterns } = useContext(PagesContext);

  const href =
    typeof _href === 'string'
      ? _href
      : _href?.[language.currentLang || 'fr'] || _href?.fr || '';

  const link = useMemo(() => {
    const matches = href.match(pageCodeIdRegex);

    if (matches) {
      const codeId = matches[0].slice(1, -1);
      const hrefPage = Object.values(pagesCtx.routes).find(
        (page) => page.pageCode === codeId || page.objectCode === codeId,
      );

      const value =
        hrefPage?.prettyUrl[langCtx.currentLang || 'fr'] ||
        hrefPage?.prettyUrl.fr;

      return { href: href.replace(pageCodeIdRegex, `/${value}` || '#') };
    }

    if (blankHrefRegex.test(href)) {
      return { href: href.replace(blankHrefRegex, '') };
    }

    const isCustomAction = href.startsWith('@');
    const [actionPattern = '', actionHref = ''] = href.split('=') || ['', ''];
    const customAction = isCustomAction
      ? actionButtonPatterns?.[actionPattern]
      : undefined;
    const actionName = actionPattern.replace('@', '');
    if (isCustomAction) {
      return customAction
        ? {
            href: `#${actionName}`,
            onClick: () => {
              customAction(actionHref);
            },
          }
        : { href: `#${actionName}` };
    }

    return { href: href };
  }, [href, langCtx.currentLang]);

  return {
    ...link,
    target: blankHrefRegex.test(href) ? '_blank' : '_self',
  };
};

export default useNavigationKustomHref;
