import {
  Box,
  BoxProps,
  Button,
  Heading,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';

import Toast from '../atomics/Toast';
import { getCSSDashedBorder } from '../../helpers/getCSSDashedBorder';
import { unzipFiles } from '../../helpers/media';
import { uploadActions } from '../../features/upload/slice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useDropzone } from 'react-dropzone';
import useTheme from '../../hooks/useTheme';

interface UploadCanvasProps extends BoxProps {
  onFileUpload?: (files: File[]) => void;
  openUploadRef?: React.MutableRefObject<any>;
}

const UploadCanvas = (props: UploadCanvasProps) => {
  const theme = useTheme();
  const { onFileUpload, openUploadRef, ...boxProps } = props;

  const onDrop = useCallback(
    async (acceptedFiles: any[]) => {
      const files = [];

      for (const file of acceptedFiles) {
        files.push(...(await unzipFiles(file)));
      }

      onFileUpload?.(files);
    },
    [onFileUpload],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
  });

  const rootProps = getRootProps();

  useEffect(() => {
    if (!openUploadRef) return;
    openUploadRef.current = open;
  }, [openUploadRef, open]);

  // @ts-ignore
  const brandColor = theme.colors?.brand?.['500'];

  return (
    <Box
      d="flex"
      flex="1"
      backgroundColor="white"
      borderRadius="base"
      boxShadow="0px 0px 21px #00000012"
      p={6}
      {...boxProps}
      position={'relative'}
    >
      <input {...getInputProps()} />
      <Box
        {...rootProps}
        display="flex"
        flex="1"
        flexDir="column"
        borderRadius="base"
        {...getCSSDashedBorder(isDragActive ? brandColor : '#60636d')}
        alignItems="center"
        py={4}
      >
        <Heading
          // textStyle="body"
          as="h2"
          fontSize="28px"
          mt={4}
          mb={7}
          color={isDragActive ? brandColor : 'brand.800'}
        >
          Ajouter à votre bibliothèque média
        </Heading>
        <Text textStyle="brand">
          Faites glisser les fichiers à importer (jpg, svg, png, mp4, gif, pdf,
          zip)
        </Text>
        <Text fontSize="14px" textStyle="brand">
          ou
        </Text>
        <Button m="auto" my={3} size="sm" p={5} colorScheme="brand">
          Choisissez un fichier
        </Button>
        <Text fontSize="14px" color="gray.400">
          Taille max des fichiers: 10 Mo
        </Text>
      </Box>
    </Box>
  );
};

export default UploadCanvas;
