import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import LanguageContext, {
  LanguageContextKey,
} from '@/lib/kustomcms-sdk/lib/contexts/language';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import {
  KustomTranslatedString,
  News,
  Offer,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Button, Text } from '@chakra-ui/react';
import Image from 'next/image';
import { useContext, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

const skeletonColor = {
  base: '#f7eee0',
  highlight: '#F9F5EC',
};
const quality = 80;

interface OffersAndNewsCardProps {
  offersAndNews: News | Offer;
  onClick: () => void;
}

const labelType = ['OFFER', 'BOOK', 'DISCOVER', 'TAKE', 'BUY'];
const labelTheme = [
  'LAST_MINUTE',
  'EARLY_BOOKING',
  'PROMOTION',
  'PACKAGE',
  'GIFT_BOX',
];

const OffersAndNewsCard: React.FC<OffersAndNewsCardProps> = ({
  offersAndNews,
  onClick,
}) => {
  const { currentLang } = useContext(LanguageContext);
  const settingsCtx = useContext(SettingsContext);

  const media = offersAndNews.medias?.[0];
  const [isHovered, setIsHovered] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const getTranslation = (key: string) => {
    return settingsCtx.translations?.[`offers_${key}`]?.[currentLang];
  };

  const textButton = offersAndNews?.buttonLabel
    ? getTranslation(offersAndNews?.buttonLabel)
      ? getTranslation(offersAndNews?.buttonLabel)
      : offersAndNews?.buttonLabel
    : null;

  const offer: Offer | null =
    offersAndNews.objectType === 'offers' ? (offersAndNews as Offer) : null;

  const offerTheme: string | null =
    offersAndNews.objectType === 'offers'
      ? (offersAndNews as Offer).theme
      : null;
  // : 'NEWS';

  const textTheme = offerTheme
    ? getTranslation(offerTheme)
      ? getTranslation(offerTheme)
      : offerTheme
    : null;

  const price =
    offer?.price &&
    (offer.price.toString().includes('.') ||
      offer.price.toString().includes(','))
      ? Number(offer.price).toFixed(2)
      : offer?.price;

  return (
    <Box
      onClick={onClick}
      bgColor="white"
      cursor="pointer"
      h="100%"
      borderRadius="base"
      position="relative"
      boxShadow="-8px 8px 8px #00000014"
      display="flex"
      flexDir="column"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {media?.url && (
        <Box overflow={'clip'} position={'relative'} borderRadius={'base'}>
          {textTheme && (
            <Box
              bg={'brand.500'}
              color="#ffffff"
              position={'absolute'}
              left={0}
              top={0}
              zIndex={2}
              py={3}
              px={4}
              fontFamily={'body'}
              fontSize={'0.9em'}
              fontWeight={700}
              borderRadius={'0 0 3px 0'}
            >
              {textTheme}
            </Box>
          )}
          {!isLoaded && (
            <Skeleton
              width="100%"
              height="100%"
              style={{
                zIndex: 0,
                position: 'absolute',
                right: 0,
                left: 0,
                top: 0,
                opacity: isLoaded ? 0 : 1,
              }}
              borderRadius={0}
              baseColor={skeletonColor.base}
              highlightColor={skeletonColor.highlight}
            />
          )}
          <Box aspectRatio={'1/1'} position={'relative'} w={'100%'}>
            <Image
              loading="lazy"
              alt={''}
              src={media!.url}
              quality={quality}
              fill
              style={{
                opacity: isLoaded ? 1 : 0,
                transition: 'transform 0.3s ease',
                transform: isHovered ? 'scale(1.2)' : 'scale(1)',
                objectFit: 'cover',
              }}
              sizes="(max-width: 960px) 95vw, (max-width: 1200px) 40vw,(max-width: 1920px) 30vw, 600px"
              onLoadingComplete={() => {
                setIsLoaded(true);
              }}
            />
          </Box>
        </Box>
      )}
      <Box
        px={[8, null, 14]}
        py={[7, null, 10]}
        display="flex"
        flexDir="column"
        flexGrow={1}
      >
        <TranslatedString
          as="h3"
          lineHeight={1}
          fontFamily="jha"
          fontSize={['24px', null, '34px', null, null, '36px']}
          color="brand.500"
        >
          {offersAndNews.title}
        </TranslatedString>
        {(offersAndNews.shortDescription?.[currentLang] ||
          offersAndNews.shortDescription?.fr) && (
          <Text
            fontFamily="jhaSemiboldItalic"
            fontSize={['14px', null, '20px']}
            display="flex"
            flexDir="row"
            flexWrap="wrap"
            mt={[4, null, 6]}
          >
            <TranslatedText value={offersAndNews.shortDescription} />
          </Text>
        )}
        <Box color="brand.500">
          {/* {offer && !offer.isPriceDisabled && offer.price ? ( */}
          {offer && !offer.isPriceDisabled && price ? (
            <Box mt={[4, null, 6]}>
              <>
                {offer.isDiscountEnabled && offer.discount ? (
                  <>
                    {offer.isDiscountPercentage ? (
                      <Box fontWeight={'bold'} fontSize={'1.25em'}>
                        -{offer.discount}%
                      </Box>
                    ) : (
                      <Box textDecoration={'line-through'}>
                        {offer.discount}€
                      </Box>
                    )}
                  </>
                ) : null}
                {offer.isPriceFrom ? (
                  <Box fontSize={'0.85em'}>à partir de :</Box>
                ) : null}
                <Box fontWeight={'700'}>
                  <Box as="span" fontSize={'1.75em'} lineHeight={'1.125'}>
                    {/* {offer.price} */}
                    {price}
                    <Box as="span" fontSize={'0.85em'}>
                      {'€ '}
                    </Box>
                  </Box>
                  {offer.priceDetails ? (
                    <>{offer.priceDetails[currentLang]}</>
                  ) : null}
                </Box>
              </>
            </Box>
          ) : null}
          {textButton && (
            <Button
              variant="brand"
              size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
              mt={[4, null, 8]}
            >
              {textButton}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const TranslatedText = ({
  value,
}: {
  value: KustomTranslatedString | undefined;
}) => {
  const { currentLang } = useContext(LanguageContext);
  return <>{value?.[currentLang || 'fr'] || value?.fr || ''}</>;
};

export default OffersAndNewsCard;
