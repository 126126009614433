import {
  KustomPage,
  KustomPageComponent,
  KustomTranslatedString,
} from '../types';
import { useCallback, useContext, useMemo } from 'react';

import PagesContext from '../contexts/pages';
import SettingsContext from '../contexts/settings';
import objectPath from 'object-path';
import { useKustomSelector } from '../admin/hooks/useKustomSelector';

export interface useKustomDataOptions<T, V> {
  // onChangeWrapper?: (
  //   defaultOnChange: (newValue: T) => void,
  //   currentValue: T | undefined,
  // ) => (newValue: any) => void;
  transformInput?: (newValue?: V, currentValue?: T) => T | undefined;
  transformOutput?: (value?: T) => V | undefined;
}

export interface useKustomDataReturn<V> {
  value: V | undefined;
  onChange?: (newValue?: V) => void;
}

const useKustomData = <T = KustomTranslatedString, V = T>(
  page: KustomPage,
  component: KustomPageComponent<any> | undefined | null,
  _dataPath: string | (() => string),
  options?: useKustomDataOptions<T, V>,
): useKustomDataReturn<V> => {
  const { user } = useKustomSelector((state) => state.app);

  const dataPath = useMemo(
    () => (typeof _dataPath === 'function' ? _dataPath() : _dataPath),
    [_dataPath],
  );

  const pagesCtx = useContext(PagesContext);

  const onChange = useCallback(
    (newValue?: T) => {
      const newComponent = JSON.parse(JSON.stringify(component));
      objectPath.set(newComponent, dataPath, newValue);

      pagesCtx.updatePageComponent(page, newComponent);
    },
    [component, dataPath],
  );

  const value = objectPath.get(component || {}, dataPath) as T | undefined;

  const data = useMemo(() => {
    return {
      value: options?.transformOutput ? options.transformOutput(value) : value,
      onChange: user
        ? options?.transformInput
          ? (v: V) => {
              onChange(options.transformInput!(v, value));
            }
          : onChange
        : undefined,
    };
  }, [component, onChange, dataPath, value, user]) as useKustomDataReturn<V>;

  if (!component) {
    return {
      value: undefined,
      onChange: () => {},
    };
  }

  return data;
};

export const useKustomTranslation = (
  key: string,
): useKustomDataReturn<KustomTranslatedString> => {
  const { user } = useKustomSelector((state) => state.app);
  const settingsCtx = useContext(SettingsContext);

  const onChange = useCallback(
    (newValue?: KustomTranslatedString) => {
      if (newValue) {
        settingsCtx.updateTranslation(key, newValue);
      }
    },
    [key],
  );

  return {
    value: settingsCtx.translations?.[key],
    onChange: user ? onChange : undefined,
  };
};

export default useKustomData;
