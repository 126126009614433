import {
  DetailedHTMLProps,
  VideoHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import { useInView } from 'react-intersection-observer';

type videoProps = DetailedHTMLProps<
  VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;

const useLazyAutoplay = (condition: boolean = true): videoProps => {
  const { ref, inView } = useInView({});

  const [autoPlay, setAutoPlay] = useState(false);

  useEffect(() => {
    if (inView) {
      setAutoPlay(true);
    }
  }, [inView]);

  return {
    ref,
    autoPlay: true,
    preload: autoPlay ? 'auto' : 'none',
    playsInline: true,
  };
};

export default useLazyAutoplay;
