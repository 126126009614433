import {
  AccordionComponentData,
  KustomPage,
  KustomPageComponentViewProps,
  MediaComponent,
  TextCarouselComponentData,
  TextImageComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import IgPosts, { IgPostsProps } from './components/IgPosts';
import TextMediasSection, {
  TextMediasSectionProps,
} from './components/TextMediasSection';

import AccordionComponent from './components/AccordionComponent';
import { Box } from '@chakra-ui/react';
import ContactFormSection from './components/contact/ContactFormSection';
import ContactMap from './components/contact/ContactMap';
import CustomSection from './components/CustomSection';
import DisableAutomaticComponentRendering from '@/lib/kustomcms-sdk/lib/components/DisableAutomaticComponentRendering';
import FAQ from './components/home/FAQ';
import FAQAccordionSection from './components/FAQ/FAQAccordionSection';
import FAQTitleSection from './components/FAQ/FAQTitleSection';
import GallerySection from './components/gallery/GallerySection';
import Hero from './components/home/Hero';
import ItemsGrid from './components/ItemsGrid';
import ItemsGridSection from './components/ItemsGridSection';
import PageHeader from './components/PageHeader';
import { Parallax } from 'react-scroll-parallax';
import ParallaxElement from './components/ParallaxElement';
import PressGrid from './components/press/PressGrid';
import RecruitmentCard from './components/recruitment/RecruitmentCard';
import RecruitmentsApplication from './components/recruitment/RecruitmentsApplication';
import RecruitmentsDescription from './components/recruitment/RecruitmentsDescription';
import RecruitmentsList from './components/recruitment/RecruitmentsList';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import Restaurants from './components/home/Restaurants';
import RoomContent from './components/rooms/RoomContent';
import RoomMedias from './components/rooms/RoomMedias';
import RoomsBreakfast from './components/rooms/RoomsBreakfast';
import RoomsDescription from './components/rooms/RoomsDescription';
import RoomsGrid from './components/rooms/RoomsGrid';
import RoomsGridTitle from './components/rooms/RoomsGridTitle';
import RoomsService from './components/rooms/RoomsService';
import Section1 from './components/home/Section1';
import Section2 from './components/home/Section2';
import Section3 from './components/home/Section3';
import Section5 from './components/home/Section5';
import SeminariesClientsSection from './components/events/SeminariesClientsSection';
import SeminariesQuotationSection from './components/events/SeminariesQuotationSection';
import SiteMapSection from './components/sitemap/SiteMapSection';

import Slideshow from './components/home/Slideshow';
import TableSection from './components/TableSection';
import TextMediaBackground from './components/TextMediaBackground';
import { registerKustomComponents } from '@/lib/kustomcms-sdk/lib/components/KustomComponent';
import theme from './theme';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import Text from './components/Text';
import RGPDForm from './components/contact/RGPDForm';
import OffersAndNewsPage from './components/offersAndNews/OffersAndNewsPage';
import { SvgShape } from './components/SvgShape';

// automatic generics
registerKustomComponents([
  {
    key: 'textImage',
    Component: PageHeader,
  },
  {
    key: 'textCarousel',
    Component: TextMediasSection,
  },
  {
    key: 'text',
    Component: Text,
  },
  {
    key: 'testimony',
    Component: IgPosts,
    extraProps: {
      lightWidgetId: 'd7e1a4e0bff15b1998497bdf9570bfcc',
    },
  },
]);

registerKustomComponents([
  // footer
  {
    id: '16904561362299605',
    Component: DisableAutomaticComponentRendering,
  },
  // menu
  { id: '1690313260958793', Component: DisableAutomaticComponentRendering },
  { id: '16903663926835705', Component: DisableAutomaticComponentRendering },
  { id: '16903703402474730', Component: DisableAutomaticComponentRendering },
  // 404
  { id: '16891913910836496', Component: DisableAutomaticComponentRendering },
  { id: '1689191712184569', Component: DisableAutomaticComponentRendering },
]);

// rooms
registerKustomComponents([
  {
    id: '16870199399413217',
    Component: PageHeader,
  },
  {
    id: '16870200843135204',
    Component: RoomsDescription,
  },
  {
    id: '17137923665017478',
    Component: DisableAutomaticComponentRendering,
  },
  {
    id: '16872728296671485',
    Component: RoomsService,
  },
  {
    id: '16899421193335740',
    Component: DisableAutomaticComponentRendering,
  },
  {
    id: '16871134819639319',
    Component: RoomsGridTitle,
  },
  {
    id: '1687025539835268',
    Component: RoomsGrid,
    extraProps: {
      grid: 1,
    },
  },
  {
    id: '168711348980887',
    Component: RoomsGridTitle,
  },
  {
    id: '16870255520869428',
    Component: RoomsGrid,
    extraProps: {
      grid: 2,
    },
  },
  {
    id: '16871049335625577',
    Component: RoomsBreakfast,
  },
]);

// room
registerKustomComponents(
  [
    {
      key: 'medias',
      Component: RoomMedias,
    },
    {
      key: 'roomSpecs',
      Component: RoomContent,
    },
    {
      key: 'text',
      Component: DisableAutomaticComponentRendering,
    },
    {
      key: 'roomBooking',
      Component: DisableAutomaticComponentRendering,
    },
  ],
  { model: 'room' },
);

// FAQ
registerKustomComponents(
  [
    {
      id: '16901322762879036',
      Component: PageHeader,
    },
    {
      key: 'text',
      Component: FAQTitleSection,
    },
    {
      key: 'accordion',
      Component: FAQAccordionSection,
    },
  ],
  { code: '1z3m2h' },
);

// plage
registerKustomComponents([
  {
    id: '16896019821945185',
    Component: PageHeader,
  },
  {
    id: '16896022399432600',
    Component: TextMediasSection,
    extraProps: {
      HeadExtraElement: () => (
        <Box
          position="absolute"
          top={'600'}
          right={'50px'}
          width={['140px', null, null, '350px']}
        >
          <Parallax speed={10}>
            <SvgShape file="illu-crabe" width="100%" />
          </Parallax>
        </Box>
      ),
    },
  },
  {
    id: '16896023897795257',
    Component: TableSection,
  },
  {
    id: '16896026041138183',
    Component: CustomSection,
    extraProps: {
      containerStyling: {
        pb: 20,
      },
    },
  },
]);

// events & seminaries
registerKustomComponents([
  {
    id: '16896173069271830',
    Component: PageHeader,
  },
  {
    id: '16896173945461468',
    Component: TextMediasSection,
    extraProps: {
      ParallaxExtraElement: ({ page }) => {
        return (
          <>
            <Box
              display={['block']}
              position="absolute"
              left={[10]}
              bottom={[36, null, null, 0]}
              width={[100, null, null, 200]}
            >
              <Parallax speed={-20}>
                <SvgShape file="illu-coquillage-4" width={'100%'} />
              </Parallax>
            </Box>
            <Box
              display={['block']}
              position="absolute"
              right={[-10, null, null, 60]}
              top={600}
              width={[150, null, null, 350]}
            >
              <Parallax speed={-20}>
                <SvgShape file="illu-mer-2" width={'100%'} />
              </Parallax>
            </Box>
          </>
        );
      },
    } as Partial<TextMediasSectionProps>,
  },
  {
    id: '16896180538487861',
    Component: TableSection,
    extraProps: {
      containerProps: {
        maxW: ['container.xl', null, null, null, null, '1780px'],
      },
    },
  },
  {
    id: '16896183156299544',
    Component: SeminariesClientsSection,
  },
  {
    id: '16896193348118131',
    Component: ItemsGridSection,
  },
  {
    id: '16896198799232552',
    Component: TextMediasSection,
  },
  {
    id: '16896201800217368',
    Component: SeminariesQuotationSection,
  },
]);

// contact
registerKustomComponents([
  {
    id: '16897792334268430',
    Component: PageHeader,
  },
  {
    id: '16897790288415528',
    Component: ContactMap,
  },
  {
    id: '16897877259346332',
    Component: TextMediasSection,
    extraProps: {
      CentralExtraElement: ({ page }) => {
        const cmp = usePageComponent<AccordionComponentData>(
          page,
          null,
          '16897862177952151',
        );
        return (
          cmp && <AccordionComponent page={page} component={cmp} index={0} />
        );
      },
    } as Partial<TextMediasSectionProps>,
  },
  {
    id: '16897862177952151',
    Component: DisableAutomaticComponentRendering,
  },
  {
    id: '16897885498478578',
    Component: ContactFormSection,
  },
]);

// recruitment
registerKustomComponents([
  {
    id: '16872620509172316',
    Component: PageHeader,
  },
  {
    id: '16872621101854938',
    Component: RecruitmentsDescription,
  },
  {
    id: '16872628014784424',
    Component: RecruitmentsList,
  },
  {
    id: '16872629498421645',
    Component: RecruitmentsApplication,
  },
]);

registerKustomComponents(
  [
    {
      key: 'jobOffer',
      Component: RecruitmentCard,
    },
  ],
  { model: 'joboffer' },
);

// spa
registerKustomComponents([
  {
    id: '1688728572994595',
    Component: PageHeader,
  },
  {
    id: '16887280364773045',
    Component: TextMediasSection,
  },
  {
    id: '16887284891502036',
    Component: TextMediasSection,
  },
  {
    id: '16887286259811288',
    Component: TextMediasSection,
  },
  {
    id: '16887287311877813',
    Component: TextMediasSection,
  },
  {
    id: '16887288700931745',
    Component: TextMediasSection,
  },
  {
    id: '16887290565805527',
    Component: TextMediaBackground,
  },
  {
    id: '16887291491413827',
    Component: TextMediasSection,
  },
  {
    id: '1688729333996603',
    Component: TextMediasSection,
    extraProps: {
      CentralExtraElement: ({ page }) => {
        const cmp = usePageComponent<TextCarouselComponentData>(
          page,
          null,
          '16887309289554663',
        );

        return cmp && <ItemsGrid component={cmp} page={page} index={0} />;
      },
    } as Partial<TextMediasSectionProps>,
  },
  {
    id: '16887309289554663',
    Component: DisableAutomaticComponentRendering,
  },
  {
    id: '16887297636845916',
    Component: TextMediasSection,
  },
  {
    id: '16887299810396704',
    Component: TextMediasSection,
  },
]);

// restaurants
registerKustomComponents([
  {
    id: '16886432379803955',
    Component: PageHeader,
  },
  {
    id: '16886433264522014',
    Component: TextMediasSection,
    extraProps: {
      ParallaxElement: () => <SvgShape file="illu-poisson-1" />,
      parallaxElementContainerStyle: {
        top: 600,
      },
    } as TextMediasSectionProps,
  },
  {
    id: '16886434120683704',
    Component: (props) => (
      <div id="la-littorine">
        <TextMediasSection {...props} />
      </div>
    ),
    extraProps: {
      ParallaxElement: () => <SvgShape file="illu-mer-1" />,
      parallaxElementContainerStyle: {
        bottom: [-600, null, -1000],
        zIndex: 2,
      },
      TopHeadExtraElement: ({ component }) => {
        const media = component?.data.atomicComponents.find(
          (c) => c.type === 'MEDIA',
        ) as MediaComponent;

        const currentDevice = useResponsiveMediasDevice();

        if (!media) {
          return null;
        }

        return (
          <Box mt={2} mb={4}>
            <ResponsiveMedias
              value={media.media}
              currentDevice={currentDevice}
              style={{
                background: 'transparent',
              }}
            />
          </Box>
        );
      },
    } as Partial<TextMediasSectionProps>,
  },
  {
    id: '16886476713498963',
    Component: TextMediasSection,
    extraProps: {
      ParallaxElement: () => <SvgShape file="illu-coquillage-1" />,
      parallaxElementContainerStyle: {
        zIndex: 2,
        bottom: [-600, null, -1000],
      },
    },
  },
  {
    id: '16886477769897940',
    Component: (props) => (
      <div id="le-casot">
        <TextMediasSection {...props} />
      </div>
    ),
    extraProps: {
      ParallaxElement: () => <SvgShape file="illu-homard" />,
      parallaxElementContainerStyle: {
        left: 0,
        bottom: [-450, null, -900],
        zIndex: 1,
      },
    },
  },
  {
    id: '16886491401484841',
    Component: IgPosts,
    extraProps: {
      lightWidgetId: '9c24a81645f65b2f9cfdcb162136a2d4',
      igAccountLink: 'https://www.instagram.com/lalittorine/',
      igUsername: 'La Littorine',
    } as IgPostsProps,
  },
]);

// gallery
registerKustomComponents(
  [
    {
      id: '16900512524711816',
      Component: PageHeader,
    },
    {
      // first component of page for anchor
      id: '16900330061271038',
      Component: GallerySection,
    },
    {
      key: 'text',
      Component: DisableAutomaticComponentRendering,
    },
    {
      key: 'medias',
      Component: DisableAutomaticComponentRendering,
    },
  ],
  { code: 'dw973c' },
);

// Offers and news
registerKustomComponents(
  [
    {
      id: '16900512524711816',
      Component: PageHeader,
    },
    {
      key: 'text',
      Component: DisableAutomaticComponentRendering,
    },
    {
      id: '17212978954231074',
      Component: OffersAndNewsPage,
    },
  ],
  { code: '51maa7l' },
);

// press
registerKustomComponents([
  {
    id: '16895308892515288',
    Component: (
      props: KustomPageComponentViewProps<TextImageComponentData>,
    ) => {
      const { component, page } = props;

      return (
        <>
          <PageHeader {...props} />
          <PressGrid />
        </>
      );
    },
  },
]);

// home
registerKustomComponents([
  {
    id: '16846967627952491',
    Component: Hero,
  },
  {
    id: '16881297908833395',
    Component: Section1,
  },
  {
    id: '16881297979184422',
    Component: DisableAutomaticComponentRendering,
  },
  {
    id: '16881300315284190',
    Component: DisableAutomaticComponentRendering,
  },
  {
    id: '16881300733583978',
    Component: DisableAutomaticComponentRendering,
  },
  {
    id: '16848708487829065',
    Component: Section2,
  },
  {
    id: '1684873683606493',
    Component: Section3,
  },
  {
    id: '16881304006695783',
    Component: DisableAutomaticComponentRendering,
  },
  {
    id: '16848773672091390',
    Component: Slideshow,
  },
  {
    id: '16849301404486423',
    Component: Section5,
  },
  {
    id: '16849333171951256',
    Component: FAQ,
  },
  {
    id: '16921823951555462',
    Component: DisableAutomaticComponentRendering,
  },
  {
    id: '16849321631032688',
    Component: IgPosts,
    extraProps: {
      lightWidgetId: 'd7e1a4e0bff15b1998497bdf9570bfcc',
    },
  },
  {
    id: '16849349373114904',
    Component: Restaurants,
  },
]);

// environment
registerKustomComponents([
  {
    id: '16908025849013094',
    Component: PageHeader,
  },
  {
    id: '16908026023311433',
    Component: TextMediasSection,
    extraProps: {
      containerProps: {
        pb: 0,
      },
    } as Partial<TextMediasSectionProps>,
  },
  {
    id: '16908026268938171',
    Component: TextMediasSection,
    extraProps: {
      containerProps: {
        py: 0,
      },
      ParallaxExtraElement: ({ page }) => (
        <ParallaxElement
          Element={() => <SvgShape file="illu-coquillage-3" />}
          width={['120px', null, null, '200px']}
          elementExtraProps={{
            fill: theme.colors.accent[500],
          }}
          right={[-10, null, null, 10]}
        />
      ),
    } as Partial<TextMediasSectionProps>,
  },
  {
    id: '16908026553707113',
    Component: TextMediasSection,
    extraProps: {
      containerProps: {
        py: 0,
      },
      ParallaxExtraElement: ({ page }) => (
        <ParallaxElement
          Element={() => <SvgShape file="illu-poisson-2" />}
          width={['180px', null, null, '300px']}
          elementExtraProps={{
            fill: theme.colors.accent[500],
          }}
          left={-20}
          bottom={50}
        />
      ),
    } as Partial<TextMediasSectionProps>,
  },
  {
    id: '16908027211018890',
    Component: TextMediasSection,
    extraProps: {
      containerProps: {
        py: 0,
      },
      ParallaxExtraElement: ({ page }) => (
        <ParallaxElement
          Element={() => <SvgShape file="illu-algue-2" />}
          width={['180px', null, null, '300px']}
          elementExtraProps={{
            fill: theme.colors.accent[500],
          }}
          right={[-20, null, null, 10]}
          bottom={50}
        />
      ),
    } as Partial<TextMediasSectionProps>,
  },
  {
    id: '16908033587581449',
    Component: TextMediasSection,
    extraProps: {
      containerProps: {
        py: 0,
      },
    } as Partial<TextMediasSectionProps>,
  },
  {
    id: '16908033949788694',
    Component: TextMediasSection,
    extraProps: {
      containerProps: {
        py: 0,
      },
    } as Partial<TextMediasSectionProps>,
  },
]);

// sitemap
registerKustomComponents([
  {
    id: '16907964855005439',
    Component: PageHeader,
  },
  {
    id: '16907966803222191',
    Component: SiteMapSection,
    extraProps: {
      filterPages: (page: KustomPage) =>
        page.model !== 'room' && page.category !== 'seo',
    },
  },
  {
    id: '16907966869093663',
    Component: SiteMapSection,
    extraProps: {
      filterPages: (page: KustomPage) => page.model === 'room',
    },
  },
  {
    id: '17116367771367680',
    Component: SiteMapSection,
    extraProps: {
      filterPages: (page: KustomPage) =>
        page.model !== 'room' && page.category === 'seo',
    },
  },
]);

// Formulaire RGPD
registerKustomComponents(
  [
    {
      id: '17120519817431767',
      Component: PageHeader,
    },
    {
      id: '17120521740985827',
      Component: Text,
    },
    {
      id: '17120532312275327',
      Component: RGPDForm,
    },
  ],
  { code: 'cvz7b' },
);
