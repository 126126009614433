import {
  Box,
  Button,
  Container,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  CarouselComponentData,
  KustomTranslatedString,
} from '@/lib/kustomcms-sdk/lib/types';
import LanguageContext, {
  LanguageContextKey,
} from '@/lib/kustomcms-sdk/lib/contexts/language';
import React, { useContext } from 'react';

import IconDiadao from '@/../public/icons/diadao.svg';
import IconLang from '@/../public/assets/icons/translate.svg';
import Link from 'next/link';
import LogoElmesColor from '@/../public/assets/img/logo-elmes-original-complet.svg';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useNavigationKustomHref from '@/lib/kustomcms-sdk/lib/hooks/useNavigationKustomHref';
import usePage from '@/lib/kustomcms-sdk/lib/hooks/usePage';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import { useRouter } from 'next/router';

interface FooterProps {}

// const langs = [
//   { key: 'fr', label: 'Français' },
//   { key: 'en', label: 'English' },
//   { key: 'es', label: 'Spanish' },
//   { key: 'de', label: 'Deutsch' },
// ];

const Footer: React.FC<FooterProps> = (props) => {
  const {} = props;

  const router = useRouter();

  const language = useContext(LanguageContext);

  const logoSize = useBreakpointValue({ base: 200, md: 290 });

  const menuPage = usePage('hds5');

  const menu1Cmp = usePageComponent<CarouselComponentData>(
    menuPage,
    null,
    '16904561362299605',
  );

  const menu = menu1Cmp?.data?.slides?.map((slide) => ({
    label: slide.title,
    href: slide.subtitle,
  }));

  return (
    <Box
      className="footer"
      bgColor="accent.100"
      display="flex"
      flexDir="column"
      alignItems="center"
      fontFamily="jhaSemilight"
      color="gray.800"
      pt={20}
    >
      <Container
        maxW="1780px"
        display="flex"
        alignItems="center"
        flexDir="column"
        px={[6, null, 20]}
      >
        <Box>
          <LogoElmesColor width={logoSize} />
        </Box>
        <Box
          mt={10}
          display="flex"
          w="100%"
          justifyContent="space-between"
          borderTop="1px solid"
          borderColor="gray.200"
          pt={6}
          pb={[16, null, null, null, 12]}
          alignItems="center"
          flexDir={['column', null, null, null, 'row']}
        >
          <Box
            fontSize="18px"
            w={['100%', null, null, null, 'unset']}
            display="flex"
            flexDir={['column-reverse', null, 'row']}
            alignItems="center"
            justifyContent="space-between"
          >
            {/* <Menu>
              <MenuButton
                fontSize="14px"
                px={5}
                as={Button}
                leftIcon={<IconLang />}
              >
                {langs.find((lang) => lang.key === language.currentLang)?.label}
              </MenuButton>
              <MenuList fontSize="14px" bgColor="gray.100">
                {langs.map((lang) => (
                  <MenuItem
                    bgColor="gray.100"
                    key={lang.key}
                    onClick={() => {
                      // language.setCurrentLang(lang.key as LanguageContextKey);
                      router.push('/', '/', { locale: lang.key });
                    }}
                  >
                    {lang.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu> */}
            <Text
              // ml={4}
              mb={[8, null, 0]}
              as="div"
            >
              © 2023 Les Elmes Hotel & Spa
            </Text>
          </Box>
          <Box color="brand.500" fontSize="16px" mt={[10, null, null, null, 0]}>
            <Stack
              direction={['column', null, null, null, 'row']}
              spacing={4}
              justify="flex-end"
              textAlign={['center', null, null, null, 'left']}
            >
              {menu?.map((item, index) => (
                <FooterNavItem key={index} {...item} />
              ))}
              <Link
                target="_blank"
                href="https://diadao.fr"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <chakra.span mr={2}>Website by Diadao</chakra.span>{' '}
                <IconDiadao pl={5} />
              </Link>
            </Stack>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

interface FooterNavItemProps {
  label: KustomTranslatedString;
  href: KustomTranslatedString;
}

const FooterNavItem: React.FC<FooterNavItemProps> = (props) => {
  const { label, href } = props;

  const linkProps = useNavigationKustomHref(href);

  return (
    <Link {...linkProps}>
      <TranslatedString as="div">{label}</TranslatedString>
    </Link>
  );
};

export default Footer;
