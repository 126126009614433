import { Box, BoxProps } from '@chakra-ui/react';

import { Parallax } from 'react-scroll-parallax';
import React from 'react';

interface ParallaxElementProps extends BoxProps {
  Element: React.ComponentType<any>;
  elementExtraProps?: any;
  speed?: number;
}

const ParallaxElement: React.FC<ParallaxElementProps> = (props) => {
  const { Element, elementExtraProps, speed = -20, ...boxProps } = props;
  return (
    <Box
      display={['block']}
      position="absolute"
      width={[100, null, null, 200]}
      {...boxProps}
    >
      <Parallax speed={speed}>
        <Element width={'100%'} {...elementExtraProps} />
      </Parallax>
    </Box>
  );
};

export default ParallaxElement;
