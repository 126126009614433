import { KustomPage, KustomPageComponent } from '../types';
import React, { Component } from 'react';

interface KustomComponentProps {
  component: KustomPageComponent<any>;
  page: KustomPage;
  index?: number;
}

interface KustomComponents {
  ids: Map<string, ComponentArgs>;
  keys: Map<string, ComponentArgs>;
}

interface ComponentArgs {
  id?: string;
  key?: string;
  Component: React.ComponentType<any>;
  extraProps?: any;
}

const kustomComponents = {
  default: {
    ids: new Map(),
    keys: new Map(),
  },
  keys: {},
  ids: {},
} as {
  default: KustomComponents;
  keys: { [key: string]: KustomComponents };
  ids: { [key: string]: KustomComponents };
};

interface Options {
  model?: string;
  code?: string;
}

export const registerKustomComponent = (
  component: ComponentArgs,
  options?: Options,
) => {
  if (options?.code) {
    if (!kustomComponents.ids[options.code]) {
      kustomComponents.ids[options.code] = {
        ids: new Map(),
        keys: new Map(),
      };
    }

    if (component.id) {
      kustomComponents.ids[options.code]?.ids.set(component.id, component);
    } else if (component.key) {
      kustomComponents.ids[options.code]?.keys.set(component.key, component);
    }

    return;
  }

  if (options?.model) {
    if (!kustomComponents.keys[options.model]) {
      kustomComponents.keys[options.model] = {
        ids: new Map(),
        keys: new Map(),
      };
    }

    if (component.id) {
      kustomComponents.keys[options.model]?.ids.set(component.id, component);
    } else if (component.key) {
      kustomComponents.keys[options.model]?.keys.set(component.key, component);
    }

    return;
  }

  if (component.id) {
    kustomComponents.default.ids.set(component.id, component);
  } else if (component.key) {
    kustomComponents.default.keys.set(component.key, component);
  }
};

export const registerKustomComponents = (
  components: ComponentArgs[],
  options?: Options,
) => {
  components.forEach((cmp) => registerKustomComponent(cmp, options));
};

const KustomComponent = (props: KustomComponentProps) => {
  const { component, page, index } = props;

  const keyRepo = kustomComponents.keys[page.model || ''];
  const idRepo = kustomComponents.ids[page.pageCode || page.objectCode || ''];

  const componentArgs =
    idRepo?.ids.get(component.id) ||
    idRepo?.keys.get(component.type) ||
    keyRepo?.ids.get(component.id) ||
    keyRepo?.keys.get(component.type) ||
    kustomComponents.default.ids.get(component.id) ||
    kustomComponents.default.keys.get(component.type);

  if (!componentArgs) {
    console.warn(
      `KustomComponent ${component.type}#${component.id} (page ${page.title.fr}) not supported`,
    );
    return null;
  }

  return (
    <componentArgs.Component
      component={component}
      page={page}
      index={index}
      {...componentArgs.extraProps}
    />
  );
};

export default KustomComponent;
