import {
  Box,
  BoxProps,
  Button,
  ButtonGroup,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import AdminThemeProvider from './AdminThemeProvider';
import EditIcon from '@/lib/kustomcms-sdk/lib/icons/Edit.svg';
import { KustomResponsiveMedia } from '../../types';
import MediaEditComponent from './medias/MediaEditComponent';
import kustomAdminTheme from '../theme';

interface EditMediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  value?: KustomResponsiveMedia;
  onChange: (value?: KustomResponsiveMedia) => void;
  triggerProps?: BoxProps;
}

const EditMediaModal = (props: EditMediaModalProps) => {
  const { value, isOpen, onChange, onClose, triggerProps } = props;

  const [editedMedia, setEditedMedia] = useState<
    KustomResponsiveMedia | undefined
  >(value);

  const [hasModalOpen, setHasModalOpen] = useState(false);

  const validate = () => {
    onChange(editedMedia);
    onClose();
  };

  const cancel = () => {
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      setEditedMedia(value);
    }
  }, [isOpen]);

  return (
    <AdminThemeProvider>
      <Popover isOpen={isOpen} onClose={onClose} closeOnBlur={!hasModalOpen}>
        <PopoverTrigger>
          <Box
            display={isOpen ? 'block' : 'none'}
            _groupHover={{
              display: 'block',
            }}
            bgColor={kustomAdminTheme.colors.brand['500']}
            p={4}
            borderRadius="6px"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            m={2}
            boxShadow={'0 0 10px 0 rgba(0, 0, 0, 0.05)'}
            {...triggerProps}
          >
            <EditIcon fill="white" width="15px" />
          </Box>
        </PopoverTrigger>
        <PopoverContent
          onClick={(e) => e.stopPropagation()}
          w={'unset'}
          border="none"
          borderRadius="md"
          overflow="hidden"
          pt={5}
        >
          <PopoverCloseButton onClick={onClose} />
          <PopoverBody>
            <MediaEditComponent
              media={editedMedia}
              onChange={setEditedMedia}
              setIsModalOpen={setHasModalOpen}
            />
          </PopoverBody>
          <PopoverFooter p={0}>
            <ButtonGroup isAttached w="100%" variant="solid" size="md">
              <Button
                w="100%"
                onClick={cancel}
                colorScheme="brand"
                borderRadius="none"
                borderRight="1px solid"
                borderColor="white"
                bgColor="#9E9E9E"
                _hover={{
                  bgColor: '#8A8A8A',
                }}
                py={7}
              >
                ANNULER
              </Button>
              <Button
                w="100%"
                colorScheme="brand"
                onClick={validate}
                borderRadius="none"
                py={7}
              >
                APPLIQUER
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </AdminThemeProvider>
  );
};

export default React.memo(EditMediaModal);
