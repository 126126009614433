import { Box, Container } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

const RoomsGridTitle: React.FC<
  KustomPageComponentViewProps<TextComponentData>
> = (props) => {
  const { component } = props;

  const textBind = useKustomData<KustomTranslatedString>(
    props.page,
    component,
    `data.text`,
  );

  return (
    <Container
      maxW={['container.lg', null, null, null, null, '1362px']}
      px={[6, null, 20]}
      pt={[24, null, 24]}
      pb={[6, null, 24]}
      position="relative"
      zIndex={1}
    >
      <RichText
        as="h2"
        fontFamily="heading"
        fontSize={['38px', null, '76px', null, null, '96px']}
        lineHeight={1}
        color="brand.500"
        {...textBind}
      />
    </Container>
  );
};

export default RoomsGridTitle;
