import {
  Box,
  BoxProps,
  Center,
  IconButton,
  Text,
  useInterval,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';

import { CloseIcon } from '@chakra-ui/icons';

export interface ToastProps {
  title: string;
  content?: string;
  error?: boolean;
  warning?: boolean;
  onClose: () => void;
  duration?: number | null;
  containerProps?: BoxProps;
}

const Toast = (props: ToastProps) => {
  const { title, content, error, warning, onClose, duration, containerProps } =
    props;
  const ref = useRef<HTMLDivElement>(null);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        if (ref.current) ref.current.style.height = '100%';
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (duration) {
      setTimeout(() => {
        handleClose();
      }, duration);
    }
  }, [duration, handleClose]);

  return (
    <Box
      backgroundColor="#081B34"
      display="flex"
      p="1.5"
      borderRadius="base"
      {...containerProps}
    >
      <Box
        width="6px"
        borderRadius="base"
        overflow="hidden"
        alignSelf="stretch"
        position="relative"
        bgColor="#223249"
      >
        <Box
          position="absolute"
          bottom={0}
          ref={ref}
          h={duration ? '10%' : '100%'}
          w={'100%'}
          backgroundColor={
            error ? 'red.500' : warning ? 'orange.500' : 'green.500'
          }
          transition={`height ${((duration || 1000) - 100) / 1000}s linear`}
        />
      </Box>
      <Box py={4} px={7} pr={3}>
        <Text color="white" fontWeight="bold" fontSize="15px" mb={2}>
          {title}
        </Text>
        {content && (
          <Text color="white" fontSize="14px">
            {content}
          </Text>
        )}
      </Box>
      <Box display="flex" alignItems="center" pr={5} ml="auto">
        <IconButton
          onClick={handleClose}
          size="sm"
          aria-label="Close toast"
          isRound
          backgroundColor="#213249"
          colorScheme="white"
          fontSize="10px"
          icon={<CloseIcon />}
        />
      </Box>
    </Box>
  );
};

export default Toast;
