import * as Yup from 'yup';

import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Textarea,
  chakra,
  useToast,
} from '@chakra-ui/react';
import { Field, FieldProps, Form, Formik } from 'formik';
import React, { useContext, useRef, useState } from 'react';

import FileInput from '../atomics/FileInput';
import Link from 'next/link';
import Toast from '../atomics/Toast';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

interface RecruitmentFormProps extends BoxProps {}

const RecruitmentForm: React.FC<RecruitmentFormProps> = (props) => {
  const { ...boxProps } = props;
  const [successSent, setSuccessSent] = useState(false);
  const [errorSent, setErrorSent] = useState('');
  const toasting = useToast();

  const resetFormSuccessState = () => {
    setSuccessSent(false);
    setErrorSent('');
  };

  const { currentLang } = useContext(LanguageContext);

  const name = useKustomTranslation('form_name')?.value?.[currentLang];
  const firstname =
    useKustomTranslation('form_firstname')?.value?.[currentLang];
  const email = useKustomTranslation('form_email')?.value?.[currentLang];
  const phone = useKustomTranslation('form_phone')?.value?.[currentLang];
  const message = useKustomTranslation('form_message')?.value?.[currentLang];
  const accept = useKustomTranslation('form_accept')?.value?.[currentLang];
  const cgu = useKustomTranslation('form_cgu')?.value?.[currentLang];
  const and = useKustomTranslation('form_and')?.value?.[currentLang];
  const policy = useKustomTranslation('form_policy')?.value?.[currentLang];
  const button = useKustomTranslation('form_button')?.value?.[currentLang];
  const coverletter =
    useKustomTranslation('form_coverletter')?.value?.[currentLang];
  const cv = useKustomTranslation('form_cv')?.value?.[currentLang];
  const errorGen = useKustomTranslation('form_error')?.value?.[currentLang];
  const toastA = useKustomTranslation('form_toastA')?.value?.[currentLang];
  const toastB = useKustomTranslation('form_toastB')?.value?.[currentLang];
  const requiredFile =
    useKustomTranslation('form_required_file')?.value?.[currentLang];
  const fileSuppr10 =
    useKustomTranslation('form_file_suppr_10')?.value?.[currentLang];
  const fileTypeNotAllow =
    useKustomTranslation('form_not_allow')?.value?.[currentLang];
  const errorEmail =
    useKustomTranslation('form_errorEmail')?.value?.[currentLang];
  const errorCgu = useKustomTranslation('form_errorCgu')?.value?.[currentLang];

  const toast = (title: string, content: string, isError?: boolean) => {
    toasting({
      position: 'bottom-right',
      duration: 3000,
      render: (props) => (
        <Toast
          {...props}
          error={isError}
          duration={3000}
          title={title}
          content={content}
        />
      ),
    });
  };

  const sendEmail = (values: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await fetch('/api/send-recruitment-email', {
          method: 'POST',
          body: values,
        });
        const json = await res.json();
        if (json.error) {
          toast(errorGen || 'error', json.error, true);
          reject();
          return;
        }
        toast(toastA || 'error', toastB || '');
        resolve(json);
      } catch (error: any) {
        toast(errorGen || 'error', error.message, true);
        reject();
      }
    });
  };

  const resumeFileRef = useRef<HTMLInputElement>(null);
  const motivationFileRef = useRef<HTMLInputElement>(null);

  const testFile = (fileRef: React.RefObject<HTMLInputElement>) =>
    Yup.mixed()
      .test('present', requiredFile || 'Fichier requis', () => {
        let valid = true;
        const files = fileRef?.current?.files;
        if (files) {
          const fileArr = Array.from(files);
          if (fileArr.length === 0) {
            valid = false;
          }
        }
        return valid;
      })
      .test(
        'is-file-too-big',
        fileSuppr10 || 'Fichier supérieur à 10Mo',
        () => {
          let valid = true;
          const files = fileRef?.current?.files;
          if (files) {
            const fileArr = Array.from(files);
            fileArr.forEach((file) => {
              const size = file.size / 1024 / 1024;
              if (size > 10) {
                valid = false;
              }
            });
          }
          return valid;
        },
      )
      .test(
        'is-file-of-correct-type',
        fileTypeNotAllow || 'Type de fichier non autorisé',
        () => {
          let valid = true;
          const files = fileRef?.current?.files;
          if (files) {
            const fileArr = Array.from(files);
            fileArr.forEach((file) => {
              const type = file.type.split('/')[1];
              const validTypes = ['pdf', 'jpg', 'jpeg', 'png'];
              if (type && !validTypes.includes(type)) {
                valid = false;
              }
            });
          }
          return valid;
        },
      );

  const validationSchema = Yup.object({
    lastname: Yup.string().required(),
    firstname: Yup.string().required(),
    email: Yup.string().email(errorEmail).required(),
    phone: Yup.string().required(),
    resumeFile: testFile(resumeFileRef),
    motivationFile: testFile(motivationFileRef),
    cgu: Yup.boolean().oneOf([true], errorCgu).required(),
  });

  return (
    <Box mt={14} {...boxProps}>
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          resumeFile: {},
          motivationFile: {},
          message: '',
          cgu: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          const data = new FormData();
          data.append('lastname', values.lastname);
          data.append('firstname', values.firstname);
          data.append('email', values.email);
          data.append('phone', values.phone);
          data.append('message', values.message);
          data.append('resumeFile', resumeFileRef.current?.files?.[0] as Blob);
          data.append(
            'motivationFile',
            motivationFileRef.current?.files?.[0] as Blob,
          );

          sendEmail(data).finally(() => {
            if (resumeFileRef.current) resumeFileRef.current.value = '';
            if (motivationFileRef.current) motivationFileRef.current.value = '';
            actions.setSubmitting(false);
            actions.resetForm();
          });
        }}
      >
        {(props) => (
          <Form>
            <Box display="flex" mb={4} flexDir={['column', null, 'row']}>
              <Field name="lastname">
                {({ field, form }: FieldProps) => (
                  <FormControl
                    flex={1}
                    variant="floating"
                    mr={[0, null, 6]}
                    mb={[4, null, 0]}
                    isInvalid={
                      !!(form.errors.lastname && form.touched.lastname)
                    }
                  >
                    <Input w="100%" {...field} size="lg" placeholder=" " />
                    <FormLabel>{name}</FormLabel>
                  </FormControl>
                )}
              </Field>
              <Field name="firstname">
                {({ field, form }: FieldProps) => (
                  <FormControl
                    flex={1}
                    variant="floating"
                    isInvalid={
                      !!(form.errors.firstname && form.touched.firstname)
                    }
                  >
                    <Input w="100%" {...field} size="lg" placeholder=" " />
                    <FormLabel>{firstname}</FormLabel>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box display="flex" mb={4} flexDir={['column', null, 'row']}>
              <Field name="email">
                {({ field, form }: FieldProps) => (
                  <FormControl
                    variant="floating"
                    flex={1}
                    mr={[0, null, 6]}
                    mb={[4, null, 0]}
                    isInvalid={!!(form.errors.email && form.touched.email)}
                  >
                    <Input w="100%" {...field} size="lg" placeholder=" " />
                    <FormLabel>{email}</FormLabel>
                  </FormControl>
                )}
              </Field>
              <Field name="phone">
                {({ field, form }: FieldProps) => (
                  <FormControl
                    variant="floating"
                    flex={1}
                    isInvalid={!!(form.errors.phone && form.touched.phone)}
                  >
                    <Input w="100%" {...field} size="lg" placeholder=" " />
                    <FormLabel>{phone}</FormLabel>
                  </FormControl>
                )}
              </Field>
            </Box>
            <Box display="flex" mb={4} flexDir={['column', null, 'row']}>
              <Field name="resumeFile">
                {({ field, form }: FieldProps) => (
                  <FormControl flex={1} mr={[0, null, 6]}>
                    <FileInput
                      w="100%"
                      label={cv}
                      mb={[4, null, 0]}
                      ref={resumeFileRef}
                      onFileChange={(file: File) => {
                        props.setFieldValue('resumeFile', file);
                      }}
                      isInvalid={
                        !!(form.errors.resumeFile && form.touched.resumeFile)
                      }
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="motivationFile">
                {({ field, form }: FieldProps) => (
                  <FormControl flex={1}>
                    <FileInput
                      w="100%"
                      label={coverletter}
                      ref={motivationFileRef}
                      onFileChange={(file: File) => {
                        props.setFieldValue('motivationFile', file);
                      }}
                      isInvalid={
                        !!(
                          form.errors.motivationFile &&
                          form.touched.motivationFile
                        )
                      }
                    />
                  </FormControl>
                )}
              </Field>
            </Box>
            <Field name="message">
              {({ field, form }: FieldProps) => (
                <FormControl
                  display={['none', null, 'block']}
                  variant="floating"
                  isInvalid={!!(form.errors.message && form.touched.message)}
                >
                  <Textarea
                    className="ignoreSmoothScroll"
                    height="100px"
                    borderWidth="2px !important"
                    w="100%"
                    bgColor="white"
                    fontFamily="jhaSemilight"
                    border="none"
                    borderRadius="2px"
                    color="brand.500"
                    pt={7}
                    px={6}
                    {...field}
                    size="lg"
                    placeholder=" "
                    rows={5}
                    overflow="auto"
                  />
                  <FormLabel>{message}</FormLabel>
                </FormControl>
              )}
            </Field>
            <Box
              display="flex"
              mt={5}
              justifyContent="space-between"
              flexDir={['column', null, 'row']}
              alignItems={['left', null, 'center']}
            >
              <Field name="cgu">
                {({ field, form }: FieldProps) => (
                  <Checkbox
                    alignItems="top"
                    colorScheme="brand"
                    fontFamily="jhaSemilight"
                    color="brand.500"
                    {...field}
                    isChecked={form.values.cgu}
                    isInvalid={!!(form.errors.cgu && form.touched.cgu)}
                  >
                    <chakra.span fontSize={'18px'}>
                      {accept}{' '}
                      <Link style={{ textDecoration: 'underline' }} href="#">
                        {cgu}
                      </Link>{' '}
                      {and}{' '}
                      <Link style={{ textDecoration: 'underline' }} href="#">
                        {policy}
                      </Link>
                      .
                    </chakra.span>
                  </Checkbox>
                )}
              </Field>
              <Button
                mr={['auto', null, 0]}
                mt={[4, null, 0]}
                isLoading={props.isSubmitting}
                onClick={resetFormSuccessState}
                rightIcon={
                  <chakra.span
                    fontSize={['42px', null, '56px']}
                    position="relative"
                    left="-1"
                    className="icon-elmes-arrow1-white"
                  />
                }
                type="submit"
                variant="brand"
                size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
              >
                {button}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default RecruitmentForm;
