import { ActionButtonComponent, KustomTranslatedString } from '../types';
import { Box, Button, ButtonProps, chakra } from '@chakra-ui/react';
import { useContext, useState } from 'react';

import EditActionButtonModal from '../admin/components/EditActionButtonModal';
import Link from 'next/link';
import PagesContext from '../contexts/pages';
import TranslatedString from './TranslatedString';
import WrapIf from './atomics/WrapIf';
import getCSSAdminWysiwygItem from '../admin/helpers/getCSSAdminWysiwygItem';
import { useKustomSelector } from '../admin/hooks/useKustomSelector';
import useNavigationKustomHref from '../hooks/useNavigationKustomHref';

interface ActionButtonProps extends Omit<ButtonProps, 'onChange' | 'value'> {
  value?: ActionButtonComponent;
  disableLink?: boolean;
  onChange?: (value: ActionButtonComponent) => void;
}

const ActionButton = (props: ActionButtonProps) => {
  const { value: button, disableLink, onChange, ...buttonProps } = props;

  const { actionButtonPatterns } = useContext(PagesContext);

  const { user } = useKustomSelector((state) => state.app);

  const isCustomAction = button?.actionString?.[0] === '@';
  const [actionPattern = '', actionHref] = button?.actionString?.split('=') || [
    '',
    '',
  ];
  const hasWysiwygEdit = !!(user && onChange);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const customAction = isCustomAction
    ? actionButtonPatterns?.[actionPattern]
    : undefined;

  const isEmail = button?.action === 'EMAIL';

  const isLinkDisabled = disableLink || isCustomAction;

  const LinkComponent =
    isEmail ||
    button?.action === 'LINK_EXTERNAL' ||
    button?.action === 'LINK_TO_BE' ||
    button?.target
      ? chakra.a
      : Link;

  const linkProps = useNavigationKustomHref(button?.actionString);

  if (!button) return null;

  return (
    <>
      <WrapIf
        condition={!isLinkDisabled && !hasWysiwygEdit}
        wrapper={(children) => (
          <LinkComponent
            href={(isEmail ? 'mailto:' : '') + linkProps.href || ''}
            target={button.target}
          >
            {children}
          </LinkComponent>
        )}
      >
        <Box
          display="inline-block"
          {...getCSSAdminWysiwygItem({
            hasWysiwygEdit,
            isWysiwygEditOpen: isModalOpen,
            setIsOpen: setIsModalOpen,
          })}
        >
          <Button
            // @ts-ignore
            onClick={() => customAction?.(actionHref)}
            {...buttonProps}
            {...(hasWysiwygEdit && {
              onClick: undefined,
            })}
          >
            <TranslatedString as="span" color="inherit">
              {button.label}
            </TranslatedString>
          </Button>
        </Box>
      </WrapIf>
      {hasWysiwygEdit && (
        <EditActionButtonModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          button={button}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default ActionButton;
