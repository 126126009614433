import Toast, { ToastProps } from '../components/atomics/Toast';
import { ToastId, UseToastOptions } from '@chakra-ui/react';

interface Toast {
  (options?: UseToastOptions | undefined): ToastId | undefined;
  close: (id: ToastId) => void;
  closeAll: (options?: any | undefined) => void;
  isActive: (id: ToastId) => boolean | undefined;
}

const runToast = (options: {
  toast: Toast;
  title: string;
  content?: string;
  type?: 'success' | 'error' | 'warning';
  toastProps?: Partial<ToastProps>;
}) => {
  const { toast, title, content, type = 'success', toastProps } = options;

  const isSuccess = type === 'success';
  const isError = type === 'error';
  const isWarning = type === 'warning';
  const duration = isSuccess ? 3000 : null;

  return toast({
    position: 'bottom-right',
    duration,
    render: (props) => (
      <Toast
        {...props}
        {...toastProps}
        error={isError}
        duration={duration}
        title={title}
        content={content}
        warning={isWarning}
      />
    ),
  });
};

export default runToast;
