import { MediaType, getMediaType } from '../../helpers/media';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';

import { KustomMedia } from '../../../types';
import ReactPlayer from 'react-player';

interface MediaViewerProps {
  media?: KustomMedia;
  onClose: () => void;
}

const MediaViewer = (props: MediaViewerProps) => {
  const { media, onClose } = props;

  const mediaType = getMediaType(media?.url || '');

  if (mediaType !== MediaType.IMAGE && mediaType !== MediaType.VIDEO) {
    return null;
  }

  return (
    <Modal size="6xl" isOpen={!!media} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="90%" height="90%" my="auto" position="relative">
        <ModalCloseButton size="lg" />
        {
          {
            [MediaType.IMAGE]: (
              <ModalBody
                w={'100%'}
                h={'100%'}
                backgroundImage={'url("' + media?.url + '")'}
                backgroundSize={'contain'}
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                mx={7}
                mr={14}
                my={4}
              ></ModalBody>
            ),
            [MediaType.VIDEO]: (
              <ModalBody mr={7} my={4} width="100%" h="100%">
                {media && (
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    controls
                    style={{
                      objectFit: 'contain',
                      padding: 10,
                      paddingBottom: 30,
                    }}
                    url={media.url}
                  />
                )}
              </ModalBody>
            ),
          }[mediaType]
        }
      </ModalContent>
    </Modal>
  );
};

export default MediaViewer;
