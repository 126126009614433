import {
  Box,
  Button,
  Text,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react';
import {
  KustomPage,
  KustomPageComponent,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediasComponentData,
  RoomBookingComponentData,
  RoomSpecsComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { MouseEventHandler, useState } from 'react';
import useKustomData, {
  useKustomDataReturn,
  useKustomTranslation,
} from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

import KustomString from '@/lib/kustomcms-sdk/lib/components/KustomString';
import Slideshow from '../atomics/Slideshow';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import RoomCardBooking from './RoomCardBooking';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';

interface RoomCardProps {
  room: KustomPage;
  index: number;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const RoomCard: React.FC<RoomCardProps> = (props) => {
  const { room, index, onClick } = props;
  const [isHovered, setIsHovered] = useState(false);

  const roomBooking = usePageComponent<RoomBookingComponentData>(
    room,
    'roomBooking',
  );
  // const bookingId = roomBooking?.data.systemData?.roomCode;
  // TEST MEWS PROV :
  const bookingId = roomBooking?.data.systemData?.tcode;

  const mediaCmp = room
    .components[3] as KustomPageComponent<MediasComponentData>;

  const mediasBind = useKustomData<KustomResponsiveMedia[]>(
    room,
    mediaCmp,
    `data.medias`,
  );

  const specsCmp = room
    .components[0] as KustomPageComponent<RoomSpecsComponentData>;
  // const specs = specsCmp.data;
  const titleBind = useKustomData<KustomTranslatedString>(
    room,
    specsCmp,
    `data.title`,
  );

  const peoplesNumberBind = useKustomData<string>(
    room,
    specsCmp,
    `data.peoplesNumber`,
  );

  const areaBind = useKustomData<string>(room, specsCmp, `data.area`);

  const bedBind = useKustomData<string | KustomTranslatedString>(
    room,
    specsCmp,
    `data.bed`,
  );
  const bedBindString = (
    typeof bedBind.value === 'string' ? bedBind : null
  ) as useKustomDataReturn<string> | null;
  const bedBindObject = (
    typeof bedBind.value === 'object' ? bedBind : null
  ) as useKustomDataReturn<KustomTranslatedString> | null;

  const peoplesLabelBind = useKustomTranslation('room_label_peoples');
  const toBookLabelBind = useKustomTranslation('to_book');

  const mediasHeight = useBreakpointValue({
    base: 260,
    lg: 360,
    '2xl': 450,
  });

  return (
    <Box
      onClick={onClick}
      bgColor="white"
      // zIndex={1}
      cursor="pointer"
      h="100%"
      borderRadius="base"
      // overflow="clip"
      position="relative"
      boxShadow="-8px 8px 8px #00000014"
      display="flex"
      flexDir="column"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Slideshow
        hideArrows
        hideIndicators
        height={mediasHeight}
        delay={index * 700}
        useOverlay
        sizes="50vw"
        isHovered={isHovered}
        onClickItem={() => {}}
        style={{
          transition: 'transform 0.3s ease',
          transform: isHovered ? 'scale(1.2)' : 'scale(1)',
          cursor: 'pointer',
        }}
        {...mediasBind}
      />
      <Box
        px={[8, null, 14]}
        py={[7, null, 10]}
        display="flex"
        flexDir="column"
        flexGrow={1}
      >
        <TranslatedString
          as="h3"
          lineHeight={1}
          fontFamily="jha"
          fontSize={['24px', null, '40px', null, null, '50px']}
          color="brand.500"
          {...titleBind}
        />
        <Box mt="auto">
          <Text
            fontFamily="jhaSemiboldItalic"
            fontSize={['14px', null, '20px']}
            mt={[4, null, 8]}
            mb={[3, null, 8]}
            display="flex"
            flexDir="row"
            flexWrap="wrap"
            // whiteSpace="wrap"
          >
            <KustomString as="span" {...peoplesNumberBind} />
            &nbsp;
            <TranslatedString as="span" {...peoplesLabelBind} />
            &nbsp;/&nbsp;
            <KustomString as="span" {...areaBind} />
            &nbsp;/&nbsp;
            {bedBindObject && <TranslatedString as="span" {...bedBindObject} />}
            {bedBindString && <KustomString as="span" {...bedBindString} />}
          </Text>
          <Box display="flex" gap={'2rem'} alignItems={'center'}>
            <Button
              variant="brand"
              size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
            >
              <TranslatedString
                color="inherit"
                {...toBookLabelBind}
                as="span"
              />
            </Button>
            {bookingId && <RoomCardBooking id={bookingId} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RoomCard;
