import {
  ActionButtonComponent,
  CodeComponent,
  CustomComponentData,
  KustomPageComponentViewProps,
  KustomTranslatedString,
  MediaComponent,
} from '@/lib/kustomcms-sdk/lib/types';
import {
  Box,
  Container,
  Fade,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SlideFade,
  chakra,
} from '@chakra-ui/react';
import React, { useContext, useState } from 'react';

import ActionButton from '@/lib/kustomcms-sdk/lib/components/ActionButton';
import ImageSmooth from '../atomics/ImageSmooth';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import VideoSmooth from '../atomics/VideoSmooth';
import getResponsiveMedia from '@/lib/kustomcms-sdk/lib/helpers/getResponsiveMedia';
import useDelay from '@/hooks/useDelay';
import useIsMounted from '@/hooks/useIsMounted';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useLazyAutoplay from '@/lib/kustomcms-sdk/lib/hooks/useLazyAutoplay';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

const Hero: React.FC<KustomPageComponentViewProps<CustomComponentData>> = (
  props,
) => {
  const { component, page } = props;

  const { currentLang } = useContext(LanguageContext);

  const [videoUrl, setVideoUrl] = useState('');

  const mediaCmp = component.data?.atomicComponents[0] as
    | CodeComponent
    | undefined;
  const mediaBlur = component.data?.atomicComponents[1] as
    | MediaComponent
    | undefined;
  const [textIndex1, textIndex2] = component.data?.atomicComponents.reduce(
    (acc, cmp, index) => (cmp.type === 'TEXT' ? [...acc, index] : acc),
    [] as number[],
  );

  const mediaUrl = mediaCmp?.code;

  const buttonCmpIndex = component.data?.atomicComponents.findIndex(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  );

  const text1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${textIndex1}.text`,
  );

  const text2Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${textIndex2}.text`,
  );

  const device = useResponsiveMediasDevice();

  const delayIndex = useDelay(300, 5);

  const buttonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${buttonCmpIndex}`,
  );

  const blurUrl =
    mediaBlur?.media && getResponsiveMedia(mediaBlur.media, device)?.url;

  const isVideo = mediaUrl?.includes('mp4');

  const lazyAutoplayBind = useLazyAutoplay();

  const isMounted = useIsMounted();

  return (
    <Box
      // h={'100vh'}
      h="calc(var(--vh, 1vh) * 100)"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      // overflowX="clip"
    >
      {mediaUrl && isVideo ? (
        <Box
          w="100%"
          h="100%"
          position="fixed"
          top={0}
          right={'var(--display-page-scroll-shift)'}
          alignSelf="flex-start"
          zIndex={-1}
        >
          <Fade in suppressHydrationWarning={true} style={{ height: '100%' }}>
            <VideoSmooth src={mediaUrl} blurPlaceholder={blurUrl} />
          </Fade>
        </Box>
      ) : (
        <Box
          w="101%"
          h="101%"
          left="-0.5%"
          top="-0.5%"
          position="fixed"
          right={'var(--display-page-scroll-shift)'}
          alignSelf="flex-start"
          zIndex={-1}
        >
          <ImageSmooth
            src={blurUrl || ''}
            alt="Les Elmes Bg"
            fill
            style={{ objectFit: 'cover' }}
            // placeholder="blur"
          />
        </Box>
      )}
      <Container
        maxW="container.lg"
        display="flex"
        flexDir="column"
        alignItems="center"
      >
        {isMounted && (
          <Box maxWidth="1000px" position="relative" mx={[10, null, 0]}>
            <Fade
              in={delayIndex > 3}
              transition={{ enter: { duration: 0.3 } }}
              suppressHydrationWarning={true}
            >
              <RichText
                as="h1"
                fontSize={['32px', null, '58px', null, null, '72px']}
                lineHeight="1"
                fontFamily="jha"
                textAlign="center"
                color="white"
                {...text1Bind}
              />
            </Fade>
            {text2Bind.value?.[currentLang] && (
              <Fade
                in={delayIndex > 4}
                transition={{ enter: { duration: 0.3 } }}
              >
                <RichText
                  as="h2"
                  mt={4}
                  textAlign="center"
                  color="white"
                  fontSize={['20px', null, '30px', null, null, '38px']}
                  fontFamily="heading"
                  {...text2Bind}
                />
              </Fade>
            )}
          </Box>
        )}
        <Box
          position="absolute"
          bottom={20}
          right={0}
          left={0}
          role="group"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <SlideFade
            in={delayIndex > 4}
            offsetY={60}
            transition={{ enter: { duration: 0.3 } }}
          >
            {buttonBind.value && (
              <Box display="flex" alignItems="center">
                <chakra.span
                  color="white"
                  fontSize="72px"
                  mr="3"
                  className="icon-elmes-arrow1-white"
                />
                <ActionButton
                  variant="link"
                  color="white"
                  fontFamily="professor"
                  fontSize={['20px', null, '24px', null, null, '32px']}
                  textDecoration="none"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                  {...buttonBind}
                  disableLink
                  onClick={() =>
                    setVideoUrl(buttonBind.value?.actionString || '')
                  }
                />
              </Box>
            )}
          </SlideFade>
        </Box>
        <Modal isOpen={!!videoUrl} onClose={() => setVideoUrl('')} size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton
              zIndex={3}
              bgColor="white"
              color="brand.500"
              size="lg"
              right={1.5}
            />
            <ModalBody p={0}>
              <video
                {...lazyAutoplayBind}
                loop
                controls
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <source src={videoUrl} type="video/mp4" />
              </video>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Container>
    </Box>
  );
};

export default Hero;
