import { Box, Container, SimpleGrid, useTheme } from '@chakra-ui/react';
import React, { useContext } from 'react';

import AnimatePresence from '../atomics/AnimatePresence';
import { KustomPage } from '@/lib/kustomcms-sdk/lib/types';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import { Parallax } from 'react-scroll-parallax';
import PressCard from './PressCard';

import { SvgShape } from '../SvgShape';

interface PressGridProps {}

const $tabStyling = {
  fontSize: '22px',
  borderBottom: 'transparent',
  color: 'brand.100',
  _selected: { color: 'brand.500' },
  _focus: { bgColor: 'transparent' },
  _hover: { opacity: 0.7 },
};

const PressGrid: React.FC<PressGridProps> = (props) => {
  const theme = useTheme();
  const { pressArticles } = useContext(PagesContext);

  return (
    <Box pt={20} pb={[20, null, null, 40]}>
      <Container
        maxW={['container.xl', null, null, null, null, '1780px']}
        display="flex"
        flexDir={['column', null, null, 'row']}
        px={[6, null, 20]}
        position="relative"
      >
        <Box
          //   display={['none', null, null, 'block']}
          position="absolute"
          top={'300'}
          left={'-50px'}
          width={['140px', null, null, '200px']}
        >
          <Parallax speed={10}>
            <SvgShape file="illu-poisson-1" width="100%" />
          </Parallax>
        </Box>
        {pressArticles.length > 3 && (
          <Box
            // display={['none', null, null, 'block']}
            position="absolute"
            bottom={'-100'}
            right={['20%']}
            width={['180px', null, null, '400px']}
          >
            <Parallax speed={10}>
              <SvgShape file="illu-poisson-3" width="100%" />
            </Parallax>
          </Box>
        )}
        <Grid items={pressArticles} />
      </Container>
    </Box>
  );
};

interface GridProps {
  items: KustomPage[];
}

const Grid: React.FC<GridProps> = (props) => {
  const { items } = props;

  return (
    <Box w="100%">
      <SimpleGrid columns={[2, null, 2, null, 3, null]} spacing={4}>
        {items.map((item, index) => (
          <AnimatePresence
            opacity
            delay={index * 0.2}
            key={item._id}
            style={{
              height: '100%',
            }}
          >
            <PressCard key={item._id} page={item} />
          </AnimatePresence>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default PressGrid;
