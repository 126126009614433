import { Box, Container } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomTranslatedString,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import ContactForm from './ContactForm';
import ParallaxElement from '../ParallaxElement';
import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { SvgShape } from '../SvgShape';

interface ContactFormSectionProps
  extends KustomPageComponentViewProps<TextComponentData> {}

const ContactFormSection: React.FC<ContactFormSectionProps> = (props) => {
  const { page, component } = props;

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  return (
    <Box overflowX="clip" position="relative">
      <ParallaxElement
        right={[0, null, null, 300]}
        top={[200, null, null, 0]}
        width={[150, null, null, 250]}
        Element={() => <SvgShape file="illu-poisson-5" />}
      />
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        position="relative"
        px={[6, null, 20]}
        pt={[20, null, 32]}
        pb={[20, null, 32]}
      >
        <RichText
          fontFamily="heading"
          lineHeight={1}
          mb={5}
          fontSize={['48px', null, '96px']}
          color="brand.500"
          {...textBind}
          as="h2"
        />
        <ContactForm />
      </Container>
    </Box>
  );
};

export default ContactFormSection;
