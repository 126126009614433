import {
  Establishment,
  KustomPage,
  KustomPageComponent,
  KustomRoutes,
  News,
  Offer,
} from '../types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { KustomClient } from '..';
import LanguageContext from './language';
import runToast from '../admin/helpers/runToast';
import { useToast } from '@chakra-ui/react';

interface PagesContextType {
  pages: KustomPage[];
  previsualizedPage?: KustomPage;
  routes: KustomRoutes;
  offers: Offer[];
  news: News[];
  rooms: KustomPage[];
  pressArticles: KustomPage[];
  blogs: KustomPage[];
  establishment: Partial<Establishment>;
  actionButtonPatterns: { [key: string]: (href: string) => void };
  updatePageComponent: (
    page: KustomPage,
    newComponent: KustomPageComponent<any>,
  ) => void;
  handlePrevisualizedPage: (pageId: string) => void;
  kustomClient?: KustomClient;
}

const PagesContext = createContext<PagesContextType>({
  pages: [],
  previsualizedPage: undefined,
  routes: {},
  offers: [],
  news: [],
  rooms: [],
  pressArticles: [],
  blogs: [],
  establishment: {},
  actionButtonPatterns: {},
  updatePageComponent: () => {},
  handlePrevisualizedPage: () => {},
});

interface PagesContextProviderProps {
  children: React.ReactNode;
  pages: KustomPage[];
  offers: Offer[];
  news: News[];
  establishment?: Establishment;
  actionButtonPatterns?: { [key: string]: (href: string) => void };
  kustomClient: KustomClient;
}

export const PagesContextProvider = (props: PagesContextProviderProps) => {
  const {
    children,
    pages: pagesProp,
    offers,
    news,
    actionButtonPatterns = {},
    establishment = {},
    kustomClient,
  } = props;

  const langCtx = useContext(LanguageContext);
  const toast = useToast();

  const [pages, setPages] = useState<KustomPage[]>(pagesProp);

  const [previsualizedPage, setPrevisualizedPage] = useState<
    KustomPage | undefined
  >(undefined);

  const routes = useMemo(
    () =>
      pages.reduce((acc, page) => {
        const href =
          page.prettyUrl?.[langCtx.currentLang || 'fr'] || page.prettyUrl?.fr;

        acc[href] = page;
        return acc;
      }, {} as KustomRoutes),
    [pages, langCtx.currentLang],
  );

  useEffect(() => {
    if (!langCtx.currentLang) return;

    const href = window.location.pathname.substring(1);
    const page = pages.find(
      (page) =>
        page.prettyUrl?.fr === href ||
        page.prettyUrl?.en === href ||
        page.prettyUrl?.es === href,
    );

    if (page && href) {
      const newHref = page.prettyUrl?.[langCtx.currentLang || 'fr'] || href;

      if (newHref !== href) {
        window.history.replaceState({}, '', '/' + newHref);
      }
    }
  }, [langCtx.currentLang]);

  const rooms = useMemo(
    () =>
      pages
        .filter((page) => page.model === 'room')
        .sort((a, b) => a.index - b.index),
    [pages, routes],
  );

  const pressArticles = useMemo(
    () =>
      pages
        .filter((page) => page.model === 'pressarticle')
        .sort((a, b) => a.index - b.index),
    [pages, routes],
  );

  const blogs = useMemo(
    () =>
      pages
        .filter((page) => page.model === 'blog')
        .sort((a, b) => a.index - b.index),
    [pages, routes],
  );

  const updatePageComponent = useCallback(
    async (page: KustomPage, newComponent: KustomPageComponent<any>) => {
      try {
        // const waitToast = runToast({
        //   toast,
        //   title: 'Mise à jour du composant...',
        //   type: 'warning',
        // });

        const newPage = await kustomClient.app
          .service('pages')
          .patch(page._id, {
            components: page.components.map((component) =>
              component.id === newComponent.id ? newComponent : component,
            ),
          });

        setPages((pages) => {
          return pages.map((p) => (p._id === page._id ? newPage : p));
        });

        // waitToast && toast.close(waitToast);
        runToast({
          toast,
          title: 'Composant mis à jour avec succès',
        });
      } catch (e: any) {
        console.error(e);
        runToast({
          toast,
          title: 'Erreur lors de la mise à jour du composant',
          content: e?.message,
          type: 'error',
        });
      }
    },
    [setPages, toast, kustomClient],
  );

  const handlePrevisualizedPage = useCallback(
    async (pageId: string) => {
      const page = await kustomClient?.fetchPage(pageId);

      setPrevisualizedPage(page);
    },
    [kustomClient],
  );

  return (
    <PagesContext.Provider
      value={{
        pages,
        previsualizedPage,
        routes,
        offers,
        news,
        establishment,
        actionButtonPatterns,
        rooms,
        pressArticles,
        blogs,
        updatePageComponent,
        handlePrevisualizedPage,
        kustomClient,
      }}
    >
      {children}
    </PagesContext.Provider>
  );
};

export default PagesContext;
