import {
  BlogPostComponentData,
  KustomPage,
  KustomPageComponent,
  MediaComponent,
  PressArticleComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Text, TextProps } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';

import Link from 'next/link';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import WrapIf from '../atomics/WrapIf';
import getResponsiveMedia from '@/lib/kustomcms-sdk/lib/helpers/getResponsiveMedia';
import moment from 'moment';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';

interface PressCardProps {
  page: KustomPage;
}

const $dateStyling = {
  fontFamily: 'jhaSemilight',
  fontSize: ['12px', null, null, '18px'],
  textTransform: 'capitalize',
} as Omit<TextProps, 'value' | 'onChange'>;

const PressCard: React.FC<PressCardProps> = (props) => {
  const { page } = props;
  const [isHovered, setIsHovered] = useState(false);

  const pressCmp = page
    .components[0] as KustomPageComponent<PressArticleComponentData>;

  const media = pressCmp.data.thumbnail;
  const title = pressCmp.data.title;
  const date = pressCmp.data.date;

  const device = useResponsiveMediasDevice();

  return (
    <WrapIf
      condition={
        !!(pressCmp.data.isExternalLink && pressCmp.data.pressArticleLink)
      }
      wrapper={(children) => (
        <a
          rel="noreferrer"
          target="_blank"
          href={pressCmp.data.pressArticleLink!}
        >
          {children}
        </a>
      )}
    >
      <WrapIf
        condition={!!pressCmp.data.pressArticleMedia}
        wrapper={(children) => {
          const media = getResponsiveMedia(
            pressCmp.data.pressArticleMedia!,
            device,
          );

          return (
            <a rel="noreferrer" target="_blank" href={media?.url}>
              {children}
            </a>
          );
        }}
      >
        <Box
          position="relative"
          bgColor="white"
          w="100%"
          h="100%"
          boxShadow="-8px 8px 8px #00000014"
          borderRadius="base"
          overflow="hidden"
          cursor="pointer"
          role="group"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <Box
            h={['249px', null, null, '380px', null, '522px']}
            position="relative"
            overflow="hidden"
          >
            {media && (
              <ResponsiveMedias
                value={media}
                currentDevice={device}
                fill
                sizes="33vw"
                width={undefined}
                height={undefined}
                style={{
                  transition: 'transform 0.3s',
                  objectFit: 'cover',
                  transform: isHovered ? 'scale(1.2)' : 'scale(1)',
                }}
              />
            )}
            <Box
              position="absolute"
              bgColor="black"
              opacity={0.1}
              top={0}
              right={0}
              bottom={0}
              left={0}
              transition="opacity 0.3s"
              _groupHover={{
                opacity: 0,
              }}
            ></Box>
          </Box>
          <Box p={[5, null, null, 8]}>
            {typeof date === 'string' ? (
              <Text {...$dateStyling}>
                {moment(date).format('DD MMMM YYYY')}
              </Text>
            ) : (
              <TranslatedString {...$dateStyling}>{date}</TranslatedString>
            )}
            <TranslatedString
              fontFamily="jhaSemiboldItalic"
              fontSize={['18px', null, null, '28px']}
            >
              {title}
            </TranslatedString>
          </Box>
        </Box>
      </WrapIf>
    </WrapIf>
  );
};

export default PressCard;
