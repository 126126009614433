import { theme as chakraTheme } from '@chakra-ui/theme';
import { background, createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { extendBaseTheme } from '@chakra-ui/react';
import { inputAnatomy } from '@chakra-ui/anatomy';

const { Button, Drawer, Container, Input, Textarea, Checkbox, Tabs, Select } =
  chakraTheme.components;

const activeLabelStyles = {
  transform: 'scale(0.55) translateY(-7px)',
};

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    fontFamily: 'jhaSemilight',
    fontSize: '18px',
    color: 'brand.500',
    borderRadius: '2px',
    _invalid: {
      borderColor: 'red',
      boxShadow: `0 0 0 1px red`,
    },
  },
});

const lg = definePartsStyle({
  field: {
    paddingX: 6,
    paddingBottom: 2.5,
    paddingTop: 6,
    fontSize: '18px',
    color: 'brand.500',
  },
});

export const inputTheme = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    lg,
  },
});

const theme = extendBaseTheme({
  radii: {
    none: '0',
    sm: '0.125rem',
    base: '4px',
    md: '0.375rem',
    lg: '0.5rem',
    xl: '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    full: '9999px',
  },
  breakpoints: {
    sm: '320px',
    md: '768px',
    // lg: '960px',
    lg: '992px',
    xl: '1200px',
    '2xl': '1920px',
  },
  // shadows: { outline: '0 0 0 2px red' },
  semanticTokens: {
    colors: {
      'chakra-body-text': { _light: 'gray.800' },
      // 'chakra-placeholder-color': { _light: 'black' },
    },
  },
  fonts: {
    jhaSemilight: `'jha_myhappy70ssemilight', sans-serif`,
    jhaSemilightItalic: `'jha_myhappy70sSLtIt', sans-serif`,
    jha: `'jha_myhappy70sregular', sans-serif`,
    jhaItalic: `'jha_myhappy70sregular_italic', sans-serif`,
    jhaSemiboldItalic: `'jha_myhappy70ssemibold_italic', sans-serif`,
    jhaSemibold: `'jha_myhappy70ssemibold', sans-serif`,

    body: `'interregular', sans-serif`,
    heading: `'professor', sans-serif`,
    samsungSharpSans: `'Samsung Sharp Sans', sans-serif`,
    samsungOne: `'SamsungOne', sans-serif`,
  },
  colors: {
    brand: {
      100: '#93B4E1',
      300: '#626da5',
      400: '#2f396b',
      500: '#020D46',
    },
    accent: {
      100: '#F9F5EC',
      200: '#f7eee0',
      300: '#e3d5c0',
      500: '#DDB079',
      800: '#AC742E',
      900: '#372612',
    },
    gray: {
      100: '#F6F6F6',
      200: '#00000029',
      300: '#ccd6e1',
      500: '#dedad3',
      800: '#3B3B3B',
    },
    green: {
      600: '#024612',
    },
  },
  sizes: {
    container: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1428px',
    },
  },
  components: {
    ...chakraTheme.components,
    Drawer: {
      ...Drawer,
    },
    Container: {
      ...Container,
      baseStyle: {
        ...Container.baseStyle,
      },
    },
    Input: {
      ...inputTheme,
    },
    Select: {
      ...Select,
      baseStyle: {
        ...Select.baseStyle,
        border: 'none !important',
        field: {
          ...Select.baseStyle?.field,
          background: 'white',
          fontFamily: 'jhaSemiboldItalic',
          fontSize: '20px',
          color: 'brand.500',
          borderRadius: '2px',
          border: 'none !important',
          outline: 'none',
          height: '4rem',
          paddingInlineStart: '1.5rem',
          _invalid: {
            borderColor: 'red',
            boxShadow: '0 0 0 1px red',
          },
        },
      },
    },
    Button: {
      ...Button,
      baseStyle: {
        ...Button.baseStyle,
        transition: 'all 0.2s ease',
        fontWeight: '500',
        fontSize: '16px',
        borderRadius: '3px',
        maxWidth: '100%',
        lineHeight: 1,
        _hover: {
          bgColor: 'brand.100',
        },
      },
      variants: {
        ...Button.variants,
        link: {
          whiteSpace: 'normal',
          padding: 0,
          color: 'brand.500',
          textDecoration: 'underline',
          textDecorationColor: 'accent.500',
          textDecorationThickness: '3px',
          textUnderlineOffset: 6,
          _hover: {
            bgColor: 'none',
            textDecorationColor: 'brand.500',
            textDecorationThickness: '3px',
            textUnderlineOffset: 6,
          },
        },
        brand: {
          backgroundColor: 'brand.500',
          fontFamily: 'body',
          fontWeight: '700',
          fontSize: '16px',
          color: 'white',
        },
      },
      sizes: {
        ...Button.sizes,
        brandLg: {
          h: '16',
          minW: '16',
          fontSize: '18px',
          px: '9',
        },
        brandMd: {
          h: '14',
          minW: '14',
          fontSize: '16px',
          px: '7',
        },
        brandSm: {
          h: '12',
          minW: '12',
          fontSize: '14px',
          px: '5',
        },
      },
    },
    Textarea: {
      ...Textarea,
    },
    Tabs,
    Text: {
      baseStyle: {
        color: 'brand.500',
        overflowWrap: 'break-word',
      },
    },
    FormLabel: {
      baseStyle: {
        fontFamily: 'jhaSemiboldItalic',
        color: 'brand.500',
        fontSize: '20px',
      },
    },
    Heading: {
      baseStyle: {
        color: 'brand.500',
      },
    },
    Checkbox,
    Form: {
      variants: {
        floating: {
          container: {
            position: 'relative',
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 1.5,
              left: 6,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'transparent',
              pointerEvents: 'none',
              my: 2,
              transformOrigin: 'left top',
              transition: 'all 0.2s ease',
            },
          },
        },
      },
    },
  },
});

export default theme;
