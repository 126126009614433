import { Box, Container, chakra } from '@chakra-ui/react';
import {
  KustomPage,
  KustomPageComponentViewProps,
  PressArticleComponentData,
  TextComponent,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useMemo } from 'react';

import Link from 'next/link';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useTranslatedString from '@/lib/kustomcms-sdk/lib/hooks/useTranslatedString';

interface SiteMapSectionProps
  extends KustomPageComponentViewProps<TextComponent> {
  filterPages?: (page: KustomPage) => boolean;
}

const SiteMapSection: React.FC<SiteMapSectionProps> = (props) => {
  const { filterPages = () => true, component } = props;

  const text = component.data.text;

  const { pages } = useContext(PagesContext);

  const validePages = useMemo(
    () => pages.filter(filterPages),
    [pages, filterPages],
  );

  const home = validePages.find((page) => page.pageCode === '4onpu');
  let updatedValidePages = [];
  if (home) {
    updatedValidePages = validePages.filter(
      (page) => page.pageCode !== '4onpu',
    );
    updatedValidePages.unshift(home);
  } else {
    updatedValidePages = validePages;
  }

  return (
    <Box>
      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        position="relative"
        px={[6, null, 20]}
        py={[14, null, 20]}
      >
        <RichText
          fontFamily="heading"
          lineHeight={1}
          mb={8}
          fontSize={['48px', null, '96px']}
          color="brand.500"
          as="h2"
        >
          {text}
        </RichText>
        <chakra.ul listStyleType="none">
          {updatedValidePages.map((page) => (
            <SiteMapItem page={page} key={page._id} />
          ))}
        </chakra.ul>
      </Container>
    </Box>
  );
};

interface SiteMapItemProps {
  page: KustomPage;
}

const SiteMapItem: React.FC<SiteMapItemProps> = (props) => {
  const { page } = props;

  const href = useTranslatedString(page.prettyUrl || {});

  const pressHref = useMemo(() => {
    if (page.model !== 'pressarticle') return undefined;
    const data = page.components?.[0]?.data as
      | PressArticleComponentData
      | undefined;

    return data?.isExternalLink
      ? data?.pressArticleLink
      : data?.pressArticleMedia?.default?.url;
  }, []);

  if (page.excludeFromMenu) return null;

  return (
    <chakra.li my={1}>
      <Link
        href={pressHref || href || '#'}
        target={pressHref ? '_blank' : undefined}
      >
        <TranslatedString
          transition="all 0.2s"
          _hover={{
            textDecoration: 'underline',
            color: 'accent.500',
          }}
          as="h3"
        >
          {page.title}
        </TranslatedString>
      </Link>
    </chakra.li>
  );
};

export default SiteMapSection;
