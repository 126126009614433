import {
  Box,
  BoxProps,
  Center,
  SimpleGrid,
  Text,
  ToastId,
  useToast,
} from '@chakra-ui/react';
import { FileWithId, uploadActions } from '../../features/upload/slice';
import { Media, mediasActions } from '../../features/medias/slice';
import {
  MediaType,
  getMediaType,
  getOriginalFilename,
} from '../../helpers/media';
import React, {
  useCallback,
  useContext,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { downloadFile, downloadFiles } from '../../helpers/download';

import AdminMediasModalsContext from '../../contexts/AdminMediasModalsContext';
import IconClose from '@/lib/kustomcms-sdk/lib/icons/icon-close.svg';
import IconRetry from '@/lib/kustomcms-sdk/lib/icons/icon-retry.svg';
import InfiniteScroll from 'react-infinite-scroller';
import LoadingMedia from '@/lib/kustomcms-sdk/lib/icons/loader-media-dots-anim.svg';
import MediaItem from './MediaItem';
import MediasSearchBar from './MediasSearchBar';
import Skeleton from 'react-loading-skeleton';
import Toast from '../atomics/Toast';
import moment from 'moment';
import { useAppDispatch } from '../../hooks/useAppDispatch';

interface MediasFinderProps {
  medias: Media[];
  selectedMedias?: Media[];
  pendingUploads?: number;
  isLoading?: boolean;
  failedUploads?: FileWithId[];
  onFailedUploadClick?: (file: FileWithId) => void;
  onRemoveSelectedMedias?: () => void;
  onMediaPick?: (media: Media) => void;
  collapsed?: boolean;
  isEditable?: boolean;
  scrollableContainerRef?: React.RefObject<HTMLElement>;
  isPublic?: boolean;
}

const mediaBoxProps: BoxProps = {
  display: 'flex',
  flex: '1 0 auto',
  alignItems: 'center',
  position: 'relative',
  h: '200px',
  borderWidth: 1,
  borderRadius: 'base',
  // _before: {
  //   content: '""',
  //   display: 'block',
  //   // paddingTop: '100%',
  // },
};

const isFormatOk = (format: string, width: number, height: number) => {
  if (format === 'square') {
    return width === height;
  }
  if (format === 'landscape') {
    return width > height;
  }
  if (format === 'portrait') {
    return width < height;
  }
  return false;
};

const PAGE_SIZE = 20;

const MediasFinder = (props: MediasFinderProps) => {
  const {
    medias,
    selectedMedias,
    pendingUploads,
    failedUploads,
    isLoading,
    onFailedUploadClick,
    collapsed,
    onMediaPick,
    onRemoveSelectedMedias,
    isEditable = true,
    scrollableContainerRef,
    isPublic,
  } = props;
  const dispatch = useAppDispatch();
  const toast = useToast();

  const mediasModalsCtx = useContext(AdminMediasModalsContext);

  const [filteredMedias, setFilteredMedias] = useState(medias);
  const [filters, setFilters] = useState({
    search: '',
    tags: [] as string[],
    types: Object.values(MediaType) as MediaType[],
    date: -1 as number | undefined,
    formats: [] as string[],
  });

  const [displayedItemsCount, setDisplayedItemsCount] = useState(PAGE_SIZE);

  const hasMore = displayedItemsCount < filteredMedias.length;

  const viewMore = (pageNumber: number) => {
    if (hasMore) {
      setDisplayedItemsCount(pageNumber * PAGE_SIZE);
    }
  };

  const isAllMediasSelected =
    filteredMedias.length !== 0 &&
    (selectedMedias?.length || 0) >= filteredMedias.length;

  useEffect(() => {
    const newMedias = medias.filter((media) => {
      if (
        !media.filename.toLowerCase().includes(filters.search.toLowerCase())
      ) {
        return false;
      }
      if (
        filters.tags.length &&
        !filters.tags.some((tag) =>
          tag === ''
            ? !media.metadata?.tags.length
            : media.metadata?.tags.includes(tag),
        )
      ) {
        return false;
      }
      if (!filters.types.includes(getMediaType(media.filename))) {
        return false;
      }
      if (
        filters.date !== -1 &&
        moment(filters.date).isAfter(media.metadata?.createdAt)
      ) {
        return false;
      }
      if (filters.formats.length) {
        if (!media.metadata?.width || !media.metadata?.height) {
          return false;
        }

        if (
          !filters.formats.some((format) => {
            return isFormatOk(
              format,
              +(media.metadata?.width || 0),
              +(media.metadata?.height || 0),
            );
          })
        ) {
          return false;
        }
      }

      return true;
    });

    setFilteredMedias(newMedias);
  }, [
    medias,
    filters.date,
    filters.formats,
    filters.search,
    filters.tags,
    filters.types,
  ]);

  useEffect(() => {
    if (
      mediasModalsCtx.editedImage &&
      !medias.find((m) => m.filename === mediasModalsCtx.editedImage?.filename)
    ) {
      const origFilename = getOriginalFilename(
        mediasModalsCtx.editedImage?.filename,
      );
      const media = medias.find(
        (m) => getOriginalFilename(m.filename) === origFilename,
      );
      if (media) {
        mediasModalsCtx.setEditedImage(media);
      }
    }
  }, [medias, mediasModalsCtx.editedImage]);

  const handleSelectAllMedias = (selection?: Media[]) => {
    dispatch(
      mediasActions.selectMedias(
        selection || (isAllMediasSelected ? [] : filteredMedias),
      ),
    );
  };

  const rmFailedUpload = (e: React.MouseEvent, file: FileWithId) => {
    e.stopPropagation();
    dispatch(uploadActions.removeFailedUpload({ file }));
  };

  const handleEditImage = useCallback((media: Media) => {
    mediasModalsCtx.setEditedImage(media);
  }, []);

  const toastIdRef = React.useRef<ToastId>();

  const onDownloadSelectedMedias = () => {
    const toastContent =
      "Génération de l'archive... veuillez ne pas fermer ou recharger la page";

    if (selectedMedias?.length) {
      if (selectedMedias.length === 1) {
        selectedMedias[0]?.url &&
          downloadFile(selectedMedias[0]?.url, selectedMedias[0]?.filename);
      } else {
        toastIdRef.current = toast({
          position: 'bottom-right',
          duration: null,
          render: (props) => (
            <Toast
              {...props}
              warning
              title="TÉLÉCHARGEMENT EN COURS 0%"
              content={toastContent}
            />
          ),
        });

        downloadFiles(
          selectedMedias?.map((media) => ({
            url: media.url,
            filename: media.filename,
          })),
          (percent) => {
            if (toastIdRef.current) {
              if (percent === 100) {
                const toastId = toastIdRef.current;
                setTimeout(() => toast.close(toastId), 2500);
              }

              toast.update(toastIdRef.current, {
                position: 'bottom-right',
                duration: null,
                render: (props) => (
                  <Toast
                    {...props}
                    warning
                    title={`TÉLÉCHARGEMENT EN COURS ${Math.round(percent)}%`}
                    content={toastContent}
                  />
                ),
              });
            }
          },
        );
      }
    }
  };

  const getScrollParent = useMemo(
    () =>
      scrollableContainerRef?.current
        ? () => {
            return scrollableContainerRef.current;
          }
        : undefined,
    [scrollableContainerRef?.current],
  );

  return (
    <>
      <MediasSearchBar
        isSticky
        filters={filters}
        onNewFilters={setFilters}
        onSelectAll={handleSelectAllMedias}
        collapsed={collapsed}
        isEditable={isEditable}
        isAllSelected={isAllMediasSelected}
        selectedMedias={selectedMedias}
        onRemoveSelectedMedias={onRemoveSelectedMedias}
        onDownloadSelectedMedias={onDownloadSelectedMedias}
      />
      <Box
        width="100%"
        backgroundColor="white"
        borderRadius="base"
        boxShadow="0px 0px 21px #00000012"
        p={6}
      >
        {isLoading ? (
          <SimpleGrid columns={5} spacing={5}>
            {[...Array(10)].map((e, i) => (
              <Box key={i} h={200} mt={-1}>
                <Skeleton width="100%" height="100%" />
              </Box>
            ))}
          </SimpleGrid>
        ) : filteredMedias?.length ||
          pendingUploads ||
          failedUploads?.length ? (
          <InfiniteScroll
            initialLoad={false}
            pageStart={1}
            loadMore={viewMore}
            hasMore={hasMore}
            loader={
              <div className="loader" key={0} style={{ clear: 'both' }}>
                Loading ...
              </div>
            }
            useWindow={false}
            getScrollParent={getScrollParent}
          >
            <SimpleGrid columns={5} spacing={5}>
              {pendingUploads
                ? Array(pendingUploads)
                    .fill(0)
                    .map((_, index) => (
                      <Center
                        {...mediaBoxProps}
                        key={index}
                        backgroundColor="gray.50"
                      >
                        <LoadingMedia />
                      </Center>
                    ))
                : null}
              {failedUploads?.length
                ? failedUploads.map((file, index) => (
                    <Box
                      {...mediaBoxProps}
                      key={index}
                      d="flex"
                      flexDir="column"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="gray.50"
                      cursor="pointer"
                      position="relative"
                      onClick={() => onFailedUploadClick?.(file)}
                    >
                      <Box
                        position="absolute"
                        top="2"
                        right="2"
                        opacity={0.5}
                        p={1.5}
                        _hover={{ bgColor: '#D9D9D9', borderRadius: 'full' }}
                        onClick={(e) => rmFailedUpload(e, file)}
                      >
                        <IconClose color="brand.850" width={10} height={10} />
                      </Box>
                      <Box
                        borderRadius="full"
                        backgroundColor="white"
                        w={66}
                        h={66}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <IconRetry width={66} height={66} />
                      </Box>
                      <Text
                        position="absolute"
                        bottom={0}
                        p={5}
                        textStyle="brand"
                        fontWeight="bold"
                        fontSize="14px"
                      >
                        RECOMMENCER
                      </Text>
                    </Box>
                  ))
                : null}
              {filteredMedias.slice(0, displayedItemsCount).map((media) => {
                const isSelected = !!selectedMedias?.find(
                  (m) => m.filename === media.filename,
                );

                return (
                  <Box
                    {...mediaBoxProps}
                    key={media.metadata.key}
                    {...(isSelected && {
                      borderWidth: '4px',
                      borderColor: 'brand.500',
                    })}
                  >
                    <MediaItem
                      isPublic={isPublic}
                      onRemove={
                        isEditable
                          ? mediasModalsCtx.setRemoveMediaOpen
                          : undefined
                      }
                      media={media}
                      onEditImage={handleEditImage}
                      isEditable={false}
                      isSelected={isSelected}
                      onPick={onMediaPick}
                      setVideoViewerOpen={mediasModalsCtx.setMediaViewerOpen}
                      setPhotoViewerOpen={mediasModalsCtx.setMediaViewerOpen}
                    />
                  </Box>
                );
              })}
            </SimpleGrid>
          </InfiniteScroll>
        ) : (
          <Center flexGrow={1}>
            <Text color="gray.400" fontSize="14px">
              {medias.length
                ? 'Aucun média ne correspond à cette recherche.'
                : 'Aucun média pour cet établissement.'}
            </Text>
          </Center>
        )}
      </Box>
    </>
  );
};

export default MediasFinder;
