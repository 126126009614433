import { Box, Container } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import React, { useContext } from 'react';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

const TextMediaBackground: React.FC<
  KustomPageComponentViewProps<TextCarouselComponentData>
> = (props) => {
  const { component, page } = props;

  const media = component.data?.slides[0]?.medias;

  const { currentLang } = useContext(LanguageContext);

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textTitle`,
  );

  const subtitleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.textSubtitle`,
  );

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.text`,
  );

  const currentDevice = useResponsiveMediasDevice();

  return (
    <Box
      position="relative"
      minH={['500px', null, null, '600px', null, '1100px']}
      display="flex"
      alignItems="center"
      mt={16}
    >
      <Box position="absolute" right={0} left={0} top={0} bottom={0}>
        <ResponsiveMedias
          currentDevice={currentDevice}
          fill
          width={undefined}
          height={undefined}
          style={{
            objectFit: 'cover',
          }}
          value={media}
        />
        <Box
          position="absolute"
          right={0}
          left={0}
          top={0}
          bottom={0}
          bgColor="rgba(0, 0, 0, 0.7)"
        ></Box>
      </Box>
      <Container maxW="container.lg" position="relative" color="white" px={10}>
        <TranslatedString
          fontFamily="heading"
          color="white"
          fontSize={['38px', null, '96px', null, null, '120px']}
          {...titleBind}
          as="h2"
        />
        {subtitleBind.value?.[currentLang] && (
          <TranslatedString
            mb={[6, null, 10]}
            mt={[4, null, 0]}
            fontFamily="jhaSemiboldItalic"
            fontSize={['18px', null, '22px', null, null, '28px']}
            color="white"
            {...subtitleBind}
            as="h3"
          />
        )}
        {textBind.value?.[currentLang] && (
          <RichText
            fontFamily="jhaSemilight"
            fontSize={['14px', null, '19px', null, null, '24px']}
            {...textBind}
            as="p"
          />
        )}
      </Container>
    </Box>
  );
};

export default TextMediaBackground;
