import { KustomPage, KustomPageComponent } from '../types';

import { useMemo } from 'react';

const usePageComponent = <T>(
  page: KustomPage | undefined,
  componentKey: string | null,
  componentId?: string,
) => {
  return useMemo(
    () =>
      !page
        ? null
        : (page.components.find((c) =>
            componentKey ? c.type === componentKey : c.id === componentId,
          ) as KustomPageComponent<T>),
    [page, componentKey, componentId],
  );
};

export default usePageComponent;
