import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Container,
  Heading,
  chakra,
} from '@chakra-ui/react';
import {
  AccordionComponentData,
  CarouselComponentData,
  CodeComponent,
  KustomPageComponentViewProps,
  KustomTranslatedString,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useState } from 'react';

import AnimatePresence from '../atomics/AnimatePresence';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData, {
  useKustomTranslation,
} from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';

const MAX_SLIDES = 5;

const FAQ: React.FC<KustomPageComponentViewProps<AccordionComponentData>> = (
  props,
) => {
  const { component, page } = props;

  const { currentLang } = useContext(LanguageContext);

  const [seeMore, setSeeMore] = useState(false);

  const slides = seeMore
    ? component.data.slides
    : component.data.slides.slice(0, MAX_SLIDES);

  const carrouselCmp = usePageComponent<CarouselComponentData>(
    page,
    null,
    '16921823951555462',
  );

  const slides2 = carrouselCmp?.data.slides;

  const titleBind = useKustomTranslation('faq_practical_information');

  const postQuestionBind = useKustomTranslation('faq_post_question');

  const questionMoreText =
    useKustomTranslation('faq_question_more')?.value?.[currentLang];
  const questionLessText =
    useKustomTranslation('faq_question_less')?.value?.[currentLang];

  return (
    <Box minH="200px" display="flex" bgColor="accent.100" py={20}>
      <Container
        maxW={['container.xl', null, null, null, null, '1780px']}
        display="flex"
        flexDir={'column'}
        px={[6, null, 20]}
      >
        <AnimatePresence x={-60} opacity>
          <Heading
            textAlign={['left', null, 'center']}
            fontSize={['40px', null, '96px', null, null, '120px']}
            color="brand.500"
            lineHeight={1}
            fontWeight={500}
            fontFamily="heading"
            mb={[10, null, 28]}
          >
            <TranslatedString {...titleBind} />
          </Heading>
        </AnimatePresence>
        <Box display="flex" flexDir={['column', null, null, 'row']}>
          <Box flex={3} px={[0, null, 6]} mr={[null, null, null, 12, null, 16]}>
            <Accordion allowToggle mt={[0, null, 14]}>
              {slides.map((slide, index) => (
                <FAQSlide
                  key={slide.id}
                  page={page}
                  component={component}
                  isLastSlide={index === slides.length - 1}
                  slideIndex={index}
                />
              ))}
            </Accordion>
            <Box
              mt={[2, null, 5]}
              display="flex"
              alignItems="center"
              position="relative"
              zIndex={1}
            >
              {MAX_SLIDES < component.data.slides.length && (
                <Button
                  colorScheme="brand"
                  fontFamily="body"
                  fontWeight="bold"
                  px={7}
                  mr={8}
                  py={[8, null, 7]}
                  onClick={() => setSeeMore((s) => !s)}
                  rightIcon={
                    <chakra.span
                      fontSize={['42px', null, '56px']}
                      _before={{
                        color: 'white',
                      }}
                      className="icon-elmes-arrow1-white"
                    />
                  }
                >
                  {!seeMore ? questionMoreText : questionLessText}
                </Button>
              )}
              <Button
                colorScheme="brand"
                textAlign="left"
                fontFamily="jha"
                variant="link"
                px={0}
                py={8}
                mt={0}
                fontSize="20px"
              >
                <TranslatedString {...postQuestionBind} />
              </Button>
            </Box>
          </Box>
          {carrouselCmp && (
            <Box
              flex={[1, 2]}
              px={[8, null, 16]}
              py={[10, null, 16]}
              mt={[16, null, null, 0]}
              mx={[-6]}
              maxW={[null, null, null, '60vw', '40vw']}
              bgColor="white"
              boxShadow="-8px 8px 8px #00000014"
            >
              {slides2?.map((item, index) => (
                <FaqPanelSlide
                  key={item.id}
                  page={page}
                  component={carrouselCmp}
                  slideIndex={index}
                />
              ))}
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

interface FAQSlideProps
  extends KustomPageComponentViewProps<AccordionComponentData> {
  slideIndex: number;
  isLastSlide: boolean;
}

const FAQSlide: React.FC<FAQSlideProps> = (props) => {
  const { page, component, index, slideIndex, isLastSlide } = props;

  const textBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.text`,
  );

  const hiddenTextBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.hiddenText`,
  );

  return (
    <AccordionItem
      borderColor="gray.500"
      borderTop={slideIndex === 0 ? 'none' : undefined}
      borderBottom={isLastSlide ? 'none' : undefined}
    >
      <h3>
        <AccordionButton
          pb={4}
          px={0}
          _hover={{
            bg: 'transparent',
          }}
          _active={{
            bg: 'transparent',
          }}
        >
          <Box as="span" flex="1" textAlign="left" mr={12}>
            <TranslatedString
              fontSize={['18px', null, '22px', null, null, '28px']}
              fontFamily="jhaSemiboldItalic"
              {...textBind}
              as="span"
            />
          </Box>
          <AccordionIcon color="brand.500" />
        </AccordionButton>
      </h3>
      <AccordionPanel pb={4} px={0}>
        <RichText
          fontSize={['18px', null, '22px', null, null, '28px']}
          fontFamily="jhaSemilight"
          color="brand.500"
          {...hiddenTextBind}
          as="p"
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

interface FaqPanelSlideProps
  extends KustomPageComponentViewProps<CarouselComponentData> {
  slideIndex: number;
}

const FaqPanelSlide: React.FC<FaqPanelSlideProps> = (props) => {
  const { page, component, slideIndex } = props;

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.title`,
  );

  const subtitleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.subtitle`,
  );

  const icon = component.data.slides[slideIndex]?.atomicComponents.find(
    (cmp) => cmp.type === 'CODE',
  ) as CodeComponent | undefined;

  return (
    <Box
      pt={slideIndex === 0 ? 0 : 6}
      mt={slideIndex === 0 ? 0 : 6}
      borderTop={slideIndex === 0 ? 'none' : '1px solid'}
      borderColor="gray.200"
    >
      <chakra.span className={icon?.code} fontSize="48px" mb={0} ml={-1} />
      <TranslatedString
        mt={1}
        mb={3}
        fontFamily="jhaSemiboldItalic"
        color="brand.500"
        fontSize={['18px', null, '22px', null, null, '28px']}
        fontWeight={700}
        {...titleBind}
        as="h3"
      />
      <TranslatedString
        fontFamily="jhaSemilight"
        fontSize={['18px', null, '22px', null, null, '28px']}
        color="brand.500"
        {...subtitleBind}
      />
    </Box>
  );
};

export default FAQ;
