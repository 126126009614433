import { motion } from 'framer-motion';

interface AnimatePresenceProps {
  x?: number;
  y?: number;
  opacity?: boolean;
  amount?: number;
  children: React.ReactNode;
  delay?: number;
  style?: React.CSSProperties;
}

const AnimatePresence = (props: AnimatePresenceProps) => {
  const { x, y, opacity, amount, delay, style } = props;

  return (
    <motion.div
      style={style}
      initial={{
        x: x || 0,
        y: y || 0,
        opacity: opacity ? 0 : 1,
      }}
      whileInView={{
        x: 0,
        y: 0,
        opacity: 1,
      }}
      transition={{
        duration: 0.4,
        ease: 'easeInOut',
        delay,
      }}
      viewport={{ once: true, amount: amount || 0.5 }}
    >
      {props.children}
    </motion.div>
  );
};

export default AnimatePresence;
