import theme from '../theme';

const breakpoints = Object.values(theme.breakpoints);

const chakraResponsiveToImageSizes = (
  responsiveSizes: (string | number | null)[],
) => {
  const sizes = responsiveSizes.reduce((acc: string, size, index) => {
    const s = typeof size === 'number' ? `${size}px` : size;

    if (s === null) return acc;

    return (
      acc +
      (acc.length ? ',' : '') +
      (index === responsiveSizes.length - 1
        ? `${s}`
        : `(min-width: ${breakpoints[index]}) ${s}`)
    );
  }, '');

  return sizes;
};

export default chakraResponsiveToImageSizes;
