import { useEffect, useState } from "react";

const useDelay = (delay: number, limit: number = 10) => {
    const [delayed, setDelayed] = useState(0);

    useEffect(() => {
        if (delayed < limit) {
            const timer = setInterval(() => {
                setDelayed(d => d + 1);
            }, delay)
            
            return () => clearInterval(timer);
        }
    }, [delay, delayed, limit]);

    return delayed;
};

export default useDelay;