import { KustomTranslatedString } from '../types';
import LanguageContext from '../contexts/language';
import { useContext } from 'react';

const useTranslatedString = (string: KustomTranslatedString) => {
  const language = useContext(LanguageContext);

  const renderString = string[language.currentLang || 'fr'] || string.fr;

  return renderString;
};

export default useTranslatedString;
