import { Box, ButtonGroup, Center, IconButton, Text } from '@chakra-ui/react';
import { Media, mediasActions } from '../../features/medias/slice';
import {
  MediaType,
  getMediaThumbnailUrl,
  getMediaType,
} from '../../helpers/media';
import React, { useCallback, useMemo, useState } from 'react';

import IconCrop from '@/lib/kustomcms-sdk/lib/icons/icon-crop.svg';
import IconDownload from '@/lib/kustomcms-sdk/lib/icons/icon-download.svg';
import IconEye from '@/lib/kustomcms-sdk/lib/icons/icon-eye.svg';
import IconPDF from '@/lib/kustomcms-sdk/lib/icons/icon-file-pdf.svg';
import IconTrash from '@/lib/kustomcms-sdk/lib/icons/icon-trash.svg';
import IconVideo from '@/lib/kustomcms-sdk/lib/icons/icon-video.svg';
import { downloadFile } from '../../helpers/download';
import { useAppDispatch } from '../../hooks/useAppDispatch';
/* eslint-disable @next/next/no-img-element */
import useTheme from '../../hooks/useTheme';

interface MediaItemProps {
  width?: string;
  height?: string;
  media: Media;
  onPick?: (m: Media) => void;
  onRemove?: (m: Media) => void;
  onEditImage?: (m: Media) => void;
  isEditable?: boolean;
  isSelected?: boolean;
  setVideoViewerOpen?: (m: Media) => void;
  setPhotoViewerOpen?: (m: Media) => void;
  RemoveIcon?: React.FC<React.SVGAttributes<SVGElement>>;
  isPublic?: boolean;
}

const MediaItem = (props: MediaItemProps) => {
  const {
    width,
    height,
    media,
    onRemove,
    onEditImage,
    onPick,
    isEditable,
    setVideoViewerOpen,
    setPhotoViewerOpen,
    RemoveIcon = IconTrash,
    isPublic,
  } = props;
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const mediaType = useMemo(() => getMediaType(media.url), [media.url]);

  const handleSelectMedia = () => {
    onPick?.(media);
    dispatch(mediasActions.toggleSelectMedia({ media, isPublic }));
  };

  const confirmRemove = (e: React.MouseEvent) => {
    e.stopPropagation();
    onRemove?.(media);
  };

  const startDownloadFile = (e: React.MouseEvent) => {
    e.stopPropagation();
    downloadFile(media.url, media.filename);
  };

  const actionsIcons = useCallback(
    (children?: React.ReactNode) => (
      <ButtonGroup
        size="sm"
        isAttached
        position="absolute"
        bottom={4}
        left="50%"
        opacity={0}
        zIndex={2}
        transform="translate(-50%, 0)"
        _groupHover={{
          opacity: 1,
        }}
      >
        {children}
        <IconButton
          aria-label="download image"
          variant="brandIcon"
          icon={<IconDownload fill="white" width={15} />}
          p={3}
          py={5}
          onClick={startDownloadFile}
          borderRightWidth={'1px'}
          borderRightColor="#2b3b50"
        />
        {onRemove && (
          <IconButton
            aria-label="Delete image"
            variant="brandIcon"
            icon={<RemoveIcon fill="white" width={15} />}
            p={3}
            py={5}
            onClick={confirmRemove}
          />
        )}
      </ButtonGroup>
    ),
    [onRemove],
  );

  const image = useMemo(
    () => (
      <Box
        w="100%"
        h="100%"
        cursor="pointer"
        backgroundImage={'url("' + getMediaThumbnailUrl(media) + '")'}
        backgroundSize="contain"
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        position="relative"
      >
        {actionsIcons(
          <>
            <IconButton
              aria-label="view image"
              variant="brandIcon"
              icon={<IconEye fill="white" width={15} />}
              p={3}
              py={5}
              onClick={() => setPhotoViewerOpen?.(media)}
              borderRightWidth={'1px'}
              borderRightColor="#2b3b50"
            />
            {isEditable && (
              <IconButton
                aria-label="Crop image"
                icon={<IconCrop fill="white" width={18} />}
                variant="brandIcon"
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  onEditImage?.(media);
                }}
                borderRightWidth={'1px'}
                borderRightColor="#2b3b50"
                p={3}
                py={5}
              />
            )}
          </>,
        )}
      </Box>
    ),
    [media, isEditable],
  );

  return (
    <Box
      w={width || '100%'}
      h={height || '100%'}
      role="group"
      borderRadius="base"
      overflow="hidden"
      cursor="pointer"
      onClick={handleSelectMedia}
    >
      {
        {
          [MediaType.PDF]: (
            <Box
              w="100%"
              h="100%"
              backgroundColor="gray.50"
              p={4}
              position="relative"
            >
              <Center flexDir="column" w="100%" h="100%">
                <IconPDF />
                <Text
                  textAlign="center"
                  mt={3}
                  textStyle="brand"
                  fontSize="14px"
                >
                  {media.filename}
                </Text>
              </Center>
              {actionsIcons(
                <IconButton
                  onClick={() => window.open(media.url)}
                  aria-label="view pdf"
                  variant="brandIcon"
                  icon={<IconEye fill="white" width={15} />}
                  p={3}
                  py={5}
                  borderRightWidth={'1px'}
                  borderRightColor="#2b3b50"
                />,
              )}
            </Box>
          ),
          [MediaType.VIDEO]: (
            <Box
              overflow="hidden"
              w="100%"
              h="100%"
              cursor="pointer"
              borderRadius="base"
            >
              <video
                style={{
                  zIndex: 0,
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)',
                  objectFit: 'cover',
                  borderRadius: theme.radii.base as string,
                }}
              >
                <source src={media.url} />
              </video>
              <IconVideo
                style={{
                  position: 'relative',
                  zIndex: 0,
                  margin: theme.space[2] as string,
                  boxShadow: '0px 0px 9px #00000066',
                  backgroundColor: 'rgba(0,0,0,0.25)',
                }}
                width={32}
                fill="white"
              />
              {actionsIcons(
                <IconButton
                  aria-label="View video"
                  icon={<IconEye fill="white" width={18} />}
                  variant="brandIcon"
                  onClick={() => setVideoViewerOpen?.(media)}
                  borderRightWidth={'1px'}
                  borderRightColor="#2b3b50"
                  p={3}
                  py={5}
                />,
              )}
            </Box>
          ),
          [MediaType.IMAGE]: image,
          [MediaType.UNKNOWN]: image,
          [MediaType.APP]: (
            <Box
              w="100%"
              h="100%"
              backgroundColor="gray.50"
              p={4}
              position="relative"
            >
              <Center flexDir="column" w="100%" h="100%">
                <Text
                  textAlign="center"
                  mt={3}
                  textStyle="brand"
                  fontSize="14px"
                >
                  {media.filename}
                </Text>
              </Center>
              {actionsIcons()}
            </Box>
          ),
        }[mediaType]
      }
    </Box>
  );
};

export default React.memo(MediaItem);
