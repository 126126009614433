import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import MediasFinder from './MediasFinder';
import { mediasActions } from '../../features/medias/slice';
import { shallowEqual } from 'react-redux';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useKustomSelector } from '../../hooks/useKustomSelector';

const MediasPublicFinder = () => {
  const { publicMedias, selectedPublicMedias } = useKustomSelector(
    ({ medias: { publicMedias, selectedPublicMedias } }) => ({
      publicMedias,
      selectedPublicMedias,
    }),
    shallowEqual,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(mediasActions.getPublicMedias());
  }, [dispatch]);

  return (
    <Box
      width="100%"
      backgroundColor="white"
      borderRadius="base"
      boxShadow="0px 0px 21px #00000012"
      mb={4}
    >
      <Accordion allowMultiple>
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                px={2}
                py={2}
                fontWeight="700"
                opacity="0.5"
                color="brand"
                flex="1"
                textAlign="left"
              >
                PHOTOS LIBRE DE DROIT{' '}
                {publicMedias.length !== 0
                  ? '(' + getStepNumber(publicMedias) + ')'
                  : ''}
              </Box>
              <Center
                backgroundColor="gray.50"
                w="36px"
                h="36px"
                borderRadius="full"
              >
                <AccordionIcon color="gray.500" opacity="0.6" />
              </Center>
            </AccordionButton>
          </h2>
          <AccordionPanel p={0}>
            <MediasFinder
              isPublic
              medias={publicMedias}
              collapsed
              isEditable={false}
              selectedMedias={selectedPublicMedias}
            />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

export default MediasPublicFinder;

function getStepNumber(arr: any[]): string {
  const length = arr.length;
  const floorLength = Math.floor(length / 10) * 10;

  if (length < 10) {
    return length + '';
  }

  if (length > 500) {
    return '500+';
  }

  return '' + floorLength + (length < floorLength ? '+' : '');
}
