import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  theme,
  useToast,
} from '@chakra-ui/react';
import { Media, mediasActions } from '../features/medias/slice';
import { MediaType, getMediaType } from '../helpers/media';
import { createContext, useCallback, useState } from 'react';

import AdminThemeProvider from '../components/AdminThemeProvider';
import ImageEditor from '../components/medias/ImageEditor';
import { KustomMedia } from '../../types';
import MediaViewer from '../components/medias/MediaViewer';
import MediasPicker from '../components/medias/MediasPicker';
import Toast from '../components/atomics/Toast';
import VideoViewer from '../components/medias/VideoViewer';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useKustomSelector } from '../hooks/useKustomSelector';
import wcmatch from 'wildcard-match';

interface AdminMediasModalsContextType {
  editedImage?: Media;
  setEditedImage: (m?: Media) => void;
  // isVideoViewerOpen?: Media;
  // setVideoViewerOpen: (m?: Media) => void;
  // isPhotoViewerOpen?: Media;
  // setPhotoViewerOpen: (m?: Media) => void;
  isMultiMediasPickerOpen: boolean;
  setMultiMediasPickerOpen: (isOpen: boolean) => void;
  isMediasPickerOpen?: string;
  setMediasPickerOpen: (key?: string) => void;
  isMediaViewerOpen?: Media;
  setMediaViewerOpen: (m?: Media) => void;
  isRemoveMediaOpen?: Media;
  setRemoveMediaOpen: (m?: Media) => void;
  subscribeMediasPick: (
    key: string,
    callback: (medias: Media[]) => void,
  ) => () => void;
}

const AdminMediasModalsContext = createContext<AdminMediasModalsContextType>({
  setEditedImage: () => {},
  // setVideoViewerOpen: () => {},
  // setPhotoViewerOpen: () => {},
  isMultiMediasPickerOpen: false,
  setMultiMediasPickerOpen: () => {},
  setMediasPickerOpen: () => {},
  setMediaViewerOpen: () => {},
  setRemoveMediaOpen: () => {},
  subscribeMediasPick: () => () => {},
});

interface AdminMediasModalsContextProviderProps {
  children: React.ReactNode;
}

const EVENTKEY_MEDIAS_PICK = 'kustomcms:medias:pick';

type PickMediasEvent = CustomEvent<{
  medias: KustomMedia[];
  key: string | undefined;
}>;

const subscribeMediasPick = (
  key: string,
  callback: (medias: Media[]) => void,
) => {
  const isMatch = wcmatch(key);

  const listener = ((e: PickMediasEvent) => {
    if (key ? e.detail.key && isMatch(e.detail.key) : true) {
      callback(e.detail.medias);
    }
  }) as EventListener;

  document.addEventListener(EVENTKEY_MEDIAS_PICK, listener);

  return () => {
    document.removeEventListener(EVENTKEY_MEDIAS_PICK, listener);
  };
};

export const AdminMediasModalsContextProvider = (
  props: AdminMediasModalsContextProviderProps,
) => {
  const { children } = props;

  const dispatch = useAppDispatch();
  const toast = useToast();

  const [editedImage, setEditedImage] = useState<Media | undefined>();

  // const [isVideoViewerOpen, setVideoViewerOpen] = useState<Media | undefined>(
  //   undefined,
  // );
  // const [isPhotoViewerOpen, setPhotoViewerOpen] = useState<Media | undefined>(
  //   undefined,
  // );
  const [isMediaViewerOpen, setIsMediaViewerOpen] = useState<Media | undefined>(
    undefined,
  );

  const [isRemoveMediaOpen, setIsRemoveMediaOpen] = useState<Media | undefined>(
    undefined,
  );

  const [isMultiMediasPickerOpen, setIsMultiMediasPickerOpen] = useState(false);

  const [isMediasPickerOpen, setIsMediasPickerOpen] = useState<
    string | undefined
  >(undefined);

  const onPickMedias = useCallback(
    (newMedias: Media[]) => {
      document.dispatchEvent(
        new CustomEvent(EVENTKEY_MEDIAS_PICK, {
          detail: {
            medias: newMedias,
            key: isMediasPickerOpen,
          },
        }),
      );

      closeMediasPicker();
    },
    [isMediasPickerOpen],
  );

  const mediasUsage = useKustomSelector((state) => state.medias.mediasUsage);

  const closeMediasPicker = useCallback(() => {
    setIsMediasPickerOpen(undefined);
    setIsMultiMediasPickerOpen(false);
  }, []);

  const handleCloseEditImage = useCallback(() => {
    setEditedImage(undefined);
  }, []);

  // const handleCloseVideoViewer = useCallback(() => {
  //   setVideoViewerOpen(undefined);
  // }, []);

  // const handleClosePhotoViewer = useCallback(() => {
  //   setPhotoViewerOpen(undefined);
  // }, []);

  const handleRemove = () => {
    if (!isRemoveMediaOpen) return;

    dispatch(mediasActions.removeMedia({ file: isRemoveMediaOpen }))
      .unwrap()
      .then(() => {
        setIsRemoveMediaOpen(undefined);
        toast({
          position: 'bottom-right',
          duration: 3000,
          render: (props) => (
            <Toast
              {...props}
              duration={3000}
              title="MÉDIA SUPPRIMÉ"
              content="1 média supprimé de la galerie"
            />
          ),
        });
      });
  };

  return (
    <AdminMediasModalsContext.Provider
      value={{
        editedImage,
        isMediaViewerOpen,
        isRemoveMediaOpen,
        isMultiMediasPickerOpen,
        isMediasPickerOpen,
        setEditedImage,
        setMediasPickerOpen: setIsMediasPickerOpen,
        setMultiMediasPickerOpen: setIsMultiMediasPickerOpen,
        setMediaViewerOpen: setIsMediaViewerOpen,
        setRemoveMediaOpen: setIsRemoveMediaOpen,
        subscribeMediasPick,
      }}
    >
      {children}
      <ImageEditor
        image={editedImage}
        isOpen={!!editedImage}
        onClose={handleCloseEditImage}
      />
      {/* Modal video viewer */}
      {/* <VideoViewer video={isVideoViewerOpen} onClose={handleCloseVideoViewer} /> */}
      {/* Modal photo viewer */}
      {/* <Modal
        size="6xl"
        isOpen={!!isPhotoViewerOpen}
        onClose={handleClosePhotoViewer}
      >
        <ModalOverlay />
        <ModalContent
          maxW="90%"
          height={'90%'}
          overflow="hidden !important"
          mt={10}
        >
          <ModalCloseButton size="lg" />
          <ModalBody
            backgroundImage={'url("' + isPhotoViewerOpen?.url + '")'}
            backgroundSize={'contain'}
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            mx={7}
            mr={14}
            my={4}
          ></ModalBody>
        </ModalContent>
      </Modal> */}
      {/* Modal remove media */}
      <Modal
        size="3xl"
        isOpen={!!isRemoveMediaOpen}
        onClose={() => setIsRemoveMediaOpen(undefined)}
      >
        <ModalOverlay />
        <ModalContent>
          <AdminThemeProvider>
            <ModalCloseButton />
            <ModalBody mr={7} my={4}>
              <Heading
                mt={2}
                mb={4}
                fontSize="30px"
                as="h4"
                textStyle="heading"
              >
                Attention
              </Heading>
              <Text mb={3} fontSize="18px" textStyle="brand">
                Êtes-vous sur de vouloir supprimer ce média de la galerie ?
              </Text>
              {isRemoveMediaOpen &&
                {
                  [MediaType.IMAGE]: (
                    <Box
                      w="100%"
                      h="280px"
                      backgroundImage={'url("' + isRemoveMediaOpen.url + '")'}
                      backgroundSize="cover"
                      backgroundRepeat="no-repeat"
                      backgroundPosition="center"
                      position="relative"
                      borderRadius="base"
                    ></Box>
                  ),
                  [MediaType.VIDEO]: (
                    <Box
                      position="relative"
                      overflow="hidden"
                      w="100%"
                      h="280px"
                      borderRadius="base"
                    >
                      <video
                        style={{
                          zIndex: 0,
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%,-50%)',
                          objectFit: 'cover',
                          borderRadius: theme.radii.base as string,
                        }}
                      >
                        <source src={isRemoveMediaOpen.url} />
                      </video>
                    </Box>
                  ),
                  [MediaType.PDF]: null,
                  [MediaType.APP]: null,
                  [MediaType.UNKNOWN]: null,
                }[getMediaType(isRemoveMediaOpen.url)]}
              {mediasUsage &&
              Object.values(
                mediasUsage[isRemoveMediaOpen?.metadata._id || ''] || {},
              ).length ? (
                <Box bgColor="gray.100" p={4} textAlign="center" mt={4}>
                  <Text color="gray.500">Média utilisé sur le site</Text>
                </Box>
              ) : null}
              <Box mt={4}>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  px={10}
                  size="xl"
                  onClick={() => setIsRemoveMediaOpen(undefined)}
                >
                  ANNULER
                </Button>
                <Button
                  px={10}
                  ml={2}
                  size="xl"
                  onClick={handleRemove}
                  colorScheme="brand"
                >
                  SUPPRIMER
                </Button>
              </Box>
            </ModalBody>
          </AdminThemeProvider>
        </ModalContent>
      </Modal>
      <MediasPicker
        isOpen={isMultiMediasPickerOpen || !!isMediasPickerOpen}
        singleMedia={!isMultiMediasPickerOpen}
        onClose={closeMediasPicker}
        onPickMedias={(newMedias) => {
          onPickMedias(newMedias);
          closeMediasPicker();
        }}
      />
      <MediaViewer
        media={isMediaViewerOpen}
        onClose={() => setIsMediaViewerOpen(undefined)}
      />
    </AdminMediasModalsContext.Provider>
  );
};

export default AdminMediasModalsContext;
