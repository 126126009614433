import {
  Box,
  Button,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import AdminThemeProvider from '../AdminThemeProvider';
import IconTrash from '@/lib/kustomcms-sdk/lib/icons/Trash.svg';
import Input from '../atomics/Input';
import { mediasActions } from '../../features/medias/slice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import useTheme from '../../hooks/useTheme';

interface EditMediaTagsProps {
  isOpen: boolean;
  onClose: () => void;
  tags: string[];
}

const EditMediaTags = (props: EditMediaTagsProps) => {
  const { isOpen, onClose, tags } = props;
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [editedTags, setEditedTags] = useState(tags);
  const [tagToRm, setTagToRm] = useState<string | undefined>(undefined);

  useEffect(() => {
    setEditedTags(tags);
  }, [tags]);

  const editTag = (index: number, tag: string) => {
    const _editedTags = [...editedTags];
    _editedTags[index] = tag;
    setEditedTags(_editedTags);
  };

  const isModified = !(
    tags.length === editedTags.length &&
    tags.every((value, index) => value === editedTags[index])
  );

  const resetAndClose = () => {
    setEditedTags(tags);
    onClose();
  };

  const handleRemove = () => {
    if (!tagToRm) return;

    const index = tags.findIndex((t) => t === tagToRm);

    if (index > -1) {
      const newTags = [...tags];
      newTags.splice(index, 1);
      dispatch(mediasActions.updateTags({ tags: newTags }));
    }
    setTagToRm(undefined);
  };

  const saveNewTags = () => {
    tags.forEach((tag, index) => {
      if (editedTags[index] !== tag) {
        dispatch(
          mediasActions.updateTagInMediasMetadata({
            tag,
            nextTag: editedTags[index] || '',
          }),
        );
      }
    });
    dispatch(mediasActions.updateTags({ tags: editedTags }));
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent pt={8}>
          <AdminThemeProvider>
            <ModalCloseButton />
            <ModalBody display="flex">
              <Box display="flex" flexDir="column" px={4}>
                <Heading mb={1} fontSize="32px" textStyle="heading">
                  Editer les thématiques
                </Heading>
                <Text textStyle="brand" mb={8}>
                  Vous pouvez renommer et supprimer les thématiques existantes.
                </Text>
                <Box>
                  {editedTags.map((tag: string, index: number) => (
                    <Box key={index} display="flex" width="100%" mb={1}>
                      <Input
                        size="lg"
                        fontSize={'16px'}
                        isFullWidth
                        mr={1}
                        value={tag.toUpperCase()}
                        onChange={(e) => {
                          editTag(index, e.target.value);
                        }}
                      />
                      <IconButton
                        size="lg"
                        backgroundColor="backgroundSecondary"
                        aria-label="remove translation"
                        variant="ghost"
                        onClick={() => setTagToRm(tag)}
                        icon={
                          <IconTrash
                            // @ts-ignore
                            fill={theme.colors.brand[900]}
                            width={12}
                          />
                        }
                        p={3}
                        py={0}
                      />
                    </Box>
                  ))}
                </Box>
                <Box mt={4} mb={5}>
                  <Button
                    mr={2}
                    px={8}
                    variant="outline"
                    onClick={resetAndClose}
                    colorScheme="gray"
                  >
                    ANNULER
                  </Button>
                  <Button
                    px={8}
                    onClick={saveNewTags}
                    isDisabled={!isModified}
                    colorScheme="brand"
                  >
                    SAUVEGARDER
                  </Button>
                </Box>
              </Box>
            </ModalBody>
          </AdminThemeProvider>
        </ModalContent>
      </Modal>
      <Modal isOpen={!!tagToRm} onClose={() => setTagToRm(undefined)} size="xl">
        <ModalOverlay />
        <ModalContent pt={8}>
          <AdminThemeProvider>
            <ModalCloseButton />
            <ModalBody>
              <Text textStyle="brand" mb={8}>
                Voulez vous vraiment supprimer la thématique &quot;{tagToRm}
                &quot; ?
              </Text>
              <Box display="flex" mt={4} mb={5}>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  px={10}
                  onClick={() => setTagToRm(undefined)}
                >
                  ANNULER
                </Button>
                <Button px={10} ml={2} onClick={handleRemove}>
                  SUPPRIMER
                </Button>
              </Box>
            </ModalBody>
          </AdminThemeProvider>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditMediaTags;
