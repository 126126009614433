import {
  ActionButtonComponent,
  CustomComponentData,
  KustomPage,
  KustomPageComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
  MediaComponent,
} from '@/lib/kustomcms-sdk/lib/types';
import { Box, Container, Grid, GridItem, chakra } from '@chakra-ui/react';
import useResponsiveMediasDevice, {
  ResponsiveMediaDeviceKeys,
} from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';

import ActionButton from '@/lib/kustomcms-sdk/lib/components/ActionButton';
import AnimatePresence from '../atomics/AnimatePresence';
import MediasViewerContext from '@/contexts/MediasViewerContext';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import getCSSAdminWysiwygItem from '@/lib/kustomcms-sdk/lib/admin/helpers/getCSSAdminWysiwygItem';
import { useContext } from 'react';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { useKustomSelector } from '@/lib/kustomcms-sdk/lib/admin/hooks/useKustomSelector';

const Section5 = (props: KustomPageComponentViewProps<CustomComponentData>) => {
  const { page, component } = props;

  const [text1Index, text2Index, text3Index] = component.data.atomicComponents
    .map((cmp, index) => (cmp.type === 'TEXT' ? index : undefined))
    .filter((i) => i !== undefined) as number[];
  const mediasIndex = component.data.atomicComponents
    .map((cmp, index) =>
      cmp.type === 'MEDIA'
        ? { index, id: cmp.id, media: (cmp as MediaComponent).media }
        : undefined,
    )
    .filter((i) => i) as {
    index: number;
    id: string;
    media: KustomResponsiveMedia;
  }[];
  const buttonIndex = component.data.atomicComponents.findIndex(
    (cmp) => cmp.type === 'ACTION_BUTTON',
  );

  const text1Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${text1Index}.text`,
  );

  const text2Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${text2Index}.text`,
  );

  const text3Bind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.atomicComponents.${text3Index}.text`,
  );

  const buttonBind = useKustomData<ActionButtonComponent>(
    page,
    component,
    `data.atomicComponents.${buttonIndex}`,
  );

  const currentDevice = useResponsiveMediasDevice();

  const mediasViewer = useContext(MediasViewerContext);

  const openMediaViewer = (index: number) => {
    if (mediasIndex.length) {
      mediasViewer.setMedias(mediasIndex.map((m) => m.media));
      mediasViewer.setIndex(index);
    }
  };

  return (
    <Box
      bgColor="accent.100"
      py={[10, null, 28, null, null, 36]}
      pb={[20, null, 28, null, null, 36]}
    >
      <Container
        maxW={['container.xl', null, null, null, null, '1780px']}
        display="flex"
        flexDir={['column', null, null, 'row']}
        px={[6, null, 20]}
      >
        <Box flex={[null, null, 2]} pr={[0, null, 20]} mb={[8, null, 14, null]}>
          <AnimatePresence x={-60} opacity>
            {text1Bind.value && (
              <RichText
                fontFamily="heading"
                lineHeight={0.9}
                color="brand.500"
                mb={[4, null, 6, null, null, 10]}
                mt={10}
                fontSize={['38px', null, '96px', null, null, '120px']}
                {...text1Bind}
                as="h2"
              />
            )}
            {text2Bind.value && (
              <RichText
                fontSize={['18px', null, '29px', null, null, '36px']}
                color="brand.500"
                fontFamily="jhaSemilight"
                mb={[6, null, 10]}
                {...text2Bind}
                as="p"
              />
            )}
            {text3Bind.value && (
              <RichText
                fontFamily="jha"
                color="brand.500"
                fontSize={['18px', null, '29px', null, null, '36px']}
                {...text3Bind}
                mb={[4, null, 8]}
                as="h3"
              />
            )}
            {buttonBind.value && (
              <ActionButton
                {...buttonBind}
                rightIcon={
                  <chakra.span
                    fontSize={['42px', null, '56px']}
                    className="icon-elmes-arrow1-white"
                  />
                }
                variant="brand"
                size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
              />
            )}
          </AnimatePresence>
        </Box>
        <Grid
          flex={[null, null, null, 3]}
          maxW="976px"
          h={['340px', null, '400px', '1120px']}
          templateRows="repeat(10, 1fr)"
          templateColumns="repeat(2, 1fr)"
          gap={4}
        >
          {mediasIndex.map(({ id, index: mediaIndex }, index) => (
            <Section5GridItem
              page={page}
              component={component}
              index={index}
              mediaIndex={mediaIndex}
              key={id}
              currentDevice={currentDevice}
              onClick={() => openMediaViewer(index)}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

interface Section5GridItemProps {
  page: KustomPage;
  component: KustomPageComponent<CustomComponentData>;
  index: number;
  mediaIndex: number;
  currentDevice: ResponsiveMediaDeviceKeys;
  onClick?: () => void;
}

const Section5GridItem: React.FC<Section5GridItemProps> = (props) => {
  const { page, component, mediaIndex, index, currentDevice, onClick } = props;

  const mediaBind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.atomicComponents.${mediaIndex}.media`,
  );

  const { user } = useKustomSelector((state) => state.app);

  const hasWysiwygEdit = !!(user && mediaBind.onChange);

  return (
    <GridItem
      rowSpan={[7, 3, 3][index]}
      colSpan={[2, 1, 1][index]}
      position="relative"
      {...getCSSAdminWysiwygItem({
        hasWysiwygEdit,
        isWysiwygEditOpen: false,
        setIsOpen: () => {},
        useNativeBorder: true,
      })}
      p={0}
      overflow="hidden"
    >
      <Box onClick={onClick} h="100%">
        <AnimatePresence
          opacity
          // y={[-60, 60, 0][index]}
          // x={[0, 0, 60][index]}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ResponsiveMedias
            {...mediaBind}
            disableCSSAdminWysiwyg
            currentDevice={currentDevice}
            style={{
              objectFit: 'cover',
              borderRadius: 4,
            }}
            sizes="50vw"
            fill
            width={undefined}
            height={undefined}
          />
        </AnimatePresence>
      </Box>
    </GridItem>
  );
};

export default Section5;
