import { useEffect, useMemo } from 'react';

const useDisablePageScroll = (disableIf: boolean) => {
  const windowTypeof = typeof window;

  const scrollWidth = useMemo(() => {
    if (windowTypeof !== 'undefined') {
      return getScrollbarWidth();
    }
    return 0;
  }, [windowTypeof]);

  useEffect(() => {
    const rootEl = document.querySelector<HTMLElement>(':root');

    if (disableIf) {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${scrollWidth}px`;
      rootEl?.style.setProperty(
        '--display-page-scroll-shift',
        `${scrollWidth}px`,
      );
    } else {
      document.body.style.overflow = 'unset';
      document.body.style.paddingRight = '0px';
      rootEl?.style.setProperty('--display-page-scroll-shift', '0px');
    }
  }, [disableIf, scrollWidth]);
};

// function getScrollbarWidth() {
//   const outer = document.createElement('div');
//   outer.style.visibility = 'hidden';
//   outer.style.overflow = 'auto';
//   (outer.style as any).msOverflowStyle = 'scrollbar';
//   document.body.appendChild(outer);

//   const inner = document.createElement('div');
//   outer.appendChild(inner);

//   const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

//   outer.parentNode?.removeChild(outer);

//   return scrollbarWidth;
// }

function getScrollbarWidth() {
  var inner = document.createElement('p');
  inner.style.width = '100%';
  inner.style.height = '200px';

  var outer = document.createElement('div');
  outer.style.position = 'absolute';
  outer.style.top = '0px';
  outer.style.left = '0px';
  outer.style.visibility = 'hidden';
  outer.style.width = '200px';
  outer.style.height = '150px';
  outer.style.overflow = 'hidden';
  outer.appendChild(inner);

  document.body.appendChild(outer);
  var w1 = inner.offsetWidth;
  outer.style.overflow = 'scroll';
  var w2 = inner.offsetWidth;
  if (w1 == w2) w2 = outer.clientWidth;

  document.body.removeChild(outer);

  return w1 - w2;
}

export default useDisablePageScroll;
