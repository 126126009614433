import { Box, Button, Text } from '@chakra-ui/react';
import React, { forwardRef, useMemo, useRef, useState } from 'react';

import type { BoxProps } from '@chakra-ui/react';

interface Props extends BoxProps {
  children: React.ReactNode;
  noOfLines: number;
}

const ExpandableText = forwardRef<HTMLDivElement, Props>(
  ({ children, noOfLines, ...rest }, ref) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const handleToggle = () => {
      setIsExpanded((ie) => !ie);
    };

    const [inputRef, setInputRef] = useState<HTMLDivElement | null>(null);

    const isTextClamped = useMemo(
      () => (inputRef ? inputRef?.clientHeight < inputRef?.scrollHeight : true),
      [inputRef],
    );

    return (
      <Box ref={ref} {...rest}>
        <Box
          ref={setInputRef}
          overflow="hidden"
          textOverflow={isExpanded ? 'unset' : 'ellipsis'}
          display="-webkit-box"
          style={{
            WebkitLineClamp: isExpanded ? 'unset' : noOfLines,
            WebkitBoxOrient: 'vertical',
            lineClamp: isExpanded ? 'unset' : noOfLines,
          }}
        >
          {children}
        </Box>
        <Button
          mt={2}
          display={isTextClamped ? 'block' : 'none'}
          size="sm"
          variant="link"
          onClick={handleToggle}
        >
          <Text>{isExpanded ? 'Cacher' : 'En savoir plus'}</Text>
        </Button>
      </Box>
    );
  },
);

ExpandableText.displayName = 'ExpandableText';

export default ExpandableText;
