import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { Box, ChakraProps, Text, chakra } from '@chakra-ui/react';
import { useContext } from 'react';

type ContactDiadaoProps = ChakraProps & {};

export function ContactDiadao({ ...props }: Readonly<ContactDiadaoProps>) {
  const { settings } = useContext(SettingsContext);

  const phoneNumber = settings?.informations?.phone;
  const mail = settings?.informations?.mainEmail;

  const titleBind = useKustomTranslation('contact_a_question_a_problem');
  const orBind = useKustomTranslation('contact_or');

  return (
    <Box fontFamily="samsungOne" fontSize="12px" textAlign="center" {...props}>
      <TranslatedString
        color="gray.800"
        fontWeight="bold"
        {...titleBind}
        as="div"
      />
      <Box mt={0.5}>
        <Text color="gray.800" as="div">
          {phoneNumber && (
            <chakra.a
              href={'tel:' + phoneNumber}
              color="gray.800"
              textDecoration="underline !important"
            >
              {phoneNumber}
            </chakra.a>
          )}
          {phoneNumber && mail && (
            <>
              {' '}
              <TranslatedString as="span" display="inline" {...orBind} />{' '}
            </>
          )}
          {mail && (
            <chakra.a
              href={'mailto:' + mail}
              color="gray.800"
              whiteSpace="nowrap"
              textDecoration="underline !important"
            >
              {mail}
            </chakra.a>
          )}
        </Text>
      </Box>
    </Box>
  );
}
