import { AppDispatch, AppThunkAPI, StoreState } from '../../store';
import { Media, MediaMutableMetadata, mediasActions } from '../medias/slice';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFileImageDimensions, getVideoDuration } from '../../helpers/media';

import { getUserCurrentEstablishment } from '../../helpers/establishments';

export interface FileWithId {
  id: Number;
  file: File;
}
export interface UploadState {
  error?: string;
  pendingUploads: number;
  failedUploads: FileWithId[];
}

const initialState: UploadState = {
  pendingUploads: 0,
  failedUploads: [],
};

export const uploadThunks = {
  uploadFile: createAsyncThunk<
    { success: boolean },
    {
      file: File;
      mutableMetadata?: Partial<MediaMutableMetadata>;
      isReupload?: Number;
      isFramingOf?: string;
    },
    AppThunkAPI
  >(
    'upload/uploadFile',
    async (
      { file, isFramingOf, mutableMetadata: argMutableMeta },
      thunkAPI,
    ) => {
      try {
        const metadata: { [key: string]: string } = {};
        const mutableMetadata = argMutableMeta || {};

        const type = file.type.split('/')[0];

        if (type === 'image') {
          const [width, height] = await getFileImageDimensions(file);
          metadata.width = '' + width;
          metadata.height = '' + height;
        }

        if (type === 'video') {
          const duration = await getVideoDuration(file);
          metadata.duration = `${Math.floor(duration / 60)}m ${Math.floor(
            duration % 60,
          )}sec`;
          mutableMetadata.autoplay = 'false';
        }

        // get presigned url
        const { url, filename: uploadFilename } = await thunkAPI.extra
          .feathersApp!.service('upload')
          .create({
            filename: file.name,
            size: file.size,
            metadata,
            mutableMetadata,
            isFramingOf,
          });

        // upload to presigned url
        const res = await fetch(url, {
          method: 'PUT',
          body: file,
        });

        // thumbnify image
        if (type === 'image') {
          const user = thunkAPI.getState().app.user;
          const bucketName = getUserCurrentEstablishment(user)?._id;
          const filename = uploadFilename.replace('/', '%2F');
          await fetch(
            `${thunkAPI.extra.config.API_URL}/medias/thumbnify/${bucketName}/${filename}`,
            {
              method: 'POST',
            },
          );
        }

        // reload medias
        await thunkAPI
          .dispatch(
            mediasActions.getEstablishmentMedias({ disableLoading: true }),
          )
          .unwrap();

        return { success: res.ok };
      } catch (err: any) {
        throw thunkAPI.rejectWithValue(err.message);
      }
    },
  ),
  cropAndUploadImage: createAsyncThunk<
    { success: boolean },
    {
      filename: string;
      croppedFilename: string;
      crop: { x: number; y: number; width: number; height: number };
      mutableMetadata?: Partial<MediaMutableMetadata>;
      isFramingOf?: string;
    },
    AppThunkAPI
  >(
    'upload/cropAndUploadImage',
    async (
      {
        filename,
        croppedFilename,
        crop,
        isFramingOf,
        mutableMetadata: argMutableMeta,
      },
      thunkAPI,
    ) => {
      try {
        const metadata: { [key: string]: string } = {};
        const mutableMetadata = argMutableMeta || {};

        metadata.width = '' + crop.width;
        metadata.height = '' + crop.height;

        const user = thunkAPI.getState().app.user;
        const bucketName = getUserCurrentEstablishment(user)?._id;

        const auth = await thunkAPI.extra.feathersApp!.get('authentication');

        const _filename = filename.replace('/', '%2F');
        const res = await fetch(
          `${thunkAPI.extra.config.API_URL}/medias/crop-and-upload/${bucketName}/${_filename}`,
          {
            method: 'POST',
            body: JSON.stringify({
              crop,
              croppedFilename,
              metadata,
              mutableMetadata,
              isFramingOf,
            }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${auth.accessToken}`,
            },
          },
        );

        if (!res.ok) {
          throw new Error(res.statusText);
        }

        // thumbnify image
        await fetch(
          `${thunkAPI.extra.config.API_URL}/medias/thumbnify/${bucketName}/${_filename}`,
          {
            method: 'POST',
          },
        );

        // reload medias
        await thunkAPI
          .dispatch(
            mediasActions.getEstablishmentMedias({ disableLoading: true }),
          )
          .unwrap();

        return { success: res.ok };
      } catch (err: any) {
        throw thunkAPI.rejectWithValue(err.message);
      }
    },
  ),
  uploadUserFile: createAsyncThunk<
    Media,
    {
      file: File;
    },
    AppThunkAPI
  >('upload/uploadUserFile', async ({ file }, thunkAPI) => {
    try {
      const metadata: { [key: string]: string } = {};
      const mutableMetadata: { [key: string]: string } = {};

      const type = file.type.split('/')[0];
      const user = thunkAPI.getState().app.user;

      if (type === 'image') {
        const [width, height] = await getFileImageDimensions(file);
        metadata.width = '' + width;
        metadata.height = '' + height;
      }

      if (type === 'video') {
        const duration = await getVideoDuration(file);
        metadata.duration = `${Math.floor(duration / 60)}m ${Math.floor(
          duration % 60,
        )}sec`;
        mutableMetadata.autoplay = 'false';
      }

      // get presigned url
      const { url, filename: newFilename } = await thunkAPI.extra
        .feathersApp!.service('upload')
        .create({
          filename: file.name,
          size: file.size,
          metadata,
          mutableMetadata,
          isUserUpload: true,
        });

      // upload to presigned url
      await fetch(url, {
        method: 'PUT',
        body: file,
      });

      // thumbnify image
      if (type === 'image') {
        const bucketName = user?._id;
        const filename = newFilename.replace('/', '%2F');
        await fetch(
          `${thunkAPI.extra.config.API_URL}/medias/thumbnify/${bucketName}/${filename}`,
          {
            method: 'POST',
          },
        );
      }

      // get media and return
      const resMediaMeta = await thunkAPI.extra
        .feathersApp!.service('media-metadata')
        .find({
          query: {
            bucketName: user?._id,
            key: file.name,
          },
        });

      const metaId = resMediaMeta[0]?._id;

      const resMedias = await thunkAPI.extra
        .feathersApp!.service('medias')
        .find({
          query: {
            ids: [metaId],
          },
        });
      return resMedias[0];
    } catch (err: any) {
      throw thunkAPI.rejectWithValue(err.message);
    }
  }),
};

export const uploadSlice = createSlice({
  name: 'medias',
  initialState,
  reducers: {
    // truc: (state, action: PayloadAction<string>) => {},
    removeFailedUpload: (
      state,
      action: PayloadAction<{ file: FileWithId }>,
    ) => {
      state.failedUploads = state.failedUploads.filter(
        (f) => f.id !== action.payload.file.id,
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadThunks.uploadFile.pending, (state, action) => {
        if (action.meta.arg.isReupload) {
          state.failedUploads = [...state.failedUploads].filter(
            (file) => file.id !== action.meta.arg.isReupload,
          );
        }
        if (!action.meta.arg.isFramingOf) {
          state.pendingUploads += 1;
        }
      })
      .addCase(uploadThunks.uploadFile.rejected, (state, action) => {
        if (!action.meta.arg.isFramingOf) {
          state.pendingUploads -= 1;

          if (action.payload !== 'INVALID_FILE_EXTENSION') {
            state.failedUploads.push({
              id: Date.now(),
              file: action.meta.arg.file,
            });
          }
        }
        state.error = action.payload as string;
      })
      .addCase(uploadThunks.uploadFile.fulfilled, (state, action) => {
        if (!action.meta.arg.isFramingOf) {
          state.pendingUploads -= 1;
        }
      });
  },
});

export const uploadActions = { ...uploadSlice.actions, ...uploadThunks };

export default uploadSlice.reducer;
