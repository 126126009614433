'use client';

import {
  ActionButtonComponent,
  CarouselComponentData,
  KustomTranslatedString,
  TextCarouselComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import {
  Box,
  Button,
  Fade,
  IconButton,
  SlideFade,
  chakra,
  useMediaQuery,
} from '@chakra-ui/react';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';

import { $kustomAdminBarHeightVarName } from '@/lib/kustomcms-sdk/lib/admin/components/KustomAuthModal';
import IconButtonSwitchIcon from './atomics/IconButtonSwitchIcon';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import { LanguageContextKey } from '@/lib/kustomcms-sdk/lib/contexts/language';
import Link from 'next/link';
import LogoElmesColor from '@/../public/assets/img/logo-elmes-original-complet.svg';
import LogoElmesWhite from '@/../public/assets/img/logo-elmes-white-complet.svg';
// import Logo from '@/../public/images/logo.svg';
import MenuModal from './MenuModal';
import ReservationDrawer from './ReservationDrawer';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useDelay from '@/hooks/useDelay';
import useDisablePageScroll from '@/hooks/useDisablePageScroll';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import useNavigationKustomHref from '@/lib/kustomcms-sdk/lib/hooks/useNavigationKustomHref';
import usePage from '@/lib/kustomcms-sdk/lib/hooks/usePage';
import usePageComponent from '@/lib/kustomcms-sdk/lib/hooks/usePageComponent';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useRouter } from 'next/router';

export const TOPBAR_HEIGHT = 100;

const EXTRA_MENU_HEIGHT = '90px';

const hoveredMenuItemStyle = {
  textDecoration: 'underline',
  textDecorationColor: 'accent.500 !important',
  textUnderlineOffset: '10px',
  textDecorationThickness: '3px',
};

const ChakraLink = chakra(Link);

type Languages = {
  [key: string]: { label: string };
  en: { label: string };
  fr: { label: string };
  es: { label: string };
};

const langs: Languages = {
  fr: { label: 'Français' },
  en: { label: 'English' },
  es: { label: 'Español' },
  de: { label: 'Deutsch' },
};

const Menu = ({ isVisible = true }: { isVisible?: boolean }) => {
  const router = useRouter();
  const { locale, locales } = router;
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredMenuLabel, setHoveredMenuLabel] =
    useState<KustomTranslatedString>({
      fr: '',
    });
  const [isMenuMixBlended, setIsMenuMixBlended] = useState(false);

  const [isReservationOpen, setIsReservationOpen] = useState(false);

  const menuModalContainerRef = useRef<HTMLDivElement>(null);

  const menuPage = usePage('vt1wuk');

  const menu1Cmp = usePageComponent<TextCarouselComponentData>(
    menuPage,
    null,
    '1690313260958793',
  );
  const menu2Cmp = usePageComponent<TextCarouselComponentData>(
    menuPage,
    null,
    '16903663926835705',
  );

  const extraMenuCmp = usePageComponent<CarouselComponentData>(
    menuPage,
    null,
    '16903703402474730',
  );

  const settingsCtx = useContext(SettingsContext);

  const giftUrl =
    settingsCtx?.settings?.modules?.extraFields?.['widgetGiftUrl']?.value;

  const menu = [menu1Cmp, menu2Cmp].map((cmp) => ({
    label: cmp?.data?.textTitle,
    routes: cmp?.data.slides.map((slide) => ({
      label: slide.title,
      href: slide.subtitle,
      media: slide.medias,
      routes: (
        slide.atomicComponents?.filter(
          (cmp) => cmp.type === 'ACTION_BUTTON',
        ) as ActionButtonComponent[]
      )?.map((cmp) => ({
        label: cmp.label,
        href: cmp.actionString,
        isExternalLink: cmp.actionString?.startsWith('http'),
      })),
    })),
  }));

  const extraMenu = extraMenuCmp?.data?.slides.map((slide) => ({
    label: slide.title,
    href: slide.subtitle,
  }));

  const closeReservation = () => {
    setIsReservationOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      menuModalContainerRef.current?.scrollTo(0, 0);
    }
  }, [isOpen]);

  const language = useContext(LanguageContext);

  const [isLarge] = useMediaQuery('(min-width: 48em)');

  useScrollPosition(({ currPos }) => {
    setIsMenuMixBlended(-currPos.y > 10);
  });

  const delayIndex = useDelay(1000, 2);

  const breakpointIndex = useBreakpointIndex();

  useDisablePageScroll(isOpen);

  const toBookLabelBind = useKustomTranslation('to_book');

  const open = (itemLabel?: KustomTranslatedString) => {
    setIsOpen(true);
    setIsOpenLangsMenu(false);
    if (itemLabel) {
      setHoveredMenuLabel(itemLabel);
      // setCurrentMenuMedia(
      //   menu.find((item) => item.label === itemLabel)?.routes[0].media || null,
      // );
    }
  };

  const close = () => {
    setIsOpen(false);
    setHoveredMenuLabel({
      fr: '',
    });
  };

  const toggle = () => {
    if (isOpen) {
      close();
    } else {
      open();
    }
  };

  const isActive = isOpen || isMenuMixBlended;

  const [isOpenLangsMenu, setIsOpenLangsMenu] = useState(false);

  return (
    <>
      <Box
        position={'relative'}
        zIndex={1004}
        id="menu"
        visibility={isVisible ? 'visible' : 'hidden'}
        pointerEvents={isVisible ? 'auto' : 'none'}
      >
        {/* BLACK OVERLAY */}
        <Box zIndex={2} position="relative">
          <Fade
            in={isOpen}
            unmountOnExit
            style={{
              height: '100%',
            }}
          >
            <Box
              position="fixed"
              top="0"
              right="0"
              left="0"
              bottom="0"
              bgColor="black"
              width={'100%'}
              height={'100%'}
              opacity={0.7}
              onClick={close}
              onMouseEnter={close}
            ></Box>
          </Fade>
        </Box>
        {/* SOCIAL NETWORKS */}
        <Box
          position="fixed"
          left={[5, null, 6, null, null, 5]}
          bottom={[5, null, 6, null, null, 7]}
          fontSize={['14px', null, '18px', null, null, '20px']}
          display={['none', null, 'flex']}
          mixBlendMode={isMenuMixBlended ? 'difference' : 'normal'}
          gap="1rem"
        >
          <Link
            href="https://www.instagram.com/leselmes_hotelspa/"
            target={'_blank'}
            aria-label="Instagram"
          >
            <chakra.span
              _before={{ color: 'white' }}
              className="icon-diadao-social-instagram"
            />
          </Link>
          <Link
            href="https://www.facebook.com/lalittorine.leselmes"
            target={'_blank'}
            aria-label="Facebook"
          >
            <chakra.span
              _before={{ color: 'white' }}
              className="icon-diadao-social-facebook"
            />
          </Link>
        </Box>
        {/* TOPBAR */}
        <Box
          zIndex={3}
          className="main-topbar"
          position="fixed"
          top={`var(${$kustomAdminBarHeightVarName}, 0)`}
          h={TOPBAR_HEIGHT}
          py={[4, null, 6]}
          pl={['10px', null, '20px']}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          w="100%"
          bgColor={isActive ? 'white' : undefined}
          height={['80px', null, '100px']}
          transition="background-color 0.2s"
          boxShadow={isActive && !isOpen ? 'sm' : undefined}
          pr={'calc(20px + var(--display-page-scroll-shift))'}
        >
          <SlideFade
            in={delayIndex > 0}
            offsetY={-60}
            transition={{ enter: { duration: 0.3 } }}
          >
            <Box display="flex" alignItems="center">
              {/* MOBILE BURGER ICON */}
              <IconButton
                display={['block', null, 'none']}
                aria-label="Open menu"
                colorScheme="brand"
                bgColor={'transparent'}
                width={['42px', null, '58px']}
                height={['42px', null, 'unset']}
                py={[0, null, 6]}
                mr={2}
                fontWeight="bold"
                _hover={{}}
                fontSize={isOpen ? '20px' : '14px'}
                onClick={toggle}
                icon={
                  <chakra.span
                    _before={{
                      color: isActive ? 'brand.500' : 'white',
                    }}
                    className={
                      isOpen ? 'icon-diadao-cross2' : 'icon-diadao-menu5'
                    }
                  ></chakra.span>
                }
              />
              <Box display="flex">
                <Box display={['none', null, 'flex']}>
                  {menu.map((item, index) => (
                    <Box
                      key={'menu' + index}
                      role="group"
                      onMouseOver={isLarge ? () => open(item.label) : undefined}
                      // onMouseLeave={isLarge ? close : undefined}
                    >
                      <TranslatedString
                        color={isActive ? 'brand.500' : 'white'}
                        px={[null, null, 4, 5]}
                        fontFamily="body"
                        py={3}
                        cursor="pointer"
                        fontWeight="bold"
                        fontSize={[null, null, '15px', '16px', null, '20px']}
                        {...(hoveredMenuLabel === item.label
                          ? hoveredMenuItemStyle
                          : undefined)}
                        as="div"
                      >
                        {item.label}
                      </TranslatedString>
                    </Box>
                  ))}
                </Box>

                {locales && locales?.length > 1 && (
                  <Box
                    position={'relative'}
                    ml={[0, 0, 1, 3]}
                    pl={[0, 0, 1, 3]}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    sx={{
                      '&:before': {
                        content: '""',
                        position: 'absolute',
                        left: '0',
                        width: '1px',
                        height: '42px',
                        backgroundColor: `${
                          isActive ? 'gray.300' : 'rgba(255,255,255,0.25)'
                        }`,
                      },
                    }}
                  >
                    <Button
                      fontFamily="body"
                      fontSize={['16px', null, '15px', '16px', null, '20px']}
                      px={4}
                      py={6}
                      fontWeight="700"
                      _hover={{
                        bgColor: 'transparent',
                      }}
                      bgColor={'transparent'}
                      color={isActive ? 'brand.500' : 'white'}
                      transition="all 0.3s"
                      onClick={() => {
                        setIsOpenLangsMenu(!isOpenLangsMenu);
                      }}
                      cursor={'pointer'}
                    >
                      {locale?.toUpperCase()}
                    </Button>
                    <Box
                      position={'absolute'}
                      background={isOpen ? 'brand.500' : 'white'}
                      top={'120%'}
                      px={6}
                      py={4}
                      color={isOpen ? 'white' : 'brand.500'}
                      display={'flex'}
                      flexDirection={'column'}
                      borderRadius={'4px'}
                      opacity={isOpenLangsMenu ? '1' : '0'}
                      pointerEvents={isOpenLangsMenu ? 'auto' : 'none'}
                      transition={'opacity ease 200ms'}
                      boxShadow="sm"
                      cursor={'pointer'}
                      sx={{
                        '& > *:not(:first-child)': {
                          borderTop: '1px solid',
                          borderColor: `${isOpen ? 'brand.400' : 'gray.300'}`,
                        },
                      }}
                    >
                      {locales.map((lang, index) => (
                        <Fragment key={lang + index}>
                          {lang !== locale ? (
                            // <ChakraLink
                            //   href={'/'}
                            //   locale={lang}
                            //   key={lang}
                            //   p={2}
                            //   onClick={() => {
                            //     window.location.href = `/${lang}`;
                            //     setIsOpenLangsMenu(false);
                            //   }}
                            // >
                            //   {langs?.[lang]?.label || lang}
                            // </ChakraLink>
                            <Box
                              key={lang + index}
                              p={2}
                              onClick={() => {
                                window.location.href = `/${lang}`;
                                setIsOpenLangsMenu(false);
                              }}
                            >
                              {langs?.[lang]?.label || lang}
                            </Box>
                          ) : null}
                        </Fragment>
                      ))}
                    </Box>
                  </Box>
                )}

                {/* <SideSelect
                options={langs.map((lang) => lang.key)}
                currentValue={language.currentLang || 'fr'}
                onValueChange={(value) => {
                  // language.setCurrentLang(value as LanguageContextKey);
                }}
                border="1px solid transparent"
                borderRadius="base"
                _hover={{
                  bgColor: isActive ? 'white' : 'rgba(255,255,255,0.2)',
                  borderColor: isActive ? 'brand.100' : 'transparent',
                  color: isActive ? 'brand.100' : 'white',
                }}
                fontFamily="body"
                textColor={isActive ? 'brand.500' : 'rgba(255,255,255,0.5)'}
                // hoverTextColor={isActive ? 'brand.100' : 'brand.500'}
                fontSize={[null, null, '16px', null, null, '20px']}
                textTransform="uppercase"
                fontWeight="bold"
                direction={['column', null, 'row']}
                my={[0, null, 1]}
                itemProps={{
                  px: [3.5, null, 5],
                  py: 2,
                }}
              /> */}
              </Box>
            </Box>
          </SlideFade>
          <Box
            width={breakpointIndex < 2 ? 110 : 200}
            position="absolute"
            left={'calc(50% - (var(--display-page-scroll-shift) / 2))'}
            // mt={[null, null, -1]}
            transform={'translateX(-50%)'}
            display="flex"
            alignItems="center"
            flexGrow={0}
          >
            <Link href="/" aria-label="Home">
              <SlideFade
                onClick={close}
                in={delayIndex > 0}
                offsetY={-60}
                transition={{ enter: { duration: 0.3 } }}
              >
                {isActive ? (
                  <LogoElmesColor
                    width={
                      breakpointIndex < 2
                        ? 100
                        : breakpointIndex < 3
                        ? 150
                        : 200
                    }
                  />
                ) : (
                  <LogoElmesWhite
                    width={
                      breakpointIndex < 2
                        ? 100
                        : breakpointIndex < 3
                        ? 150
                        : 200
                    }
                  />
                )}
              </SlideFade>
            </Link>
          </Box>
          <Box
            // position="fixed"
            right={5}
            top={[3, null, 6]}
            mt={[null, null, 0.5]}
          >
            <SlideFade
              in={delayIndex > 0}
              offsetY={-60}
              transition={{ enter: { duration: 0.3 } }}
            >
              <IconButtonSwitchIcon
                id="diadao-button-gift"
                aria-label="gift"
                colorScheme="brand"
                bgColor={isActive ? 'brand.100' : 'white'}
                mr={[2, null, 3]}
                width={['42px', null, '58px']}
                height={['42px', null, '0']}
                py={[0, null, 6, null, null, 6]}
                fontSize={['58px', null, null, null, null, '64px']}
                fontWeight="bold"
                _hover={{
                  bgColor: isActive ? 'brand.500' : 'brand.500',
                }}
                onClick={() => window.open(giftUrl, '_blank')}
                icon={
                  <chakra.span
                    _before={{
                      color: isActive ? 'white' : 'brand.500',
                    }}
                    className={'icon-elmes-gift1-white'}
                  ></chakra.span>
                }
                iconHover={
                  <chakra.span
                    className={'icon-elmes-gift1-white'}
                  ></chakra.span>
                }
              />
              {breakpointIndex > 1 ? (
                <Button
                  id="diadao-button-booking"
                  colorScheme="brand"
                  fontFamily="body"
                  fontSize={['14px', null, '16px', null, null, '18px']}
                  fontWeight="700"
                  px={6}
                  py={6}
                  _hover={{
                    bgColor: isActive ? 'brand.100' : 'brand.500',
                    color: 'white',
                  }}
                  bgColor={isActive ? undefined : 'white'}
                  color={isActive ? undefined : 'brand.500'}
                  transition="all 0.3s"
                  onClick={() => setIsReservationOpen(true)}
                  variant="brand"
                >
                  <TranslatedString
                    color={'inherit'}
                    {...toBookLabelBind}
                    as="span"
                  />
                </Button>
              ) : (
                <IconButtonSwitchIcon
                  id="diadao-button-booking"
                  aria-label="gift"
                  colorScheme="brand"
                  bgColor={isActive ? undefined : 'white'}
                  width={['42px', null, '58px']}
                  height={['42px', null, 'unset']}
                  py={[0, null, 6]}
                  fontSize={['22px', null, null, null, null, '24px']}
                  fontWeight="bold"
                  _hover={{
                    bgColor: isActive ? 'brand.100' : 'brand.500',
                  }}
                  onClick={() => setIsReservationOpen(true)}
                  icon={
                    <chakra.span
                      _before={{
                        color: isActive ? 'white' : 'brand.500',
                      }}
                      className={'icon-elmes-calendar-mobile'}
                    ></chakra.span>
                  }
                  iconHover={
                    <chakra.span
                      _before={{
                        color: 'white',
                      }}
                      className={'icon-elmes-calendar-mobile'}
                    ></chakra.span>
                  }
                />
              )}
            </SlideFade>
          </Box>
        </Box>
        {/* MENU CONTENT */}
        <Box
          zIndex={2}
          className="main-menu-content"
          display={isOpen ? 'flex' : 'none'}
          position={'fixed'}
          bottom={[0, null, 'unset']}
          top={[
            `calc(79px + var(${$kustomAdminBarHeightVarName}, 0px))`,
            null,
            `calc(100px + var(${$kustomAdminBarHeightVarName}, 0px))`,
          ]}
          w={'100%'}
          h={['100%', null, null, 'unset']}
          overflow={['auto', null, null, 'hidden']}
          maxH={['calc(100% - 79px)', null, null, 'calc(90vh - 80px)']}
          ref={menuModalContainerRef}
        >
          <Fade
            in={isOpen}
            unmountOnExit
            transition={{
              // @ts-ignore
              duration: 0.2,
            }}
            style={{
              width: '100%',
            }}
          >
            <Box
              w={'100%'}
              bgColor="white"
              boxShadow="sm"
              overflowX="hidden"
              position="relative"
              display="flex"
              flexDir="column"
              overflowY={['auto', null, null, 'hidden']}
            >
              <Box
                // flex={['unset', null, null, 0]}
                overflowY={['unset', null, null, 'auto']}
                overflowX="hidden"
                flexShrink={[0, null, null, 1]}
                pb={[5, null, null, 0]}
                px={[5, null, 'unset']}
              >
                <Box>
                  {menu.map((item, index) => (
                    <MenuModal
                      index={index}
                      key={index}
                      label={item.label}
                      routes={item.routes || []}
                      isOpen={
                        breakpointIndex < 2
                          ? true
                          : hoveredMenuLabel === item.label
                      }
                      onClose={close}
                    />
                  ))}
                </Box>
              </Box>
              <Box
                h={['unset', null, null, EXTRA_MENU_HEIGHT]}
                flexShrink={0}
                bgColor="accent.100"
                w="100%"
                px={[3, null, null, 5]}
                py={[10, null, null, 0]}
                display="flex"
                alignItems="center"
              >
                <Fade
                  in={isOpen}
                  transition={{
                    enter: {
                      delay: 0.3,
                    },
                  }}
                >
                  <Box display={'flex'} flexDir={['column', null, null, 'row']}>
                    {extraMenu?.map((item, index) => (
                      <SubMenuItem
                        key={'item' + index}
                        label={item.label}
                        href={item.href}
                        close={close}
                      />
                    ))}
                  </Box>
                </Fade>
              </Box>
            </Box>
          </Fade>
        </Box>
      </Box>
      <ReservationDrawer
        isOpen={isReservationOpen}
        onClose={closeReservation}
      />
    </>
  );
};

interface SubMenuItemProps {
  label: KustomTranslatedString;
  href: KustomTranslatedString;
  close: () => void;
}

const SubMenuItem: React.FC<SubMenuItemProps> = (props) => {
  const { label, href, close } = props;

  const linkProps = useNavigationKustomHref(href);

  return (
    <Link {...linkProps} onClick={close}>
      <Box role="group">
        <TranslatedString
          color={'brand.500'}
          px={[5, null, null, 3, 5]}
          fontFamily="body"
          py={[1, null, null, 3]}
          cursor="pointer"
          fontWeight="bold"
          fontSize={['19px', null, null, '17px', '19px']}
          _groupHover={hoveredMenuItemStyle}
          as="div"
        >
          {label}
        </TranslatedString>
      </Box>
    </Link>
  );
};

export default Menu;
