import { Box, BoxProps, Flex, Input, Text, chakra } from '@chakra-ui/react';
import React, { forwardRef, useContext, useEffect } from 'react';

import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import useCombinedRefs from '@/hooks/useCombinedRefs';
import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

interface FileInputProps extends BoxProps {
  label?: string;
  onFileChange?: (file: File) => void;
  isInvalid?: boolean;
}

const FileInput = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const { label, onFileChange, isInvalid, ...boxProps } = props;
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = React.useState<string | null>(null);
  const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
  const dragCounter = React.useRef<number>(0);

  const usedRef = useCombinedRefs<HTMLInputElement>(ref, hiddenFileInput);

  const { currentLang } = useContext(LanguageContext);

  const handleClick = (event: React.MouseEvent) => {
    usedRef.current?.click();
  };

  const bpIndex = useBreakpointIndex();

  const uploadFile = (file?: File) => {
    if (!file) return;
    setFileName(file.name);
    onFileChange?.(file);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];
    uploadFile(fileUploaded);
  };

  const browse =
    useKustomTranslation('recruitment_browse')?.value?.[currentLang];
  const file = useKustomTranslation('recruitment_file')?.value?.[currentLang];

  useEffect(() => {
    if (!usedRef.current?.value) {
      setFileName(null);
    }
  }, [usedRef.current?.value]);

  const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current++;
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragOver(true);
    }
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragOver(false);
    }
  };

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      uploadFile(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
      dragCounter.current = 0;
    }
  };

  return (
    <Box
      cursor="pointer"
      onClick={handleClick}
      {...boxProps}
      bgColor="white"
      h="100%"
      py={[4, null, 6]}
      px={6}
      borderRadius="base"
      {...(isInvalid && {
        border: '1px solid red',
      })}
      display="flex"
      flexDir={['row', null, 'column']}
      alignItems={['center', null, 'flex-start']}
      justifyContent={['space-between', null, 'center']}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <Text
        fontSize="20px"
        fontFamily="jhaSemiboldItalic"
        flex="1 1 0"
        color={isDragOver ? 'brand.100' : 'brand.500'}
      >
        {label}
      </Text>
      {fileName ? (
        <Text
          fontFamily="jhaSemilight"
          fontSize="18px"
          flex="1 1 0"
          color={isDragOver ? 'brand.100' : 'brand.500'}
        >
          {fileName}
        </Text>
      ) : (
        <Flex>
          {bpIndex < 2 ? (
            <chakra.span
              fontFamily="jhaSemilight"
              fontSize="18px"
              textDecor="underline"
              color={isDragOver ? 'brand.100' : 'brand.500'}
            >
              {browse}
            </chakra.span>
          ) : (
            <Text
              fontFamily="jhaSemilight"
              fontSize="18px"
              display={['none', null, 'inline-block']}
              color={isDragOver ? 'brand.100' : 'brand.500'}
              mr={1}
            >
              {file} <chakra.span textDecor="underline">{browse}</chakra.span>
            </Text>
          )}
        </Flex>
      )}
      <Input
        type="file"
        ref={usedRef}
        multiple={false}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </Box>
  );
});

FileInput.displayName = 'FileInput';

export default FileInput;
