import { Box } from '@chakra-ui/react';
import React from 'react';

interface DisableAutomaticComponentRenderingProps {}

const DisableAutomaticComponentRendering: React.FC<
  DisableAutomaticComponentRenderingProps
> = (props) => {
  return null;
};

export default DisableAutomaticComponentRendering;
