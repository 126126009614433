import { CarouselComponentSlide, KustomResponsiveMedia } from '../types';

import { uniqID } from '../admin/helpers/random';

const defaultTranslatedString = {
  en: '',
  fr: '',
  es: '',
};

const kustomDataMediasToSlidesTransformOptions = {
  transformInput: (
    medias?: KustomResponsiveMedia[],
    currentSlides?: CarouselComponentSlide[],
  ) => {
    const newSlides = medias?.map((media) => {
      const slide =
        currentSlides?.find((s) => s.medias.id === media.id) ||
        ({
          id: uniqID(),
          title: defaultTranslatedString,
          subtitle: defaultTranslatedString,
          medias: media,
          atomicComponents: [],
        } as CarouselComponentSlide);

      slide.medias = media;

      return slide;
    });

    return newSlides;
  },
  transformOutput: (slides?: CarouselComponentSlide[]) => {
    return slides?.map((slide) => slide.medias);
  },
};

export default kustomDataMediasToSlidesTransformOptions;
