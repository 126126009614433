import { useContext, useMemo } from 'react';

import PagesContext from '../contexts/pages';

const usePage = (id: string) => {
  const pagesCtx = useContext(PagesContext);

  const menuPage = useMemo(
    () =>
      Object.values(pagesCtx.routes).find(
        (route) => route.pageCode === id || route.objectCode === id,
      ),
    [pagesCtx.routes],
  );

  return menuPage;
};

export default usePage;
