import Image, { ImageProps } from 'next/image';
import { useEffect, useState } from 'react';

import { Box } from '@chakra-ui/react';
import useLazyAutoplay from '@/lib/kustomcms-sdk/lib/hooks/useLazyAutoplay';

interface VideoSmoothProps {
  src: string;
  blurPlaceholder?: ImageProps['src'];
}

const VideoSmooth = (props: VideoSmoothProps) => {
  const { src, blurPlaceholder } = props;
  // const [blur, setBlur] = useState(true);

  const lazyAutoplayBind = useLazyAutoplay();

  return (
    <Box w="100%" h="100%">
      <video
        // onLoadedData={() => {
        //   setBlur(false);
        // }}
        // autoPlay
        {...lazyAutoplayBind}
        playsInline
        muted
        loop
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      >
        <source src={src} type="video/mp4" />
      </video>
      {/* {blurPlaceholder && (
        <Image
          alt="blur placeholder"
          src={blurPlaceholder}
          className={blur ? 'img-blur' : 'unblur'}
          fill
          loading="lazy"
          style={{
            objectFit: 'cover',
          }}
        />
      )} */}
    </Box>
  );
};

export default VideoSmooth;
