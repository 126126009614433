import { useCallback, useRef } from 'react';

const useRefCallback = <T = HTMLElement>(
  refInitialValue: T | null,
  callback: (v: T) => void,
) => {
  const ref = useRef(refInitialValue);

  return useCallback((node: T) => {
    ref.current = node;
    callback(node);
  }, []);
};

export default useRefCallback;
