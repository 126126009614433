import { IconButton, IconButtonProps } from '@chakra-ui/react';

import { useState } from 'react';

interface IconButtonSwitchIconProps extends IconButtonProps {
  iconHover?: IconButtonProps['icon'];
}

const IconButtonSwitchIcon: React.FC<IconButtonSwitchIconProps> = (props) => {
  const { iconHover, ...iconButtonProps } = props;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <IconButton
      {...iconButtonProps}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      icon={isHovered ? iconHover : iconButtonProps.icon}
    />
  );
};

export default IconButtonSwitchIcon;
