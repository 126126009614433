import { Box, IconButton, chakra } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface BookingDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const BookingDrawer = (props: BookingDrawerProps) => {
  const { isOpen: _isOpen, onClose: _onClose } = props;

  const [isOpen, setIsOpen] = useState(_isOpen);

  useEffect(() => {
    setIsOpen(_isOpen);
  }, [_isOpen]);

  const onClose = () => {
    setIsOpen(false);
    _onClose();
  };

  return (
    <Box
      position={'fixed'}
      top={0}
      zIndex={'10000'}
      width={'100%'}
      height={'100%'}
      left={0}
      transform={isOpen ? 'translateX(0)' : 'translateX(100%)'}
      transition={isOpen ? 'none' : 'transform 0.1s linear 0.3s'}
    >
      <Box
        position={'absolute'}
        left={0}
        top={0}
        width={'100%'}
        height={'100%'}
        bg={'rgba(0, 0, 0, 0.3)'}
        opacity={isOpen ? 1 : 0}
        transition={'opacity 0.3s'}
        onClick={onClose}
      ></Box>
      <Box
        bg="#f9f5ec"
        boxShadow={isOpen ? '-29px 0px 93px #00000029' : 'none'}
        transform={isOpen ? 'translateX(0)' : 'translateX(100%)'}
        transition={
          isOpen ? 'transform 0.4s ease-out' : 'transform 0.25s ease-out'
        }
        maxWidth={['100%', null, '800px']}
        width={'100%'}
        height={'100%'}
        position={'absolute'}
        right={0}
        top={0}
      >
        <IconButton
          aria-label="Close"
          fontSize={['12px']}
          p={[5, null, null, 0]}
          py={[6, null, null, 0]}
          bgColor="transparent"
          _hover={{
            bgColor: 'gray.100',
          }}
          icon={
            <chakra.span
              className="icon-diadao-cross1"
              fontSize="14px"
              color={'gray.800'}
            />
          }
          position="absolute"
          top={1}
          right={1}
          onClick={onClose}
          zIndex={2}
        />
        <Box
          height={'100%'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={['flex-start', 'center']}
          overflowY={'auto'}
          overflowX={'hidden'}
          pt={[6, 0]}
        >
          <Box id="diadao-hsw-qs-booking" position={'relative'}></Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingDrawer;
