import { KustomResponsiveMedia } from '../types';
import { useBreakpointValue } from '@chakra-ui/react';

export type ResponsiveMediaDeviceKeys = Omit<keyof KustomResponsiveMedia, 'id'>;

const useResponsiveMediasDevice = (): ResponsiveMediaDeviceKeys => {
  return (
    useBreakpointValue({
      base: 'mobile',
      sm: 'mobile',
      md: 'tablet',
      lg: 'default',
      xl: 'default',
    }) || 'default'
  );
};

export default useResponsiveMediasDevice;
