import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProps,
  Box,
} from '@chakra-ui/react';
import {
  AccordionComponentData,
  KustomPageComponentViewProps,
  KustomTranslatedString,
} from '@/lib/kustomcms-sdk/lib/types';

import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

interface AccordionComponentProps
  extends KustomPageComponentViewProps<AccordionComponentData> {
  containerProps?: AccordionProps;
}

const AccordionComponent: React.FC<AccordionComponentProps> = (props) => {
  const { component, containerProps } = props;

  const slides = component.data.slides;

  return (
    <Accordion allowToggle mt={[0, null, 14]} {...containerProps}>
      {slides.map((slide, index) => (
        <AccordionComponentItem
          key={slide.id}
          page={props.page}
          component={component}
          slideIndex={index}
          isLastSlide={index === slides.length - 1}
        />
      ))}
    </Accordion>
  );
};

interface AccordionComponentItemProps
  extends KustomPageComponentViewProps<AccordionComponentData> {
  slideIndex: number;
  isLastSlide?: boolean;
}

const AccordionComponentItem: React.FC<AccordionComponentItemProps> = (
  props,
) => {
  const { component, slideIndex, isLastSlide } = props;

  const textBind = useKustomData<KustomTranslatedString>(
    props.page,
    component,
    `data.slides.${slideIndex}.text`,
  );

  const hiddenTextBind = useKustomData<KustomTranslatedString>(
    props.page,
    component,
    `data.slides.${slideIndex}.hiddenText`,
  );

  return (
    <AccordionItem
      borderTop={slideIndex === 0 ? 'none' : undefined}
      borderBottom={isLastSlide ? 'none' : undefined}
    >
      <AccordionButton
        pb={4}
        px={0}
        _hover={{
          bg: 'transparent',
        }}
        _active={{
          bg: 'transparent',
        }}
      >
        <Box as="span" flex="1" textAlign="left" mr={12}>
          <TranslatedString
            fontSize={['18px', null, '22px', null, null, '28px']}
            fontFamily="jhaSemiboldItalic"
            {...textBind}
            as="h3"
          />
        </Box>
        <AccordionIcon color="brand.500" />
      </AccordionButton>
      <AccordionPanel pb={4} px={0}>
        <RichText
          fontSize={['18px', null, '22px', null, null, '28px']}
          fontFamily="jhaSemilight"
          color="brand.500"
          {...hiddenTextBind}
          as="p"
        />
      </AccordionPanel>
    </AccordionItem>
  );
};

export default AccordionComponent;
