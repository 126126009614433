import { createContext, useState } from 'react';

interface ReservationRouteContextType {
  route: string;
  modalRoute: string;
  setRoute: (route: string) => void;
  setModalRoute: (modalRoute: string) => void;
}

const ReservationRouteContext = createContext<ReservationRouteContextType>({
  route: '',
  modalRoute: '',
  setRoute: () => {},
  setModalRoute: () => {},
});

interface ReservationRouteContextProviderProps {
  children: React.ReactNode;
}

export const ReservationRouteContextProvider = (
  props: ReservationRouteContextProviderProps,
) => {
  const { children } = props;

  const [route, setRoute] = useState<string>('');
  const [modalRoute, setModalRoute] = useState<string>('');

  return (
    <ReservationRouteContext.Provider
      value={{ route, setRoute, modalRoute, setModalRoute }}
    >
      {children}
    </ReservationRouteContext.Provider>
  );
};

export default ReservationRouteContext;
