import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
  Text,
} from '@chakra-ui/react';

import IconDownload from '@/lib/kustomcms-sdk/lib/icons/icon-download.svg';
import IconTrash from '@/lib/kustomcms-sdk/lib/icons/icon-trash.svg';
import Input from '../atomics/Input';
import { Media } from '../../features/medias/slice';
import { MediaType } from '../../helpers/media';
import { getUserCurrentEstablishment } from '../../helpers/establishments';
import moment from '../../helpers/date';
import useInput from '../../hooks/useInput';
import { useKustomSelector } from '../../hooks/useKustomSelector';
import useTheme from '../../hooks/useTheme';

const typeFilters = [
  { label: 'Photos', value: MediaType.IMAGE },
  { label: 'Vidéos', value: MediaType.VIDEO },
  { label: 'PDF', value: MediaType.PDF },
  { label: 'Autres', value: MediaType.APP },
];

const dateFilters = [
  { label: 'Toutes les dates', value: -1 },
  { label: "Aujourd'hui", value: Date.now() },
  {
    label: 'Les 7 derniers jours',
    value: moment().subtract(7, 'd').valueOf(),
  },
  { label: 'Le mois dernier', value: moment().subtract(30, 'd').valueOf() },
  {
    label: 'Les 3 derniers mois',
    value: moment().subtract(90, 'd').valueOf(),
  },
];

const formatFilters = [
  { label: 'Carré', value: 'square' },
  { label: 'Vertical', value: 'portrait' },
  { label: 'Horizontal', value: 'landscape' },
];

const menuButtonStyleProps = {
  borderColor: 'gray.700',
  borderWidth: 2,
  px: 2,
  color: 'white',
  minW: 20,
  fontSize: '14px',
  backgroundColor: 'gray.700',
  _hover: {
    backgroundColor: 'gray.700',
    borderColor: 'white',
  },
  _active: {
    backgroundColor: 'gray.700',
    borderColor: 'white',
  },
  fontWeight: 500,
  letterSpacing: 0,
  textAlign: 'center' as any,
};

const menuItemStyleProps = {
  fontSize: '14px',
  py: 3,
  color: 'brand.800',
  flexDir: 'column' as any,
  alignItems: 'flex-start',
};

export interface MediasFilters {
  search: string;
  types: MediaType[];
  tags: string[];
  formats: string[];
  date: number | undefined;
}

interface MediasSearchBarProps {
  onNewFilters?: (filters: MediasFilters) => void;
  filters: MediasFilters;
  onSelectAll?: (selection?: Media[]) => void;
  onRemoveSelectedMedias?: () => void;
  onDownloadSelectedMedias?: () => void;
  isAllSelected?: boolean;
  selectedMedias?: Media[];
  collapsed?: boolean;
  isEditable?: boolean;
  isSticky?: boolean;
}

const MediasSearchBar = (props: MediasSearchBarProps) => {
  const {
    onNewFilters,
    onSelectAll,
    collapsed,
    isEditable,
    isAllSelected,
    selectedMedias,
    onRemoveSelectedMedias,
    onDownloadSelectedMedias,
    isSticky,
    filters,
  } = props;
  const tagsFilters = useKustomSelector(
    (state) => getUserCurrentEstablishment(state.app.user)?.mediaTags || [],
  );

  const theme = useTheme();

  // useEffect(() => {
  //   onNewFilters?.(search, types, tags, formats, fromDate);
  // }, [onNewFilters, search, types, fromDate, tags, formats]);

  const toggleTypeFilter = (type: MediaType) => {
    const newTypes = [...filters.types];
    const index = filters.types.indexOf(type);

    if (index < 0) {
      newTypes.push(type);
    } else {
      newTypes.splice(index, 1);
    }
    // setTypes(newTypes);
    onNewFilters?.({
      ...filters,
      types: newTypes,
    });
  };

  const toggleAllFilters = () => {
    // setTypes(
    //   types.length === typeFilters.length
    //     ? []
    //     : typeFilters.map((f) => f.value),
    // );
    onNewFilters?.({
      ...filters,
      types:
        filters.types.length === typeFilters.length
          ? []
          : typeFilters.map((f) => f.value),
    });
  };

  const toggleAllTags = () => {
    // setTags(tags.length > tagsFilters.length ? [] : [...tagsFilters, '']);
    onNewFilters?.({
      ...filters,
      tags:
        filters.tags.length > tagsFilters.length ? [] : [...tagsFilters, ''],
    });
  };

  const toggleTagsFilter = (tag: string) => {
    const newTags = [...filters.tags];
    const index = filters.tags.indexOf(tag);

    if (index < 0) {
      newTags.push(tag);
    } else {
      newTags.splice(index, 1);
    }

    // setTags(newTags);
    onNewFilters?.({
      ...filters,
      tags: newTags,
    });
  };

  const toggleFormatFilter = (format: string) => {
    const newFormats = [...filters.formats];
    const index = filters.formats.indexOf(format);

    if (index < 0) {
      newFormats.push(format);
    } else {
      newFormats.splice(index, 1);
    }

    // setFormats(newFormats);
    onNewFilters?.({
      ...filters,
      formats: newFormats,
    });
  };

  return (
    <Box
      background={'brand.900'}
      mb={collapsed ? 0 : 5}
      display="flex"
      height="111px"
      alignItems={'center'}
      borderRadius="base"
      px={6}
      {...(isSticky && {
        position: 'sticky',
        top: 4,
        zIndex: 999,
      })}
    >
      <Input
        size="md"
        backgroundColor="gray.700"
        minW={280}
        fontSize="14px"
        color="white"
        value={filters.search}
        onChange={(e) =>
          onNewFilters?.({
            ...filters,
            search: e.target.value,
          })
        }
        placeholder="Recherche par nom"
      />
      {/* MEDIA TYPE */}
      <Box position={'relative'} ml={3}>
        <Menu closeOnSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                {...menuButtonStyleProps}
                borderColor={
                  filters.types.length !== typeFilters.length
                    ? 'white'
                    : 'gray.700'
                }
              >
                Médias
              </MenuButton>
              <MenuList zIndex={99} py={0}>
                <MenuItem
                  {...menuItemStyleProps}
                  backgroundColor="gray.50"
                  onClick={toggleAllFilters}
                >
                  <Checkbox
                    as="div"
                    isChecked={filters.types.length === typeFilters.length}
                  >
                    Tous
                  </Checkbox>
                </MenuItem>
                {typeFilters.map((type, index) => (
                  <MenuItem
                    {...menuItemStyleProps}
                    key={type.value}
                    onClick={() => toggleTypeFilter(type.value)}
                  >
                    <Checkbox
                      as="div"
                      isChecked={filters.types.includes(type.value)}
                      fontWeight="500"
                    >
                      {type.label}
                    </Checkbox>
                  </MenuItem>
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
      {/* DATES */}
      <Box position={'relative'} ml={3}>
        <Menu closeOnSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                {...menuButtonStyleProps}
                borderColor={!!(filters.date !== -1) ? 'white' : 'gray.700'}
              >
                Dates
              </MenuButton>
              <MenuList zIndex={99} py={0}>
                <RadioGroup
                  onChange={(v) => {
                    // setFromDate(+v);
                    onNewFilters?.({
                      ...filters,
                      date: +v,
                    });
                  }}
                  value={filters.date + ''}
                >
                  {dateFilters.map((date) => (
                    <MenuItem
                      onClick={() => {
                        // setFromDate(date.value);
                        onNewFilters?.({
                          ...filters,
                          date: date.value,
                        });
                      }}
                      {...menuItemStyleProps}
                      key={date.value}
                    >
                      <Radio fontWeight="500" value={date.value + ''}>
                        {date.label}
                      </Radio>
                    </MenuItem>
                  ))}
                </RadioGroup>
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
      {/* THÉMATIQUES */}
      <Box position={'relative'} ml={3}>
        <Menu closeOnSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                {...menuButtonStyleProps}
                borderColor={!!filters.tags.length ? 'white' : 'gray.700'}
              >
                Thématiques
              </MenuButton>
              <MenuList zIndex={99} py={0}>
                <MenuItem
                  {...menuItemStyleProps}
                  backgroundColor="gray.50"
                  onClick={toggleAllTags}
                >
                  <Checkbox
                    as="div"
                    isChecked={filters.tags.length > tagsFilters.length}
                  >
                    Toutes
                  </Checkbox>
                </MenuItem>
                {/* <MenuItem
                    {...menuItemStyleProps}
                    backgroundColor="gray.50"
                    onClick={() => toggleTagsFilter('')}
                  >
                    <Checkbox as="div" isChecked={tags.includes('')}>
                      Aucune thématique
                    </Checkbox>
                  </MenuItem> */}
                {tagsFilters.map((tag: string, index: number) => (
                  <MenuItem
                    {...menuItemStyleProps}
                    key={tag}
                    onClick={() => toggleTagsFilter(tag)}
                  >
                    <Checkbox
                      as="div"
                      isChecked={filters.tags.includes(tag)}
                      fontWeight="500"
                    >
                      {tag}
                    </Checkbox>
                  </MenuItem>
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
      {/* FORMATS */}
      <Box position={'relative'} ml={3}>
        <Menu closeOnSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                {...menuButtonStyleProps}
                borderColor={!!filters.formats.length ? 'white' : 'gray.700'}
              >
                Format
              </MenuButton>
              <MenuList zIndex={99} py={0}>
                {formatFilters.map((format) => (
                  <MenuItem
                    {...menuItemStyleProps}
                    key={format.value}
                    onClick={() => toggleFormatFilter(format.value)}
                  >
                    <Checkbox
                      as="div"
                      isChecked={filters.formats.includes(format.value)}
                      fontWeight="500"
                    >
                      {format.label}
                    </Checkbox>
                  </MenuItem>
                ))}
              </MenuList>
            </>
          )}
        </Menu>
      </Box>

      <Box ml="auto" display="flex">
        {(selectedMedias?.length || 0) > 0 && (
          <>
            <Box position="relative" mr={3}>
              <IconButton
                aria-label="download medias"
                icon={
                  // @ts-ignore
                  <IconDownload fill={theme.colors.brand[850]} width={18} />
                }
                onClick={() => onDownloadSelectedMedias?.()}
                bgColor="white"
                borderRightWidth={'1px'}
                borderRightColor="rgba(255, 255, 255, 0.15)"
                p={3}
                py={5}
              />
              <Box
                bgColor="red"
                position="absolute"
                top={-3}
                right={-3}
                minW={'23px'}
                h={'23px'}
                borderRadius="full"
                textAlign="center"
              >
                <Text
                  color="white"
                  fontWeight={600}
                  fontSize="14px"
                  position="relative"
                  top="+1px"
                  whiteSpace={'nowrap'}
                >
                  {selectedMedias!.length}
                </Text>
              </Box>
            </Box>
            {isEditable && (
              <Box position="relative" mr={3}>
                <IconButton
                  aria-label="remove medias"
                  // @ts-ignore
                  icon={<IconTrash fill={theme.colors.brand[850]} width={18} />}
                  onClick={() => onRemoveSelectedMedias?.()}
                  bgColor="white"
                  borderRightWidth={'1px'}
                  borderRightColor="rgba(255, 255, 255, 0.15)"
                  p={3}
                  py={5}
                />
                <Box
                  bgColor="red"
                  position="absolute"
                  top={-3}
                  right={-3}
                  minW={'23px'}
                  h={'23px'}
                  borderRadius="full"
                  textAlign="center"
                >
                  <Text
                    color="white"
                    fontWeight={600}
                    fontSize="14px"
                    position="relative"
                    top="+1px"
                    whiteSpace={'nowrap'}
                  >
                    {selectedMedias!.length}
                  </Text>
                </Box>
              </Box>
            )}
          </>
        )}

        {isEditable &&
          (selectedMedias?.length && !isAllSelected ? (
            <Button
              fontSize="14px"
              onClick={() => onSelectAll?.([])}
              bgColor="white"
            >
              Désélectionner
            </Button>
          ) : (
            <Button
              fontSize="14px"
              onClick={() => onSelectAll?.()}
              bgColor="white"
            >
              {isAllSelected ? 'Tout désélectionner' : 'Tout sélectionner'}
            </Button>
          ))}
      </Box>
    </Box>
  );
};

export default MediasSearchBar;
