import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Fade,
  Input,
  Portal,
  SimpleGrid,
  Slide,
  useTheme,
} from '@chakra-ui/react';
import {
  KustomPage,
  KustomPageComponentViewProps,
  RoomListComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

import LanguageContext from '@/lib/kustomcms-sdk/lib/contexts/language';
import PagesContext from '@/lib/kustomcms-sdk/lib/contexts/pages';
import { Parallax } from 'react-scroll-parallax';
import RoomCard from './RoomCard';

import useBreakpointIndex from '@/hooks/useBreakpointIndex';
import { useRouter } from 'next/router';
import { SvgShape } from '../SvgShape';

const Sketchs = {
  '1687025539835268': [
    () => <SvgShape file="illu-mer-1" width="100%" />,
    () => <SvgShape file="illu-etoiles" width="100%" />,
  ],
} as Record<string, any[] | undefined>;

const RoomsGrid: React.FC<
  KustomPageComponentViewProps<RoomListComponentData> & { grid: number }
> = (props) => {
  const { component, grid } = props;
  const theme = useTheme();
  const router = useRouter();

  const [isRoomsOpen, setIsRoomsOpen] = useState(false);

  const pagesContext = useContext(PagesContext);

  const roomsId = component.data.activeRoomsId;

  const rooms = useMemo(() => {
    const rooms = pagesContext.rooms;

    return roomsId
      .map((roomId) => rooms.find((room) => room._id === roomId))
      .filter((room) => !!room)
      .sort((a, b) => a!.index - b!.index);
  }, [roomsId, pagesContext.rooms]);

  const [Sketch1, Sketch2] = Sketchs[component.id] || [];

  const bpIndex = useBreakpointIndex();
  const { currentLang } = useContext(LanguageContext);

  const goToRoom = (room: KustomPage) => {
    const bt = document.querySelector(
      `.diadao-rooms-sticky-opened #diadao-hdp-bar-mobile .hsc-sticky-close-enabled 
      .diadao-button-hdp-mobile.hsc-button-sticky-rooms-exit`,
    ) as HTMLButtonElement;
    if (bt) {
      bt.click();
    }
    const href = room.prettyUrl[currentLang || 'fr'] || room.prettyUrl.fr;
    if (!href) {
      return;
    }
    setIsRoomsOpen(true);
    setTimeout(() => {
      router.push(href);
    }, 200);
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const containerRefIsVisible = useRef(false);

  function handleScroll() {
    const { top } = cardRef.current?.getBoundingClientRect() || {};
    if (!top) return;

    if (!containerRefIsVisible.current && top < window.innerHeight * 0.75) {
      containerRefIsVisible.current = true;
      document.body.style.setProperty('--custom-hdp-bar-opacity', '1');
      document.body.style.setProperty('--custom-hdp-bar-translate-y', '0');
    } else if (
      containerRefIsVisible.current &&
      top > window.innerHeight * 0.75
    ) {
      containerRefIsVisible.current = false;
      document.body.style.setProperty('--custom-hdp-bar-opacity', '0');
      document.body.style.setProperty('--custom-hdp-bar-translate-y', '100%');
    }
  }

  useEffect(() => {
    if (grid === 1) {
      const style = document.createElement('style');
      style.innerHTML = `
      #diadao-hdp-bar {
        transform: translateY(var(--custom-hdp-bar-translate-y, 100%)) !important;
        opacity: var(--custom-hdp-bar-opacity, 0) !important;
        transition: transform 0.15s easeInOut !important, opacity 0.15s easeInOut !important;
        }
      .container-hdp-bar-rooms {
        transform: translateY(var(--custom-hdp-bar-translate-y, 100%)) !important;
        opacity: var(--custom-hdp-bar-opacity, 0) !important;
        transition: transform 0.15s easeInOut !important, opacity 0.15s easeInOut !important;
        }
      `;
      document.head.appendChild(style);
      document.addEventListener('scroll', handleScroll);
    }
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Container
      maxW={['container.xl', null, null, null, null, '1780px']}
      px={[0, null, 20]}
      position="relative"
      ref={containerRef}
    >
      {Sketch1 && (
        <Box display={['block']} position="absolute" top={-20} left={-100}>
          <Parallax speed={-20}>
            <Sketch1
              width={bpIndex > 5 ? '400' : '300'}
              fill={theme.colors.accent[500]}
            />
          </Parallax>
        </Box>
      )}
      {Sketch2 && (
        <Box display={['block']} position="absolute" right={0} bottom={0}>
          <Parallax speed={-20}>
            <Sketch2
              width={bpIndex > 5 ? '400' : '200'}
              fill={theme.colors.accent[500]}
            />
          </Parallax>
        </Box>
      )}
      <SimpleGrid columns={[1, null, null, 2]} spacing={10}>
        {rooms.map((room, index) =>
          room ? (
            <Box key={index} ref={index === 0 ? cardRef : null}>
              <RoomCard
                key={index}
                room={room}
                index={index}
                onClick={() => goToRoom(room)}
              />
            </Box>
          ) : null,
        )}
      </SimpleGrid>
      <Portal>
        {/* <Slide
          in={isRoomsOpen}
          direction="right"
          style={{
            zIndex: 999,
          }}
        >
          <Box
            position="absolute"
            zIndex={999}
            top="0"
            bottom="0"
            right="0"
            left="0"
            bgColor="accent.100"
          ></Box>
        </Slide> */}
        <Drawer isOpen={isRoomsOpen} placement="right" onClose={() => {}}>
          <DrawerOverlay />
          <DrawerContent w="100vw" maxW="100vw" bgColor="accent.100">
            <DrawerBody>
              {/* <Box
            position="absolute"
            zIndex={999}
            top="0"
            bottom="0"
            right="0"
            left="0"
            bgColor="accent.100"
          ></Box> */}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Portal>
    </Container>
  );
};

export default RoomsGrid;
