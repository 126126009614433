import { Box, Button, Container } from '@chakra-ui/react';
import {
  KustomPageComponentViewProps,
  TextComponent,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';
import React, { useContext, useEffect, useState } from 'react';

import GoogleMap from 'google-maps-react-markers';
import Image from 'next/image';
import ImgMarkerDesktop from '@/../public/assets/Contact/GoogleMarker.png';
import ImgMarkerMobile from '@/../public/assets/Contact/GoogleMarker-mobile.png';
import ParallaxElement from '../ParallaxElement';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';

import mapStyle from './mapStyle';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';

import useIsMounted from '@/hooks/useIsMounted';
import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import CookiesConsentContext from '@/contexts/CookiesConsentContext';
import SettingsContext from '@/lib/kustomcms-sdk/lib/contexts/settings';
import { useKustomTranslation } from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { SvgShape } from '../SvgShape';

interface ContactMapProps
  extends KustomPageComponentViewProps<TextComponentData> {}

const hotelPos = {
  lat: 42.48742364934328,
  lng: 3.12683463458558,
};

const ContactMap: React.FC<ContactMapProps> = (props) => {
  const { component } = props;

  const isMounted = useIsMounted();

  const { text, atomicComponents } = component.data;
  const desc = atomicComponents?.find((atomic) => atomic.type === 'TEXT') as
    | TextComponent
    | undefined;

  const gmapsButtonLabel = useKustomTranslation('map_enable_google_map');
  const gmapsText = useKustomTranslation('map_rgpd_message');

  const { consents, getConsent, openConsent } = useContext(
    CookiesConsentContext,
  );

  const [allowDisplayMap, setAllowDisplayMap] = useState(
    isMounted ? getConsent('gmaps') : null,
  );
  useEffect(() => {
    setAllowDisplayMap(getConsent('gmaps'));
  }, [consents, getConsent]);

  const settingsCtx = useContext(SettingsContext);
  const googleMapAPIKey =
    settingsCtx?.settings?.googleServices?.googleMapAPIKey;

  return (
    <Box position="relative" overflow="clip">
      <ParallaxElement
        right={[-20, null, null, 400]}
        top={300}
        width={[250, null, null, 400]}
        Element={() => <SvgShape file="illu-mer-1" />}
      />
      <ParallaxElement
        left={[-10, null, null, 4]}
        bottom={[-50, null, null, 100]}
        width={[120, null, null, 200]}
        Element={() => <SvgShape file="illu-algue-2" />}
      />

      <Container
        maxW={['container.lg', null, null, null, null, '1362px']}
        position="relative"
        overflowX="clip"
        px={[6, null, 20]}
        pt={[20, null, 32]}
        pb={[10, null, 20]}
      >
        <Box position="relative">
          <RichText
            fontFamily="heading"
            lineHeight={1}
            mb={4}
            fontSize={['48px', null, '96px']}
            color="brand.500"
            as="h2"
          >
            {text}
          </RichText>
          <RichText
            mt={[12, null, 20]}
            fontSize={['20px', null, '28px']}
            fontFamily="jhaSemilight"
            color="brand.500"
            mr={[0, null, 60]}
            as="p"
          >
            {desc?.text}
          </RichText>
        </Box>
      </Container>

      {googleMapAPIKey && (
        <Container
          maxW={['container.xl', null, null, null, null, '1780px']}
          px={[6, null, 20]}
          pb={[6, null, 20]}
        >
          <Box
            w="100%"
            h={['420px', null, '819px']}
            position="relative"
            bg="gray.200"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {allowDisplayMap ? (
              <GoogleMap
                apiKey={googleMapAPIKey}
                defaultCenter={hotelPos}
                defaultZoom={8}
                options={{ styles: mapStyle }}
              >
                <HotelMarker
                  key="hotelMarker"
                  markerId="hotelMarker"
                  lat={hotelPos.lat}
                  lng={hotelPos.lng}
                />
              </GoogleMap>
            ) : (
              <Box
                fontFamily="jhaSemilight"
                color="brand.500"
                display="flex"
                flexDir="column"
                gap="1.5rem"
                justifyContent="center"
                alignItems="center"
              >
                <TranslatedString
                  color="inherit"
                  fontSize="1.25rem"
                  {...gmapsText}
                />
                <Button
                  variant="brand"
                  size={['brandSm', null, 'brandMd', null, null, 'brandLg']}
                  onClick={openConsent}
                >
                  <TranslatedString color="inherit" {...gmapsButtonLabel} />
                </Button>
              </Box>
            )}
          </Box>
        </Container>
      )}
    </Box>
  );
};

const HotelMarker = (props: any) => {
  const device = useResponsiveMediasDevice();

  const MarkerImg = device === 'mobile' ? ImgMarkerMobile : ImgMarkerDesktop;

  return (
    <Box w={device === 'mobile' ? 96 : 231} transform="translate(-50%, -100%)">
      <Image loading="lazy" src={MarkerImg} alt="marker" />
    </Box>
  );
};

export default ContactMap;
