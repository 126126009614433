import {
  Box,
  Portal,
  chakra,
  useBreakpointValue,
  useTheme,
} from '@chakra-ui/react';
import {
  AtomicComponent,
  CarouselComponentData,
  CodeComponent,
  KustomPage,
  KustomPageComponent,
  KustomPageComponentViewProps,
  KustomResponsiveMedia,
  KustomTranslatedString,
} from '@/lib/kustomcms-sdk/lib/types';
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import BgSlideShow from '@/../public/assets/img/bg_slideshow.jpg';
import EditMediaModal from '@/lib/kustomcms-sdk/lib/admin/components/EditMediaModal';
import { ParallaxProvider } from 'react-scroll-parallax';
import ResponsiveMedias from '@/lib/kustomcms-sdk/lib/components/ResponsiveMedias';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

import TranslatedString from '@/lib/kustomcms-sdk/lib/components/TranslatedString';
import getCSSAdminWysiwygItem from '@/lib/kustomcms-sdk/lib/admin/helpers/getCSSAdminWysiwygItem';
import { gsap } from 'gsap';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';
import { useKustomSelector } from '@/lib/kustomcms-sdk/lib/admin/hooks/useKustomSelector';
import useResponsiveMediasDevice from '@/lib/kustomcms-sdk/lib/hooks/useResponsiveMediasDevice';
import Link from 'next/link';
import useNavigationKustomHref from '@/lib/kustomcms-sdk/lib/hooks/useNavigationKustomHref';
import { SvgShape } from '../SvgShape';

gsap.registerPlugin(ScrollTrigger);

const slideTransforms = [
  'matrix(1, -0.05, 0.05, 1, 0, 0)',
  'matrix(1, 0.09, -0.09, 1, 0, 0)',
  'matrix(1, -0.07, 0.07, 1, 0, 0)',
  'rotate(-1deg)',
  'rotate(4deg)',
];

const sketchSpeed = [0.8, null, 0.4, 0.4];

const Slideshow: React.FC<
  KustomPageComponentViewProps<CarouselComponentData>
> = (props) => {
  const { page, component } = props;

  const theme = useTheme();

  const { user } = useKustomSelector((state) => state.app);

  const hasWysiwygEdit = !!user;

  const slides = component.data?.slides;

  const currentDevice = useResponsiveMediasDevice();

  const ref = useRef<HTMLDivElement>(null);

  const mediaSize = useBreakpointValue({
    base: [
      {
        width: 290,
        height: 211,
      },
      {
        width: 190,
        height: 264,
      },
      {
        width: 348,
        height: 211,
      },
      {
        width: 190,
        height: 264,
      },
    ],
    md: [
      { width: 616, height: 448 },
      { width: 409.5, height: 560 },
      { width: 739, height: 448 },
      { width: 409.5, height: 560 },
    ],
    lg: [
      { width: 704, height: 512 },
      { width: 468, height: 640 },
      { width: 844, height: 512 },
      { width: 468, height: 640 },
    ],
    '2xl': [
      {
        width: 880,
        height: 640,
      },
      {
        width: 585,
        height: 800,
      },
      {
        width: 1056,
        height: 640,
      },
      {
        width: 585,
        height: 800,
      },
    ],
  });

  const sketchSizes = useBreakpointValue({
    base: [
      {
        width: 102,
        height: 142,
        sketchWidth: 68,
        bottom: -51,
        right: 500,
        transform: 'matrix(0.95, 0.31, -0.31, 0.95, 0, 0)',
      },
      null,
      {
        width: 136,
        height: 129,
        sketchWidth: 102,
        bottom: '120px',
        right: 800,
        transform: 'rotate(370deg)',
      },
      {
        width: 102,
        height: 119,
        sketchWidth: 102,
        bottom: -41,
        right: 500,
        transform: 'rotate(250deg)',
        sketchTransform: 'rotate(-40deg)',
      },
    ],
    md: [
      {
        width: 210,
        height: 294,
        sketchWidth: 140,
        bottom: -105,
        right: 500,
        transform: 'matrix(0.95, 0.31, -0.31, 0.95, 0, 0)',
      },
      null,
      {
        width: 280,
        height: 266,
        sketchWidth: 210,
        bottom: 216,
        right: 500,
        transform: 'rotate(352deg)',
      },
      {
        width: 240,
        height: 420,
        sketchWidth: 240,
        bottom: -105,
        right: 500,
        transform: 'rotate(250deg)',
        sketchTransform: 'rotate(-40deg)',
      },
    ],
    lg: [
      {
        width: 240,
        height: 336,
        sketchWidth: 160,
        bottom: -130,
        right: 500,
        transform: 'matrix(0.95, 0.31, -0.31, 0.95, 0, 0)',
      },
      null,
      {
        width: 380,
        height: 304,
        sketchWidth: 240,
        bottom: 300,
        right: 500,
        transform: 'rotate(5deg)',
        sketchTransform: 'rotate(-24deg) translate(20px, -20px)',
      },
      {
        width: 240,
        height: 420,
        sketchWidth: 240,
        bottom: -140,
        right: 500,
        transform: 'rotate(250deg)',
        sketchTransform: 'rotate(-40deg)',
      },
    ],
    '2xl': [
      {
        width: 300,
        height: 420,
        sketchWidth: 200,
        bottom: -150,
        right: 500,
        transform: 'matrix(0.95, 0.31, -0.31, 0.95, 0, 0)',
      },
      null,
      {
        width: 400,
        height: 380,
        sketchWidth: 300,
        bottom: 360,
        right: 500,
        transform: 'rotate(5deg)',
        sketchTransform: 'rotate(-24deg) translate(40px, -20px)',
      },
      {
        width: 300,
        height: 500,
        sketchWidth: 300,
        bottom: -130,
        right: 500,
        transform: 'rotate(250deg)',
        sketchTransform: 'rotate(-40deg)',
      },
    ],
  });

  const sketchs = [
    <SvgShape
      key={0}
      file="illu-coquillage-4-dark"
      width={`${sketchSizes?.[0]?.sketchWidth}px`}
    />,
    null,
    <SvgShape
      key={2}
      file="illu-coquillage-3-dark"
      width={`${sketchSizes?.[2]?.sketchWidth}px`}
      style={{
        transform: sketchSizes?.[2]?.sketchTransform,
      }}
    />,
    <SvgShape
      key={4}
      file="illu-homard-dark"
      width={`${sketchSizes?.[3]?.sketchWidth}px`}
      style={{
        transform: sketchSizes?.[3]?.sketchTransform,
      }}
    />,
  ];

  useEffect(() => {
    if (!ref.current || currentDevice === 'mobile') return;

    let sections = gsap.utils.toArray<HTMLElement>('.horizontal-item');
    let sectionsParallax = gsap.utils.toArray<HTMLElement>(
      '.horizontal-item-parallax',
    );

    const x = sections.reduce(
      (acc, el) => acc - el.getBoundingClientRect().width,
      0,
    );

    const size = x + window.innerWidth - 150;

    let scrollTween = gsap.to([...sections], {
      x: size,
      ease: 'none',
      scrollTrigger: {
        trigger: ref.current,
        // start: 'top top',
        pin: true,
        pinType: 'fixed',
        scrub: 0.1,
        end: '+=' + -size,
      },
    });

    let scrollTweenParallax = gsap.to(sectionsParallax, {
      scrollTrigger: {
        containerAnimation: scrollTween,
        trigger: '.horizontal-item-parallax',
        // scrub: true,
        // pin: true,
        scrub: 0,
        pinType: 'fixed',
        end: '+=' + -(size * 2),
      },
      x: (i, target) => {
        return -size * (target.dataset.speed || 0.25);
      },
      ease: 'none',
    });

    return () => {
      scrollTween.revert();
      scrollTweenParallax.revert();
    };
  }, [ref, currentDevice]);

  // const [cursorPosition, setCursorPosition] = useState<[number, number] | null>(
  //   null,
  // );

  // const [cursorType, setCursorType] = useState<'default' | 'pointer'>(
  //   'default',
  // );

  // const setupCursor = (e: MouseEvent<HTMLElement>) => {
  //   setCursorPosition([e.clientX, e.clientY]);
  //   setCursorType('default');
  // };

  // const releaseCursor = () => {
  //   setCursorPosition(null);
  // };

  // const swipe = (e: MouseEvent<HTMLElement>) => {
  //   if (e.clientX > window.innerWidth / 2) {
  //     window.scrollBy({
  //       top: 800,
  //       left: 0,
  //       behavior: 'smooth',
  //     });
  //   } else {
  //     window.scrollBy({
  //       top: -800,
  //       left: 0,
  //       behavior: 'smooth',
  //     });
  //   }
  // };

  return (
    <Box
      position="relative"
      // cursor={hasWysiwygEdit ? undefined : 'none'}
      // onMouseEnter={setupCursor}
      // onMouseLeave={releaseCursor}
      // onMouseMove={setupCursor}
    >
      <Box
        ref={ref}
        // alignItems="center"
        pt={20}
        pb={[40]}
        minH="100vh"
        className="horizontal-container"
        display="flex"
        flexDir={['column', null, 'row']}
        flexWrap="nowrap"
        overflow="hidden"
        alignItems="center"
        px={20}
        bgImage={`url(${BgSlideShow.src})`}
        bgAttachment="fixed"
        position="relative"
      >
        <ParallaxProvider
          scrollAxis="horizontal"
          scrollContainer={ref.current || undefined}
        >
          {slides?.map((slide, index) => {
            return (
              <SlideshowSlide
                key={index}
                page={page}
                component={component}
                slideIndex={index}
                mediaSize={mediaSize}
                sketchSizes={sketchSizes}
                sketch={sketchs[index]}
                // setCursorPosition={setCursorPosition}
                // setCursorType={setCursorType}
                hasWysiwygEdit={hasWysiwygEdit}
              />
            );
          })}
        </ParallaxProvider>
      </Box>
      {/* <Portal>
        <Box
          display={
            hasWysiwygEdit
              ? 'none'
              : ['none', null, cursorPosition ? 'flex' : 'none']
          }
          w="100px"
          h="100px"
          transform={`translate(-50%, -50%)`}
          pointerEvents="none"
          position="fixed"
          alignItems="center"
          justifyContent="center"
          left={cursorPosition?.[0] + 'px'}
          top={cursorPosition?.[1] + 'px'}
          zIndex={100}
        >
          <Box
            position="absolute"
            borderRadius="full"
            bgColor="brand.500"
            transition="transform 0.3s ease"
            transform={cursorType === 'pointer' ? 'scale(1.2)' : 'scale(1)'}
            w="100px"
            h="100px"
          ></Box>
          {cursorPosition && (
            <Box
              position="relative"
              top="+3px"
              animation="rotateReveal 0.3s ease"
            >
              <chakra.span
                fontSize="28px"
                color="white"
                className="icon-diadao-swipe"
              ></chakra.span>
            </Box>
          )}
        </Box>
      </Portal> */}
    </Box>
  );
};

interface SlideshowSlideProps {
  page: KustomPage;
  component: KustomPageComponent<CarouselComponentData>;
  slideIndex: number;
  mediaSize?: {
    width: number;
    height: number;
  }[];
  sketchSizes?: ({
    width: number;
    height: number;
    sketchWidth: number;
    bottom: number | string;
    right: number;
    transform: string;
    sketchTransform?: string;
  } | null)[];
  sketch?: React.ReactNode;
  // setCursorPosition: (position: [number, number] | null) => void;
  // setCursorType: (type: 'default' | 'pointer') => void;
  hasWysiwygEdit: boolean;
}

const SlideshowSlide = (props: SlideshowSlideProps) => {
  const {
    page,
    component,
    slideIndex,
    mediaSize,
    sketchSizes,
    sketch,
    // setCursorPosition,
    // setCursorType,
    hasWysiwygEdit,
  } = props;

  const [isWysiwygEditOpen, setIsWysiwygEditOpen] = useState(false);

  const closeWysiwygEdit = useCallback(() => {
    setIsWysiwygEditOpen(false);
  }, []);

  const theme = useTheme();

  const titleBind = useKustomData<KustomTranslatedString>(
    page,
    component,
    `data.slides.${slideIndex}.title`,
  );

  const mediaBind = useKustomData<KustomResponsiveMedia>(
    page,
    component,
    `data.slides.${slideIndex}.medias`,
  );

  const codeBind = useKustomData<CodeComponent>(
    page,
    component,
    `data.slides.${slideIndex}.atomicComponents.0`,
  );

  const currentDevice = useResponsiveMediasDevice();

  const linkProps = useNavigationKustomHref(codeBind?.value?.code);

  return (
    <Box
      zIndex={1}
      position="relative"
      className="horizontal-item"
      filter="drop-shadow(-8px 8px 8px #00000029)"
      display="flex"
      w={[
        mediaSize?.[slideIndex % 4]?.width!,
        null!,
        mediaSize?.[slideIndex % 4]?.width! + 1000,
      ]}
      h={mediaSize?.[slideIndex % 4]?.height!}
      flexDir={['column', null, 'row']}
      px={[null, null, 20]}
      my={10}
    >
      {sketch && (
        <Box
          zIndex={0}
          className="horizontal-item-parallax"
          data-speed={sketchSpeed[slideIndex]}
          position="absolute"
          width={sketchSizes?.[slideIndex]?.width}
          height={sketchSizes?.[slideIndex]?.height}
          bgColor="white"
          transform={sketchSizes?.[slideIndex]?.transform}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bottom={sketchSizes?.[slideIndex]?.bottom}
          right={sketchSizes?.[slideIndex]?.right}
          // right={sketchSizes?.[index]?.right}
          // boxShadow="-8px 8px 8px #00000029"
        >
          {sketch}
        </Box>
      )}
      <Box
        zIndex={1}
        position="relative"
        w={mediaSize?.[slideIndex % 4]?.width}
        h={mediaSize?.[slideIndex % 4]?.height}
        transform={slideTransforms[slideIndex]}
        role="group"
        // onMouseEnter={() => {
        //   setCursorType('pointer');
        // }}
        // onMouseLeave={(e) => {
        //   setCursorType('default');
        // }}
        // onMouseMove={(e) => {
        //   e.stopPropagation();
        //   setCursorType('pointer');
        //   setCursorPosition([e.clientX, e.clientY]);
        // }}
        // onClick={(e) => {
        //   e.stopPropagation();
        // }}
        {...getCSSAdminWysiwygItem({
          hasWysiwygEdit,
          isWysiwygEditOpen,
          setIsOpen: setIsWysiwygEditOpen,
        })}
      >
        <Link href={linkProps?.href || ''}>
          <Box
            w={mediaSize?.[slideIndex % 4]?.width}
            h={mediaSize?.[slideIndex % 4]?.height}
            overflow={'clip'}
          >
            <Box
              w={mediaSize?.[slideIndex % 4]?.width}
              h={mediaSize?.[slideIndex % 4]?.height}
              position="relative"
              flexShrink={0}
              transform="scale(1.15)"
              transition="all 0.3s ease"
              _groupHover={{
                transform: 'scale(1)',
              }}
            >
              <Box h="100%">
                <ResponsiveMedias
                  value={mediaBind.value}
                  currentDevice={currentDevice}
                  sizes={`${mediaSize?.[slideIndex % 4]?.width || undefined}`}
                  style={{
                    objectFit: 'cover',
                    borderRadius: theme.radii.sm,
                  }}
                  fill
                  width={undefined}
                  height={undefined}
                />
              </Box>
            </Box>
          </Box>
          <Box
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            display={hasWysiwygEdit ? 'none' : undefined}
            background="transparent linear-gradient(180deg, #00000000 0%, #020D4600 57%, #020D464D 100%) 0% 0% no-repeat padding-box"
          ></Box>
          <Box
            display={['unset', null, slideIndex % 2 ? 'flex' : null]}
            position="absolute"
            bottom={0}
          >
            <Box
              minW={['unset', null, '400px']}
              maxW={['100%', null, 'none']}
              pl={5}
            >
              <TranslatedString
                color="white"
                fontSize={['18px', null, '60px']}
                lineHeight={1}
                fontFamily="jhaSemilight"
                mb={4}
                {...titleBind}
                userSelect="none"
                isHeading={true}
                as="h2"
              />
            </Box>
          </Box>
          {hasWysiwygEdit && mediaBind.onChange && (
            <EditMediaModal
              isOpen={isWysiwygEditOpen}
              onClose={closeWysiwygEdit}
              value={mediaBind.value}
              onChange={mediaBind.onChange}
              triggerProps={{
                cursor: 'pointer',
                onClick: () => setIsWysiwygEditOpen((io) => !io),
              }}
            />
          )}
        </Link>
      </Box>
    </Box>
  );
};

export default React.memo(Slideshow);
