import { Box } from '@chakra-ui/react';
import { ChakraNestedProvider } from '../../components/ChakraNestedProvider';
import { ThemeProvider } from 'styled-components';
import adminTheme from '../theme';

interface AdminThemeProviderProps {
  children: React.ReactNode;
}

const AdminThemeProvider = ({ children }: AdminThemeProviderProps) => (
  <ChakraNestedProvider theme={adminTheme} cssVarsRoot=".kustomAdminUI">
    <ThemeProvider theme={adminTheme}>
      <Box
        className="kustomAdminUI"
        fontFamily="body"
        color="chakra-body-text"
        lineHeight="var(--chakra-lineHeights-base)"
      >
        {children}
      </Box>
    </ThemeProvider>
  </ChakraNestedProvider>
);

export default AdminThemeProvider;
