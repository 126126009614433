import React, { FunctionComponent } from 'react';

export function ClientComponent<P extends object>(
  Component: React.ComponentType<P>,
) {
  class HOC extends React.Component<P> {
    state = {
      isClient: false,
    };

    componentDidMount(): void {
      this.setState({ isClient: true });
    }
    render() {
      const { isClient } = this.state;

      return isClient ? <Component {...this.props} /> : null;
    }
  }

  return HOC;
}
