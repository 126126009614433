import { Box, Heading, Text, TextProps } from '@chakra-ui/react';
import LanguageContext, { LanguageContextKey } from '../contexts/language';
import React, { useContext, useState } from 'react';

// import EditTextModal from '../admin/components/EditTextModal';
import { KustomTranslatedString } from '../types';
import WrapIf from './atomics/WrapIf';
import dynamic from 'next/dynamic';
import getCSSAdminWysiwygItem from '../admin/helpers/getCSSAdminWysiwygItem';
import { useKustomSelector } from '../admin/hooks/useKustomSelector';

const DynamicEditTextModal = dynamic(
  () => import('../admin/components/EditTextModal'),
);

interface TranslatedStringProps
  extends Omit<TextProps, 'children' | 'onChange'> {
  value?: KustomTranslatedString;
  isHeading?: boolean;
  children?: KustomTranslatedString;
  onChange?: (value: KustomTranslatedString) => void;
}

const TranslatedString: React.FC<TranslatedStringProps> = (props) => {
  const { value: text, isHeading, children, onChange, ...textProps } = props;

  const TextComponent = isHeading ? Heading : Text;

  const { user } = useKustomSelector((state) => state.app);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasWysiwygEdit = !!(user && onChange);

  const language = useContext(LanguageContext);

  if (!text && !children) return null;

  const renderText = (text || children) as KustomTranslatedString;

  const lang = (language.currentLang || 'fr') as LanguageContextKey;
  const renderString = renderText[lang] || renderText.fr;

  return (
    <WrapIf
      condition={hasWysiwygEdit}
      wrapper={(children) => (
        <DynamicEditTextModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          value={renderString}
          onChange={(newValue) => {
            onChange?.({
              ...renderText,
              [lang]: newValue,
            });
          }}
        >
          {children}
        </DynamicEditTextModal>
      )}
    >
      <TextComponent
        {...textProps}
        {...getCSSAdminWysiwygItem({
          hasWysiwygEdit,
          isWysiwygEditOpen: isModalOpen,
          setIsOpen: setIsModalOpen,
        })}
      >
        {renderString}
      </TextComponent>
    </WrapIf>
  );
};

export default TranslatedString;
