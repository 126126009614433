import {
  Input as BaseInput,
  InputProps as BaseInputProps,
  Box,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  Textarea,
  TextareaProps,
  chakra,
} from '@chakra-ui/react';

import EyeIcon from '../../../icons/icon-eye.svg';
import styled from 'styled-components';
import { useState } from 'react';
import useTheme from '../../hooks/useTheme';

type Props = BaseInputProps & TextareaProps;
interface InputProps extends Props {
  label?: string;
  error?: boolean | string;
  isFullWidth?: boolean;
  isTextarea?: boolean;
  leftElement?: React.ReactNode;
  maxLengthTip?: number;
  inputRef?: React.Ref<HTMLInputElement>;
}

const Input = (props: InputProps) => {
  const {
    label,
    error,
    value,
    type,
    isTextarea,
    isFullWidth,
    maxLength,
    maxLengthTip,
    mt,
    mb,
    size,
    leftElement,
    inputRef,
    ...otherProps
  } = props;

  const [show, setShow] = useState(type !== 'password');
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const theme = useTheme();

  const toggleShow = () => setShow((s) => !s);

  // @ts-ignore
  const height = theme.components.Input?.sizes?.[size || 'xl']?.field?.h;

  const inputHeight = String(
    typeof height === 'string' ? height : theme.sizes[height],
  );

  return (
    <Wrapper
      $error={focused ? false : !!error}
      width={isFullWidth ? '100%' : otherProps.width}
      $hasValue={typeof value !== 'undefined' && value !== ''}
      mt={mt}
      mb={mb}
      position="relative"
      $inputBgColor={
        (otherProps.bg || otherProps.bgColor || '#F6F7FB') as string
      }
    >
      <InputGroup size={size || 'xl'}>
        {leftElement && (
          <InputLeftElement pointerEvents="none">
            {leftElement}
          </InputLeftElement>
        )}
        {isTextarea ? (
          <Textarea
            onFocus={onFocus}
            onBlur={onBlur}
            isInvalid={!!value && !!error}
            bg="backgroundSecondary"
            border="none"
            value={value}
            borderRadius="base"
            px={4}
            py={4}
            {...otherProps}
          />
        ) : (
          <BaseInput
            className="UIInput-Input"
            ref={inputRef}
            onFocus={onFocus}
            onBlur={onBlur}
            isInvalid={!!value && !!error}
            bg="backgroundSecondary"
            border="none"
            type={show ? 'text' : type}
            value={value}
            maxLength={maxLength}
            _placeholder={{
              color: 'gray.500',
            }}
            {...(leftElement && { pl: 9 })}
            {...otherProps}
            {...(error && { borderColor: 'red.500', border: '1px solid' })}
          />
        )}
        {label && (
          <Label className="UIInput-Label" $inputHeight={inputHeight}>
            {label}
          </Label>
        )}
        {type === 'password' && (
          <InputRightElement width="4.5rem" h={inputHeight}>
            <Box
              onClick={toggleShow}
              opacity={show ? 1 : 0.3}
              p={4}
              _hover={{ opacity: 0.6, cursor: 'pointer' }}
            >
              <EyeIcon />
            </Box>
          </InputRightElement>
        )}
      </InputGroup>
      {maxLength && (
        <Box ml={2} mt={'px'}>
          <Text color="gray.500" fontSize="12px">
            {String(value).length} / {maxLength}
          </Text>
        </Box>
      )}
      {maxLengthTip && (
        <Box ml={2} mt={'px'}>
          <Text color="gray.500" fontSize="12px">
            {String(value).length} ({maxLengthTip} caractères max recommandé)
          </Text>
        </Box>
      )}
    </Wrapper>
  );
};

const ElementHideOutline = ($bgColor: string) => `
  &::before {
    content: '-';
    color: white;
    display: block;
    background: ${$bgColor};
    z-index: -1;
    position: absolute;
    height: 13px;
    right: 0;
    left: 0;
    bottom: 0;
  }
`;

const Wrapper = chakra(styled.div<{
  $hasValue: boolean;
  $error: boolean;
  $inputBgColor: string;
}>`
  position: relative;

  &:focus-within label {
    transform: translate(${({ theme }) => theme.space[3]}, -9px) scale(0.7);
    color: ${({ theme, $error }) =>
      // @ts-ignore
      $error ? theme.colors.red['500'] : theme.colors.brand['500']};

    ${({ $inputBgColor }) => ElementHideOutline($inputBgColor)}
  }

  ${({ $hasValue, $error, theme, $inputBgColor }) =>
    $hasValue
      ? `
      label {
        transform: translate(${theme.space[3]}, -9px) scale(0.7);
        ${ElementHideOutline($inputBgColor)}
        
        ${
          $error
            ? // @ts-ignore
              `color: ${theme.colors.red['500']}`
            : ``
        }
      }
`
      : ``}
`);

const Label = styled.label<{ $inputHeight: string }>`
  position: absolute;
  pointer-events: none;
  transform: translate(
      ${({ theme }) => theme.space[3]},
      calc((${({ $inputHeight }) => $inputHeight} - 24px) * 0.5)
    )
    scale(1);
  padding: 0 ${({ theme }) => theme.space[1]};
  transform-origin: top left;
  border-radius: ${({ theme }) => theme.radii.base};
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  left: 0;
  z-index: 999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) =>
    // @ts-ignore
    theme.colors.gray[700]};
`;

export default Input;
