import { createContext, useEffect, useState } from 'react';

import useIsMounted from '@/hooks/useIsMounted';

type Window = typeof window & {
  axeptioKustomChoices?: {};
  openAxeptioCookies?: () => void;
};

export type Consents = { [key: string]: boolean | string | {} } | {};

type CookiesConsentContextType = {
  consents: Consents;
  getWinConsents: () => void;
  setConsents: (consents: Consents) => void;
  openConsent: () => void;
  getConsent: (key: string) => boolean | undefined;
  getConsents: () => Consents;
};

const CookiesConsentContext = createContext<CookiesConsentContextType>({
  consents: {},
  getWinConsents: () => {},
  setConsents: () => {},
  openConsent: () => {},
  getConsent: () => false,
  getConsents: () => ({}),
});

type CookiesConsentContextProviderProps = {
  children: React.ReactNode;
};

export const CookiesConsentContextProvider = ({
  children,
}: CookiesConsentContextProviderProps) => {
  const isMounted = useIsMounted();

  const [consents, setConsents] = useState<Consents>({});

  const _window: Window = isMounted ? window : ({} as Window);

  const openConsent = () => {
    const open: () => void = _window.openAxeptioCookies || (() => {});
    if (open) open();
  };

  const getWinConsents = () => {
    const choices: {
      [key: string]: boolean;
    } = _window.axeptioKustomChoices || {};

    setConsents(choices);
  };

  const getConsent = (key: string) => {
    const choices: {
      [key: string]: boolean;
    } = _window.axeptioKustomChoices || {};

    const consent: boolean | undefined =
      choices && typeof choices === 'object' && key in choices
        ? choices[key]
        : false;

    return consent;
  };

  const getConsents = () => {
    const choices: {
      [key: string]: boolean;
    } = _window.axeptioKustomChoices || {};

    return choices;
  };

  return (
    <CookiesConsentContext.Provider
      value={{
        consents,
        setConsents,
        getWinConsents,
        openConsent,
        getConsent,
        getConsents,
      }}
    >
      {children}
    </CookiesConsentContext.Provider>
  );
};

export default CookiesConsentContext;
