export const SITE_URL = 'https://www.hotel-des-leselmes.com';

export const ESTABLISHMENT_ID = '646a6ddc62ccd7c8f1291b5a';

export const KUSTOM_API_KEY = '5bef257c-ee5c-4963-a740-4bdfa93e7ba5';

export const SEND_MAIL_TO = 'contact@hotel-des-elmes.com';

export const KUSTOM_URL = 'https://kustomcms.fr';

export const KUSTOM_CLIENT_URL = 'https://kustomcms.fr';

export const KUSTOM_SERVER_URL = 'https://server.kustomcms.fr';
// export const KUSTOM_SERVER_URL = 'https://server2.kustomcms.fr';
// export const KUSTOM_SERVER_URL = 'http://185:3030';
// export const KUSTOM_SERVER_URL = 'http://localhost:3031';
