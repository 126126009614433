import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Spinner,
  Text,
  chakra,
  useTheme,
} from '@chakra-ui/react';
import { FormEvent, useEffect, useRef, useState } from 'react';

import AdminThemeProvider from './AdminThemeProvider';
import Input from './atomics/Input';
import { KustomPage } from '../../types';
import Link from 'next/link';
import LoginLockLogo from '../../icons/login-lock.svg';
import { PinInput } from 'react-input-pin-code';
import { appActions } from '../features/app/slice';
import { mediasActions } from '../features/medias/slice';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useKustomSelector } from '../hooks/useKustomSelector';
import useRefCallback from '../hooks/useRefCallback';
import { useRouter } from 'next/router';
import useTranslations from '../hooks/useTranslations';

interface KustomAuthModalProps {
  establishmentId: string;
  clientUrl: string;
  pages: KustomPage[];
}

const TOKEN_FIELDS = 6;

const $kustomAdminViewClassname = 'kustomAdminView';

export const $kustomAdminBarHeightVarName = '--kustom-admin-bar-height';

export const KustomAuthModal = (props: KustomAuthModalProps) => {
  const { establishmentId, clientUrl, pages } = props;
  const router = useRouter();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const t = useTranslations();

  const {
    user,
    error,
    credentialsCache,
    status: appStatus,
  } = useKustomSelector((state) => state.app);

  const [isPending, setIsPending] = useState(false);

  const [displayAuth, setDisplayAuth] = useState(false);
  const [queriedAuth, setQueriedAuth] = useState(false);

  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: '',
  });

  const [modalPage, setModalPage] = useState(
    appStatus === 'MISSING_TOKEN' ? 'ASK_TOKEN' : 'LOGIN',
  );

  const adminBarRef = useRefCallback<HTMLDivElement>(null, (node) => {
    const barHeight = node?.getBoundingClientRect().height || 0;
    document.documentElement.style.setProperty(
      $kustomAdminBarHeightVarName,
      `${barHeight}px`,
    );
  });

  useEffect(() => {
    if (appStatus === 'MISSING_TOKEN') {
      setModalPage('ASK_TOKEN');
    } else {
      setModalPage('LOGIN');
    }
  }, [appStatus]);

  const [token, setToken] = useState(['', '', '', '', '', '']);

  const displayedError = error === 'Failed to fetch' ? undefined : error;

  // onLogin
  useEffect(() => {
    if (user && establishmentId) {
      if (document) document.body.classList.add($kustomAdminViewClassname);

      dispatch(
        appActions.updateCurrentEstablishment({
          establishmentId,
        }),
      );

      dispatch(mediasActions.getEstablishmentMedias())
        .unwrap()
        .then(() => {
          dispatch(mediasActions.computeMediasUsage({ pages }));
        });
    } else {
      if (document) document.body.classList.remove($kustomAdminViewClassname);
      // unset var
      document.documentElement.style.removeProperty(
        $kustomAdminBarHeightVarName,
      );
    }
  }, [!!user]);

  useEffect(() => {
    if (typeof router.query.admin !== 'undefined') {
      setDisplayAuth(true);
      setQueriedAuth(true);
    }
  }, [router.query.admin]);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    dispatch(appActions.relogin())
      .unwrap()
      .then(() => {
        setIsReady(true);
      });
  }, []);

  useEffect(() => {
    if (isPending && error) {
      setIsPending(false);
    }
  }, [isPending, error]);

  useEffect(() => {
    setIsPending(false);
  }, [appStatus, user]);

  const login = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const action = appActions.login({
      email: loginFormData.email,
      password: loginFormData.password,
    });
    setIsPending(true);
    dispatch(action);
  };

  const logout = async () => {
    dispatch(appActions.logout());
  };

  useEffect(() => {
    const tokenStr = token.reduce((acc, v) => acc + v, '');
    if (tokenStr.length === TOKEN_FIELDS) {
      setIsPending(true);
      dispatch(
        appActions.login({
          useCache: true,
          token: tokenStr,
        }),
      );
    }
  }, [token, dispatch]);

  return (
    <>
      {queriedAuth && isReady && !user && (
        <Portal>
          <Modal isOpen={displayAuth} onClose={() => setDisplayAuth(false)}>
            <ModalOverlay />
            <ModalContent>
              <AdminThemeProvider>
                <ModalCloseButton />
                <ModalBody py={5} bgColor="accent.100">
                  {isPending && <Spinner />}
                  {
                    {
                      LOGIN: (
                        <Box>
                          <form onSubmit={login}>
                            <Center mt={10} mb={14}>
                              <img
                                src={'/assets/logo-kustom-light.svg'}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  maxWidth: '200px',
                                  objectFit: 'contain',
                                }}
                                alt=""
                              />
                            </Center>
                            <Heading
                              textStyle="heading"
                              as="h1"
                              size="md"
                              marginBottom={3}
                            >
                              Connexion
                            </Heading>
                            <Input
                              isInvalid={!!error}
                              marginBottom={7}
                              label={'Adresse mail'}
                              value={loginFormData.email}
                              onChange={(e) =>
                                setLoginFormData((fd) => ({
                                  ...fd,
                                  email: e.target.value,
                                }))
                              }
                            />
                            <Input
                              isInvalid={!!error}
                              marginBottom={3}
                              label="Mot de passe"
                              type="password"
                              value={loginFormData.password}
                              onChange={(e) =>
                                setLoginFormData((fd) => ({
                                  ...fd,
                                  password: e.target.value,
                                }))
                              }
                            />
                            <Button
                              mt={4}
                              marginBottom={3}
                              w="100%"
                              // onClick={login}
                              // onClick={() => console.log('login')}
                              isLoading={isPending}
                              colorScheme="brand"
                              size="xl"
                              type="submit"
                            >
                              LOGIN
                            </Button>
                            <Center>
                              <Link
                                href={clientUrl + '/auth/login/askreset'}
                                target="_blank"
                              >
                                <Button variant="link" colorScheme="brand">
                                  MOT DE PASSE OUBLIÉ ?
                                </Button>
                              </Link>
                            </Center>
                          </form>
                        </Box>
                      ),
                      ASK_TOKEN: (
                        <>
                          <Center mt={20} flexDir={'column'}>
                            <LoginLockLogo width={100} />
                            <Heading
                              mt={8}
                              mb={16}
                              textStyle="heading"
                              textAlign={'center'}
                              size="md"
                              as="h1"
                            >
                              Saisissez le code envoyé à l&apos;adresse{' '}
                              {hashEmail(credentialsCache?.email!)}
                            </Heading>
                          </Center>
                          <Box mb={10}>
                            <PinInput
                              name={'token'}
                              inputMode={'numeric'}
                              type="number"
                              values={token}
                              placeholder=""
                              onChange={(_, __, values) => {
                                dispatch(appActions.clearError());
                                setToken(values);
                              }}
                              containerStyle={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                              }}
                              inputStyle={{
                                width: '40px',
                                borderRadius: `${theme.radii.md}`,
                                fontSize: '32px',
                                textAlign: 'center',
                                height: '70px',
                                backgroundColor: theme.colors
                                  .backgroundSecondary as string,
                                border: 'none',
                              }}
                              // @ts-ignore
                              focusBorderColor={theme.colors.brand['500']}
                              // @ts-ignore
                              errorBorderColor={theme.colors.red['500']}
                              validate={
                                error
                                  ? 'abcdef'
                                  : token.reduce((acc, v) => acc + v, '')
                              }
                            />
                          </Box>
                        </>
                      ),
                    }[modalPage]
                  }
                  {displayedError && (
                    <chakra.p px={5} fontSize={12} color="red.500">
                      {t(displayedError)}
                    </chakra.p>
                  )}
                  <Center flexDir="column">
                    <chakra.p mt={8} mb={1} fontSize={12} fontWeight="bold">
                      AGENCE DIADAO
                    </chakra.p>
                    <chakra.p fontSize={12}>
                      T. +33(0)4 67 40 22 73 / support@diadao.fr
                    </chakra.p>
                  </Center>
                </ModalBody>
              </AdminThemeProvider>
            </ModalContent>
          </Modal>
        </Portal>
      )}
      {/* Bottom Admin UI Bar */}
      {user && (
        <Portal>
          <AdminThemeProvider>
            <Box
              ref={adminBarRef}
              shadow="lg"
              px={4}
              py={1.5}
              position="fixed"
              top={0}
              right={0}
              left={0}
              zIndex={9999}
              display="flex"
              bgColor="white"
              alignItems="center"
              justifyContent={'space-between'}
            >
              <Box display="flex" alignItems="center">
                <Text fontWeight="bold" mr={4}>
                  Kustom Admin
                </Text>
                <Text fontSize={'14px'}>{user.email}</Text>
              </Box>
              <Box>
                <Button
                  colorScheme="brand"
                  fontSize="13px"
                  size="sm"
                  onClick={logout}
                  py={3}
                >
                  Déconnexion
                </Button>
              </Box>
            </Box>
          </AdminThemeProvider>
        </Portal>
      )}
    </>
  );
};

function hashEmail(email: string = '') {
  const [name = '', domain = ''] = email.split('@');

  const newName = name
    .split('')
    .map((char, index) =>
      index === 0 || index === name.length - 1 ? char : '*',
    )
    .join('');

  return `${newName}@${domain}`;
}

export default KustomAuthModal;
