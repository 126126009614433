import {
  KustomPageComponentViewProps,
  TextComponentData,
} from '@/lib/kustomcms-sdk/lib/types';

import { Box } from '@chakra-ui/react';
import React from 'react';
import RichText from '@/lib/kustomcms-sdk/lib/components/RichText';
import useKustomData from '@/lib/kustomcms-sdk/lib/hooks/useKustomData';

const RoomDescription: React.FC<
  KustomPageComponentViewProps<TextComponentData>
> = (props) => {
  const { component } = props;

  const descBind = useKustomData(props.page, component, 'data.text');

  return (
    <Box pt={[8, null, 10, null, null, 20]}>
      <RichText
        color="brand.500"
        fontFamily="jhaSemilight"
        fontSize={['18px', null, '23px', null, null, '28px']}
        {...descBind}
        as="p"
      />
    </Box>
  );
};

export default RoomDescription;
