import { KustomMedia, KustomResponsiveMedia } from '../../types';

// import { KustomClient } from '../..';
import { LanguageContextKey } from '../../contexts/language';

const getKustomMediaData = (
  media: KustomMedia,
  currentLang: LanguageContextKey,
  // kustomClient?: KustomClient,
) => {
  const filename =
    media.metadata.keys?.[currentLang] ||
    media.metadata.keys?.fr ||
    media.metadata.key;

  // const url =
  //   kustomClient?.API_URL +
  //   `/medias/${media.mutableMetadata.bucketName}/${filename}`;

  const url = media.url;

  const alt =
    media.metadata.alts?.[currentLang] ||
    media.mutableMetadata?.alts?.[currentLang] ||
    media.metadata.alts?.fr ||
    media.mutableMetadata?.alts?.fr;

  return { url, alt };
};

export default getKustomMediaData;
